import  { useState, useEffect } from "react";
import {
    Typography,
    Grid,
   Button
} from "@mui/material";
import {useParams} from "react-router";
import {AAR, Conduct} from "../../interfaces/interfaces";
import WelcomeAARViewBanner from "../../components/welcomes/aar/WelcomeAARViewBanner";
import AARComponent from "../../components/scenario/AARComponent";
import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";

interface Params {
    companyId: string;
    scenarioId: string;
    simulationId: string;
}
const AfterActionReport = () => {
    // @ts-ignore
    const { companyId,scenarioId,simulationId } = useParams<Params>();
    const [aar, setAAR] = useState<AAR>();
    const [isAARLoading, setIsAARLoading] = useState<boolean>(false);
    const token = localStorage.getItem('token');
    const [conduct , setConduct] = useState<Conduct>();

    const handleGenAAR = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/simulation/genAAR/' + token + '/' + companyId + '/' + scenarioId + '/' + simulationId);
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsAARLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/simulation/getAAR/' + token + '/' + companyId + '/' + scenarioId + '/' + simulationId);
                const data = await response.json();
                setAAR(data);
                setIsAARLoading(false);
            } catch (error) {
                console.error("Error updating scenario:", error);
            }
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/gettabletop/' + token + '/' + companyId + '/' + scenarioId + '/' + simulationId);
                const data = await response.json();
                setConduct(data);
            } catch (error) {
                console.error("Error updating scenario:", error);
            }
        };
        fetchData();
    }, []);

  return (
      <Grid container rowSpacing={4.5} columnSpacing={3}>
          <Grid item xs={12}>
            <WelcomeAARViewBanner  aar={aar}  />
          </Grid>
                {!aar && <Typography variant="h6">No AAR Generated Yet - <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleGenAAR}
                >
                    Generate AAR
                </Button></Typography>}
          {isAARLoading &&
              <QuickLoaderComponent loadBanner={"Generating AAR....."} />
          }
          <Grid item xs={12}>
            {aar && conduct &&
                <AARComponent aar={aar} companyId={companyId} scenarioId={scenarioId}
                              conduct={conduct} conductId={simulationId} editable={false}/>
            }
          </Grid>
    </Grid>
  );
};

export default AfterActionReport;