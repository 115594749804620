import {useContext, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import {APP_DEFAULT_PATH, PORTAL_DEFAULT_PATH} from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import JWTContext from "../../contexts/JWTContext";

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const jwtContext = useContext(JWTContext);
  const user = jwtContext ? jwtContext.user : null;
//    console.log("In logo: "+user?.role);
  const defaultPath = user?.role != 'Designer' ? PORTAL_DEFAULT_PATH : APP_DEFAULT_PATH;

  //START HERE FOR NAVIGATION AND USER PERMISSIONS

  useEffect(() => {
    if (isLoggedIn) {
      navigate(location?.state?.from ? location?.state?.from : defaultPath, {
        state: {
          from: ''
        },
        replace: true
      });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;
