import React from 'react';
import {Stack, Typography} from "@mui/material";
import { Timer} from "@mui/icons-material";


interface TimeElapsedComponentProps {
    timeText: string;
}

const TimeElapsedComponent: React.FC<TimeElapsedComponentProps> = ({ timeText }) => {
    if (timeText) {
        return (
                <Typography variant={"caption"} color={"textSecondary"}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}><Timer/>&nbsp;{timeText}  </Stack>
                    </Typography>
        );
    }
    return null;
};

export default TimeElapsedComponent;