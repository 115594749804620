import {Button, Chip, List, ListItem, ListItemText} from '@mui/material';
import { UserLinkKey } from '../../interfaces/interfaces';
import {DeleteForeverOutlined} from "@mui/icons-material";

interface UserListProps {
    userList: UserLinkKey[];
    onRemoveUser: (user: UserLinkKey) => void;
}

const UserList: React.FC<UserListProps> = ({ userList, onRemoveUser }) => {
    return (
        <List>
            {userList.map((user, index) => (
                <ListItem key={index}>
                    <ListItemText
                        primary={`${user.username} (${user.userEmail})`}
                        secondary={`Role: ${user.userRole}${user.lastLoggedIn ? `, Last Logged In: ${user.lastLoggedIn}` : ''}`}                    />
                    {user.isMe && <Chip variant={'outlined'} color={"primary"} label={'You'} />}
                    {!user.isMe &&
                        <Button variant="contained" color="error" onClick={() => onRemoveUser(user)}>
                           <DeleteForeverOutlined/> Remove
                        </Button>
                    }
                </ListItem>
            ))}
        </List>
    );
};

export default UserList;