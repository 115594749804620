import {useEffect, useRef, useState} from 'react';

// material-ui
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack, TextField,
  Typography
} from '@mui/material';

// project imports

// types
import {Annotation, GameMessage} from "../../interfaces/interfaces";
import Scrollbar from "react-scrollbars-custom";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ReactMarkdown from 'react-markdown';
import ChatBubble from "../ChatBubble";

interface ChatHistoryProps {
  chat: GameMessage[];
  onAnnotation?: (annotation: Annotation) => void;
}

const SimulationChatHistory = ({  chat, onAnnotation }: ChatHistoryProps) => {
  const bottomRef = useRef(null);
  const [openAnnotateDialog, setOpenAnnotateDialog] = useState(false);
  const [annotateTextValue, setAnnotateTextValue] = useState("");
  const [annotationTargetValue, setAnnotationTargetValue] = useState("");

  function handleAnnotate(id: string) {
    setOpenAnnotateDialog(true);
    console.log("Annotate: "+id);
    setAnnotationTargetValue(id);
    setAnnotateTextValue("");
  };

  const handleSaveAnnotation = async () => {
    setOpenAnnotateDialog(false);
    onAnnotation && onAnnotation({
      companyId: "",
      gameMessageId: annotationTargetValue,
      madeBy: "",
      scenarioId: "",
      simulationId: "",
      annotationId: "",
      text: annotateTextValue });
    setAnnotateTextValue("");
    setAnnotationTargetValue("");
  }

  const closeAnnotateDialog = () => {
    setOpenAnnotateDialog(false);
    setAnnotateTextValue("");
    setAnnotationTargetValue("");
  };

  useEffect(() => {
    // @ts-ignore
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [chat]);

  return (
      <Scrollbar style={{ width: '100%', height: '800px' }}>
      <Grid container spacing={2.5}>
      {chat && chat.map((history, index) => (
         <>
           {/*{history.messageType && history.messageType !== "MSG_INFO_SNAPSHOT" &&*/}
                <>
                  {history.gameQuestion && history.gameQuestion != "..." && history.messageType && history.messageType !== "MSG_INFO_SNAPSHOT" &&
                      <Grid item xs={12} key={index}>
                        <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                        <ChatBubble variant="elevation" colorType={"primary"}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant={"h6"}>
                              <ReactMarkdown>{history.gameQuestion}</ReactMarkdown>
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography fontSize={"smaller"} variant={"caption"} color={"textSecondary"} align={"right"}>You</Typography>
                          </Grid>
                        </Grid>
                      </ChatBubble>
                        </Stack>
                      </Grid>
                  }
                  <Grid item xs={12} key={index}>
                    <Grid container>
                      <Grid item xs={12}>
                        {history.answer &&
                            <ChatBubble variant="elevation" colorType={"paper"}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant={"h6"}>
                                    <ReactMarkdown>{history.answer}</ReactMarkdown>
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography fontSize={"smaller"} variant={"caption"} color={"textSecondary"} align={"right"}>AI Assistant</Typography>
                                </Grid>
                              </Grid>
                            </ChatBubble>
                        }
                        {onAnnotation &&
                            <Grid item xs={1}>
                              <FeedbackIcon onClick={() => handleAnnotate(history.id)}/>
                            </Grid>
                        }
                      </Grid>
                      {onAnnotation && history.annotations && history.annotations.map((annotation, index) => (
                          <Grid item xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
                              <Typography variant="caption" color={"textSecondary"} sx={{overflowWrap: 'anywhere'}}>
                                {annotation.text}
                              </Typography>
                            </Stack>
                          </Grid>
                      ))}
                      {history.actions &&
                          <>
                            <br/>
                            <Grid item xs={12}>
                              <Typography variant="h6" color={"textPrimary"}
                                          sx={{overflowWrap: 'anywhere'}}><u>Actions</u></Typography>
                            </Grid>
                          </>
                      }
                      {history.actions && history.actions.map((annotation, index) => (
                          <>
                            <Grid item xs={12}>
                              <Stack direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Typography variant="caption" color={"textSecondary"} sx={{overflowWrap: 'anywhere'}}>
                                  {annotation}
                                </Typography>
                              </Stack>
                            </Grid>
                          </>
                      ))}
                      {history.actionResultDetails &&
                          <>
                            <br/>
                            <Grid item xs={12}>
                              <Typography variant="h6" color={"textPrimary"}
                                          sx={{overflowWrap: 'anywhere'}}><u>Action Details</u></Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption" color={"textSecondary"}
                                          sx={{overflowWrap: 'anywhere'}}>{history.actionResultDetails}</Typography>
                            </Grid>
                          </>
                      }
                      {history.actionMockData &&
                          <>
                            <br/>
                            <Grid item xs={12}>
                              <Typography variant="h6" color={"textPrimary"}
                                          sx={{overflowWrap: 'anywhere'}}><u>Mock Data</u></Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption" color={"textSecondary"}
                                          sx={{overflowWrap: 'anywhere'}}>{history.actionMockData}</Typography>
                            </Grid>
                          </>
                      }
                      {history.sentByName && (
                          <Grid item xs={12} sx={{mt: 1}}>
                            <Typography align="right" variant="subtitle2" color="textSecondary">
                              {(history.sentByName)}
                            </Typography>
                          </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
            {/*}*/}
          </>
      ))}
      <Grid item ref={bottomRef} />
    </Grid>
        <Dialog open={openAnnotateDialog} fullWidth={true} onClose={closeAnnotateDialog}>
          <DialogTitle>Add Annotation</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                  name="text"
                  placeholder="Annotate...."
                  fullWidth={true}
                  variant="outlined"
                  value={annotateTextValue}
                  onChange={(e) => setAnnotateTextValue(e.target.value)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAnnotateDialog}>Cancel</Button>
            <Button onClick={handleSaveAnnotation} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Scrollbar>
  );
};

export default SimulationChatHistory;
