import { lazy } from 'react';

// project import
import LandingPageLayout from 'layout/LandingPage';
import Loadable from 'components/Loadable';

const LandingPageConductViewPage = Loadable(lazy(() => import('pages/landing-pages/landing-page-conduct-view-page')));

// ==============================|| AUTH ROUTING ||============================== //

const LandingPageRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <LandingPageLayout />,
      children: [
        {
          path: 'landing-conduct/:code',
          element: <LandingPageConductViewPage />
        }
      ]
    }
  ]
};

export default LandingPageRoutes;
