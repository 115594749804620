import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Tooltip} from "@mui/material";
import { Inject } from "../../interfaces/interfaces";

interface EditInjectDialogProps {
    inject: Inject;
    open: boolean;
    onClose: () => void;
    onSave: (inject: Inject) => void;
}

const EditInjectDialog: React.FC<EditInjectDialogProps> = ({ inject, open, onClose, onSave }) => {
    const [editedInject, setEditedInject] = useState<Inject>(inject);

    const handleSave = async (event: React.FormEvent) => {
        event.preventDefault();
        onSave(editedInject);
    };
    useEffect(() => {
        setEditedInject(inject);
    }, [inject]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>Edit & Post Inject</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSave}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Inject Description"
                        fullWidth
                        multiline={true}
                        value={editedInject.description}
                        onChange={(e) => setEditedInject({ ...editedInject, description: e.target.value })}
                    />

                    <DialogActions>
                        <Button variant={"outlined"} onClick={onClose}>Cancel</Button>
                        <Tooltip title={"Post the Inject to the current step action"}>
                        <Button variant={"outlined"} type="submit">Post</Button>
                        </Tooltip>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default EditInjectDialog;