// material-ui
// import { useTheme } from '@mui/material/styles';

import {useOrganization} from "../../hooks/useOrganization";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'types/config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  // const theme = useTheme();
	const organization = useOrganization();

  return (
	 <>
		 {organization && organization.organization?.logoSmallLocation ?
			  <img src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${organization.organization.logoSmallLocation}`} alt="image" style={{ height: '35px', width:'35px'}}/>
		  :
	      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
				 width="100%" viewBox="0 0 164 138" enable-background="new 0 0 164 138" >
			<path fill="#232323"
			 opacity="1.000000" stroke="none"
				d="
			M104.000000,139.000000
				C69.333336,139.000000 35.166676,139.000000 1.000009,139.000000
				C1.000006,93.000008 1.000006,47.000019 1.000003,1.000021
				C55.666653,1.000014 110.333305,1.000014 164.999969,1.000007
				C164.999969,46.999981 164.999969,92.999962 164.999985,138.999969
				C144.833328,139.000000 124.666664,139.000000 104.000000,139.000000
			M148.358856,115.392731
				C148.527618,84.630875 148.696960,53.869022 148.861145,23.107140
				C148.864685,22.442961 148.862930,21.771019 148.772308,21.115322
				C148.157852,16.669649 146.010025,13.268922 141.727829,11.620544
				C137.532639,10.005656 133.541946,10.558552 130.073059,13.550647
				C127.681664,15.613337 125.374237,17.774355 123.051888,19.915928
				C109.626778,32.295967 96.208389,44.683300 82.455360,57.048344
				C82.455360,57.048344 82.130280,57.120304 81.854492,56.482704
				C74.345871,49.668663 66.834709,42.857418 59.329311,36.039818
				C51.208469,28.663170 43.112087,21.259453 34.966610,13.910136
				C31.279879,10.583758 27.031187,9.824390 22.495899,11.865355
				C18.352720,13.729862 15.976866,17.058313 15.991264,21.648891
				C16.044947,38.763588 16.221169,55.877899 16.140944,73.888779
				C15.196467,100.844902 30.634935,116.954811 57.417294,116.954430
				C85.881859,116.954018 114.346451,116.963814 142.810928,116.910370
				C144.513962,116.907173 146.216019,116.383141 148.358856,115.392731
			z"/>
			<path fill="#FEFDFD" opacity="1.000000" stroke="none"
				d="
			M82.130280,57.120304
				C82.130280,57.120304 82.455360,57.048344 82.775391,57.362740
				C95.962387,69.480797 108.767021,81.353394 121.718254,93.063866
				C130.343460,100.862747 139.177795,108.430336 147.918533,116.101456
				C146.216019,116.383141 144.513962,116.907173 142.810928,116.910370
				C114.346451,116.963814 85.881859,116.954018 57.417294,116.954430
				C30.634935,116.954811 15.196467,100.844902 16.374254,73.762321
				C21.668648,88.782745 38.313656,94.892410 50.490631,85.883270
				C54.434788,82.965187 57.916676,79.413620 61.551239,76.087601
				C68.433372,69.789703 75.272995,63.445354 82.130280,57.120304
			z"/>
			<path fill="#F4CC4D" opacity="1.000000" stroke="none"
				d="
			M148.138702,115.747093
				C139.177795,108.430336 130.343460,100.862747 121.718254,93.063866
				C108.767021,81.353394 95.962387,69.480797 82.941574,57.372650
				C96.208389,44.683300 109.626778,32.295967 123.051888,19.915928
				C125.374237,17.774355 127.681664,15.613337 130.073059,13.550647
				C133.541946,10.558552 137.532639,10.005656 141.727829,11.620544
				C146.010025,13.268922 148.157852,16.669649 148.772308,21.115322
				C148.862930,21.771019 148.864685,22.442961 148.861145,23.107140
				C148.696960,53.869022 148.527618,84.630875 148.138702,115.747093
			z"/>
			<path fill="#EE8734" opacity="1.000000" stroke="none"
				d="
			M81.992386,56.801506
				C75.272995,63.445354 68.433372,69.789703 61.551239,76.087601
				C57.916676,79.413620 54.434788,82.965187 50.490631,85.883270
				C38.313656,94.892410 21.668648,88.782745 16.479485,73.314110
				C16.221169,55.877899 16.044947,38.763588 15.991264,21.648891
				C15.976866,17.058313 18.352720,13.729862 22.495899,11.865355
				C27.031187,9.824390 31.279879,10.583758 34.966610,13.910136
				C43.112087,21.259453 51.208469,28.663170 59.329311,36.039818
				C66.834709,42.857418 74.345871,49.668663 81.992386,56.801506
			z"/>
			</svg>
	  }

			 </>  );
};

export default LogoIcon;


/**
 * if you want to use image instead of svg uncomment following, and comment out <svg> element.
 *
 * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="Mantis" width="100" />
 *
 */
// <svg width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path
//     d="M7.27577 57.2242L17.5616 46.9384L17.5724 46.9276H36.9234L29.2238 54.6273L27.2358 56.6152L19.3511 64.5L20.3276 65.4792L64.5 109.649L109.649 64.5L101.761 56.6152L101.206 56.0572L92.0766 46.9276H111.428L111.438 46.9384L119.5 55.0002L129 64.5L64.5 129L0 64.5L7.27577 57.2242ZM64.5 0L101.77 37.2695H82.4185L64.5 19.3511L46.5816 37.2695H27.2305L64.5 0Z"
//     fill={theme.palette.primary.dark}
//   />
//   <path
//     d="M19.3509 64.5L27.2357 56.6152L29.2236 54.6273L21.5267 46.9276H17.5722L17.5615 46.9384L7.27561 57.2242L17.1483 67.0487L19.3509 64.5Z"
//     fill="url(#paint0_linear)"
//   />
//   <path
//     d="M101.762 56.6152L109.649 64.5L108.868 65.2807L108.871 65.2834L119.5 55.0002L111.438 46.9384L111.428 46.9276H110.644L101.206 56.0572L101.762 56.6152Z"
//     fill="url(#paint1_linear)"
//   />
//   <path
//     d="M17.5508 46.9276L17.5615 46.9384L27.2357 56.6152L64.4999 93.8767L111.449 46.9276H17.5508Z"
//     fill={theme.palette.primary.main}
//   />
//   <defs>
//     <linearGradient id="paint0_linear" x1="25.0225" y1="49.3259" x2="11.4189" y2="62.9295" gradientUnits="userSpaceOnUse">
//       <stop stopColor={theme.palette.primary.darker} />
//       <stop offset="0.9637" stopColor={theme.palette.primary.dark} stopOpacity="0" />
//     </linearGradient>
//     <linearGradient id="paint1_linear" x1="103.5" y1="49.5" x2="114.5" y2="62" gradientUnits="userSpaceOnUse">
//       <stop stopColor={theme.palette.primary.darker} />
//       <stop offset="1" stopColor={theme.palette.primary.dark} stopOpacity="0" />
//     </linearGradient>
//   </defs>
// </svg>

// 	  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
// 		   x="0px" y="0px"
// 		   width="100%" viewBox="0 0 858 708" enable-background="new 0 0 858 708">
// <path fill="#232323" opacity="1.000000" stroke="none"
// 	  d="
// M515.000000,709.000000
// 	C343.333344,709.000000 172.166672,709.000000 1.000000,709.000000
// 	C1.000000,473.000000 1.000000,237.000000 1.000000,1.000000
// 	C287.000000,1.000000 573.000000,1.000000 859.000000,1.000000
// 	C859.000000,237.000000 859.000000,473.000000 859.000000,709.000000
// 	C744.500000,709.000000 630.000000,709.000000 515.000000,709.000000
// M831.625183,679.285156
// 	C831.808838,678.966370 831.992432,678.647583 832.715576,677.638489
// 	C832.765564,676.642273 832.856262,675.646179 832.859741,674.649841
// 	C833.112244,601.544312 833.367188,528.438782 833.600708,455.333282
// 	C833.753418,407.537872 833.873596,359.742371 834.009644,311.946930
// 	C834.207214,242.503830 834.405945,173.060745 834.604065,103.617653
// 	C834.605530,103.117966 834.596558,102.618065 834.608215,102.118629
// 	C834.950134,87.494499 831.993652,73.671028 823.654480,61.478992
// 	C811.693665,43.991959 795.416748,33.228024 773.315796,30.522404
// 	C766.898560,30.700964 760.391907,30.272228 754.084961,31.200338
// 	C744.609253,32.594753 735.639526,35.822399 727.312195,42.127773
// 	C720.153625,48.288143 712.883545,54.324581 705.863953,60.639420
// 	C696.500061,69.063171 687.330750,77.703232 677.489807,86.524818
// 	C663.001038,99.783188 648.512329,113.041557 633.443665,126.564064
// 	C621.071716,137.929337 608.699768,149.294617 595.783020,160.976898
// 	C584.966064,170.871536 574.149109,180.766159 562.786743,190.976181
// 	C551.506226,201.272141 540.225708,211.568085 528.302673,222.087540
// 	C524.745728,225.312622 521.166199,228.513199 517.635986,231.767242
// 	C505.715881,242.754761 493.813293,253.761230 481.259613,264.988251
// 	C465.416931,279.388062 449.554626,293.766388 433.763275,308.222260
// 	C432.254578,309.603394 431.188934,311.468597 429.412628,312.932098
// 	C429.079071,312.805969 428.745514,312.679871 428.074402,311.980286
// 	C414.086609,299.177002 400.137177,286.331482 386.098907,273.583832
// 	C374.798645,263.322418 363.390900,253.179352 351.707062,242.401840
// 	C346.376801,237.460281 341.111267,232.446625 335.702271,227.592819
// 	C325.787445,218.695663 315.787964,209.892838 305.525604,200.471664
// 	C301.029938,196.275040 296.601074,192.003937 292.024933,187.896942
// 	C281.768982,178.692459 271.439789,169.569611 260.875366,159.847092
// 	C256.281342,155.538895 251.766068,151.142853 247.077362,146.940292
// 	C236.474014,137.436356 225.785080,128.027939 214.913742,117.974968
// 	C201.619370,105.978035 188.325012,93.981102 174.708405,81.401588
// 	C165.110641,72.522362 155.724655,63.398239 145.840576,54.850063
// 	C139.258530,49.157616 131.965881,44.286819 124.559494,38.511932
// 	C111.252205,30.972656 97.098389,28.383448 81.221436,31.136818
// 	C70.111916,33.049156 60.023342,37.337685 50.386166,44.343605
// 	C32.127552,58.675777 23.643139,77.503716 23.700563,100.651627
// 	C23.929764,193.045044 23.971523,285.438904 24.101025,377.832611
// 	C24.110336,384.476501 24.267132,391.120178 24.449183,398.765320
// 	C24.455559,413.751617 24.545528,428.738434 24.450573,443.724091
// 	C24.263996,473.168823 28.317667,501.865234 38.677471,529.584045
// 	C47.762447,553.891907 60.181194,576.212952 77.136269,596.696777
// 	C99.570724,623.246094 126.333603,644.142456 158.624847,659.019653
// 	C170.102585,663.185364 181.341446,668.300476 193.121552,671.264343
// 	C206.342545,674.590576 220.052719,675.972412 234.266037,678.715698
// 	C248.524780,678.872681 262.783539,679.029663 277.931976,679.461426
// 	C295.187134,679.483459 312.442352,679.547058 329.697388,679.510071
// 	C338.475220,679.491211 347.252686,679.291626 356.918640,679.459229
// 	C376.658386,679.481140 396.398193,679.537231 416.137848,679.507812
// 	C423.752411,679.496399 431.366699,679.290649 439.879028,679.459045
// 	C444.684540,679.479248 449.490326,679.541443 454.295532,679.511902
// 	C469.857544,679.416260 485.419342,679.286865 501.878967,679.456360
// 	C508.342987,679.476624 514.807312,679.544861 521.270996,679.507446
// 	C535.841492,679.423157 550.411682,679.286621 565.879761,679.456909
// 	C575.828613,679.477295 585.777771,679.551453 595.726318,679.504700
// 	C608.478577,679.444702 621.230408,679.287415 634.880005,679.457886
// 	C652.303162,679.478516 669.726379,679.540161 687.149414,679.502930
// 	C696.094116,679.483765 705.038452,679.288269 714.880310,679.458679
// 	C752.625305,679.480835 790.370361,679.505554 828.115356,679.510742
// 	C829.096008,679.510864 830.076599,679.298462 831.625183,679.285156
// z"/>
// 		  <path fill="#6219F0" opacity="1.000000" stroke="none"
// 				d="
// M774.008728,30.962425
// 	C795.416748,33.228024 811.693665,43.991959 823.654480,61.478992
// 	C831.993652,73.671028 834.950134,87.494499 834.608215,102.118629
// 	C834.596558,102.618065 834.605530,103.117966 834.604065,103.617653
// 	C834.405945,173.060745 834.207214,242.503830 834.009644,311.946930
// 	C833.873596,359.742371 833.753418,407.537872 833.600708,455.333282
// 	C833.367188,528.438782 833.112244,601.544312 832.859741,674.649841
// 	C832.856262,675.646179 832.765564,676.642273 832.147217,677.899902
// 	C831.375122,678.127319 831.171387,678.093262 830.785217,677.779846
// 	C811.401917,659.982056 792.200989,642.463684 773.001587,624.519653
// 	C773.335327,618.807983 773.956787,613.521851 773.958252,608.235657
// 	C774.010864,415.811249 774.005859,223.386841 774.008728,30.962425
// z"/>
// 		  <path fill="#25036E" opacity="1.000000" stroke="none"
// 				d="
// M428.411987,312.553772
// 	C428.745514,312.679871 429.079071,312.805969 429.771240,313.340668
// 	C433.700989,317.169128 437.272156,320.589050 440.459534,324.114319
// 	C439.717194,326.247742 439.045776,328.275665 439.044800,330.303894
// 	C438.988281,446.593719 438.987396,562.883545 438.981110,679.173462
// 	C431.366699,679.290649 423.752411,679.496399 416.137848,679.507812
// 	C396.398193,679.537231 376.658386,679.481140 356.463928,678.902344
// 	C356.006165,581.666931 356.012268,484.988403 355.950623,388.309875
// 	C355.948944,385.718109 355.213623,383.126831 354.975769,380.258820
// 	C368.843018,367.423431 382.574585,354.885864 396.260681,342.298828
// 	C407.006958,332.415558 417.696960,322.471069 428.411987,312.553772
// z"/>
// 		  <path fill="#6720F1" opacity="1.000000" stroke="none"
// 				d="
// M773.662231,30.742414
// 	C774.005859,223.386841 774.010864,415.811249 773.958252,608.235657
// 	C773.956787,613.521851 773.335327,618.807983 772.683411,624.423218
// 	C757.230591,610.839355 742.097351,596.926453 727.267334,582.723145
// 	C727.713684,580.458740 727.981384,578.484680 727.981995,576.510498
// 	C728.003418,505.560547 728.005371,434.610596 727.998718,363.660645
// 	C727.988647,256.403137 727.970215,149.145645 727.955261,41.888138
// 	C735.639526,35.822399 744.609253,32.594753 754.084961,31.200338
// 	C760.391907,30.272228 766.898560,30.700964 773.662231,30.742414
// z"/>
// 		  <path fill="#6C28F2" opacity="1.000000" stroke="none"
// 				d="
// M727.633728,42.007957
// 	C727.970215,149.145645 727.988647,256.403137 727.998718,363.660645
// 	C728.005371,434.610596 728.003418,505.560547 727.981995,576.510498
// 	C727.981384,578.484680 727.713684,580.458740 726.917725,582.702026
// 	C722.522034,579.643494 718.779175,576.315735 714.871948,572.698059
// 	C711.295166,569.261292 707.883728,566.113403 704.470276,562.967712
// 	C695.588867,554.782837 686.706482,546.598877 677.993286,538.001343
// 	C678.441345,535.473022 678.964600,533.357971 678.965210,531.242798
// 	C679.003540,385.364960 679.004456,239.487106 678.961609,93.609276
// 	C678.960876,91.156250 678.385315,88.703377 678.077942,86.250427
// 	C687.330750,77.703232 696.500061,69.063171 705.863953,60.639420
// 	C712.883545,54.324581 720.153625,48.288143 727.633728,42.007957
// z"/>
// 		  <path fill="#F2A380" opacity="1.000000" stroke="none"
// 				d="
// M124.991272,39.048279
// 	C131.965881,44.286819 139.258530,49.157616 145.840576,54.850063
// 	C155.724655,63.398239 165.110641,72.522362 174.520538,81.888916
// 	C173.888443,84.066048 173.055878,85.755707 173.055267,87.445671
// 	C173.005005,228.829941 173.019272,370.214233 172.635864,511.711426
// 	C167.505554,512.158447 162.768280,512.492554 157.586319,512.672668
// 	C146.427368,510.993378 135.713104,509.468170 124.999207,507.477905
// 	C124.996819,351.024628 124.994041,195.036453 124.991272,39.048279
// z"/>
// 		  <path fill="#270475" opacity="1.000000" stroke="none"
// 				d="
// M354.820435,380.535309
// 	C355.213623,383.126831 355.948944,385.718109 355.950623,388.309875
// 	C356.012268,484.988403 356.006165,581.666931 356.019775,678.759644
// 	C347.252686,679.291626 338.475220,679.491211 329.697388,679.510071
// 	C312.442352,679.547058 295.187134,679.483459 277.498627,678.954590
// 	C277.376038,675.996765 277.956177,673.546082 277.958191,671.094788
// 	C278.008270,609.430054 278.044861,547.765137 277.955627,486.100464
// 	C277.938812,474.479309 277.378235,462.858948 277.229553,450.938660
// 	C286.583649,442.072815 295.776733,433.506531 305.277527,424.812531
// 	C308.915955,421.680695 312.272552,418.704742 315.572662,415.667419
// 	C327.051605,405.102570 338.510986,394.516449 350.290833,383.832886
// 	C352.009369,382.663330 353.414886,381.599304 354.820435,380.535309
// z"/>
// 		  <path fill="#F2A786" opacity="1.000000" stroke="none"
// 				d="
// M124.775383,38.780106
// 	C124.994041,195.036453 124.996819,351.024628 124.624207,507.465942
// 	C108.578392,503.649628 93.739479,497.613251 80.875046,486.987762
// 	C80.949165,484.158600 80.996773,481.826324 80.996834,479.494049
// 	C81.000374,338.527069 80.982552,197.560104 81.043373,56.593159
// 	C81.047020,48.140629 81.675964,39.688370 82.013947,31.235985
// 	C97.098389,28.383448 111.252205,30.972656 124.775383,38.780106
// z"/>
// 		  <path fill="#230368" opacity="1.000000" stroke="none"
// 				d="
// M439.430054,679.316223
// 	C438.987396,562.883545 438.988281,446.593719 439.044800,330.303894
// 	C439.045776,328.275665 439.717194,326.247742 440.798645,324.199280
// 	C447.621918,329.786835 453.664093,335.459839 459.838226,340.985382
// 	C466.713135,347.138184 473.708099,353.156799 480.794891,359.541138
// 	C487.673706,366.088806 494.408417,372.329132 501.102417,379.063599
// 	C501.041138,381.887695 501.002777,384.217682 501.002563,386.547668
// 	C500.993317,484.088593 500.987488,581.629517 500.981201,679.170471
// 	C485.419342,679.286865 469.857544,679.416260 454.295532,679.511902
// 	C449.490326,679.541443 444.684540,679.479248 439.430054,679.316223
// z"/>
// 		  <path fill="#7030F2" opacity="1.000000" stroke="none"
// 				d="
// M677.783875,86.387627
// 	C678.385315,88.703377 678.960876,91.156250 678.961609,93.609276
// 	C679.004456,239.487106 679.003540,385.364960 678.965210,531.242798
// 	C678.964600,533.357971 678.441345,535.473022 677.689331,537.838623
// 	C672.766663,534.135864 668.311157,530.188782 663.867920,526.228027
// 	C654.350647,517.744263 644.839844,509.253418 635.221924,500.501465
// 	C634.686218,499.617065 634.255005,498.996613 634.001709,497.960327
// 	C634.453125,495.596191 634.965271,493.647980 634.965881,491.699677
// 	C635.003906,372.181305 635.005432,252.662933 634.959351,133.144577
// 	C634.958435,130.862915 634.349060,128.581482 634.023560,126.299942
// 	C648.512329,113.041557 663.001038,99.783188 677.783875,86.387627
// z"/>
// 		  <path fill="#220362" opacity="1.000000" stroke="none"
// 				d="
// M501.430084,679.313416
// 	C500.987488,581.629517 500.993317,484.088593 501.002563,386.547668
// 	C501.002777,384.217682 501.041138,381.887695 501.393982,379.258423
// 	C510.789276,387.052063 519.852295,395.145020 529.119629,403.491058
// 	C541.219177,414.467560 553.114441,425.190948 565.190735,436.102997
// 	C565.638611,436.728058 565.905396,437.164520 566.002686,438.012878
// 	C565.555481,440.707550 565.036316,442.990204 565.034912,445.273163
// 	C564.986938,523.239197 564.988037,601.205261 564.981995,679.171326
// 	C550.411682,679.286621 535.841492,679.423157 521.270996,679.507446
// 	C514.807312,679.544861 508.342987,679.476624 501.430084,679.313416
// z"/>
// 		  <path fill="#F19E78" opacity="1.000000" stroke="none"
// 				d="
// M173.028915,511.598511
// 	C173.019272,370.214233 173.005005,228.829941 173.055267,87.445671
// 	C173.055878,85.755707 173.888443,84.066048 174.681656,82.180206
// 	C188.325012,93.981102 201.619370,105.978035 214.949188,118.729965
// 	C214.656433,121.769348 214.041885,124.053619 214.041016,126.338127
// 	C213.994629,248.501480 213.994797,370.664856 214.043289,492.828186
// 	C214.044128,494.932159 214.693253,497.035889 214.826202,499.408966
// 	C201.492218,506.236237 187.608414,510.130829 173.028915,511.598511
// z"/>
// 		  <path fill="#F19972" opacity="1.000000" stroke="none"
// 				d="
// M215.039886,499.139709
// 	C214.693253,497.035889 214.044128,494.932159 214.043289,492.828186
// 	C213.994797,370.664856 213.994629,248.501480 214.041016,126.338127
// 	C214.041885,124.053619 214.656433,121.769348 215.057800,119.032806
// 	C225.785080,128.027939 236.474014,137.436356 247.077362,146.940292
// 	C251.766068,151.142853 256.281342,155.538895 260.969757,160.577301
// 	C260.766144,164.843521 260.254578,168.377243 260.216217,171.916092
// 	C260.144287,178.551559 260.379547,185.189758 260.424713,191.827255
// 	C260.468628,198.275314 260.447510,204.723938 260.430847,211.172241
// 	C260.413330,217.966522 260.328552,224.760818 260.345001,231.554932
// 	C260.360596,238.003647 260.492218,244.451996 260.530579,250.900757
// 	C260.572845,258.006500 260.610199,265.112793 260.569336,272.218384
// 	C260.532227,278.667419 260.346008,285.115570 260.301178,291.564636
// 	C260.255035,298.202332 260.268555,304.840851 260.314758,311.478638
// 	C260.359650,317.927551 260.517792,324.375793 260.555359,330.824707
// 	C260.596710,337.930328 260.586365,345.036591 260.544617,352.142242
// 	C260.506744,358.591095 260.370148,365.039337 260.325226,371.488190
// 	C260.278992,378.126007 260.244659,384.764557 260.290741,391.402191
// 	C260.335541,397.851349 260.595825,404.300293 260.565063,410.748352
// 	C260.533386,417.378296 260.194336,424.006317 260.117920,430.636627
// 	C260.034424,437.881592 259.997375,445.130157 260.135773,452.373413
// 	C260.215698,456.556824 260.681885,460.732880 260.837128,465.220764
// 	C251.747650,473.321594 242.795303,481.113861 233.508911,488.983093
// 	C227.129868,492.419922 221.084869,495.779816 215.039886,499.139709
// z"/>
// 		  <path fill="#21035C" opacity="1.000000" stroke="none"
// 				d="
// M565.430908,679.314087
// 	C564.988037,601.205261 564.986938,523.239197 565.034912,445.273163
// 	C565.036316,442.990204 565.555481,440.707550 566.266113,438.238037
// 	C577.137878,447.342133 587.576782,456.632935 598.207031,466.198303
// 	C600.572327,468.516357 602.704773,470.605927 604.927063,472.595215
// 	C614.545044,481.204865 624.189148,489.785187 633.823792,498.376160
// 	C634.255005,498.996613 634.686218,499.617065 634.875244,500.789337
// 	C634.422058,503.295929 634.027893,505.250580 634.026550,507.205475
// 	C633.987854,564.527649 633.988281,621.849854 633.982422,679.172058
// 	C621.230408,679.287415 608.478577,679.444702 595.726318,679.504700
// 	C585.777771,679.551453 575.828613,679.477295 565.430908,679.314087
// z"/>
// 		  <path fill="#F3AB8C" opacity="1.000000" stroke="none"
// 				d="
// M81.617691,31.186401
// 	C81.675964,39.688370 81.047020,48.140629 81.043373,56.593159
// 	C80.982552,197.560104 81.000374,338.527069 80.996834,479.494049
// 	C80.996773,481.826324 80.949165,484.158600 80.546524,486.864258
// 	C79.508148,487.002258 78.846992,486.766846 78.050430,486.245300
// 	C67.189774,477.608612 57.273266,468.460419 49.893021,456.924866
// 	C50.507324,454.403137 50.968655,452.130341 50.969261,449.857391
// 	C51.005566,314.611298 51.003902,179.365204 51.005856,44.119110
// 	C60.023342,37.337685 70.111916,33.049156 81.617691,31.186401
// z"/>
// 		  <path fill="#2A047F" opacity="1.000000" stroke="none"
// 				d="
// M214.826202,499.408966
// 	C221.084869,495.779816 227.129868,492.419922 233.831055,489.267883
// 	C234.658142,490.442444 234.978226,491.409149 234.978607,492.375977
// 	C235.002609,552.834778 235.010880,613.293640 234.940170,673.752380
// 	C234.938431,675.235535 234.033646,676.717651 233.550049,678.200256
// 	C220.052719,675.972412 206.342545,674.590576 193.121552,671.264343
// 	C181.341446,668.300476 170.102585,663.185364 158.315643,658.417114
// 	C158.051743,653.908936 158.125214,650.003296 158.136185,646.097534
// 	C158.156052,639.021362 158.133621,631.945129 158.138321,624.868896
// 	C158.142502,618.553223 158.092361,612.236572 158.189514,605.922241
// 	C158.288712,599.475403 158.699432,593.030334 158.676880,586.585571
// 	C158.653732,579.967102 158.058670,573.348572 158.097855,566.732422
// 	C158.152298,557.540161 158.764053,548.350159 158.766235,539.159058
// 	C158.768311,530.381653 158.295212,521.604126 158.031006,512.826660
// 	C162.768280,512.492554 167.505554,512.158447 172.635864,511.711426
// 	C187.608414,510.130829 201.492218,506.236237 214.826202,499.408966
// z"/>
// 		  <path fill="#7437F3" opacity="1.000000" stroke="none"
// 				d="
// M634.001709,497.960327
// 	C624.189148,489.785187 614.545044,481.204865 604.927063,472.595215
// 	C602.704773,470.605927 600.572327,468.516357 598.203003,465.735535
// 	C598.005005,463.999847 598.000366,463.001495 598.000366,462.003143
// 	C598.000000,363.829773 598.011292,265.656403 597.930115,167.483109
// 	C597.928284,165.208267 596.885254,162.934280 596.327881,160.659882
// 	C608.699768,149.294617 621.071716,137.929337 633.733643,126.432007
// 	C634.349060,128.581482 634.958435,130.862915 634.959351,133.144577
// 	C635.005432,252.662933 635.003906,372.181305 634.965881,491.699677
// 	C634.965271,493.647980 634.453125,495.596191 634.001709,497.960327
// z"/>
// 		  <path fill="#F0956B" opacity="1.000000" stroke="none"
// 				d="
// M260.974243,464.912231
// 	C260.681885,460.732880 260.215698,456.556824 260.135773,452.373413
// 	C259.997375,445.130157 260.034424,437.881592 260.117920,430.636627
// 	C260.194336,424.006317 260.533386,417.378296 260.565063,410.748352
// 	C260.595825,404.300293 260.335541,397.851349 260.290741,391.402191
// 	C260.244659,384.764557 260.278992,378.126007 260.325226,371.488190
// 	C260.370148,365.039337 260.506744,358.591095 260.544617,352.142242
// 	C260.586365,345.036591 260.596710,337.930328 260.555359,330.824707
// 	C260.517792,324.375793 260.359650,317.927551 260.314758,311.478638
// 	C260.268555,304.840851 260.255035,298.202332 260.301178,291.564636
// 	C260.346008,285.115570 260.532227,278.667419 260.569336,272.218384
// 	C260.610199,265.112793 260.572845,258.006500 260.530579,250.900757
// 	C260.492218,244.451996 260.360596,238.003647 260.345001,231.554932
// 	C260.328552,224.760818 260.413330,217.966522 260.430847,211.172241
// 	C260.447510,204.723938 260.468628,198.275314 260.424713,191.827255
// 	C260.379547,185.189758 260.144287,178.551559 260.216217,171.916092
// 	C260.254578,168.377243 260.766144,164.843521 261.102264,160.860504
// 	C271.439789,169.569611 281.768982,178.692459 292.024933,187.896942
// 	C296.601074,192.003937 301.029938,196.275040 305.294098,200.855743
// 	C304.708405,202.934341 304.045685,204.628647 304.044434,206.323425
// 	C303.992645,277.257477 303.992310,348.191528 304.040649,419.125580
// 	C304.041962,421.063995 304.646545,423.002045 304.969818,424.940247
// 	C295.776733,433.506531 286.583649,442.072815 276.931610,451.070740
// 	C271.306519,455.972321 266.140381,460.442261 260.974243,464.912231
// z"/>
// 		  <path fill="#1F0456" opacity="1.000000" stroke="none"
// 				d="
// M634.431213,679.314941
// 	C633.988281,621.849854 633.987854,564.527649 634.026550,507.205475
// 	C634.027893,505.250580 634.422058,503.295929 634.979736,501.053314
// 	C644.839844,509.253418 654.350647,517.744263 663.867920,526.228027
// 	C668.311157,530.188782 672.766663,534.135864 677.520508,538.251892
// 	C686.706482,546.598877 695.588867,554.782837 704.470276,562.967712
// 	C707.883728,566.113403 711.295166,569.261292 714.472351,572.789978
// 	C713.824768,574.994080 713.057312,576.815491 713.052856,578.638733
// 	C712.978638,609.043396 712.971680,639.448303 713.042908,669.852966
// 	C713.050232,672.960205 713.655212,676.066040 713.982910,679.172485
// 	C705.038452,679.288269 696.094116,679.483765 687.149414,679.502930
// 	C669.726379,679.540161 652.303162,679.478516 634.431213,679.314941
// z"/>
// 		  <path fill="#2B0486" opacity="1.000000" stroke="none"
// 				d="
// M157.586304,512.672607
// 	C158.295212,521.604126 158.768311,530.381653 158.766235,539.159058
// 	C158.764053,548.350159 158.152298,557.540161 158.097855,566.732422
// 	C158.058670,573.348572 158.653732,579.967102 158.676880,586.585571
// 	C158.699432,593.030334 158.288712,599.475403 158.189514,605.922241
// 	C158.092361,612.236572 158.142502,618.553223 158.138321,624.868896
// 	C158.133621,631.945129 158.156052,639.021362 158.136185,646.097534
// 	C158.125214,650.003296 158.051743,653.908936 158.008484,658.217773
// 	C126.333603,644.142456 99.570724,623.246094 77.117706,595.950073
// 	C77.399414,592.919617 77.953918,590.636780 77.961807,588.352112
// 	C78.079033,554.412048 78.123871,520.471680 78.185829,486.531433
// 	C78.846992,486.766846 79.508148,487.002258 80.497826,487.361145
// 	C93.739479,497.613251 108.578392,503.649628 124.623825,507.931000
// 	C135.713104,509.468170 146.427368,510.993378 157.586304,512.672607
// z"/>
// 		  <path fill="#F2AD91" opacity="1.000000" stroke="none"
// 				d="
// M50.696011,44.231358
// 	C51.003902,179.365204 51.005566,314.611298 50.969261,449.857391
// 	C50.968655,452.130341 50.507324,454.403137 49.640747,456.721710
// 	C48.479839,456.139008 47.853855,455.565277 47.411030,454.874207
// 	C36.293045,437.523010 28.239727,419.001373 25.806202,398.323242
// 	C25.777956,398.083252 24.858690,397.948090 24.355350,397.763977
// 	C24.267132,391.120178 24.110336,384.476501 24.101025,377.832611
// 	C23.971523,285.438904 23.929764,193.045044 23.700563,100.651627
// 	C23.643139,77.503716 32.127552,58.675777 50.696011,44.231358
// z"/>
// 		  <path fill="#773CF4" opacity="1.000000" stroke="none"
// 				d="
// M596.055420,160.818390
// 	C596.885254,162.934280 597.928284,165.208267 597.930115,167.483109
// 	C598.011292,265.656403 598.000000,363.829773 598.000366,462.003143
// 	C598.000366,463.001495 598.005005,463.999847 598.011536,465.460968
// 	C587.576782,456.632935 577.137878,447.342133 566.435608,437.826172
// 	C565.905396,437.164520 565.638611,436.728058 565.187744,435.641541
// 	C565.002563,355.660431 565.014221,276.329376 564.930176,196.998428
// 	C564.927917,194.885330 563.888123,192.773331 563.332214,190.660797
// 	C574.149109,180.766159 584.966064,170.871536 596.055420,160.818390
// z"/>
// 		  <path fill="#2D0A7C" opacity="1.000000" stroke="none"
// 				d="
// M260.837097,465.220764
// 	C266.140381,460.442261 271.306519,455.972321 276.770630,451.370300
// 	C277.378235,462.858948 277.938812,474.479309 277.955627,486.100464
// 	C278.044861,547.765137 278.008270,609.430054 277.958191,671.094788
// 	C277.956177,673.546082 277.376038,675.996765 277.053772,678.817200
// 	C262.783539,679.029663 248.524780,678.872681 233.908051,678.458008
// 	C234.033646,676.717651 234.938431,675.235535 234.940170,673.752380
// 	C235.010880,613.293640 235.002609,552.834778 234.978607,492.375977
// 	C234.978226,491.409149 234.658142,490.442444 234.165100,489.190918
// 	C242.795303,481.113861 251.747650,473.321594 260.837097,465.220764
// z"/>
// 		  <path fill="#F09165" opacity="1.000000" stroke="none"
// 				d="
// M305.277527,424.812531
// 	C304.646545,423.002045 304.041962,421.063995 304.040649,419.125580
// 	C303.992310,348.191528 303.992645,277.257477 304.044434,206.323425
// 	C304.045685,204.628647 304.708405,202.934341 305.443176,201.145233
// 	C315.787964,209.892838 325.787445,218.695663 335.702271,227.592819
// 	C341.111267,232.446625 346.376801,237.460281 351.562195,242.931808
// 	C350.944855,245.850693 350.065186,248.238785 350.060364,250.628647
// 	C349.970734,295.065125 349.985229,339.501801 349.977844,383.938446
// 	C338.510986,394.516449 327.051605,405.102570 315.572662,415.667419
// 	C312.272552,418.704742 308.915955,421.680695 305.277527,424.812531
// z"/>
// 		  <path fill="#7A43F4" opacity="1.000000" stroke="none"
// 				d="
// M563.059448,190.818497
// 	C563.888123,192.773331 564.927917,194.885330 564.930176,196.998428
// 	C565.014221,276.329376 565.002563,355.660431 565.006714,435.452911
// 	C553.114441,425.190948 541.219177,414.467560 529.145813,403.049866
// 	C529.001160,396.371948 529.015381,390.388153 529.073364,384.404755
// 	C529.131287,378.430908 529.212585,372.457123 529.321289,366.483978
// 	C529.441589,359.873322 529.732422,353.263031 529.712646,346.653320
// 	C529.692810,340.043488 529.226257,333.434265 529.240356,326.825378
// 	C529.254456,320.217834 529.794617,313.610779 529.784546,307.003998
// 	C529.774475,300.396545 529.179443,293.789398 529.189575,287.182648
// 	C529.199646,280.575165 529.846252,273.968018 529.836182,267.361298
// 	C529.826050,260.753754 529.231262,254.149170 529.105042,247.539246
// 	C528.941711,238.982941 528.988708,230.422623 528.945190,221.864044
// 	C540.225708,211.568085 551.506226,201.272141 563.059448,190.818497
// z"/>
// 		  <path fill="#7F4AF5" opacity="1.000000" stroke="none"
// 				d="
// M528.623901,221.975800
// 	C528.988708,230.422623 528.941711,238.982941 529.105042,247.539246
// 	C529.231262,254.149170 529.826050,260.753754 529.836182,267.361298
// 	C529.846252,273.968018 529.199646,280.575165 529.189575,287.182648
// 	C529.179443,293.789398 529.774475,300.396545 529.784546,307.003998
// 	C529.794617,313.610779 529.254456,320.217834 529.240356,326.825378
// 	C529.226257,333.434265 529.692810,340.043488 529.712646,346.653320
// 	C529.732422,353.263031 529.441589,359.873322 529.321289,366.483978
// 	C529.212585,372.457123 529.131287,378.430908 529.073364,384.404755
// 	C529.015381,390.388153 529.001160,396.371948 528.941528,402.796783
// 	C519.852295,395.145020 510.789276,387.052063 501.434692,378.764282
// 	C494.408417,372.329132 487.673706,366.088806 481.122650,359.323364
// 	C481.774261,354.716766 482.587585,350.638947 482.631531,346.552856
// 	C482.702576,339.953857 482.054932,333.348083 482.097229,326.747650
// 	C482.156006,317.577759 482.816040,308.409027 482.773132,299.241852
// 	C482.719299,287.746948 482.214264,276.254150 481.903992,264.760437
// 	C493.813293,253.761230 505.715881,242.754761 517.635986,231.767242
// 	C521.166199,228.513199 524.745728,225.312622 528.623901,221.975800
// z"/>
// 		  <path fill="#1E0450" opacity="1.000000" stroke="none"
// 				d="
// M714.431580,679.315552
// 	C713.655212,676.066040 713.050232,672.960205 713.042908,669.852966
// 	C712.971680,639.448303 712.978638,609.043396 713.052856,578.638733
// 	C713.057312,576.815491 713.824768,574.994080 714.636719,573.079895
// 	C718.779175,576.315735 722.522034,579.643494 726.614563,582.992310
// 	C742.097351,596.926453 757.230591,610.839355 772.681885,624.848755
// 	C792.200989,642.463684 811.401917,659.982056 830.792480,678.062500
// 	C831.007141,678.811340 831.032166,678.998230 831.057251,679.185120
// 	C830.076599,679.298462 829.096008,679.510864 828.115356,679.510742
// 	C790.370361,679.505554 752.625305,679.480835 714.431580,679.315552
// z"/>
// 		  <path fill="#EF8C5E" opacity="1.000000" stroke="none"
// 				d="
// M350.290833,383.832886
// 	C349.985229,339.501801 349.970734,295.065125 350.060364,250.628647
// 	C350.065186,248.238785 350.944855,245.850693 351.724060,243.222778
// 	C363.390900,253.179352 374.798645,263.322418 386.098907,273.583832
// 	C400.137177,286.331482 414.086609,299.177002 428.243195,312.267029
// 	C417.696960,322.471069 407.006958,332.415558 396.260681,342.298828
// 	C382.574585,354.885864 368.843018,367.423431 354.975769,380.258820
// 	C353.414886,381.599304 352.009369,382.663330 350.290833,383.832886
// z"/>
// 		  <path fill="#2E058B" opacity="1.000000" stroke="none"
// 				d="
// M24.402267,398.264648
// 	C24.858690,397.948090 25.777956,398.083252 25.806202,398.323242
// 	C28.239727,419.001373 36.293045,437.523010 47.411030,454.874207
// 	C47.853855,455.565277 48.479839,456.139008 49.272781,456.970551
// 	C57.273266,468.460419 67.189774,477.608612 78.050430,486.245300
// 	C78.123871,520.471680 78.079033,554.412048 77.961807,588.352112
// 	C77.953918,590.636780 77.399414,592.919617 77.042480,595.620972
// 	C60.181194,576.212952 47.762447,553.891907 38.677471,529.584045
// 	C28.317667,501.865234 24.263996,473.168823 24.450573,443.724091
// 	C24.545528,428.738434 24.455559,413.751617 24.402267,398.264648
// z"/>
// 		  <path fill="#8452F5" opacity="1.000000" stroke="none"
// 				d="
// M481.581787,264.874329
// 	C482.214264,276.254150 482.719299,287.746948 482.773132,299.241852
// 	C482.816040,308.409027 482.156006,317.577759 482.097229,326.747650
// 	C482.054932,333.348083 482.702576,339.953857 482.631531,346.552856
// 	C482.587585,350.638947 481.774261,354.716766 480.978546,359.016052
// 	C473.708099,353.156799 466.713135,347.138184 459.838226,340.985382
// 	C453.664093,335.459839 447.621918,329.786835 441.182434,324.093933
// 	C437.272156,320.589050 433.700989,317.169128 430.023712,313.429138
// 	C431.188934,311.468597 432.254578,309.603394 433.763275,308.222260
// 	C449.554626,293.766388 465.416931,279.388062 481.581787,264.874329
// z"/>
// 		  <path fill="#2D0A7C" opacity="1.000000" stroke="none"
// 				d="
// M831.341187,679.235107
// 	C831.032166,678.998230 831.007141,678.811340 830.974854,678.341858
// 	C831.171387,678.093262 831.375122,678.127319 831.877441,678.244995
// 	C831.992432,678.647583 831.808838,678.966370 831.341187,679.235107
// z"/>
// </svg>
