import {Runbook, RunbookTask} from "../../interfaces/interfaces";
import {
    Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
    Grid, Stack, TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import MainCard from "../MainCard";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import RunbookStatusSelect from "../runbooks/RunbookStatusSelect";

interface CompanyRunbookProps {
    runbook: Runbook;
    editable?: boolean;
    onUpdate?: (updatedRunbook: Runbook) => void;
}

const CompanyRunbookComponent: React.FC<CompanyRunbookProps> = ({ runbook, editable, onUpdate }) => {
    const [updatedRunbook, setUpdatedRunbook] = useState(runbook);
    const [openDialog, setOpenDialog] = useState(false);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [taskNameValue, setTaskNameValue] = useState("");
    const [taskDescriptionValue, setTaskDescriptionValue] = useState("");
    const handleOpenDialog = () => {
        setOpenDialog(true);
        setTaskNameValue("");
        setTaskDescriptionValue("");
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenStatusDialog = () => {
        setOpenStatusDialog(true);
    }
    const handleCloseStatusDialog = () => {
        setOpenStatusDialog(false);
    }
    const handleSaveTask = () => {
        let taskValue: RunbookTask = {} as RunbookTask;
        taskValue.taskName = taskNameValue;
        taskValue.taskDescription = taskDescriptionValue;
        // console.log("Task Value:", taskValue);
        setUpdatedRunbook((prevRunbook) => ({
            ...prevRunbook,
            tasks: prevRunbook.tasks ? [...prevRunbook.tasks, taskValue] : [taskValue]
        }));
        handleCloseDialog();
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdatedRunbook((prevRunbook) => ({
            ...prevRunbook,
            [name]: value,
        }));

    };

    useEffect(() => {
        setUpdatedRunbook(runbook);
    }, [runbook]);

    useEffect(() => {
        if (onUpdate) {
            onUpdate(updatedRunbook);
        }
    }, [updatedRunbook, onUpdate]);

    // useEffect(() => {
    //     setUpdatedRunbook(runbook);
    // }, [runbook]);
    // const handleUpdate = () => {
    //     setLoadBanner("Updating Runbook...");
    //     setLoading(true);
    //     const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/updaterunbook/${token}/${runbook.companyId}/${runbook.runbookId}`;
    //     try {
    //         fetch(updateURL, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(updatedRunbook),
    //         });
    //
    //     } catch (error) {
    //         console.error("Error updating Runbook:", error);
    //     }
    //     setLoading(false);
    //     // Reset the form or perform any necessary actions
    // };

    let statusChip;

    switch (runbook.status) {
        case 'Approved':
            statusChip = <Chip color="success" label="Approved"  size="small" variant="outlined" />;
            break;
        case 'Pending Approval':
            statusChip= <Chip color="success" label="Pending Approval" size="small" variant="outlined" />;
            break;
        case 'Under Review':
            statusChip= <Chip color="primary" label="Under Review" size="small" variant="light" />;
            break;
        case 'Archived':
            statusChip= <Chip color="secondary" label="Archived" size="small" variant="light" />;
            break;
        default:
            statusChip= <Chip color="primary" label={runbook.status} size="small" variant="outlined" />;
            break;
    }

    // async function handleWordsmith(attributeName: keyof Runbook) {
    //     if (runbook) {
    //         const attributeValue = runbook[attributeName];
    //         try {
    //             const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/assistant/wordsmith/' +
    //                 token + '/' + runbook.companyId + '/' + attributeValue);
    //             const data = await response.json();
    //             // @ts-ignore
    //             setUpdatedRunbook(prevScenario => ({
    //                 ...prevScenario,
    //                 [attributeName]: data
    //             }));
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    // }

    return (
        <>
            <Grid item xs={12} >
                    <Grid container xs={12} >
                    <Grid item xs={4}>
                                <MainCard >
                                    <Grid container spacing={2} >
                                    <Grid item xs={8}>
                                       <Typography variant={"h6"}>{statusChip}</Typography>
                                    </Grid>
                                    {editable &&
                                        <Grid item xs={4}>
                                            <Button variant={"text"} size={"small"} onClick={handleOpenStatusDialog}>Change Status</Button>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <TextField
                                        name="description"
                                        label = "Overview"
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            readOnly: !editable,
                                        }}
                                        rows={8}
                                        value={updatedRunbook.description}
                                        onChange={handleInputChange}
                                        />
                                        {/*{editable &&*/}
                                        {/*<Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('description')}>*/}
                                        {/*    Wordsmith*/}
                                        {/*</Button>*/}
                                        {/*}*/}
                                    </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="authorization"
                                                label = "Authorization"
                                                fullWidth
                                                multiline
                                                rows={8}
                                                InputProps={{
                                                    readOnly: !editable,
                                                }}
                                                value={updatedRunbook.authorization}
                                                onChange={handleInputChange}
                                            />
                                            {/*{editable &&*/}
                                            {/*<Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('authorization')}>*/}
                                            {/*    Wordsmith*/}
                                            {/*</Button>*/}
                                            {/*}*/}
                                        </Grid>
                                    </Grid>
                                    <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog}>
                                        <DialogTitle>Change Runbook Status</DialogTitle>
                                        <DialogContent>
                                            <RunbookStatusSelect runbook={runbook} />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseStatusDialog}>Cancel</Button>
                                            <Button onClick={handleCloseStatusDialog} color="primary">Save</Button>
                                        </DialogActions>
                                    </Dialog>
                                </MainCard>
                    </Grid>
                    <Grid item xs={8}>
                            <Grid item xs={12}>
                                <MainCard title="Process Steps"
                                          secondary={
                                                <>
                                                    {editable &&
                                                    <Stack>
                                                    {/*<PlayPauseStopButtons handlePlay={handlePlayAction} handlePause={handlePauseAction} handleStop={handleStopAction}/>*/}
                                                            <Button variant="text" size="small" color="primary" onClick={handleOpenDialog}>
                                                                Add Steps
                                                            </Button>
                                                    </Stack>
                                                    }
                                              </>
                                          }
                                >
                                    {updatedRunbook.tasks ? (
                                        <Timeline position="alternate">
                                            { updatedRunbook.tasks && updatedRunbook.tasks.map((task:RunbookTask) => {
                                                return (
                                                    <TimelineItem>
                                                        <TimelineSeparator>
                                                            <TimelineDot variant="outlined" color="primary" />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent>
                                                            <Typography variant="h6" component="span">
                                                                {task.taskName}
                                                            </Typography>
                                                            <Typography color="textSecondary">{task.taskDescription}</Typography>
                                                            {/*<Button variant={"outlined"} size={"extraSmall"}>View Steps</Button>*/}
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                );
                                            })}
                                        </Timeline>
                                        ) : (
                                        <Typography variant={"caption"}>No tasks defined</Typography>
                                    )}
                                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                                        <DialogTitle>Add Task</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                name="taskNameValue"
                                                placeholder="Task Name"
                                                variant="standard"
                                                value={taskNameValue}
                                                onChange={(e) => setTaskNameValue(e.target.value)}
                                            />
                                            <TextField
                                                name="taskDescriptionValue"
                                                placeholder="Task Description"
                                                variant="standard"
                                                value={taskDescriptionValue}
                                                onChange={(e) => setTaskDescriptionValue(e.target.value)}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDialog}>Cancel</Button>
                                            <Button onClick={handleSaveTask} color="primary">Save</Button>
                                        </DialogActions>
                                    </Dialog>
                                </MainCard>
                            </Grid>

                    </Grid>
                    </Grid>
            </Grid>
        </>
    );
};
export default CompanyRunbookComponent;
