import MainCard from "../MainCard";
import {
    Box,
    Button,
    ButtonGroup, Card, CardContent, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Divider, Drawer,
    Grid, IconButton,
    Link,
    Stack,
    Tab,
    Tabs, TextField, Tooltip,
    Typography
} from "@mui/material";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import PlayPauseStopButtons from "../PlayPauseStopButtons";
import {Link as RouterLink} from "react-router-dom";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
    ArrowBackOutlined,
    DoneOutlined, EditCalendarOutlined,
     FileUploadOutlined, InsertPageBreakOutlined, JoinFullOutlined,
    PeopleAltOutlined,
    RefreshOutlined
} from "@mui/icons-material";
import ScriptItemListComponent from "../tabletop/ScriptItemListComponent";
import ScenarioInjectsTabletopComponent from "../tabletop/ScenarioInjectsTabletopComponent";
import ScenarioTabletopChatComponent from "../scenario/ScenarioTabletopChatComponent";
import React, {useEffect, useState} from "react";
import {
    AppUserProfile,
    Artifact,
    Conduct, ConductBrief,
    ConductFile, ConductScriptItem, Inject,
    Scenario,
    ScriptItem,
    UserLinkKey
} from "../../interfaces/interfaces";
import FinishedBanner from "../tabletop/FinishedBanner";
import FileUploader from "../FileUploader";
import FileList from "../FileList";
import ArtifactList from "../ArtifactList";
import DialogContentText from "@mui/material/DialogContentText";
import ScrollX from "../ScrollX";
import ConductBriefOverviewDialog from "../dialog/ConductBriefOveviewDialog";
import ScenarioOverviewDialog from "../dialog/ScenarioOverviewDialog";
import ScenarioWalkthroughDialog from "../dialog/ScenarioWalkthroughDialog";
import EditInjectDialog from "../dialog/EditInjectDialog";
import SectionFlow from "../tabletop/SectionFlow";
import ScheduleConductAndParticipantsDialog from "../dialog/ScheduleConductAndParticipantsDialog";
import ScenarioParticipantAddDialog from "../dialog/ScenarioParticipantAddDialog";
import ScenarioImageGridDisplay from "../scenario/ScenarioImageGridDisplay";
import LandingPageLink from "../tabletop/LandingPageLink";
import ReactMarkdown from "react-markdown";
interface ScenarioConductParams {
    scenario: Scenario;
    resumeConductId?: string;
    portalId: string;
    userProfile: AppUserProfile | null | undefined;
}

const  PortalScenarioConductFullComponent: React.FC<ScenarioConductParams> = ({ scenario, resumeConductId, portalId , userProfile  }) => {
    const [conduct, setConduct] = useState<Conduct>();
    const companyId = scenario.companyId;
    const token = localStorage.getItem('token');
    const [conductId  , setConductId] = useState<string|undefined>(resumeConductId);
    const [conductScriptItems, setConductScriptItems] = useState<ScriptItem[]>([]);
    const [chatId, setChatId] = useState("");
    const [isScenarioLoading, setIsScenarioLoading] = useState<boolean>(false);
    const [isStopped, setIsStopped] = useState<boolean>(false);
    const [openAddParticipantDialog, setOpenAddParticipantDialog] = useState(false);
    const [bannerText, setBannerText] = useState<string>("Loading Scenario");
    const [showPlayPauseStop, setShowPlayPauseStop] = useState<boolean>(true);
    const [showCompleteButton, setShowCompleteButton] = useState<boolean>(false);
    const [showScheduleButton, setShowScheduleButton] = useState<boolean>(true);
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const status = isStopped ? "Stopped" : "In Progress";
    const [selectedScriptId, setSelectedScriptId] = useState<string | null>(null);
    const [artifacts, setArtifacts] = useState(conduct?.artifacts);

    useEffect(() => {
      setConductScriptItems(conductScriptItems);
    }, [conductScriptItems]);

    useEffect(() => {
        if (resumeConductId) {
            handleConductResume();
        } else if (conduct==null || conductId==null) {
            handleChatStart();
        } else {
            setIsStopped(true);
            setShowCompleteButton(false);
        }

    }, []);

    const handleChatStart = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/startnewtabletop/' + token + '/' +
                companyId+ '/' + portalId+'/'+scenario.scenarioId);
            const data = await response.json();
            setConduct(data);
            setChatId(data.chatId);
            setConductId(data.conductId);
            setConductScriptItems(data.script);
        } catch (error) {
            console.error(error);
        }
    }
    const handleCloseAddParticpantDialog = () => {
        setOpenAddParticipantDialog(false);
    };

    useEffect(() => {
        if (conduct) {
            setArtifacts(conduct.artifacts);
        }
    }, [conduct]);

    function handleConductStop() {
        setIsStopped(true);
        setShowCompleteButton(true);

        const fetchData = async () => {
            try {
                setBannerText("Stopping Tabletop...");
                setIsScenarioLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/tabletop/stop/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId);
                const data = await response.json();
                setConduct(data);
                setIsScenarioLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }

    function handleAddParticpantDialog(participantName: string, participantEmail: string, participantRole: string) {
        //console.log("Add Participant "+participantName+" "+participantRole+" "+participantEmail);
        const fetchData = async () => {
            try {
                setBannerText("Adding Participant...");
                setIsScenarioLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/tabletop/addparticipant/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: participantName,
                            role: participantRole,
                            email: participantEmail
                        })
                    });
                const data = await response.json();
                setConduct(data);
                setIsScenarioLoading(false);
            } catch (error) {
                console.error(error);
            }
        }; fetchData();
        setOpenAddParticipantDialog(false);
    }

    async function handleConductResume() {
        try {
            setBannerText("Resuming Tabletop...");
            setIsScenarioLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/resumetabletopconduct/' + token + '/' +
                companyId + '/' +portalId+'/'+ scenario.scenarioId + '/' + conductId);
            const data = await response.json();
            setConduct(data);
            setChatId(data.chatId);
            setConductId(data.conductId);
            setConductScriptItems(data.script);
            setShowCompleteButton(false);
            setIsScenarioLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    async function handleCompleteTabletop() {
        try {
            setBannerText("Completing Tabletop...");
            setIsScenarioLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/tabletop/complete/' + token + '/' +
                companyId + '/' +portalId+'/'+ scenario.scenarioId + '/' + conductId);
            const data = await response.json();
            setConduct(data);
            setShowPlayPauseStop(false);
            setIsScenarioLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    function handleConductStart() {
        setBannerText("Starting Tabletop...");
        setIsStarted(true);
        setShowScheduleButton(false);
        setIsScenarioLoading(true);
        setShowCompleteButton(false);
        setIsScenarioLoading(false);
        setIsStopped(false);
    }

    function handleConductPause() {
        const fetchData = async () => {
            try {
                setBannerText("Pausing Tabletop...")
                setIsScenarioLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/tabletop/pause/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId);
                const data = await response.json();
                setConduct(data);
                setIsScenarioLoading(false);
                setIsStopped(true);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }

function handleConductUpdate(updatedConduct: Conduct | null) {
    const fetchData = async () => {
        try {
            setBannerText("Updating Tabletop...");
            setIsScenarioLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/company/scenario/tabletop/updateconduct/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedConduct)
            });
            const data = await response.json();
            setConduct(data);
            setIsScenarioLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    fetchData();
}

    function handleConductParticipantAttended(participantId: string) {

        if (conduct && conduct.participants) {
            const updatedParticipants = conduct?.participants.map(p => {
                if (p.participantId === participantId) {
                    return {...p, attended: !p.attended};
                } else {
                    return p;
                }
            });
            // @ts-ignore
           conduct.participants = updatedParticipants;
        }
        if (conduct) {
            handleConductUpdate(conduct);
        }
    }
    const [conductReviewDrawerOpen, setConductReviewDrawerOpen] = useState(false);
    const [conductPlanDrawerOpen, setConductPlanDrawerOpen] = useState(false);
    const drawerWidth = 450;
    const toggleConductPlanDrawer = () => {
        setDrawersToClosed();

        setConductPlanDrawerOpen(!conductPlanDrawerOpen);
    };

    const setDrawersToClosed = () => {
        setConductPlanDrawerOpen(false);
        setConductReviewDrawerOpen(false);
        setConductFileDrawerOpen(false);
        setConductScheduleDrawerOpen(false);
    }
    const toggleConductReviewDrawer = () => {
        setDrawersToClosed();

        setConductReviewDrawerOpen(!conductReviewDrawerOpen);
    };

    const toggleConductFileDrawer = () => {
        setDrawersToClosed();

        setConductFileDrawerOpen(!conductFileDrawerOpen);
    };

    const [conductFileDrawerOpen, setConductFileDrawerOpen] = useState(false);

    const toggleConductScheduleDrawer = () => {
        setDrawersToClosed();
        setConductScheduleDrawerOpen(!conductScheduleDrawerOpen);
    };

    const [conductScheduleDrawerOpen, setConductScheduleDrawerOpen] = useState(false);

    const [participants, setParticipants] = useState<UserLinkKey[]>([]);
    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/access/getportalusers/'+token+ '/' +companyId+'/' +portalId);
                const data = await response.json();
                setParticipants(data);
            } catch (error) {
                console.error('Failed to fetch participants:', error);
            }
        };

        fetchParticipants();
    }, []);

    const handleUpload = (json: any) => {
        // Parse the JSON object
        const data = JSON.parse(json);

        // Use the parsed data to update the conduct state
        setConduct(data);
    };

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleFileReview(file: ConductFile, reviewed: boolean) {
        console.log("Review File: "+file.fileName);
        //call the API to update the file and then update the state
        const fetchData = async () => {
            try {
                setBannerText("Reviewing File...");
                setIsScenarioLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/files/conduct/reviewfile/'+token+ '/' +companyId+'/' +
                    portalId+'/'+ scenario.scenarioId+'/' + conductId+'/'+file.fileId+'/'+reviewed);
                const data = await response.json();
                setConduct(data);
                setIsScenarioLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }
    function handleDownload(file: ConductFile) {
        const url = process.env.REACT_APP_REST_API_URL + '/api/files/conduct/getfile/' + token + '/' + companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + file.fileId;
        window.open(url, '_blank');
    }

    function handleArtifactDownload(artifact: Artifact) {
        console.log("Download Artifact");
        setCurrentArtifact(artifact);
        setOpenArtifactDialog(true);
    }

    const [isGeneratingArtifact, setIsGeneratingArtifact] = useState(false);

    async function handleRegenerateArtifact(artifact: Artifact) {
        if (!artifact) return;

        try {
            const url = process.env.REACT_APP_REST_API_URL + '/api/files/conduct/regenerateartifacttext/' + token + '/' + companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId + '/' + artifact.artifactId;
            setIsGeneratingArtifact(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(artifact)
            });

            const data = await response.json();

            // replace the artifact in the conduct.artifacts array
            if (conduct) {
                conduct.artifacts = conduct.artifacts.map(a => a.artifactId === data.artifactId ? data : a);
                setArtifacts(conduct.artifacts);
            }
        } catch (error) {
            console.error('Failed to create artifact:', error);
        }
        setIsGeneratingArtifact(false);
    }

    const [currentArtifact, setCurrentArtifact] = useState<Artifact | null>(null);
    const [openArtifactDialog, setOpenArtifactDialog] = useState(false);

    const [artifactName, setArtifactName] = useState('');
    const [artifactDescription, setArtifactDescription] = useState('');

    // Function to create an artifact
    const createArtifact = async () => {
        const artifact = {
            name: artifactName,
            description: artifactDescription
        };

        try {
            setIsGeneratingArtifact(true);
            const url = process.env.REACT_APP_REST_API_URL + '/api/files/conduct/createartifact/' + token + '/' + companyId + '/' + portalId + '/' + scenario.scenarioId + '/' + conductId;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(artifact)
            });

            const data = await response.json();

            //make sure data is added to conduct.artifacts.  If it doesn't exist, create it.
            if (conduct) {
                if (!conduct.artifacts) {
                    conduct.artifacts = [];
                }
                conduct.artifacts.push(data);
                setArtifacts(conduct.artifacts);
            }

        } catch (error) {
            console.error('Failed to create artifact:', error);
        }
        setArtifactName('');
        setArtifactDescription('');
        setIsGeneratingArtifact(false);
    };

    function handleConductParticipantNotify(participantId: string) {
        const notifyParticipant = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/tabletop/notifyparticipant/'+token+ '/' +portalId+'/' +companyId+'/'+ scenario.scenarioId+'/' + conductId+"/"+participantId);
                const data = await response.json();
                setConduct(data);
            } catch (error) {
                console.error('Failed to fetch participants:', error);
            }
        };

        notifyParticipant();

    }

    function handleConductParticipantRemove(participantId: string) {
        const removeParticipant = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/tabletop/removeparticipant/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId+"/"+participantId);
                const data = await response.json();
                setConduct(data);
            } catch (error) {
                console.error('Failed to fetch participants:', error);
            }
        };

        removeParticipant();

    }

    function handleRefresh() {
        const refreshConduct = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/gettabletop/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId);
                const data = await response.json();
                setConduct(data);
            } catch (error) {
                console.error('Failed to fetch conduct:', error);
            }
        };

        refreshConduct();
    }

    const [isJoinButtonClicked, setIsJoinButtonClicked] = useState(false);
    function handleParticipantJoin() {
        const fetchData = async () => {
            try {
                setBannerText("Joining Tabletop...")
                setIsScenarioLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/scenario/tabletop/join/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId);
                const data = await response.json();
                setConduct(data);
                setChatId(data.chatId);
                setConductId(data.conductId);
                setConductScriptItems(data.script);
                setIsScenarioLoading(false);
                setIsStopped(false);
                setIsStarted(true);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }
    const [conductBriefOverviewOpen, setConductBriefOverviewOpen] = useState(false);
    const [conductBrief, setConductBrief] = useState<ConductBrief | null>(null);

    async function handleConductBriefInit() {

        if (conduct && !conductBrief) {
            setConductBrief(await initializeConductBrief(conduct));
        }
        setConductBriefOverviewOpen(true)
    }

    const [scenarioWalkthroughOpen, setScenarioWalkthroughOpen] = useState(false);

    function handleScenarioWalkthroughOpen() {
        setScenarioWalkthroughOpen(true);
    }

    function handleScenarioWalkthroughClose() {
        setScenarioWalkthroughOpen(false);
    }


    function handleUpdateConductBrief(conductBrief: ConductBrief) {
        console.log("Update Conduct Brief: "+conductBrief.name)
        //post to the server
        const fetchData = async () => {
            try {
                setBannerText("Updating Conduct Brief...");
                setIsScenarioLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/company/conduct/updatebrief/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(conductBrief)
                });
                const data = await response.json();
                setConductBrief(data);
                setIsScenarioLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }

    async function initializeConductBrief(conduct: Conduct): Promise<ConductBrief> {
        // Call an API for the ConductBrief and if not found return a new one
        const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/company/conduct/getbrief/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId);
        let data = await response.json();

        //if data gets return add on the conduct name, description, objectives, notes, and actionItemSummary

        if (data) {
            data = {
                name: conduct.name,
                description: conduct.description,
                objectives: conduct.objective,
                observations: "",
                notes: conduct.notes,
                actionItemSummary: ""
            };
        }

        return data;
    }

    const [scenarioOverviewOpen, setScenarioOverviewOpen] = useState(false);

    const handleScenarioOverviewOpen = () => {
        setScenarioOverviewOpen(true);
    };

    const handleScenarioOverviewClose = () => {
        setScenarioOverviewOpen(false);
    };

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedInject, setSelectedInject] = useState<Inject>({ description: '', type: '' });

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    function handleAddInject(inject: Inject) {
        setSelectedInject(inject);
        setDialogOpen(true);
    }

    const handleSaveInject = async (inject: Inject) => {
        const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/conduct/inject/message/'+token+ '/' +companyId+'/' +portalId+'/'+ scenario.scenarioId+'/' + conductId+'/'+selectedScriptId+'/'+conduct?.chatId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({text: inject.description}),
        });
        const data = await response.json();
        console.log(data);
        handleCloseDialog();
    };

    function handleScriptItemSelect(conductScriptItem: ConductScriptItem) {
        console.log("Script Item Selected: "+conductScriptItem.id);
        setSelectedScriptId(conductScriptItem.id);
    }

    return (
        <Grid container spacing={2}>
            {scenario && conduct && <ScenarioOverviewDialog
                title={scenario.name}
                objectives={conduct.objective}
                agenda={conduct.agenda}
                instructions={scenario.forParticipantInstructions}
                scenarioStory={scenario.scenarioStory}
                scenarioOverviewOpen={scenarioOverviewOpen}
                handleScenarioOverviewClose={handleScenarioOverviewClose}
            />}
            {scenario && conduct &&
                <ScenarioWalkthroughDialog
                    scenario={scenario}
                    conduct={conduct}
                    scenarioOverviewOpen={scenarioWalkthroughOpen}
                    handleScenarioWalkthroughClose={handleScenarioWalkthroughClose}
                    handleResourceDownload={handleDownload}
                />
            }
            <Grid item xs={12}>
                <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {isScenarioLoading &&
                                <QuickLoaderComponent loadBanner={bannerText} />
                            }
                        </Grid>
                    <Grid item xs={5}>
                                <Stack direction="row" justifyContent="flex-start" alignItems={"center"} spacing={1}>
                                <Typography variant="h3">{scenario.name}</Typography>
                                {conduct && !conduct.isComplete &&
                                    <Chip variant="filled" label={status} size={"small"} color={ isStopped? "warning":"success"} />
                                }
                                {conduct && conduct.isComplete &&
                                    <Chip variant="filled" label={"Tabletop Complete"} color={"success"} />
                                }
                                </Stack>
                            </Grid>
                    <Grid item xs={3}>
                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                            <ButtonGroup>
                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                <>
                                {conduct && !conduct.isComplete && showScheduleButton &&
                                    <Tooltip title="Schedule your tabletop">
                                        <Button variant="outlined" size="small" onClick={toggleConductScheduleDrawer}><EditCalendarOutlined titleAccess={"Schedule your tabletop"}/></Button>
                                    </Tooltip>
                                }
                                </>
                             }
                            <Tooltip title={userProfile && userProfile.role != 'PortalViewParticipant' ? "Add/edit tabletop participants" : "View tabletop participants"}>
                                    <Button variant="outlined" size="small" onClick={() => setOpenAddParticipantDialog(true)}><PeopleAltOutlined /></Button>
                            </Tooltip>
                            <Tooltip title={userProfile && userProfile.role != 'PortalViewParticipant' ? "Add/view files to the tabletop" : "View files"}>
                                <Button variant="outlined" size="small" onClick={toggleConductFileDrawer}><FileUploadOutlined /></Button>
                            </Tooltip>
                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                <>
                                <Tooltip title="View tabletop injects">
                                    <Button variant="outlined" size="small" onClick={toggleConductPlanDrawer}><InsertPageBreakOutlined /></Button>
                                </Tooltip>
                                {/*<Tooltip title="View tabletop activity">*/}
                                {/*<Button variant="outlined" size="small" onClick={toggleConductReviewDrawer}><AssessmentIcon titleAccess={"View tabletop activity"}/></Button>*/}
                                {/*</Tooltip>*/}
                                </>
                            }
                            </ButtonGroup>
                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                            <ButtonGroup>
                                <Tooltip title={"View scenario background"}>
                                    <Button  variant="outlined" onClick={handleScenarioOverviewOpen}>
                                        Overview
                                    </Button>
                                </Tooltip>
                                {isStarted &&
                                    <Button  variant="outlined" onClick={handleScenarioWalkthroughOpen}>
                                        Walkthrough
                                    </Button>
                                }
                            </ButtonGroup>
                            }
                        </Stack>

                    </Grid>
                    <Grid item xs={4}>
                            <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                {userProfile && userProfile.role != 'PortalViewParticipant' ?
                                    (<>
                                    {showPlayPauseStop && !conduct?.isComplete &&
                                        <ButtonGroup>
                                            <PlayPauseStopButtons handlePlay={handleConductStart} handlePause={handleConductPause} handleStop={handleConductStop} />
                                        </ButtonGroup>
                                    }
                                    {conduct && showCompleteButton && !conduct.isComplete &&
                                        <Tooltip title="Complete the Tabletop">
                                        <Button size={"small"} variant={"contained"} color={"success"} onClick={handleCompleteTabletop}>
                                            <DoneOutlined />  Complete
                                        </Button>
                                        </Tooltip>
                                    }
                                    {conduct && conduct.isComplete &&
                                        <>
                                            <ButtonGroup>
                                            <Tooltip title="View/Edit the Post-tabletop brief">
                                                <Button size={"small"} variant={"outlined"}
                                                        onClick={() => handleConductBriefInit()}
                                                >
                                                    <AssessmentIcon />  View Brief
                                                </Button>
                                            </Tooltip>
                                            <Link  component={RouterLink} to={`/portal/aar/${portalId}/${companyId}/${scenario.scenarioId}/${conduct.conductId}`}>
                                            <Tooltip title="View the After-Action Report for this Tabletop">
                                            <Button size={"small"} variant={"outlined"}>
                                                <AssessmentIcon />  View AAR
                                            </Button>
                                            </Tooltip>
                                             </Link>
                                            </ButtonGroup>
                                        </>
                                    }
                                    </>) : (
                                        <>
                                            <Button size={"small"} variant={"outlined"} onClick={() => { handleParticipantJoin(); setIsJoinButtonClicked(true); }} disabled={isJoinButtonClicked}>
                                                <JoinFullOutlined />  Join
                                            </Button>
                                        </>
                                    )
                            }
                            <Link color="primary" component={RouterLink} to={`/portal/viewportal/${companyId}/${portalId}`}>
                                <Tooltip title="Exit the Tabletop and return to the Portal">
                                <Button
                                    variant="outlined"
                                    size={"small"}
                                >
                                    <ArrowBackOutlined/>Portal
                                </Button>
                                </Tooltip>
                            </Link>
                        </Stack>
                    </Grid>
                        {scenario &&
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {chatId && isStarted && conduct ?
                                            <>
                                            <ScenarioTabletopChatComponent
                                                scenario={scenario} chatId={chatId}
                                                portalId={portalId}
                                                conduct={conduct}
                                                participants={conduct?.participants}
                                                isTabletopStarted={isStarted}
                                                conductScriptItems={conductScriptItems}
                                                conductFiles={conduct?.files}
                                                conductArtifacts={conduct?.artifacts}
                                                setConductScriptItems={setConductScriptItems}
                                                onConductUpdate={handleConductUpdate}
                                                //selectedScriptId={selectedScriptId}
                                                //selectedActionId={selectedActionId}
                                                onCompleteTabletop={handleCompleteTabletop}
                                                setSelectedConductScriptItem={handleScriptItemSelect}
                                                userProfile={userProfile}
                                                refreshConduct={handleRefresh}
                                            />
                                            </>
                                            :
                                            <>
                                            <Box style={{ overflow: 'auto', minHeight: window.innerHeight }}>
                                                {conduct?.isComplete ?
                                                    <Card>
                                                        <CardContent>
                                                           <FinishedBanner/>
                                                        </CardContent>
                                                    </Card>
                                                :
                                                    <Card>
                                                        <CardContent style={{ height: '750px', overflow: 'auto' }}>
                                                            <ScrollX>
                                                            <Grid container={true} spacing={2}>
                                                                <Grid item xs={12}>
                                                                    {scenario && <SectionFlow sections={scenario.script} /> }
                                                                </Grid>
                                                                <Grid item xs={scenario?.scenarioStory?.images?8:5}>
                                                                    <Typography variant={"h5"}>
                                                                        Scenario Background
                                                                    </Typography>
                                                                        {scenario.scenarioStory && scenario.scenarioStory.storyBackground ?
                                                                            <Typography variant={"h6"}>
                                                                                {scenario.scenarioStory?.storyBackground.split('\n').map((line, index) => (
                                                                                    <Typography key={index} variant="h5">
                                                                                       <ReactMarkdown children={line} />
                                                                                    </Typography>
                                                                                ))}
                                                                            </Typography>
                                                                            :
                                                                            <Typography variant={"h6"}>
                                                                                No background information available
                                                                            </Typography>
                                                                        }
                                                                    <Typography variant={"h5"}>
                                                                        <br/>Scenario Overview<br/>
                                                                    </Typography>
                                                                        {scenario.scenarioStory && scenario.scenarioStory.storyOverview ?
                                                                            <Typography variant={"h6"}>
                                                                                {scenario.scenarioStory?.storyOverview.split('\n').map((line, index) => (
                                                                                    <Typography key={index} variant="h5">
                                                                                        <ReactMarkdown children={line} />
                                                                                    </Typography>
                                                                                ))}
                                                                            </Typography>
                                                                            :
                                                                            <Typography variant={"h6"}>
                                                                                No overview information available
                                                                            </Typography>
                                                                        }
                                                                    <Typography variant={"h5"}>
                                                                        Scenario Objectives
                                                                    </Typography>
                                                                    {scenario.objective &&
                                                                        <Typography variant={"h6"}>
                                                                            {scenario.objective.split('\n').map((line, index) => (
                                                                                <Typography key={index} variant="h5">
                                                                                    <ReactMarkdown children={line} />
                                                                                </Typography>
                                                                            ))}
                                                                        </Typography>
                                                                    }
                                                                </Grid>
                                                                {scenario?.scenarioStory?.images &&
                                                                    <Grid item xs={4}>
                                                                        <Grid container={true} spacing={2}>
                                                                            <Grid item xs={12}>
                                                                                <Stack direction="row" justifyContent="center" alignItems={"center"} spacing={1}>
                                                                                <Grid container={true} spacing={2}>
                                                                                    <ScenarioImageGridDisplay images={scenario?.scenarioStory?.images}/>
                                                                                </Grid>
                                                                                </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                {conduct && conduct.landingPageCode &&
                                                                    <Grid item xs={12}>
                                                                    <Typography variant={"h5"}>
                                                                        <LandingPageLink landingPageCode={conduct.landingPageCode}/>
                                                                    </Typography>
                                                                    </Grid>
                                                                }
                                                            {/*<Button size={"small"} variant={"outlined"} onClick={handleConductStart}>*/}
                                                            {/*    {userProfile && userProfile.role!="PortalViewParticipant" ? "Start":"Join"} Tabletop*/}
                                                            {/*</Button>*/}
                                                            {/*    <Grid item xs={12}>*/}
                                                            {/*        <Divider/>*/}
                                                            {/*    </Grid>*/}
                                                            </Grid>
                                                            </ScrollX>
                                                        </CardContent>
                                                    </Card>
                                                }
                                            </Box>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    {/*<Typography variant="caption">Conduct: {conductId}</Typography>*/}
                    {/*<Typography variant="caption">Conduct Status: {conduct?.status}</Typography>*/}
                    </Grid>
                    </MainCard>
            </Grid>

            <ScenarioParticipantAddDialog
                open={openAddParticipantDialog}
                userProfile={userProfile}
                onClose={handleCloseAddParticpantDialog}
                onRemove={handleConductParticipantRemove}
                userLinks={participants}
                participants={conduct?.participants}
                onAdd={handleAddParticpantDialog}
                onAttended={handleConductParticipantAttended}
                onNotify={handleConductParticipantNotify}
                conduct={conduct}
            />


            <Drawer open={conductFileDrawerOpen} onClose={toggleConductFileDrawer}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            overflow: 'auto', // Add this line
                        },
                    }}
                    anchor="left"
                    variant={"temporary"}
            >
                <MainCard title="Manage Files for the Tabletop" sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        overflow: 'auto', // Add this line
                    }
                }} >

                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label={`Files ${conduct && conduct?.files?.length > 0 ? `(${conduct.files.length})` : ''}`} />
                        <Tab label={`Artifacts ${conduct && conduct?.artifacts?.length > 0 ? `(${conduct.artifacts.length})` : ''}`} />
                    </Tabs>

                    {tabValue === 0 && (
                        <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <Typography variant="caption">Current Tabletop files</Typography>
                        </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={handleRefresh}>
                                    <RefreshOutlined/>
                                </IconButton>
                            </Grid>
                        <Grid item xs={12}>

                            {conduct && conduct.files && conduct.files.length > 0 ?
                                <FileList userProfile={userProfile} files={conduct?.files} onDownload={handleDownload} onReview={handleFileReview} /> : <Typography variant="caption">No files uploaded</Typography>
                            }
                        </Grid>
                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">Add files to the Tabletop</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FileUploader title={"Tabletop Exercise: Upload Files"} apiUrl={process.env.REACT_APP_REST_API_URL+`/api/files/upload/conduct/${token}/${companyId}/${portalId}/${scenario.scenarioId}/${conductId}`} onUpload={handleUpload}/>
                                </Grid>
                                </>
                            }
                    </Grid>
                    )}

                    {tabValue === 1 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="caption">Current Tabletop artifacts</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {conduct && artifacts && artifacts.length > 0 ?
                                    <ArtifactList userProfile={userProfile} artifacts={artifacts} onDownload={handleArtifactDownload} onRegenerate={handleRegenerateArtifact} /> : <Typography variant="caption">No artifacts uploaded</Typography>
                                }
                            </Grid>
                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="caption">Create a new artifact</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {isGeneratingArtifact && <QuickLoaderComponent loadBanner={"Generating Artifact..."} />}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Name"
                                        fullWidth={true}
                                        variant="outlined"
                                        value={artifactName}
                                        onChange={e => setArtifactName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Description"
                                        fullWidth={true}
                                        variant="outlined"
                                        multiline={true}
                                        rows={2}
                                        value={artifactDescription}
                                        onChange={e => setArtifactDescription(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="outlined" onClick={createArtifact}>Create Artifact</Button>
                                </Grid>
                                </>
                            }
                        </Grid>
                    )}

                </MainCard>
            </Drawer>
            {conductBrief &&
            <ConductBriefOverviewDialog
                conductBrief={conductBrief}
                conductBriefOverviewOpen={conductBriefOverviewOpen}
                handleConductBriefOverviewClose={() => setConductBriefOverviewOpen(false)}
                updateConductBrief={handleUpdateConductBrief} // replace with your function to update the conduct brief
            />
            }
            {conduct &&
                <ScheduleConductAndParticipantsDialog
                    scheduleConductOpen={conductScheduleDrawerOpen}
                    handleUpdateConduct={handleConductUpdate}
                    handleScheduleConductClose={toggleConductScheduleDrawer}
                    conduct={conduct}
                    userProfile={userProfile}
                />
            }


            {/*<ScheduleDrawer*/}
            {/*    open={conductScheduleDrawerOpen}*/}
            {/*    onClose={toggleConductScheduleDrawer}*/}
            {/*    conduct={conduct}*/}
            {/*    handleDialogUpdate={handleDialogUpdate}*/}
            {/*    setConduct={setConduct}*/}
            {/*/>*/}

            <Drawer open={conductPlanDrawerOpen} onClose={toggleConductPlanDrawer}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                        },
                    }}
                    anchor="left"
                    variant={"persistent"}
            >
                <MainCard title="Scenario Injects" sx={{ overflow: 'auto' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Defined Tabletop Injects</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" color={"secondary"}>Injects are the actions, events, or information that are injected into the tabletop to drive the scenario.
                                    The scenario designer has defined these as possible injects to use during the tabletop exercise.  Click the "+" to post this inject to the tabletop chat.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}> <Divider/></Grid>
                            <Grid item xs={12}>
                                {conduct && conduct.injects && <ScenarioInjectsTabletopComponent injects={conduct.injects} onAddInject={handleAddInject}/>}
                            </Grid>
                        </Grid>

                    </Grid>
                </MainCard>
            </Drawer>

            <Drawer open={conductReviewDrawerOpen} onClose={toggleConductReviewDrawer}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                        },
                    }}
                    anchor="left"
                    variant={"persistent"}
            >
                <MainCard title="Tabletop Review" >
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="h6">Tabletop Review</Typography>
                            </Grid>
                            <ScriptItemListComponent scriptItems={conductScriptItems} showSteps={false} showConductSteps={true}/>
                        </Grid>

                    </Grid>
                </MainCard>
            </Drawer>

            <Dialog
                open={openArtifactDialog}
                fullWidth={true}
                onClose={() => setOpenArtifactDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Artifact Details"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {currentArtifact && currentArtifact.artifactText && currentArtifact?.artifactText.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Card>
                                <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                    <Button variant="contained" onClick={() => setOpenArtifactDialog(false)} color="primary" autoFocus>
                                        Close
                                    </Button>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <EditInjectDialog
                inject={selectedInject}
                open={dialogOpen}
                onClose={handleCloseDialog}
                onSave={handleSaveInject}
            />
        </Grid>
);
};
export default PortalScenarioConductFullComponent;