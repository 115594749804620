// third-party
import { FormattedMessage } from 'react-intl';

// assets
// type
import { NavItemType } from 'types/menu';
import {LibraryBooksOutlined} from "@mui/icons-material";
import {SettingOutlined} from "@ant-design/icons";

// icons


// ==============================|| MENU ITEMS - PAGES ||============================== //

const myItems: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="my-items" />,
  type: 'group',
  children: [
    // {
    //   id: 'knowledge-base',
    //   title: <FormattedMessage id="my-knowledge-base" />,
    //   type: 'item',
    //   url: '/knowledgebase',
    //   icon: LibraryBooksIcon,
    // },
    {
      id: 'scenariolibrary',
      title: <FormattedMessage id="my-scenario-library" />,
      type: 'item',
      url: '/scenariolibrary',
      icon: LibraryBooksOutlined,
    },
    {
      id: 'organization',
      title: <FormattedMessage id="my-organization" />,
      type: 'item',
      url: '/organization',
      icon: SettingOutlined,
    }
    // {
    //   id: 'models',
    //   title: <FormattedMessage id="my-models" />,
    //   type: 'item',
    //   url: '/models',
    //   icon: ModelTrainingIcon,
   // }
    // {
    //   id: 'scenarios',
    //   title: <FormattedMessage id="my-scenarios" />,
    //   type: 'item',
    //   url: '/scenarios',
    //   icon: SensorOccupiedIcon,
    // }
  ]
};

export default myItems;
