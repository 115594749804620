// project import
import dashboard from './sample-page';
import myItems from './my-item-page';

// types
import { NavItemType } from 'types/menu';
import pages from "./pages";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, myItems, pages]
};

export default menuItems;
