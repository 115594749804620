import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    Divider,
    Grid, List,
    Stack,
    Typography
} from '@mui/material';
import {
    Conduct,
    ConductFile,
    Scenario,
    ScenarioImage,
    ScenarioScriptSectionStep,
    ScriptItem
} from "../../interfaces/interfaces";
import ScrollX from "../ScrollX";
import SectionFlow from "../tabletop/SectionFlow";
import {
    ArrowBackOutlined,
    ArrowForwardOutlined, OpenInBrowserOutlined
} from "@mui/icons-material";
import ScenarioImageGridDisplay from "../scenario/ScenarioImageGridDisplay";
import {useOrganization} from "../../hooks/useOrganization";
import OrganizationBrandingImageDisplay from "../organization/OrganizationBrandingImageDisplay";
import ReactMarkdown from "react-markdown";

interface ScenarioWalkthroughDialogProps {
    scenario:Scenario;
    conduct?: Conduct;
    scenarioOverviewOpen: boolean;
    handleScenarioWalkthroughClose: () => void;
    handleResourceDownload?: (file:ConductFile) => void;
}


const ScenarioWalkthroughDialog: React.FC<ScenarioWalkthroughDialogProps> = ({ scenario, scenarioOverviewOpen,
                                                                                 handleScenarioWalkthroughClose, conduct, handleResourceDownload }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [currentScriptItem, setCurrentScriptItem] = useState<ScriptItem | undefined>(undefined);

    //get the organization from the context
    const organization = useOrganization();
    type ScriptItemFlat = { type: 'section', content: string, background:string, images?:ScenarioImage[], stepType?:string } |
        { type: 'step', content: string, stepContent: string, conductStepId?:string, images?:ScenarioImage[], stepType?:string, talkingPoint?:string, stepResources?:string[] };

    const scriptSource = conduct && conduct.script ? conduct.script : scenario.script;

    const flattenedScript: ScriptItemFlat[] = scriptSource ? scriptSource.reduce((acc: ScriptItemFlat[], scriptItem) => {
        acc.push({ type: 'section', content: scriptItem.section, background: scriptItem.background, images:scriptItem?.images});
        if (scriptItem.conductScriptItems && scriptItem.conductScriptItems.length > 0) {
            scriptItem.conductScriptItems.forEach(step => {
                acc.push({type: 'step', content: step.name, stepContent: step.instructions, conductStepId: step.id, images:step?.images, stepType: step.type, talkingPoint: step.talkingPoint, stepResources: step.resources});
            });
        } else {
            if (scriptItem.scenarioScriptSectionSteps && scriptItem.scenarioScriptSectionSteps.length > 0) {
                scriptItem.scenarioScriptSectionSteps.forEach(step => {
                    acc.push({type: 'step', content: step.stepName, stepContent: step.stepContent, images:step?.images, stepType: step.stepType, talkingPoint: step.stepTalkingPoint, stepResources: step.stepResources});
                });
            }
        }
        return acc;
    }, []) : [];
    const handleClose = () => {
        setCurrentStep(0);
        handleScenarioWalkthroughClose();
    };

    const renderStepContent = () => {
        if (currentStep < 3) {
            switch (currentStep) {
                case 0:
                    return {
                        title: 'Overview',
                        images: scenario.scenarioStory?.images,
                        content: scenario.scenarioStory?.storyOverview ? scenario.scenarioStory?.storyOverview.split('\n').map((line: string, index: number) => (
                            <>
                                {line && line.length>0 && <ReactMarkdown key={index} children={`#### ${line}`} /> }
                            </>
                        )) : 'Tabletop Exercise Overview for '+scenario.name
                    };
                case 1:
                    return {
                        title: scenario.scenarioStory && scenario.scenarioStory.storyBackground? 'Background' : 'Description',
                        content: scenario.scenarioStory && scenario.scenarioStory.storyBackground ? scenario.scenarioStory.storyBackground.split('\n').map((line: string, index: number) => (
                            <>
                                {line && line.length>0 && <ReactMarkdown key={index} children={`#### ${line}`} /> }
                            </>
                        )) : scenario.description
                    };
                case 2:
                    return {
                        title: 'Objectives',
                        content: scenario.objective ? scenario.objective.split('\n').map((line: string, index: number) => (
                            <>
                            {line && line.length>0 && <ReactMarkdown key={index} children={`#### ${line}`} /> }
                            </>
                        )) : 'No objectives provided'
                    };
                case 3:
                    return {
                        title: 'Script',
                        content: scenario.script ? scenario.script.map((scriptItem: ScriptItem, index: number) => (
                            <React.Fragment key={index}>
                                <Typography variant="h3">{scriptItem.section} </Typography>
                                {scriptItem.scenarioScriptSectionSteps.map((step: ScenarioScriptSectionStep, stepIndex: number) => (
                                    <React.Fragment key={stepIndex}>
                                        <Typography variant="h4">{step.stepName} </Typography>
                                        {step.stepContent && step.stepContent.split(/\n|•/).map((line, index) => (
                                            <>
                                                {line && line.length>0 && <ReactMarkdown key={index} children={`### ${line}`} /> }
                                            </>
                                        ))}
                                        {step.stepType && <Chip label={step.stepType} color={"primary"}/> }
                                        <ScenarioImageGridDisplay images={step.images}/>
                                        <br/>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        )) : 'No script provided'
                    };
                default:
                    return null;
            }
        } else {
            const scriptStep = currentStep - 3 ;
            if (scriptStep < flattenedScript.length) {
                const item = flattenedScript[scriptStep];
                if (item.type === 'section') {
                    return {
                        title: item.content,
                        content: "",
                        background: item.background,
                        images: item.images
                    };
                } else if (item.type === 'step') {
                    return {
                        title: item.content,
                        content: item.stepContent,
                        images: item.images,
                        stepType: item.stepType,
                        talkingPoint: item.talkingPoint,
                        stepResources: item.stepResources,
                        conductStepId: item.conductStepId
                    };
                }
            }
        }    };
    const stepContent = renderStepContent();
    //need the current date formated as mm/dd/yyyy
    const currentDate = new Date();

    useEffect(() => {
        if (currentStep >= 3) {
            const scriptStep = currentStep - 3;
            if (scriptStep < flattenedScript.length) {
                const item = flattenedScript[scriptStep];
                if (item.type === 'section') {
                    setCurrentScriptItem(scriptSource.find(scriptItem => scriptItem.section === item.content));
                }
            }
        }
    }, [currentStep, flattenedScript, scriptSource]);
    return (
    <Dialog fullScreen open={scenarioOverviewOpen} onClose={handleClose}>
        <Card sx={{ border: '1px solid #000', overflow: 'auto' }}>
                <CardContent >
                    <Grid container={true} spacing={4}>
                        <Grid item xs={1} sx={{ backgroundColor: organization.organization?.brandHexColor ? organization.organization?.brandHexColor : undefined }}>
                            {organization && organization.organization?.logoSmallLocation ?
                                <OrganizationBrandingImageDisplay location={organization.organization.logoSmallLocation} />:
                                <Typography variant="h5"  gutterBottom>
                                    <Chip label={(scenario.exerciseType?scenario.exerciseType:"Tabletop")} variant={"outlined"} />
                                </Typography>
                            }
                        </Grid>
                        <Grid item={true} xs={10}>
                            <Grid container={true} spacing={3} >
                                <Grid item={true} xs={12}>
                                <Grid container={true} spacing={2} >
                                    <Grid item={true} xs={12}>
                                        <Stack direction={"row"} justifyContent={"center"}>
                                            <Typography variant="h1"  gutterBottom>
                                                {scenario.name} Walkthrough
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                        {scenario && <SectionFlow sections={scenario.script} currentSection={currentScriptItem} /> }
                                    </Grid>
                                    <Grid item={true} xs={12}>
                                    <Divider/>
                                    </Grid>
                                </Grid>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Typography variant="h2" gutterBottom>
                                        {stepContent?.title}
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Grid container={true} spacing={2} >
                                        <Box style={{ minHeight: '700px', overflow: 'auto' }}>
                                        <ScrollX>
                                            <Grid item={true} xs={12}>
                                            <Grid container={true} spacing={2} >
                                                <Grid item={true} xs={stepContent?.images?8:12}>
                                                    {typeof stepContent?.content === 'string' ? stepContent.content.split(/\n|•/).map((line, index) => (
                                                            <>
                                                                {line && line.length>0 && <ReactMarkdown key={index} children={`## ${line}`} /> }
                                                            </>
                                                        )) :
                                                        <Typography variant="h4" gutterBottom>
                                                            {stepContent?.content}
                                                        </Typography>
                                                    }
                                                    {stepContent?.background && stepContent.background.split(/\n|•/).map((line, index) => (
                                                       <>
                                                           {line && line.length>0 && <ReactMarkdown key={index} children={`## ${line}`} /> }
                                                       </>
                                                    ))}
                                                </Grid>
                                                {stepContent?.images &&
                                                    <Grid item={true} xs={stepContent?.images?4:12}>
                                                            <Stack direction={"row"} justifyContent={"center"}>
                                                            <ScenarioImageGridDisplay images={stepContent.images}/>
                                                            </Stack>
                                                    </Grid>
                                                }
                                            </Grid>
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                            <Grid container={true} spacing={2} >
                                                {/*<Grid item={true} xs={12}>*/}
                                                {/*    <Stack direction={"row"} justifyContent={"center"} alignItems={'center'}>*/}
                                                {/*        {stepContent?.stepType && (*/}
                                                {/*            <>*/}
                                                {/*                {stepContent.stepType === 'Discussion' &&*/}
                                                {/*                    <Stack direction="column" alignItems="center">*/}
                                                {/*                        <ForumOutlined style={{ fontSize: 64 }} color={"warning"}/>*/}
                                                {/*                        <Typography variant="caption" fontStyle={"italic"} gutterBottom>Discussion</Typography>*/}
                                                {/*                    </Stack>*/}
                                                {/*                }*/}
                                                {/*                {stepContent.stepType === 'Inject' &&*/}
                                                {/*                    <Stack direction="column" alignItems="center">*/}
                                                {/*                        <WarningAmberOutlined style={{ fontSize: 64 }} color={"warning"}/>*/}
                                                {/*                        <Typography variant="caption" fontStyle={"italic"} gutterBottom>Inject</Typography>*/}
                                                {/*                    </Stack>}*/}
                                                {/*            </>*/}
                                                {/*        )}*/}
                                                {/*    </Stack>*/}
                                                {/*</Grid>*/}
                                                {stepContent?.talkingPoint &&
                                                <Grid item={true} xs={12}>
                                                    <Grid container={true} spacing={1} >
                                                        <Grid item={true} xs={1}></Grid>
                                                        <Grid item={true} xs={10}>
                                                            <Stack direction={"row"} justifyContent={"center"} alignItems={'center'}>
                                                             <Typography variant="h4" fontStyle={"italic"} gutterBottom>{stepContent.talkingPoint}</Typography>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item={true} xs={2}></Grid>
                                                    </Grid>
                                                </Grid>
                                                }
                                            </Grid>
                                            </Grid>
                                        </ScrollX>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={1} >
                            <Stack direction={"column"} justifyContent={"flex-end"} alignItems={"end"}>
                            <Typography variant="h5"  gutterBottom>
                                <Chip label={currentDate.toLocaleDateString()} variant={"outlined"} />
                            </Typography>
                            </Stack>
                            <Stack direction={"column"} justifyContent={"flex-end"} alignItems={"end"}>
                            <Typography variant="h5"  gutterBottom>
                                <Chip label={(scenario.exerciseType?scenario.exerciseType:"Tabletop")} variant={"outlined"} />
                            </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <DialogActions>
                {stepContent?.stepResources && stepContent.stepResources.length > 0 &&
                    <Grid item={true} xs={12} >
                        <Grid container={true} >
                            <Grid item={true} xs={3} >
                                <List>
                                    {stepContent.stepResources.map((resourceId, index) => {
                                        const resource = scenario?.files?.find(res => res.fileId === resourceId);
                                        return (
                                            <>
                                                { resource && handleResourceDownload &&
                                                    <Button variant={"outlined"}
                                                            onClick={() => handleResourceDownload(resource)}>
                                                        {resource.shortName}&nbsp;&nbsp;<OpenInBrowserOutlined/>
                                                    </Button>
                                                }
                                            </>
                                        );
                                    })}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button variant={"contained"} disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                        <ArrowBackOutlined/>
                    </Button>
                    <Button variant={"contained"} disabled={currentStep === (4+flattenedScript.length)} onClick={() => setCurrentStep(currentStep + 1)}>
                        <ArrowForwardOutlined/>
                    </Button>
                    <Button onClick={handleClose} color="primary" variant={"contained"}>
                        Close
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ScenarioWalkthroughDialog;