import {
    Box,
    Card,
    CardContent, CardHeader, Chip, Divider,
    Grid,
    ListItemText, Stack, Typography,
} from "@mui/material";
import { ScriptItem} from "../../interfaces/interfaces";
import React, {useEffect, useState} from "react";
import {CheckCircle, CircleOutlined, ForkRightOutlined} from "@mui/icons-material";

interface ScenarioScriptTabletopComponentProps {
    scriptItems: ScriptItem[];
    showSteps?: boolean;
    showConductSteps?: boolean;
}

const ScriptItemListComponent: React.FC<ScenarioScriptTabletopComponentProps> = ({ scriptItems, showSteps, showConductSteps }) => {
    const [updatedScriptItems, setUpdatedScriptItems] = useState<ScriptItem[]>(scriptItems || []);


    useEffect(() => {
        setUpdatedScriptItems(scriptItems);
    }, [scriptItems]);

    return (
        <>
            <Grid container={true} spacing={2}>
            {updatedScriptItems && updatedScriptItems.map((scriptItem:ScriptItem,index) => {
                return (
                    <>
                        {scriptItem &&
                           <>
                               <Grid item={true} xs={12}>
                                <Card>
                                    <CardHeader title={"Section "+(index+1)+": "+(scriptItem.name?scriptItem.name:scriptItem.section)} subheader={`${scriptItem.section} - ${scriptItem.background}`} />
                                    <CardContent>
                                        {showConductSteps && scriptItem.conductScriptItems && scriptItem.conductScriptItems.length > 0 &&
                                            <>
                                                {scriptItem.conductScriptItems.map((step, stepIndex) => (
                                                   <>
                                                       <Grid item={true} xs={12}>
                                                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                                                            <ListItemText  secondary={`Step ${stepIndex+1}: ${step.name}`}/>
                                                            <Box>
                                                                {step.branches && step.branches.length > 0 && (
                                                                    <ForkRightOutlined color={"primary"}/>
                                                                )}
                                                                {step.isComplete &&
                                                                    <CheckCircle style={{ color: 'green' }} />
                                                                }
                                                                {!step.isComplete && !step.branches &&
                                                                    <CircleOutlined style={{ color: 'primary' }} />
                                                                }
                                                            </Box>
                                                        </Stack>
                                                    </Grid>
                                                   </>
                                                ))}
                                            </>
                                        }
                                        {showSteps && scriptItem.scenarioScriptSectionSteps && scriptItem.scenarioScriptSectionSteps.length > 0 &&
                                            <>
                                                <Grid container={true} spacing={2}>
                                                    {scriptItem.scenarioScriptSectionSteps.map((step, stepIndex) => (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <Typography variant="h4" component="div">
                                                                    Step {stepIndex+1}</Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <Typography variant="h4" component="div">
                                                                    {step.stepName}</Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                 <Chip label={step.stepType} color={"primary"}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ListItemText primary={"Overview"} secondary={`${step.stepContent} `} sx={{ userSelect: 'text' }} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ListItemText primary={"Instructions"} secondary={step.stepInstructions ? `${step.stepInstructions} ` : '---'}  sx={{ userSelect: 'text' }} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <ListItemText primary={"Talking Points"} secondary={step.stepTalkingPoint ? `${step.stepTalkingPoint} ` : '---'} sx={{ userSelect: 'text' }} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider/>
                                                            </Grid>
                                                        </>
                                                    ))}
                                                </Grid>
                                            </>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                        }
                    </>
                );
            })}
            </Grid>
        </>
    );
};

export default ScriptItemListComponent;