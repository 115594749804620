import React, { useState, useEffect } from 'react';
import { Scenario} from "../../interfaces/interfaces";
import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface ScenarioListProps {
    companyId: string;
    label: string;
    onScenarioSelected: (scenarioId: string) => void;

}
const ScenarioListComponent: React.FC<ScenarioListProps> = ({  companyId, label, onScenarioSelected }) => {
    const [selectedScenario, setSelectedScenario] = useState("");
    const [scenarios, setScenarios] = useState<Scenario[] | null>(null);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/scenario/company/getscenarios/'+token+'/'+companyId);
                const data = await response.json();
                setScenarios(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: SelectChangeEvent) => {
        setSelectedScenario( e.target.value);
        onScenarioSelected(e.target.value);
    };

  return (
      <>
          <InputLabel id="risk-select-list">{label}</InputLabel>
          <Select
              name="type"
              placeholder="Scenario"
              fullWidth
              value={selectedScenario}
              onChange={handleInputChange}
          >
              <MenuItem value="">
                  <em>Select Risk Type</em>
              </MenuItem>
              {scenarios && scenarios.map((scenario,item) => (
                  <MenuItem key={scenario.scenarioId} value={scenario.scenarioId}>{scenario.name}</MenuItem>
              ))}
          </Select>
      </>

  );
};

export default ScenarioListComponent;