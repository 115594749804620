import React, {useEffect, useState} from 'react';
import {AAR, Conduct} from "./interfaces/interfaces";
import {Button, ButtonGroup, Card, CardContent, Chip, Divider, Grid, Stack, TextField, Typography} from "@mui/material";
import QuickLoaderComponent from "./components/cards/QuickLoaderComponent";
import {CheckCircleOutlined} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import MainCard from "./components/MainCard";

interface ActionAnalysisComponentProps {
    conduct: Conduct; // replace with the actual type
    portalId: string;
    companyId: string;
    scenarioId: string;
    aar: AAR;
    editable: boolean;
    onUpdate: () => void;
    isPortal: boolean;
}

const ActionAnalysisComponent: React.FC<ActionAnalysisComponentProps> = ({ conduct, portalId, scenarioId, aar, isPortal, companyId, editable, onUpdate }) => {
    const [frameworkBanner, setFrameworkBanner] = useState<string>("Loading Framework Analysis...");
    const [isFrameworkAnalysisLoading, setFrameworkAnalysisLoading] = useState<boolean>(false);
    const [updatedAAR, setAAR] = useState<AAR>(aar);
    const [frameworkAnalysis, setFrameworkAnalysis] = useState<string[]>([]);

    useEffect(() => {
        if (updatedAAR) {
            setFrameworkAnalysis([updatedAAR.actionAnalysis]);
        }
    }, []);

    useEffect(() => {
        if (frameworkAnalysis) {
            setAAR(prevState => ({
                ...prevState,
                actionAnalysis: frameworkAnalysis.join('\n\n')
            }));
        }
    }, [frameworkAnalysis]);

    const handleSpec = async (specName: string, analysisPrompt: string) => {
        setFrameworkBanner(`Analyzing actions against ${specName}..`);
        setFrameworkAnalysisLoading(true);
        let token = localStorage.getItem("token");
        try {
            const response = await fetch(`${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/analyzeaaractions/${token}/${companyId}/${portalId}/${scenarioId}/${conduct.conductId}/${analysisPrompt}`);
            const data = await response.text(); // get response as text
            setFrameworkAnalysis(prevState => [
                ...prevState,
                `${data}`
            ]);
            setAAR(prevState => ({
                ...prevState,
                actionAnalysis: prevState.actionAnalysis
                    ? `${prevState.actionAnalysis}\n\n ${data}`
                    : `${data}`
            }));
        } catch (error) {
            console.error("Error adding spec:", error);
        }
        setFrameworkAnalysisLoading(false);
    };

    const handleUpdate = async () => {
        setFrameworkBanner("Updating After-Action Review...");
        setFrameworkAnalysisLoading(true);
        let token = localStorage.getItem("token");

        const updateURL = isPortal
            ? `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/updateaar/${token}/${companyId}/${portalId}/${scenarioId}/${conduct.conductId}`
            : `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/scenario/tabletop/aar/updateaar/${token}/${companyId}/${scenarioId}/${conduct.conductId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedAAR),
            });
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setFrameworkAnalysisLoading(false);
        setFrameworkBanner("After-Action Review Updated!");
        onUpdate();
    }
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    return (
        <MainCard title="Action Analysis">
        <Card>
            <CardContent>
                <Grid container={true} spacing={2}>
                    {editable &&
                        <>
                            {isFrameworkAnalysisLoading &&
                                <Grid item xs={12}>
                                    <QuickLoaderComponent loadBanner={frameworkBanner}/>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                <Stack direction={"column"} spacing={2}>
                                    <Chip label=" Standards " />
                                        <ButtonGroup orientation={"vertical"} size={"large"} >
                                            {["SOC 2", "ISO 27001","HIPAA", "ISO 22301", "FISMA", "CSA CCM", "NIST 800-171",  "CMMC", "FedRAMP", "ISO 42001", "ISO 27701", "GDPR", "PCI DSS", "CCPA"].sort().map((specName) => {
                                                const isSpecInAnalysis = updatedAAR.actionAnalysis?.includes(specName);
                                                return isSpecInAnalysis ? (
                                                    <Button size="small" variant="outlined" color="success" disabled>
                                                        <CheckCircleOutlined />
                                                        {specName}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => handleSpec(specName, `Analyze these actions and provide detailed feedback as to their effectiveness and relevance to the following standards framework: ${specName}. highlight the framework and note specific framework sections or criteria that the action corresponds to. your response should be in markdown format with the name of the spec in the title.`)}
                                                    >
                                                        {specName}
                                                    </Button>
                                                );
                                            })}
                                        </ButtonGroup>
                                </Stack>

                                <br/>
                                <Stack direction={"column"} spacing={2}>
                                    <Chip label=" Frameworks " />
                                    <ButtonGroup orientation={"vertical"} size={"large"} >
                                        {["MITRE ATT&CK", "HITRUST CSF", "CIS Top 18", "CSPM", "FedRAMP","NIST 800-53", "NIST CSF"].sort().map((specName) => {
                                            const isSpecInAnalysis = updatedAAR.actionAnalysis?.includes(specName);
                                            return isSpecInAnalysis ? (
                                                <Button size="small" variant="outlined" color="success" disabled>
                                                    <CheckCircleOutlined />
                                                    {specName}
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleSpec(specName, `Analyze these actions and provide detailed feedback as to their effectiveness and relevance to the following standards framework: ${specName}. highlight the framework and note specific framework sections or criteria that the action corresponds to. your response should be in markdown format with the name of the spec in the title.`)}
                                                >
                                                    {specName}
                                                </Button>
                                            );
                                        })}
                                    </ButtonGroup>
                                </Stack>

                            </Grid>
                            <Grid item xs={9}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                                                <ButtonGroup size={"small"}>
                                                    <Button
                                                        variant="outlined"
                                                        disabled={!isEditMode}
                                                        onClick={() => setIsEditMode(false)}
                                                    >
                                                        Preview
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        disabled={isEditMode}
                                                        onClick={() => setIsEditMode(true)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </ButtonGroup>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {frameworkAnalysis.map((analysis, index) => (
                                            <>
                                                <Grid item xs={12}>
                                                    {isEditMode ? (
                                                        <TextField
                                                            key={index}
                                                            multiline
                                                            fullWidth
                                                            value={analysis}
                                                            onChange={(event) => {
                                                                const newAnalysis = [...frameworkAnalysis];
                                                                newAnalysis[index] = event.target.value;
                                                                setFrameworkAnalysis(newAnalysis);
                                                            }}
                                                        />
                                                    ) : (
                                                        <ReactMarkdown key={index} children={analysis} />
                                                    )}                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={1}>
                                {frameworkAnalysis && frameworkAnalysis.length > 0 ?
                                    <Button
                                    onClick={handleUpdate}
                                    variant="contained"
                                    color="primary"
                                    disabled={aar?.isPublished}
                                >
                                    Update
                                </Button>
                                 : <Typography variant="caption">Add some analysis first by clicking on the Framework or Standard you are interested in</Typography>
                                }
                            </Grid>
                        </>
                    }
                </Grid>
            </CardContent>
        </Card>
        </MainCard>
    );
};

export default ActionAnalysisComponent;