// material-ui
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputLabel,
    List, ListItem,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';

// project import
import {Link} from "react-router-dom";

import React, {useEffect, useState} from "react";
import {
    AppUserProfile,
    Artifact, Company,
    ConductFile,
    Inject,
    Organization,
    Scenario,
    ScenarioRole,
    ScriptItem
} from "interfaces/interfaces";
import {useParams} from "react-router";
import HoverSocialCard from "../../../components/cards/statistics/HoverSocialCard";
import { useTheme} from "@mui/material/styles";
import {a11yProps, TabPanel} from "../../../hooks/tabPanelHelpers";
import MainCard from "../../../components/MainCard";
import {
    ArchitectureOutlined, ArrowBackOutlined,
    CheckBoxOutlined, ContentCopyOutlined, DownloadRounded,
    LibraryBooksOutlined,
    PeopleOutlineOutlined, SettingsOutlined,
    TipsAndUpdatesOutlined, UploadFileOutlined
} from "@mui/icons-material";
import QuickLoaderComponent from "../../../components/cards/QuickLoaderComponent";
import { SettingOutlined} from "@ant-design/icons";
import ScenarioScriptEditorComponent from "../../../components/scenario/ScenarioScriptEditorComponent";
import ScenarioRoleList from "../../../components/scenario/ScenarioRoleList";
import ScenarioInjectsTabletopComponent from "../../../components/tabletop/ScenarioInjectsTabletopComponent";
import ScenarioStatusSelect from "../../../components/scenario/ScenarioStatusSelect";
import TipsDialog from "../../../components/dialog/TipsDialog";
import StatusCard from "../../../components/statistics/StatusCard";
import ScriptItemListComponent from "../../../components/tabletop/ScriptItemListComponent";
import FileList from "../../../components/FileList";
import FileUploader from "../../../components/FileUploader";
import ArtifactList from "../../../components/ArtifactList";
import DialogContentText from "@mui/material/DialogContentText";
import ExerciseFrequencySelectList from "../../../components/scenario/ExerciseFrequencySelectList";
import ScenarioOverviewDialog from "../../../components/dialog/ScenarioOverviewDialog";
import ScenarioWalkthroughDialog from "../../../components/dialog/ScenarioWalkthroughDialog";
import ScenarioDashboardReportCards from "../../../components/scenario/ScenarioDashboardReportCards";
import ScenarioDashboardDetails from "../../../components/scenario/ScenarioDashboardDetails";
import ScenarioImageGridDisplay from "../../../components/scenario/ScenarioImageGridDisplay";
import AIAssistantIcon from "../../../components/icon/AIAssistantIcon";
import SectionFlow from "../../../components/tabletop/SectionFlow";

interface Params {
    scenarioId: string;
    orgId: string;
}
const DesignerOrganizationScenarioViewPage= () => {
    // @ts-ignore
    const { scenarioId, orgId } = useParams<Params>();
    const [scenario, setScenario] = useState<Scenario|undefined>(undefined);
    // const [conduct, setConduct] = useState<Conduct>();
    const token = localStorage.getItem('token');
    const theme = useTheme();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isDialogLoading, setDialogLoading] = useState<boolean>(false);
    const [isArtifactLoading, setArtifactLoading] = useState<boolean>(false);
    const [artifactLabel, setArtifactLabel] = useState<string>("Loading...");
    const [lowMedHigh, setLowMedHigh] = useState<string[]>();
    const [eventTypes, setEventTypes] = useState<string[]>();
    const [categories, setCategories] = useState<string[]>();
    const [eventLikelihoods, setEventLikelihoods] = useState<string[]>();
    const [label, setLabel] = useState<string>("Loading...");
    const [organization, setOrganization] = useState<Organization>();
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [openParticipantDialog, setOpenParticipantDialog] = useState(false);
    const [openSetupDialog, setOpenSetupDialog] = useState(false);
    const [openScenarioPlanDialog, setOpenScenarioPlanDialog] = useState(false);
    // const [openTabletopStartDialog, setOpenTabletopStartDialog] = useState(false);
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [artifacts, setArtifacts] = useState(scenario?.artifacts);
    const [userProfile, setUserProfile] = useState<AppUserProfile | null | undefined>(null);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [orgs, setOrgs] = useState<Organization[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<string>('');
    const [selectedOrg, setSelectedOrg] = useState<string>('');
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenStatusDialog = () => {
        setOpenStatusDialog(true);
    }
    const handleCloseStatusDialog = () => {
        setOpenStatusDialog(false);
    }
    const [openTips, setOpenTips] = useState(false);

    function handleTips() {
        setOpenTips(true);
    }

    function closeTips() {
        setOpenTips(false);
    }

    // const StyledTabPanel = styled(TabPanel)`
    //                 overflow: auto;
    //                 `;

    const [tabSelectValue, setTabSelectValue] = useState(0);

    const handleTabSelectChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSelectValue(newValue);
    };


    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/organization/company/getcompanies/'+token);
                const data = await response.json();
                setCompanies(data);
            } catch (error) {
                console.error(error);
            }
            try {
                const response2 = await fetch(process.env.REACT_APP_REST_API_URL + '/api/auth/profile/view/' + token);
                const data2 = await response2.json();
                setUserProfile(data2);
            } catch (error) {
                console.error(error);
            }
        };

        fetchCompanies();
    }, []);

    useEffect(() => {
        const fetchOrganizations = async () => {
            //if (userProfile != null && userProfile.email === "demo@opsbook.ai") {
                try {
                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/organization/getorganizations/' + token);
                    const data = await response.json();
                    setOrgs(data);
                } catch (error) {
                    console.error(error);
                }
            //}
        };

        fetchOrganizations();
    }, [userProfile]);

    const handleSelectChange = (event: SelectChangeEvent<string>) => {
        setSelectedCompany(event.target.value);
    };

    const handleDialogUpdate = () => {
        setOpenStatusDialog(false);
        setOpenSetupDialog(false);
        setOpenParticipantDialog(false);
        setOpenScenarioPlanDialog(false);
        handleUpdate();
    }
    const handleUpdate = async () => {
        setLabel("Updating Scenario...");
        setLoading(true);
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/scenario/organization/updatescenario/${token}/${scenario?.orgId}/${scenario?.scenarioId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(scenario),
            });
            const data = await response.json();
            setScenario(data);
            console.log("Updated scenario:", scenario);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setLoading(false);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // @ts-ignore
        setScenario((prevScenario) => ({
            ...prevScenario,
            [name]: value,
        }));
    };

    const handleScriptChange = (script: ScriptItem[]) => {
        const name = "script";
        //console.log("Script Change", script);
        // @ts-ignore
        setScenario((prevScenario) => ({
            ...prevScenario,
            [name]: script,
        }));
    }

    const handleSelectInputChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target;
        // @ts-ignore
        setScenario((prevScenario) => ({
            ...prevScenario,
            [name]: value,
        }));
    }
    const [genImageLoading, setGenImageLoading] = useState(false);

    let statusChip;

    switch (scenario?.status) {
        case 'Approved':
            statusChip = <Chip color="success" label="Approved"  size="small" variant="light" />;
            break;
        case 'Pending Approval':
            statusChip= <Chip color="success" label="Pending Approval" size="small" variant="outlined" />;
            break;
        case 'Under Review':
            statusChip= <Chip color="primary" label="Under Review" size="small" variant="light" />;
            break;
        case 'Archived':
            statusChip= <Chip color="secondary" label="Archived" size="small" variant="light" />;
            break;
        default:
            statusChip= <Chip color="primary" label={scenario?.status} size="small" variant="outlined" />;
            break;
    }

    async function handleGenerateScriptImage(scriptId: string) {
       // console.log("Generate Script Image: "+scriptId);
        setLabel("Generating Script Image..")
        setGenImageLoading(true)
        setLoading(true)
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/assistant/generateimage/' + token + '/' + scenario?.scenarioId+'/'+scriptId);
            // Parse the response as JSON
            const updatedScenario = await response.json();
            // Set the state of the scenario with the updated data
            setScenario(updatedScenario);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
        setGenImageLoading(false)
    }

    const handleGenerateScenarioScript = async () => {
        setLabel("Generating Script..")
        setDialogLoading(true)
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generatescript/list/' + token + '/' + orgId+ '/' + scenario?.scenarioId+'/generate scenario script');
            const data = await response.json();
            setScenario(data);
        } catch (error) {
            console.error(error);
        }
        setDialogLoading(false)
    };

    const handleGenerateScenarioInjects = async () => {
        setLabel("Generating Injects..")
        setDialogLoading(true)

        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generateinjectlist/' + token + '/' + orgId + '/' + scenario?.scenarioId + '/generate scenario injects');
            //console.log(response);
            const data = await response.json();
            setScenario(prevScenario => {
                if (!prevScenario) {
                    // Handle the case where prevScenario is undefined
                    return;
                }
                return {
                    ...prevScenario,
                    injects: data
                };
            });
        } catch (error) {
            console.error(error);
        }
        setDialogLoading(false)
    };
    useEffect(() => {
        // setLatestMessage("");
        const fetchData = async () => {
            try {
                setLabel("Loading Scenario...");
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/scenario/organization/getscenario/'+token+'/'+orgId+'/' + scenarioId);
                const data = await response.json();
                setScenario(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/organization/getorganization/' + token);
                const data = await response.json();
                setOrganization(data);
            } catch (error) {
                console.error(error);
            }
            try {
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/utils/list/lowmedhigh/'+token);
                const data = await response.json();
                setLowMedHigh(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
            try {
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/utils/scenario/geteventtypes/'+token);
                const data = await response.json();
                setEventTypes(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
            try {
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/utils/scenario/getcategories/'+token);
                const data = await response.json();
                setCategories(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
            try {
                setLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/utils/scenario/geteventlikelihoods/'+token);
                const data = await response.json();
                setEventLikelihoods(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
            }

        };
        fetchData();
    }, []);

    function handleRoleUpdate(newScenarioRoles: ScenarioRole[]) {
        if (scenario) {
            // @ts-ignore
            setScenario((prevScenario) => ({
                ...prevScenario,
                roles: newScenarioRoles,
            }));
        }
    }

    const handleAddInject = (updatedInjects: Inject[]) => {
        // @ts-ignore
        setScenario((prevScenario) => ({
            ...prevScenario,
            injects: updatedInjects,
        }));
    }

    function handleOpenSetup() {
        setOpenSetupDialog(true);
        console.log("Open Setup"+openSetupDialog);
    }

    // function handleOpenTabletopDialog() {
    //     setOpenTabletopStartDialog(true);
    // }

    function handleOpenParticipants() {
        setOpenParticipantDialog(true);
    }

    function handleOpenFile() {
        setOpenFileDialog(true);
    }
    function handleOpenScenarioPlanDialog() {
        setOpenScenarioPlanDialog(true);
    }

async function handleWordsmith(attributeName: keyof Scenario) {
    setLoading(true);
    if (scenario) {
        let attributeValue = scenario[attributeName];
        if (!attributeValue) {
            attributeValue = "Generate a sample " + attributeName + " for the scenario called " + scenario.name;
        }
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/wordsmith/' +
                token + '/' + orgId + '/' +scenario.scenarioId+'/'+ attributeValue);
            const data = await response.json();
            // @ts-ignore
            setScenario(prevScenario => ({
                ...prevScenario,
                [attributeName]: data
            }));
        } catch (error) {
            console.error(error);
        }
    }

    setLoading(false);
}

async function handleGenerateScenarioObjectives() {
    setLabel("Generating Objectives..")
    setDialogLoading(true)
    try {
        const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generate/' + token + '/' + scenario?.scenarioId + '/generate a new scenario objective');
        const data = await response.json();
        // @ts-ignore
        setScenario(prevScenario => ({
            ...prevScenario,
            objective: data
        }));
    } catch (error) {
        console.error(error);
    }
    setDialogLoading(false)
}

    // function handlePortalSelect(selectedPortalId: string) {
    //     handleConductStart(selectedPortalId);
    // }
    async function handleGenerateBackstory() {
        setLabel("Generating Backstory..")
        setDialogLoading(true)
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generate/' + token + '/' + scenario?.scenarioId + '/generate a new scenario backstory (starting 5-7 days before the incident) with a full story about hints, clues to the discovery to make it more interesting. this should be a detailed story about the events leading up to the incident and 4 paragraphs in length');
            const data = await response.json();
            // @ts-ignore
            setScenario(prevScenario => {
                if (prevScenario) {
                    return {
                        ...prevScenario,
                        scenarioStory: {
                            ...prevScenario.scenarioStory,
                            storyBackground: data
                        }
                    };
                }
            });
        } catch (error) {
            console.error(error);
        }
        setLabel("Generating Story Overview..")
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/assistant/generate/' + token + '/' + scenario?.scenarioId + '/generate a new scenario story overview (starting at the time of the incident) with a detailed story about the initial incident discovery to make it more interesting. this should be a detailed story about the events right before and at the time of the incident and 4 paragraphs in length');
            const data = await response.json();
            // @ts-ignore
            setScenario(prevScenario => {
                if (prevScenario) {
                    return {
                        ...prevScenario,
                        scenarioStory: {
                            ...prevScenario.scenarioStory,
                            storyOverview: data
                        }
                    };
                }
            });
        } catch (error) {
            console.error(error);
        }
        setDialogLoading(false)
    }

    const handleScenarioStoryInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // @ts-ignore
        setScenario(prevScenario => {
            if (prevScenario) {
                return {
                    ...prevScenario,
                    scenarioStory: {
                        ...prevScenario.scenarioStory,
                        [name]: value
                    }
                };
            } else {
                // Provide a default value for prevScenario if it's undefined
                return {
                    scenarioStory: {
                        [name]: value
                    }
                };
            }
        });
    };

    const downloadCSV = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/download/script/' + token + '/' + orgId + '/' + scenario?.scenarioId );
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = scenario?.name+'_script.csv';
            link.click();
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };

    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleDownload(file: ConductFile) {
        const url = process.env.REACT_APP_REST_API_URL + '/api/files/scenario/getfile/' + token + '/' + orgId + '/' + scenario?.scenarioId  + '/' + file.fileId;
        window.open(url, '_blank');
    }

    function handleArtifactDownload(artifact: Artifact) {
        console.log("Download Artifact "+artifact.artifactId+" "+artifact.name);
        setCurrentArtifact(artifact);
        setOpenArtifactDialog(true);
    }

    const handleUpload = (json: any) => {
        // Parse the JSON object
        const data = JSON.parse(json);

        // Use the parsed data to update the conduct state
        setScenario(data);
    };

    async function handleRemoveArtifact(artifact: Artifact) {
        if (!artifact) return;
        setArtifactLabel("Remove Artifact...")
        setArtifactLoading(true);
         try {
            let url = new URL(process.env.REACT_APP_REST_API_URL + '/api/files/organization/scenario/removeartifact/' + token + '/' + orgId + '/' + scenario?.scenarioId  + '/' + artifact.artifactId);

            const response = await fetch(url.toString());

            const data = await response.json();
             if (scenario) {
                 scenario.artifacts = scenario.artifacts.filter(a => a.artifactId !== data.artifactId);
                 setArtifacts(scenario.artifacts);
             }

        } catch (error) {
            console.error('Failed to remove artifact:', error);
        }
        setArtifactLoading(false);
    }
    async function handleRegenerateArtifact(artifact: Artifact) {
        if (!artifact) return;
        setArtifactLabel("Re-creating Artifact...")
        setArtifactLoading(true);
        try {
            const url = process.env.REACT_APP_REST_API_URL + '/api/files/organization/scenario/regenerateartifacttext/' + token + '/' + orgId + '/' + scenario?.scenarioId  + '/' + artifact.artifactId;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(artifact)
            });

            const data = await response.json();
            if (scenario) {
                scenario.artifacts = scenario.artifacts.map(a => a.artifactId === data.artifactId ? data : a);
                setArtifacts(scenario.artifacts);
            }

            // replace the artifact in the conduct.artifacts array
            // if (conduct) {
            //     conduct.artifacts = conduct.artifacts.map(a => a.artifactId === data.artifactId ? data : a);
            //     setArtifacts(conduct.artifacts);
            // }
        } catch (error) {
            console.error('Failed to create artifact:', error);
        }
        setArtifactLoading(false);
    }

    const [currentArtifact, setCurrentArtifact] = useState<Artifact | null>(null);
    const [openArtifactDialog, setOpenArtifactDialog] = useState(false);

    const [artifactName, setArtifactName] = useState('');
    const [artifactDescription, setArtifactDescription] = useState('');

    // Function to create an artifact
    const createArtifact = async () => {
        const artifact = {
            name: artifactName,
            description: artifactDescription
        };
        setArtifactLabel("Creating Artifact...")
        setArtifactLoading(true);
        try {
            const url = process.env.REACT_APP_REST_API_URL + '/api/files/organization/scenario/createartifact/' + token + '/' + orgId + '/' + scenario?.scenarioId;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(artifact)
            });

            const data = await response.json();

            if (scenario) {
                // Check if scenario.artifacts is null or undefined
                if (!scenario.artifacts) {
                    // If it is, create a new array and add the returned data to it
                    scenario.artifacts = [data];
                } else {
                    // If scenario.artifacts already exists, add the returned data to the existing array
                    scenario.artifacts.push(data);
                }
                // Update the state
                // @ts-ignore
                setScenario(prevScenario => ({
                    ...prevScenario,
                    artifacts: scenario.artifacts
                }));
                console.log(scenario.scenarioId);
            }


        } catch (error) {
            console.error('Failed to create artifact:', error);
        }
        setArtifactLoading(false);
    };

    useEffect(() => {
        if (scenario) {
            setArtifacts(scenario.artifacts);
        }
    }, [scenario]);

    async function handleCopyScenario(selectedCompany: string) {
        try {
            setLoading(true);
            setLabel("Copying Scenario...");
            await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/pushscenario/company/' + token + '/' +organization?.id+"/" + scenario?.scenarioId + '/'+selectedCompany );
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    async function handleCopyScenarioToOrg(selectedCompany: string) {
        try {
            setLoading(true);
            setLabel("Copying Scenario to Organization...");
            await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/pushscenario/org/' + token + '/' +organization?.id+"/" + scenario?.scenarioId + '/'+selectedOrg );
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const [openGenScriptDialog, setOpenGenScriptDialog] = useState(false);

    const handleOpenGenScriptDialog = () => {
        setOpenGenScriptDialog(true);
    };

    const handleCloseGenScriptDialog = () => {
        setOpenGenScriptDialog(false);
    };

    const handleSelectOrgChange = (event: SelectChangeEvent<string>) => {
        setSelectedOrg(event.target.value);
    };

    const [scenarioOverviewOpen, setScenarioOverviewOpen] = useState(false);
    const [scenarioWalkthroughOpen, setScenarioWalkthroughOpen] = useState(false);

    const handleScenarioOverviewOpen = () => {
        setScenarioOverviewOpen(true);
    };

    const handleScenarioOverviewClose = () => {
        setScenarioOverviewOpen(false);
    };

    function handleScenarioWalkthroughOpen() {
        setScenarioWalkthroughOpen(true);
    }

    function handleScenarioWalkthroughClose() {
        setScenarioWalkthroughOpen(false);
    }

    const [scenarioPlanTabChange, setScenarioPlanTabChange] = useState(0);
    const handleScenarioPlanTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setScenarioPlanTabChange(newValue);
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid container={true} spacing={2} >
                <Grid item xs={12} >
                    <Grid container={true} spacing={2} >
                        <Grid item xs={9} >
                            <HoverSocialCard secondary={scenario?.name} primary={"Scenario Designer: Organization Scenario Library Template"} color={theme.palette.primary.main} />
                        </Grid>
                        <Grid item xs={3}>
                            {scenario && scenario.status &&
                                <StatusCard primary={scenario.status}  iconPrimary={CheckBoxOutlined}
                                            onStatusChange={handleOpenStatusDialog}/>
                            }
                        </Grid>
                            {/*<BoomComponent color={theme.palette.background.paper} leftOrRight={scenario?.leftOrRightOfBoom} leftCoverage={(scenario?.leftOfBoomPercent ? scenario.leftOfBoomPercent:0)} rightCoverage={(scenario?.rightOfBoomPercent ? scenario.rightOfBoomPercent:0)}/>*/}

                    </Grid>
                </Grid>
                <Grid item xs={12} >

                <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
                    <Grid item xs={12} >
                        {isLoading &&
                            <QuickLoaderComponent loadBanner={label} />
                        }
                                <Grid container spacing={1} >
                                    <Grid item={true} xs={5} >
                                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                                    <ButtonGroup>
                                        <Tooltip title="Edit general settings & information about this scenario" arrow>
                                        <Button variant="outlined" size={"small"} color="primary" onClick={handleOpenSetup}>
                                            <SettingOutlined/>
                                        </Button>
                                        </Tooltip>
                                        <Tooltip title="Add/Edit to the scenario story & script" arrow>
                                        <Button variant="outlined" size={"small"} color="primary" onClick={handleOpenScenarioPlanDialog}>
                                            <ArchitectureOutlined/>
                                        </Button>
                                        </Tooltip>
                                        <Tooltip title="Define the people & roles needed for this scenario" arrow>
                                        <Button variant="outlined" size={"small"} color="primary" onClick={handleOpenParticipants}>
                                            <PeopleOutlineOutlined/>
                                        </Button>
                                        </Tooltip>

                                        <Tooltip title="Add files or generate artifacts for this scenario" arrow>
                                        <Button variant="outlined" size={"small"} color="primary" onClick={handleOpenFile}>
                                            <UploadFileOutlined/>
                                        </Button>
                                        </Tooltip>
                                        <Tooltip title={scenario?.status !== 'Approved' ? "The scenario must be approved before it can be pushed to Clients" : "Push this approved scenario to a Client"}>
                                            <span>
                                                <Button onClick={() => setOpenDialog(true)} disabled={scenario?.status !== 'Approved'}>
                                                    <ContentCopyOutlined/>Copy
                                                </Button>
                                            </span>
                                        </Tooltip>
                                        {/*<Button variant="outlined" size={"small"} color="primary" disabled={true}>*/}
                                        {/*    Compliance*/}
                                        {/*</Button>*/}
                                        {organization && organization.isDemo ?
                                            (
                                                <Button
                                                    onClick={handleTips}
                                                    variant="contained"
                                                    color="success"
                                                    size="small"
                                                    startIcon={<TipsAndUpdatesOutlined />}
                                                >
                                                    Tips
                                                </Button>
                                            )
                                            : null  }
                                    </ButtonGroup>
                                </Stack>
                                    </Grid>
                                    <Grid item={true} xs={4} >
                                        <Stack direction="row" justifyContent="flex-start" spacing={1}>
                                            <ButtonGroup>
                                                <Tooltip title={"View overview of the exercise"}>
                                                    <Button  size={"small"} variant="outlined" onClick={handleScenarioOverviewOpen}>
                                                        Overview
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title={"View a detailed walkthrough of the exercise"}>
                                                    <Button  size={"small"} variant="outlined" onClick={handleScenarioWalkthroughOpen}>
                                                        Walkthrough
                                                    </Button>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Stack>
                                    </Grid>
                                    <Grid item={true} xs={3} >
                                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                            <Link to={"/scenariolibrary"} style={{ textDecoration: 'none' }}>
                                                <Button variant="outlined" size={"small"} color="primary">
                                                    <ArrowBackOutlined/><LibraryBooksOutlined/>
                                                </Button>
                                            </Link>
                                        </Stack>
                                    </Grid>
                                </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}><Divider/></Grid>
                    </Grid>
                <Tabs value={tabSelectValue} onChange={handleTabSelectChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Story" {...a11yProps(1)} />
                    <Tab label="Plan" {...a11yProps(2)} />
                    {/*<Tab label="Assistant" {...a11yProps(3)} />*/}
                </Tabs>

                <Grid item xs={12}  sm={12} md={12} lg={12}>
                    <TabPanel value={tabSelectValue} index={0} >
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                {scenario && scenario.script &&
                                    <SectionFlow sections={scenario.script} /> }
                            </Grid>

                            <Grid item xs={8}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12}>
                                        <ScenarioDashboardDetails  scenario={scenario} openSetupDialog={handleOpenScenarioPlanDialog}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <MainCard title="Roles"secondary={
                                    <IconButton onClick={handleOpenParticipants} size={"small"}>
                                        <SettingsOutlined fontSize={"small"}/>
                                    </IconButton>
                                }
                                >
                                    <ListItem key={"999"}>
                                        <Typography variant="subtitle1">Facilitator</Typography>
                                    </ListItem>
                                    {scenario && scenario.roles && scenario.roles.length > 0 && scenario.roles.map((role, index) => (
                                        <ListItem key={index}>
                                            <Typography variant="subtitle1">{role.name}</Typography>
                                        </ListItem>

                                    ))}

                                </MainCard>
                            </Grid>
                            <Grid item xs={2}>
                                {scenario &&  <ScenarioDashboardReportCards scenario={scenario} openSetupDialog={handleOpenSetup}/> }
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={tabSelectValue} index={1} >
                        <Grid container={true} spacing={2} >
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader title="Scenario Story" />
                                    <CardContent>
                                        <Grid container={true} spacing={2} >
                                            {scenario && scenario.scenarioStory && scenario.scenarioStory.storyBackground &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography color="secondary" variant={"h5"}>Story Background</Typography>
                                                        <Typography color="secondary" variant={"body1"}>
                                                            {scenario?.scenarioStory.storyBackground.split('\n').map((paragraph, index) => (
                                                                <p key={index}>{paragraph}</p>
                                                            ))}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {scenario && scenario.scenarioStory && scenario.scenarioStory.storyOverview &&
                                                scenario.scenarioStory.storyBackground &&
                                                <Grid item xs={12}>
                                                    <Divider ></Divider>
                                                </Grid>
                                            }
                                            {scenario && scenario.scenarioStory && scenario.scenarioStory.storyOverview
                                                ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography color="secondary" variant={"h5"}>Story Overview</Typography>
                                                            <Typography color="secondary" variant={"body1"}>
                                                                {scenario?.scenarioStory.storyOverview.split('\n').map((paragraph, index) => (
                                                                    <p key={index}>{paragraph}</p>
                                                                ))}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                )
                                                : (
                                                    <Typography color="secondary" variant={"body1"}>
                                                        No Story Defined
                                                    </Typography>
                                                )
                                            }
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={tabSelectValue} index={2} >
                            <Grid container={true} spacing={2} >
                                <Grid item xs={12}>
                                    <MainCard title="Scenario Script"
                                              secondary={
                                                  <IconButton onClick={downloadCSV}>
                                                      <DownloadRounded />
                                                  </IconButton>
                                              }
                                    >
                                        {scenario && scenario.script &&
                                            <ScriptItemListComponent scriptItems={scenario?.script} showSteps={true} />
                                        }
                                    </MainCard>
                                </Grid>
                            </Grid>
                    </TabPanel>

                    <Dialog fullWidth={true}  fullScreen={true} open={openFileDialog} onClose={() => setOpenFileDialog(false)}>
                        <DialogTitle>Scenario Files</DialogTitle>
                        <DialogContent>
                        <MainCard title="Manage Files for the Tabletop" sx={{
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                overflow: 'auto', // Add this line
                            }
                        }} >

                           <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label={`Files ${scenario && scenario?.files?.length > 0 ? `(${scenario.files.length})` : ''}`} />
                                <Tab label={`Artifacts ${scenario && scenario?.artifacts?.length > 0 ? `(${scenario.artifacts.length})` : ''}`} />
                            </Tabs>

                            {tabValue === 0 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">Current Tabletop files</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {scenario && scenario.files && scenario.files.length > 0 ?
                                            <FileList userProfile={userProfile} files={scenario.files} onDownload={handleDownload} /> : <Typography variant="caption">No files uploaded</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">Add files to the Tabletop</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileUploader title={"Tabletop Exercise: Upload Files"} apiUrl={process.env.REACT_APP_REST_API_URL+`/api/files/upload/scenario/${token}/${orgId}/${scenario?.scenarioId}`} onUpload={handleUpload}/>
                                    </Grid>
                                </Grid>
                            )}

                            {tabValue === 1 && (
                                <Grid container spacing={2}>
                                    {isArtifactLoading &&
                                        <Grid item xs={12}>
                                        <QuickLoaderComponent loadBanner={artifactLabel} />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography variant="caption">Current Tabletop artifacts</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {scenario && artifacts && artifacts.length > 0 ?
                                            <ArtifactList userProfile={userProfile} artifacts={artifacts} onDownload={handleArtifactDownload} onRegenerate={handleRegenerateArtifact} onRemove={handleRemoveArtifact}/> : <Typography variant="caption">No artifacts uploaded</Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption">Create Artifacts for the Tabletop</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Name"
                                            fullWidth={true}
                                            variant="outlined"
                                            value={artifactName}
                                            onChange={e => setArtifactName(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Instructions to generate the artifact..."
                                            fullWidth={true}
                                            variant="outlined"
                                            multiline={true}
                                            minRows={2}
                                            value={artifactDescription}
                                            onChange={e => setArtifactDescription(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" onClick={createArtifact}>Create Artifact</Button>
                                    </Grid>
                                </Grid>
                            )}

                        </MainCard>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Card>
                                    <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                        <Button variant="contained" onClick={() => setOpenFileDialog(false)}>Close</Button>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </DialogActions>
                    </Dialog>

                    <Dialog fullWidth={true}  fullScreen={true} open={openScenarioPlanDialog} onClose={() => setOpenScenarioPlanDialog(false)}>
                        <DialogTitle>Organizational Template: Scenario Plan for {scenario?.name}</DialogTitle>
                        <DialogContent>
                            <Card>
                                <CardContent>
                                    <Tabs value={scenarioPlanTabChange} onChange={handleScenarioPlanTabChange}
                                          aria-label="Scenario Plan Tabs">
                                        <Tab label="Story"/>
                                        <Tab label="Objective"/>
                                        <Tab label="Script"/>
                                        <Tab label="Injects"/>
                                        <Tab label="Agenda"/>
                                        <Tab label="Instructions"/>
                                    </Tabs>

                                    {scenarioPlanTabChange === 0 && (
                                        <MainCard title="Story" secondary={
                                           <>
                                               <ButtonGroup>
                                               <Button variant="outlined" size="small" color="primary" onClick={handleGenerateBackstory}>
                                                   <AIAssistantIcon/> Generate
                                                </Button>
                                               {scenario?.scenarioStory &&
                                                   <Button variant="outlined" size="small" onClick={() => handleGenerateScriptImage(scenario?.scenarioStory.scenarioStoryId)}>
                                                       <AIAssistantIcon/> Generate Image
                                                    </Button>
                                               }
                                               </ButtonGroup>
                                           </>
                                        }>
                                            {genImageLoading && <QuickLoaderComponent loadBanner={"Generating Image..."} />}
                                            {isDialogLoading &&
                                                <QuickLoaderComponent loadBanner={label} />
                                            }
                                            <Grid container={true} spacing={2} >
                                                <Grid item xs={scenario?.scenarioStory?.images?8:12}>
                                                    <Grid container={true} spacing={2} >
                                                        <Grid item xs={12}>
                                                        <Typography color="secondary">
                                                            <TextField
                                                                name="storyBackground"
                                                                label="Backstory (before the scenario)"
                                                                fullWidth
                                                                multiline
                                                                minRows={12}
                                                                sx={{ width: '100%' }}
                                                                value={scenario?.scenarioStory?.storyBackground}
                                                                onChange={handleScenarioStoryInputChange}
                                                            />
                                                        </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography color="secondary">
                                                                <TextField
                                                                    name="storyOverview"
                                                                    label="Story"
                                                                    fullWidth
                                                                    multiline
                                                                    minRows={12}
                                                                    sx={{ width: '100%' }}
                                                                    value={scenario?.scenarioStory?.storyOverview}
                                                                    onChange={handleScenarioStoryInputChange}
                                                                />
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                                {scenario?.scenarioStory?.images &&
                                                    <Grid item xs={4}>
                                                        <ScenarioImageGridDisplay images={scenario?.scenarioStory?.images} />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </MainCard>
                                    )}

                                    {scenarioPlanTabChange === 1 && (
                                        <MainCard title="Define Objectives" secondary={
                                            <Button variant="outlined" size="small" color="primary" onClick={handleGenerateScenarioObjectives}>
                                                <AIAssistantIcon/> Generate
                                            </Button>
                                        }>
                                            <Grid container={true} spacing={2} >
                                                <Grid item xs={12}>
                                                    <Grid item xs={12}  sm={12} md={12} lg={12}>
                                                        <Typography color="secondary">
                                                            <TextField
                                                                name="objective"
                                                                label="Objective"
                                                                fullWidth
                                                                multiline
                                                                minRows={24}
                                                                sx={{ width: '100%' }}
                                                                value={scenario?.objective}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    )}

                                    {scenarioPlanTabChange === 2 && (
                                        <MainCard title={`Script Definition for ${scenario?.name}`} secondary={
                                            <>{organization && organization.isEnterpriseLicense &&
                                                <>
                                                    <Dialog
                                                        open={openGenScriptDialog}
                                                        onClose={handleCloseGenScriptDialog}
                                                    >
                                                        <DialogTitle>{"Confirmation"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                {"Generating a new script will overwrite any existing information. Are you sure?"}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleCloseGenScriptDialog} color="primary">
                                                                Cancel
                                                            </Button>
                                                            <Button onClick={handleGenerateScenarioScript} color="primary" autoFocus>
                                                                Confirm
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>

                                                    <Tooltip title={"Generate a new scenario script. This will replace existing content."}>
                                                        <Button variant="outlined" size="small" color="primary"
                                                                onClick={handleOpenGenScriptDialog}>
                                                            <AIAssistantIcon/> Generate
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            }
                                            </>
                                        }>
                                            <Grid container={true} spacing={2} >
                                                <Grid item xs={12}>
                                                    {scenario &&
                                                        <Typography color="secondary">
                                                            <List sx={{ p: 0 }}>
                                                                {scenario.script ? ( <ScenarioScriptEditorComponent scenarioId={scenario.scenarioId} script={scenario.script} updateScript={handleScriptChange}  generateImage={handleGenerateScriptImage}/>

                                                                ) : (
                                                                    <Typography variant={"caption"}>No script defined</Typography>
                                                                )}
                                                            </List>
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    )}
                                    {scenarioPlanTabChange === 3 && (
                                        <MainCard title="Injects" secondary={
                                            <Tooltip title={"Generate a list of injects for this scenario. If there are existing injects, new items will be added to the list."}>
                                                <Button variant="outlined" size="small" color="primary" onClick={handleGenerateScenarioInjects}>
                                                    <AIAssistantIcon/>Generate
                                                </Button>
                                            </Tooltip>
                                        }>
                                            <Grid container={true} spacing={2} >
                                                <Grid item xs={12}>
                                                    <Typography color="secondary">
                                                        {scenario && <ScenarioInjectsTabletopComponent injects={scenario.injects} editable={true} onUpdate={handleAddInject} />}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    )}
                                    {scenarioPlanTabChange === 4 && (
                                        <MainCard title={`Agenda for ${scenario?.name}`} >
                                            <Grid container={true} spacing={2}>
                                                {isDialogLoading &&
                                                    <Grid item xs={12}>
                                                        <QuickLoaderComponent loadBanner={label}/>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Grid container={true} spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography color="secondary">
                                                                <TextField
                                                                    name="agenda"
                                                                    label="Agenda"
                                                                    fullWidth
                                                                    multiline
                                                                    rows={12}
                                                                    sx={{width: '100%'}}
                                                                    value={scenario?.agenda}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    )}
                                    {scenarioPlanTabChange === 5 && (
                                        <MainCard title="Briefing">
                                            <Grid container={true} spacing={2} >
                                                <Grid item xs={12}>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                name="forParticipantNotes"
                                                                label="Participant Notes"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                                value={scenario?.forParticipantNotes}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                name="forParticipantInstructions"
                                                                label="Participant Instructions"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                                value={scenario?.forParticipantInstructions}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                name="forParticipantBackground"
                                                                label="Participant Background"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                                value={scenario?.forParticipantBackground}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                name="forFacilitatorNotes"
                                                                label="Facilitator Notes"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                                value={scenario?.forFacilitatorNotes}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                name="forFacilitatorInstructions"
                                                                label="Facilitator Instructions"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                                value={scenario?.forFacilitatorInstructions}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                name="forFacilitatorBackground"
                                                                label="Facilitator Background"
                                                                fullWidth
                                                                multiline
                                                                rows={8}
                                                                value={scenario?.forFacilitatorBackground}
                                                                onChange={handleInputChange}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </MainCard>
                                    )}
                                </CardContent>
                            </Card>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Card>
                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                            <Button variant="contained"
                                                    onClick={() => { handleUpdate(); }}>Save</Button>
                                            <Button variant="contained"
                                                    onClick={() => { handleDialogUpdate(); }}>Save & Close</Button>
                                            <Button variant="contained"
                                                    onClick={() => setOpenScenarioPlanDialog(false)}>Cancel</Button>
                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <Dialog fullWidth={true}  fullScreen={true} open={openParticipantDialog} onClose={() => setOpenParticipantDialog(false)}>
                    <DialogTitle>Role Design</DialogTitle>
                    <DialogContent>
                        <MainCard title="Suggested Roles for this Scenario">
                        <Grid container={true} spacing={2} >
                            <Grid item xs={12}>
                                <Typography variant={"caption"}>Define the roles needed for this scenario.
                                    The prescribed roles will be used to help make sure the appropriate participants are included in the exercise.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {scenario && <ScenarioRoleList initialRoles={scenario?.roles} onUpdate={handleRoleUpdate} editable={true}/>}
                            </Grid>
                        </Grid>
                        </MainCard>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <Card>
                                    <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                        <Button variant="contained" onClick={handleDialogUpdate} color="primary">Save</Button>
                                        <Button variant="contained" onClick={() => setOpenParticipantDialog(false)}>Cancel</Button>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>

                    <Dialog
                        open={openArtifactDialog}
                        fullWidth={true}
                        onClose={() => setOpenArtifactDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Artifact Details"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {currentArtifact && currentArtifact.name &&
                                    <Typography variant={"h6"}>{currentArtifact.name}</Typography>}
                                <br/>
                                {currentArtifact && currentArtifact.artifactText && currentArtifact?.artifactText.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br/>
                                    </React.Fragment>
                                ))}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Card>
                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                            <Button variant="contained" onClick={() => setOpenArtifactDialog(false)} color="primary" autoFocus>
                                                Close
                                            </Button>
                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <Dialog fullWidth={true}  fullScreen={true} open={openSetupDialog} onClose={() => setOpenSetupDialog(false)}>
                        <DialogTitle>Scenario Setup</DialogTitle>
                        <DialogContent>
                            <MainCard title="Setup">
                                {scenario &&
                                    <Grid container spacing={3} >
                                        <Grid item xs={12}>
                                            <Grid container spacing={3} >
                                                <Grid item xs={4}>
                                                    <TextField
                                                        name="name"
                                                        label="Name"
                                                        fullWidth
                                                        value={scenario.name}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        name="industry"
                                                        label="Industry"
                                                        fullWidth
                                                        value={scenario.industry}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        name="type"
                                                        label="Type"
                                                        fullWidth
                                                        value={scenario.type}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant={"h6"}>Status: {statusChip}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Button variant={"text"} size={"small"} onClick={handleOpenStatusDialog}>Change</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        name="description"
                                                        label="Description"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        sx={{ width: '100%' }}
                                                        value={scenario.description}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('description')}>
                                                        Wordsmith
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        name="focusArea"
                                                        label="Focus Area"
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                        sx={{ width: '100%' }}
                                                        value={scenario.focusArea}
                                                        onChange={handleInputChange}
                                                    />
                                                    <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('focusArea')}>
                                                        Wordsmith
                                                    </Button>
                                                </Grid>
                                                {organization && organization.isEnterpriseLicense &&
                                                    <Grid item xs={12}>
                                                    <Typography color="secondary">
                                                        <TextField
                                                            name="instructions"
                                                            label="Scenario Generation Instructions"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            sx={{ width: '100%' }}
                                                            value={scenario.instructions}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Typography>
                                                    <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('instructions')}>
                                                        Wordsmith
                                                    </Button>
                                                </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Typography color="secondary">
                                                        <TextField
                                                            name="notes"
                                                            label="Scenario Notes"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            sx={{ width: '100%' }}
                                                            value={scenario.notes}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Typography>
                                                    <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('notes')}>
                                                        Wordsmith
                                                    </Button>
                                                </Grid>
                                                {/*<Grid item xs={12}  sm={12} md={12} lg={12}>*/}
                                                {/*    <TextField*/}
                                                {/*        name="notes"*/}
                                                {/*        label="Scenario Notes"*/}
                                                {/*        fullWidth*/}
                                                {/*        multiline*/}
                                                {/*        sx={{ width: '100%' }}*/}
                                                {/*        value={scenario.notes}*/}
                                                {/*        onChange={handleInputChange}*/}
                                                {/*    />*/}
                                                {/*    <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('notes')}>*/}
                                                {/*        Wordsmith*/}
                                                {/*    </Button>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={4}>
                                                    <MainCard title="Timing">
                                                        <Grid container spacing={3} >
                                                            <Grid item xs={12}>
                                                                <ExerciseFrequencySelectList
                                                                    frequency={scenario.frequency}
                                                                    handleSelectInputChange={handleSelectInputChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <InputLabel id="risk-select-list">Time</InputLabel>
                                                                <TextField
                                                                    id="estimatedTime"
                                                                    fullWidth
                                                                    type={"number"}
                                                                    value={scenario?.estimatedTime}
                                                                    onChange={(e) => setScenario({ ...scenario, estimatedTime: parseInt(e.target.value) })}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <InputLabel id="risk-select-list">Time (units)</InputLabel>
                                                                <Select
                                                                    value={scenario?.estimatedTimeUnit}
                                                                    onChange={(e) => setScenario({ ...scenario, estimatedTimeUnit: e.target.value })}
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value={"Hours"}>Hours</MenuItem>
                                                                    <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                                    <MenuItem value={"Days"}>Days</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </MainCard>

                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MainCard title="Effort & Difficulty">
                                                        <Grid container spacing={3} >
                                                            <Grid item xs={12}>
                                                                <InputLabel id="risk-select-list">Level of Effort</InputLabel>
                                                                <Select
                                                                    name="levelOfEffort"
                                                                    placeholder="Level of Effort"
                                                                    fullWidth
                                                                    value={scenario.levelOfEffort}
                                                                    onChange={handleSelectInputChange}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Select Level of Effort</em>
                                                                    </MenuItem>
                                                                    {lowMedHigh && lowMedHigh.map((lmh,item) => (
                                                                        <MenuItem key={lmh} value={lmh}>{lmh}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <InputLabel id="risk-select-list">Difficulty Level</InputLabel>
                                                                <Select
                                                                    name="difficulty"
                                                                    placeholder="Scenario Difficulty"
                                                                    fullWidth
                                                                    value={scenario.difficulty}
                                                                    onChange={handleSelectInputChange}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Select Difficulty Level</em>
                                                                    </MenuItem>
                                                                    {lowMedHigh && lowMedHigh.map((lmh,item) => (
                                                                        <MenuItem key={lmh} value={lmh}>{lmh}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </MainCard>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <MainCard title="Event Info">
                                                        <Grid container spacing={3} >
                                                            <Grid item xs={12}>
                                                                <InputLabel id="risk-select-list">Category</InputLabel>
                                                                <Select
                                                                    name="category"
                                                                    placeholder="Category"
                                                                    fullWidth
                                                                    value={scenario.category}
                                                                    onChange={handleSelectInputChange}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Select Category </em>
                                                                    </MenuItem>
                                                                    {categories && categories.map((lmh,item) => (
                                                                        <MenuItem key={lmh} value={lmh}>{lmh}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <InputLabel id="risk-select-list">Event Type</InputLabel>
                                                                <Select
                                                                    name="eventType"
                                                                    placeholder="Type"
                                                                    fullWidth
                                                                    value={scenario.eventType}
                                                                    onChange={handleSelectInputChange}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Select Event Type</em>
                                                                    </MenuItem>
                                                                    {eventTypes && eventTypes.map((lmh,item) => (
                                                                        <MenuItem key={lmh} value={lmh}>{lmh}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <InputLabel id="risk-select-list">Event Likelihood</InputLabel>
                                                                <Select
                                                                    name="eventLikelihood"
                                                                    placeholder="Event Likelihood"
                                                                    fullWidth
                                                                    value={scenario.eventLikelihood}
                                                                    onChange={handleSelectInputChange}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Select Event Likelihood</em>
                                                                    </MenuItem>
                                                                    {eventLikelihoods && eventLikelihoods.map((lmh,item) => (
                                                                        <MenuItem key={lmh} value={lmh}>{lmh}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </MainCard>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}  sm={12} md={12} lg={12}>
                                    <Typography variant={"caption"}>ID: {scenario?.scenarioId}</Typography>
                                </Grid>
                                <Grid item xs={12}  sm={12} md={12} lg={12}>
                                    {scenario?.templateScenarioId &&<Typography variant={"caption"}>Library ID: {scenario?.templateScenarioId}</Typography>}
                                </Grid>
                            </MainCard>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Card>
                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                            <Button variant="contained" onClick={handleDialogUpdate} color="primary">Save</Button>
                                            <Button variant="contained" onClick={() => setOpenSetupDialog(false)}>Cancel</Button>
                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    {scenario &&
                        <ScenarioOverviewDialog
                            title={scenario.name}
                            agenda={scenario.agenda}
                            objectives={scenario.objective}
                            instructions={scenario.forParticipantInstructions}
                            scenarioStory={scenario.scenarioStory}
                            scenarioOverviewOpen={scenarioOverviewOpen}
                            handleScenarioOverviewClose={handleScenarioOverviewClose}
                        />
                    }

                    {scenario &&
                        <ScenarioWalkthroughDialog
                            scenario={scenario}
                            scenarioOverviewOpen={scenarioWalkthroughOpen}
                            handleScenarioWalkthroughClose={handleScenarioWalkthroughClose}
                        />
                    }

                    <Dialog fullWidth={true} open={openDialog} onClose={() => setOpenDialog(false)}>
                        <DialogTitle>Select a Company  {userProfile && userProfile.email==="demo@opsbook.ai"?"or Organization":""}</DialogTitle>
                        <DialogContent>
                            <Typography variant={"h6"}>Copy Scenario to a Company within this Organization</Typography>

                            <Select fullWidth={true} value={selectedCompany || ""} onChange={handleSelectChange}>
                                <MenuItem value="" disabled>
                                    Select Company
                                </MenuItem>
                                {companies.map((company) => (
                                    <MenuItem key={company.companyId} value={company.companyId}>
                                        {company.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {userProfile && userProfile.email==="demo@opsbook.ai" &&
                                <>
                                    <br/>
                                <Divider/>
                                    <br/>
                                    <Typography variant={"h6"}>Copy Scenario to another Organization</Typography>
                                <Select fullWidth={true} value={selectedOrg || ""} onChange={handleSelectOrgChange}>
                                    <MenuItem value="" disabled>
                                        Select Organization
                                    </MenuItem>
                                    {orgs.map((org:Organization ) => (
                                        <MenuItem key={org.id} value={org.id}>
                                            {org.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                </>
                            }
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Card>
                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                            <Button variant="contained"  onClick={() => {
                                                setOpenDialog(false);
                                                handleCopyScenario(selectedCompany);
                                            }}>Copy Scenario</Button>
                                            {userProfile && userProfile.email==="demo@opsbook.ai" &&
                                                <Button variant="contained"  onClick={() => {
                                                    setOpenDialog(false);
                                                    handleCopyScenarioToOrg(selectedOrg);
                                                }}>Copy Scenario to Org</Button>
                                            }
                                            <Button variant="contained"  onClick={() => setOpenDialog(false)}>Cancel</Button>
                                        </Stack>
                                    </Card>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    {/*<Dialog fullWidth={true}  open={openTabletopStartDialog} onClose={() => setOpenTabletopStartDialog(false)}>*/}
                    {/*    <DialogTitle>Conduct Tabletop</DialogTitle>*/}
                    {/*    <DialogContent>*/}
                    {/*        {scenario && scenario.status=='Approved' ?*/}
                    {/*            <MainCard title="Start Tabletop">*/}
                    {/*                <Stack direction="row" justifyContent="flex-start" spacing={1}>*/}
                    {/*                    <RunCircleOutlined />*/}
                    {/*                    {companyId  && <PortalListSelect companyId={companyId} selectLabel={"Select Portal to Conduct Tabletop"} onPortalSelect={handlePortalSelect} /> }*/}
                    {/*                    {conduct && conduct.portalId &&*/}
                    {/*                        <Link color="primary" component={RouterLink} to={`/portal/conduct/${conduct.portalId}/${companyId}/${scenario?.scenarioId}/${conduct.conductId}`}>*/}
                    {/*                            <Button variant="contained" color={"success"} size={"medium"} endIcon={<ArrowForwardTwoTone />} >*/}
                    {/*                                Start*/}
                    {/*                            </Button>*/}
                    {/*                        </Link>}*/}
                    {/*                </Stack>*/}
                    {/*            </MainCard> :*/}
                    {/*            <MainCard title="Start Tabletop">*/}
                    {/*                <Typography variant={"caption"}>Scenario must be approved to conduct a tabletop. Status can be changed in the 'Setup' of the Scenario.</Typography>*/}
                    {/*                <br/>*/}
                    {/*                <Typography variant={"caption"}>Current status is: {scenario?.status}</Typography>*/}
                    {/*            </MainCard>*/}
                    {/*        }*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button onClick={() => setOpenTabletopStartDialog(false)}>Cancel</Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}

                    {/*<Grid item xs={12}  sm={12} md={12} lg={12}>*/}
                    {/*    <MainCard title="Risks" >*/}
                    {/*        <Grid container={true} spacing={2} >*/}
                    {/*            <Grid item xs={12}>*/}
                    {/*                {scenario.riskIds &&*/}
                    {/*                    <CompanyRisksList riskIds={scenario.riskIds} companyId={scenario.companyId}/>*/}
                    {/*                }*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={8}>*/}
                    {/*                <RiskListSelect companyId={scenario.companyId} label={"Link Risk"} onRiskSelected={handleRiskSelect} />*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={4} sx={{*/}
                    {/*                display: 'flex',*/}
                    {/*                alignItems: 'flex-end',*/}
                    {/*            }}>*/}
                    {/*                <Button variant="contained"  color="primary" onClick={handleRiskLink}>*/}
                    {/*                    Link*/}
                    {/*                </Button>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}
                    {/*    </MainCard>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}  sm={12} md={12} lg={12}>*/}
                    {/*    <MainCard title="Runbooks" >*/}
                    {/*        <Grid container={true} spacing={2} >*/}
                    {/*            <Grid item xs={12}>*/}
                    {/*                {scenario.runbookIds &&*/}
                    {/*                    <CompanyRunbooksList runbookIds={scenario.runbookIds} companyId={scenario.companyId}/>*/}
                    {/*                }*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={8}>*/}
                    {/*                <RunbookListSelect companyId={scenario.companyId} label={"Link Runbook"} onRunbookSelected={handleRunbookSelected} />*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={4} sx={{*/}
                    {/*                display: 'flex',*/}
                    {/*                alignItems: 'flex-end',*/}
                    {/*            }}>*/}
                    {/*                <Button variant="contained"  color="primary" onClick={handleRunbookLink}>*/}
                    {/*                    Link*/}
                    {/*                </Button>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}
                    {/*    </MainCard>*/}
                    {/*</Grid>*/}

                    {scenario &&
                        <Dialog fullWidth= {true} open={openStatusDialog} onClose={handleCloseStatusDialog}>
                            <DialogTitle>Change Scenario Status</DialogTitle>
                            <DialogContent>
                                <ScenarioStatusSelect scenario={scenario} />
                            </DialogContent>
                            <DialogActions>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} >
                                        <Card>
                                            <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                                <Button variant="contained" onClick={handleDialogUpdate} color="primary">Save</Button>
                                                <Button variant="contained" onClick={handleCloseStatusDialog}>Cancel</Button>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    }
                </Grid>
                    <TipsDialog tipsText={[ "Scenarios are detailed narratives or descriptions that outline specific situations, events, or circumstances, often used for planning, analysis, or simulation purposes. In the context of risk and threat management, scenarios typically involve various factors such as potential risks, threats, vulnerabilities, and their potential consequences, helping organizations anticipate and prepare for various possible situations", "Use this page to review and edit scenario details (edit under the 'Setup' tab)","The 'Scenario Plan' tab lets you review and edit the script the scenario follows providing you a place to fine tune how a simulation or tabletop would/could be conducted.","'Reports' provide links to previously run Simulations/Tabletops.", "You can perform a simulated exercise of the scenario by clicking the 'Simulate' button or perform a live tabletop exercise by clicking 'Tabletop'."]} open={openTips} handleClose={closeTips}/>
                </MainCard>
                </Grid>
            </Grid>
        </Grid>


    );
};
export default DesignerOrganizationScenarioViewPage;
