import React from 'react';
import {AAR, Conduct} from "../interfaces/interfaces";
import {Button} from "@mui/material";
import {FilePdfOutlined} from "@ant-design/icons";

interface PrintPDFProps {
    aar: AAR;
    conduct: Conduct;
}

const PrintPDF: React.FC<PrintPDFProps> = ({ aar, conduct }) => {
    const handlePrintPDF = () => {
        const printWindow = window.open('', '_blank');
        const recommendations = aar?.recommendations || [];
        const formattedRecommendations = recommendations.map(line => `<p>${line}</p>`).join('');

        const lessonsLearned = aar?.lessonsLearned || [];
        const formattedLessonsLearned = lessonsLearned.map(line => `<p>${line}</p>`).join('');

        const whatWentWell = aar?.whatWentWell || [];
        const formattedWhatWentWell = whatWentWell.map(line => `<p>${line}</p>`).join('');

        const challenges = aar?.challenges || [];
        const formattedChallenges = challenges.map(line => `<p>${line}</p>`).join('');

        const areasForImprovement = aar?.areasForImprovement || [];
        const formattedAreasForImprovement = areasForImprovement.map(line => `<p>${line}</p>`).join('');

        const actions = conduct?.actions || [];
        const formattedActions = actions.map(action => `<li>${action.messageAction}</li>`).join('');

        const participants = conduct?.participants || [];
        const formattedParticipants = participants.map(participant => `<li>${participant.name}, ${participant.role}</li>`).join('');

        const actionAnalysis = aar?.actionAnalysis ? `
            <h3>Action Analysis</h3>
            <caption>${aar.actionAnalysis}</caption>
        ` : '';

        printWindow?.document.write(`
        <html>
            <head>
                <title>After-Action Report for Tabletop Exercise</title>
                <style>
                    /* Add your print styles here */
                </style>
            </head>
            <body>
                <h1>After-Action Report for Conducted Tabletop Exercise</h1>
                <h2>${aar?.reportTitle}</h2>
                <h5>Date of Exercise</h5>
                <p>${aar?.date}</p>
                <h5>Date of Report</h5>
                <p>${aar?.userAudit.createdDate}</p>
                    <h3>Participants</h3>
                ${formattedParticipants}
                <h3>Executive Summary</h3>
                <caption>${aar?.executiveSummary}</caption>
                 <h3>Objectives</h3>
                <caption>${conduct?.objective}</caption>
                <h3>Actions</h3>
                ${formattedActions}
                ${actionAnalysis}
                <h3>What Went Well?</h3>
                ${formattedWhatWentWell}
                <h3>Challenges</h3>
                ${formattedChallenges}
                <h3>Areas for Improvement?</h3>
                ${formattedAreasForImprovement}
                <h3>Lessons Learned</h3>
                ${formattedLessonsLearned}
                <h3>Recommendations</h3>
                ${formattedRecommendations}
                <h3>Conclusion</h3>
                <caption>${aar?.conclusion}</caption>
            </body>
        </html>
    `);

        printWindow?.document.close();
        printWindow?.print();
    };

    return (
        <Button variant="outlined" size={"small"} onClick={handlePrintPDF}><FilePdfOutlined/>&nbsp;Print</Button>
    );
};

export default PrintPDF;