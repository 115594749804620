import React, { FC } from 'react';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Button,
  Typography,
  List,
  ListItem,
  Stack
} from '@mui/material';
import {ScriptResource} from "../../interfaces/interfaces";

interface ResourceSelectProps {
  resources: ScriptResource[];
  onLinkResource?: (resourceId: string, scriptId: string) => void;
  scriptId: string;
}

const ResourceSelect: FC<ResourceSelectProps> = ({ resources, scriptId, onLinkResource }) => {
  const [selectedResource, setSelectedResource] = React.useState('');

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedResource(event.target.value);
    };

  function handleLinkResource(resourceId:string, scriptId: string) {
    if (selectedResource && onLinkResource) {
      //console.log('Linking resource', selectedResource, 'to script', scriptId);
       onLinkResource(selectedResource, scriptId);
    }

  }

  return (
      <>
        <Stack spacing={2} direction="column">
      <InputLabel id="demo-simple-select-label">Available Resources</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            value={selectedResource}
            label="Resource"
            fullWidth={true}
            onChange={handleChange}
          >
            {resources.map((resource) => (
              <MenuItem value={resource.resourceId} key={resource.resourceId}>
                <List>
                  <ListItem>
                    <Typography variant="body1" component="span">
                      {resource.resourceShortName}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2" component="span" color="text.secondary">
                      {resource.resourceName} ({resource.resourceType})
                    </Typography>
                  </ListItem>
                </List>
              </MenuItem>
            ))}
          </Select>
        <Button
            variant={"outlined"}
            onClick={() => handleLinkResource(selectedResource, scriptId)}
            disabled={!selectedResource}
        >
          Link Resource
        </Button>
        </Stack>
      </>
  );
};

export default ResourceSelect;