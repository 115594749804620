import { useState } from "react";
import {Scenario} from "../interfaces/interfaces";

export const useAudioRecorder = (token: string | null, scenario: Scenario, setAssistantLabel?: (label: string) => void, handleSendMessage?: (message: string) => void) => {
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [showRecordingBanner, setShowRecordingBanner] = useState(false);
  const [recordingBannerLabel, setRecordingBannerLabel] = useState("");

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
          setAudioChunks([...audioChunks]);
        }
      };
      setMediaRecorder(recorder);
      recorder.start(1000);
      setIsRecording(true);
      setRecordingBannerLabel("Recording audio...");
      setShowRecordingBanner(true);
    } catch (error) {
      console.error("Error initializing MediaRecorder or getUserMedia:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.onstop = async () => {
        setTimeout(async () => {
          setRecordingBannerLabel("Sending audio to Assistant...");
          if (audioChunks.length > 0) {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            await sendAudioBlob(audioBlob);
            setAudioChunks([]);
          }
          setIsRecording(false);
          setShowRecordingBanner(false);
        }, 500);
      };
      mediaRecorder.stop();
      //mediaRecorder.stream.getTracks().forEach(track => track.stop());
    } else {
      console.error("MediaRecorder not initialized");
    }
  };

  const sendAudioBlob = async (audioBlob: Blob) => {
    try {
      const formData = new FormData();
      formData.append('audio', audioBlob);

      const callURL = process.env.REACT_APP_REST_API_URL + '/api/scenario/company/assistant/audio/' + token + '/' + scenario.companyId;
      const response = await fetch(callURL, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (setAssistantLabel) {
          setAssistantLabel("Audio response received!");
        }
        setRecordingBannerLabel("Sending transcription to Assistant...");
        setShowRecordingBanner(true);
        if (handleSendMessage) {
          handleSendMessage(data.answer);
        }
        if (mediaRecorder) {
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
        setShowRecordingBanner(false);
      } else {
        console.error(response);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return {
    startRecording,
    stopRecording,
    isRecording,
    showRecordingBanner,
    recordingBannerLabel
  };
};