import {createBrowserRouter} from 'react-router-dom';

// project import
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import PortalRoutes from "./PortalRoutes";
import LandingPageRoutes from "./LandingPageRoutes";

export function createRoutesForRole() {

    // const filteredLoginRoutes = LoginRoutes.filter(route => route.roles.includes(userRole));
  // const filteredMainRoutes = MainRoutes.filter(route => route.roles.includes(userRole));
  // const filteredPortalRoutes = PortalRoutes.filter(route => route.roles.includes(userRole));

  // return createBrowserRouter([...filteredLoginRoutes, ...filteredMainRoutes, ...filteredPortalRoutes], { basename: process.env.REACT_APP_BASE_NAME });
    return createBrowserRouter([LoginRoutes, MainRoutes, PortalRoutes, LandingPageRoutes], { basename: process.env.REACT_APP_BASE_NAME });
}