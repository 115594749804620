import React from "react";
import { ScenarioImage} from "../../interfaces/interfaces";
import {Grid} from "@mui/material";


interface ScenarioImageProps {
    images:ScenarioImage[];
}
const ScenarioImageGridDisplay: React.FC<ScenarioImageProps> = ({ images }) => {


    return (
        <>
            {images && images.length > 0 &&
                images.map((image, index) => (
                    <>
                        <Grid item xs={12}>
                            <img src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${image.location}`} alt="image" style={{width: '500px', height: '500px'}}/>
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <Typography variant="h4">{process.env.REACT_APP_REST_API_URL}{image.location}</Typography>*/}
                        {/*</Grid>*/}
                    </>
                ))
            }
        </>
    );
};

export default ScenarioImageGridDisplay;