import {
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Stack, Grid, Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {Inject} from "../../interfaces/interfaces";
import React, {useEffect, useState} from "react";
import {AddOutlined, MergeOutlined} from "@mui/icons-material";

interface ScenarioInjectsTabletopComponentProps {
    injects: Inject[];
    editable?: boolean;
    onUpdate?: (newInjects: Inject[]) => void;
    onBranchInject?: (inject: Inject) => void;
    onAddInject?: (inject: Inject) => void;
}

const ScenarioInjectsTabletopComponent: React.FC<ScenarioInjectsTabletopComponentProps> = ({ injects, editable,
                                                                                               onUpdate,
                                                                                               onBranchInject, onAddInject }) => {
    const [open, setOpen] = useState(false);
    const [newInject, setNewInject] = useState<Inject>({ description: '', type: '' });
    const [localInjects, setInjects] = useState<Inject[]>(injects);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddInject = (event: React.FormEvent) => {
        event.preventDefault();
        if (onUpdate) {
            onUpdate([...localInjects, newInject]);
        }
        setNewInject({ description: '', type: '' });
        handleClose();
    };

    const handleDeleteInject = (index: number) => {
        if (onUpdate) {
            const newInjects = [...localInjects];
            newInjects.splice(index, 1);
            onUpdate(newInjects);
        }
    };

    useEffect(() => {
        setInjects(injects);
        //console.log("set injects "+localInjects.length);
    }, [injects]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                        {editable && <Button variant="outlined" color="primary" size="small" onClick={handleOpen}><AddOutlined/> Inject</Button>}
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                        <List sx={{ p: 0 }}>
                        {localInjects && localInjects.length > 0 ? (
                            localInjects.map((inject: Inject, index) => (
                                <ListItem disablePadding divider>
                                    <Grid container={true} spacing={2} >
                                        <Grid item={true} xs={11}>
                                            <Stack spacing={0}>
                                                <ListItemButton>
                                                    <ListItemText primary={`${index+1}. ${inject.description}`} sx={{ userSelect: 'text' }}/>
                                                </ListItemButton>
                                            </Stack>
                                        </Grid>
                                        <Grid item={true} xs={1}>
                                            {editable && <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteInject(index)}>
                                                <DeleteIcon />
                                            </IconButton>}
                                            {onAddInject && <IconButton edge="end" aria-label="add" onClick={() => onAddInject(inject)}>
                                                <AddOutlined />
                                            </IconButton>}
                                            {onBranchInject && <IconButton edge="end" aria-label="branch" onClick={() => onBranchInject(inject)}>
                                                <MergeOutlined />
                                            </IconButton>}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        ) : (
                            <Typography variant="subtitle1">No Injects Defined</Typography>
                        )}
                    </List>
                </Grid>

            </Grid>

                    <Dialog open={open} fullWidth={true} onClose={handleClose}>
                <DialogTitle>Add Inject</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleAddInject}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="description"
                            label="Inject Description"
                            fullWidth
                            required={true}
                            value={newInject.description}
                            onChange={(e) => setNewInject({ ...newInject, description: e.target.value })}
                        />
                        <TextField
                            margin="dense"
                            id="type"
                            label="Inject Type (optional)"
                            fullWidth
                            value={newInject.type}
                            onChange={(e) => setNewInject({ ...newInject, type: e.target.value })}
                        />
                        <DialogActions>
                            <Button variant="contained" onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" type="submit" disabled={!newInject.description}>Add</Button>                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ScenarioInjectsTabletopComponent;