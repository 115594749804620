import  { FC } from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const SimCard: FC<{ difficulty: number, label: string, variant?: string, size?:string }> = ({ difficulty, label, variant, size }) => {
    const color = difficulty >= 8 ? "error" : difficulty >= 4 ? "success" : "primary";
    const variantColor = variant === "combined" ? "light" : "filled";
    const labelSize = size === "small" ? "small" : "medium";
    return (
        <Box>
            <Chip variant={variantColor} color={color} size={labelSize} label={label ? label+" "+difficulty : difficulty}/>
        </Box>
    );
};

export default SimCard;