// third-party
import { FormattedMessage } from 'react-intl';

// assets

// type
import { NavItemType } from 'types/menu';

import { AspectRatioOutlined} from "@mui/icons-material";
// icons


// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="my-portal" />,
  type: 'group',
  children: [
    // {
    //
    //   id: 'maintenance',
    //   title: <FormattedMessage id="maintenance" />,
    //   type: 'collapse',
    //   icon: icons.RocketOutlined,
    //   children: [
    //     {
    //       id: 'error-404',
    //       title: <FormattedMessage id="error-404" />,
    //       type: 'item',
    //       url: '/maintenance/404',
    //       target: true
    //     },
    //     {
    //       id: 'error-500',
    //       title: <FormattedMessage id="error-500" />,
    //       type: 'item',
    //       url: '/maintenance/500',
    //       target: true
    //     },
    //     {
    //       id: 'coming-soon',
    //       title: <FormattedMessage id="coming-soon" />,
    //       type: 'item',
    //       url: '/maintenance/coming-soon',
    //       target: true
    //     },
    //     {
    //       id: 'under-construction',
    //       title: <FormattedMessage id="under-construction" />,
    //       type: 'item',
    //       url: '/maintenance/under-construction',
    //       target: true
    //     }
    //   ]
    // },
    // {
    //   id: 'List',
    //   title: <FormattedMessage id="Clients" />,
    //   type: 'item',
    //   url: '/companies',
    //   icon: icons.BusinessIcon,
    // },
    {
      id: 'Reports',
      title: <FormattedMessage id="Portals" />,
      type: 'item',
      url: '/portals',
      icon: AspectRatioOutlined,
    }
  ]
};

export default pages;
