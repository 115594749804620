import React, {createContext, useEffect, useReducer} from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'contexts/auth-reducer/actions';
import authReducer from 'contexts/auth-reducer/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { KeyedObject } from 'types/root';
import {AuthProps, JWTContextType, UserProfile} from 'types/auth';
import {logout} from "../hooks/logout";
const chance = new Chance();

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: {
    userId: '',
    email: '',
    name: '',
    role: '',
    isDemo: false
  }
};

//START HERE FOR NAVIGATION AND USER PERMISSIONS


const verifyToken: (st: string) => boolean = (serviceToken) => {
  console.log("verifyToken: "+serviceToken);
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {

  if (serviceToken) {
    //console.log("setSession: "+serviceToken);
    localStorage.setItem('token', serviceToken);
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get('/api/account/me');
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        //console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string) => {
  //console.log(process.env.NODE_ENV);
    let uname = email;
    let username = email;

    const response = await axios.post(process.env.REACT_APP_REST_API_URL+'/api/auth/login', { uname, username, email, password });
    setSession(response.data);
    const profileResponse = await fetch(process.env.REACT_APP_REST_API_URL+'/api/auth/profile/view/'+response.data);
    const userData = await profileResponse.json();
    const user: UserProfile = userData;
    //console.log("post login: response:"+response+" response.data:"+response.data.role+" user:"+user.role+" user:"+user);

    console.log("post login:"+user.role);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: {
            userId: user.userId,
            email: user.email,
            name: user.name,
            role: user.role,
            isDemo: user.isDemo
        }
      }
    });

    // dispatch({
    //   type: LOGIN,
    //   payload: {
    //     isLoggedIn: true,
    //     user
    //   }
    // });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string, company:string) => {
    const id = chance.bb_pin();
    const response = await axios.post(process.env.REACT_APP_REST_API_URL+'/api/auth/register', {
      id,
      email,
      password,
      firstName,
      lastName,
      company
    });
    const { user } = response.data;
    //console.log("post login: response:"+response+" response.data:"+response.data);
    if (response.data!='error') {
      setSession(response.data);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user
        }
      });
    } else {
     // console.error("Error registering user");
      dispatch({
        type: LOGOUT,
        payload: {
          isLoggedIn: false,
          user: null,
          error: "Error registering your account.  Make sure all fields are filled out and you are using a business email to register."
        }
      });
      throw new Error("Error registering your account.  Make sure all fields are filled out and you are using a business email to register.");
    }
  };

  const logoutUser = () => {
    setSession(null);
    logout(dispatch);
  };

  // const logout = () => {
  //   setSession(null);
  //   dispatch({ type: LOGOUT });
  // };

  const resetPassword = async (email: string) => {
    const response =  await axios.post(process.env.REACT_APP_REST_API_URL+'/api/auth/resetpassword', { email });
    //console.log(response.data);
    if (response.data!='error') {
      setSession(response.data);
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: false
        }
      });
    }
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout:logoutUser, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
