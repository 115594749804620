import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { ConductBrief } from "../../interfaces/interfaces";

interface ConductBriefOverviewDialogProps {
    conductBrief: ConductBrief;
    conductBriefOverviewOpen: boolean;
    handleConductBriefOverviewClose: () => void;
    updateConductBrief: (conductBrief: ConductBrief) => void;
}

const ConductBriefOverviewDialog: React.FC<ConductBriefOverviewDialogProps> = ({ conductBrief, conductBriefOverviewOpen, handleConductBriefOverviewClose, updateConductBrief }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [updatedConductBrief, setUpdatedConductBrief] = useState<ConductBrief>(conductBrief);

    useEffect(() => {
        setUpdatedConductBrief(conductBrief);
    }, [conductBrief]);

    const handleClose = () => {
        setCurrentStep(0);
        handleConductBriefOverviewClose();
    };

    interface AttributeTitles {
        objectives: string;
        observations: string;
        notes: string;
        actionItemSummary: string;
        [key: string]: string;
    }

    const attributeTitles: AttributeTitles = {
        'objectives': ' Objectives',
        'observations': 'Observations',
        'notes': 'Tabletop Notes',
        'actionItemSummary': 'Action Items (Summary)'
    };

    const renderStepContent = () => {
        const attributes: (keyof AttributeTitles)[] = [  'objectives', 'observations', 'notes','actionItemSummary'];
        return {
            title: attributeTitles[attributes[currentStep]],
            content: updatedConductBrief[attributes[currentStep]]
        };
    };

    const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const attributes: (keyof AttributeTitles)[] = [ 'objectives', 'observations', 'notes','actionItemSummary'];
        setUpdatedConductBrief({...updatedConductBrief, [attributes[currentStep]]: event.target.value});
    };

    const stepContent = renderStepContent();
    const currentDate = new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });
    return (
        <Dialog fullScreen open={conductBriefOverviewOpen} onClose={handleClose}>
            <Card>
                <CardContent>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={2}>
                        </Grid>
                        <Grid item={true} xs={8}>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12}>
                                    <Typography variant="h2"  gutterBottom>
                                        Post-Exercise Brief
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    {updatedConductBrief &&
                                        <>
                                        <Grid container={true} spacing={2}>
                                            <Grid item={true} xs={6}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={updatedConductBrief.name}
                                                onChange={(event) => setUpdatedConductBrief({...updatedConductBrief, name: event.target.value})}
                                            />
                                            </Grid>
                                            {/*<Grid item={true} xs={4}>*/}
                                            {/*<TextField*/}
                                            {/*    fullWidth*/}
                                            {/*    variant="outlined"*/}
                                            {/*    value={updatedConductBrief.type}*/}
                                            {/*    onChange={(event) => setUpdatedConductBrief({...updatedConductBrief, type: event.target.value})}*/}
                                            {/*/>*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                        </>
                                    }
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Typography variant="h2"  gutterBottom>
                                        {stepContent.title}
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Box style={{ minHeight: '800px', overflow: 'auto' }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={10}
                                        variant="outlined"
                                        value={stepContent.content}
                                        onChange={handleUpdate}
                                    />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={2}>
                            <Typography variant="h5"  gutterBottom>
                                {currentDate}
                        </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <DialogActions>
                <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button variant={"contained"} disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                        Previous
                    </Button>
                    <Button variant={"contained"} disabled={currentStep === 3} onClick={() => setCurrentStep(currentStep + 1)}>
                        Next
                    </Button>
                    <Button onClick={() => {updateConductBrief(updatedConductBrief); handleClose();}} color="primary" variant={"contained"}>
                        Save & Close
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ConductBriefOverviewDialog;