// material-ui

// project import
import React, {useEffect, useState} from "react";
import {Annotation, GameMessage, Scenario, Simulation} from "interfaces/interfaces";
import {useParams} from "react-router";
import ScriptItemListComponent from "../../components/tabletop/ScriptItemListComponent";
import ScenarioInjectsTabletopComponent from "../../components/tabletop/ScenarioInjectsTabletopComponent";
import {
    Box,
    Button, ButtonGroup,
    Card,
    CardContent, Chip,
    Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl,
    Grid, Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Stack, Tab, Tabs, TextField,
    Typography
} from "@mui/material";
import MainCard from "../../components/MainCard";
// import WelcomeSimulateViewBanner from "../../sections/simulation/WelcomeSimulateViewBanner";
import FeedbackIcon from '@mui/icons-material/Feedback';
import HoverSocialCard from "../../components/cards/statistics/HoverSocialCard";
import {useTheme} from "@mui/material/styles";
import {a11yProps, TabPanel} from "../../hooks/tabPanelHelpers";
// import AARComponent from "../../components/scenario/AARComponent";
// import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";
// import ScenarioSimulationStatsCard from "../../components/scenario/ScenarioSimulationStatsCard";
import {Link as RouterLink} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";

// ==============================|| SAMPLE PAGE ||============================== //

interface Params {
    companyId: string;
    scenarioId: string;
    simulationId: string;
}

const SimulateViewPage= () => {
    // @ts-ignore
    const { companyId,scenarioId,simulationId } = useParams<Params>();
    const [scenario, setScenario] = useState<Scenario>();
    const [simulation, setSimulation] = useState<Simulation>();
    const token = localStorage.getItem('token');
    const [openAnnotateDialog, setOpenAnnotateDialog] = useState(false);
    const [annotateTextValue, setAnnotateTextValue] = useState("");
    const [annotationTargetValue, setAnnotationTargetValue] = useState("");
    // const [aar, setAAR] = useState<AAR>();
    // const [isAARLoading, setIsAARLoading] = useState<boolean>(false);

    const getSimData = async () => {
        try {
            // console.log(id);
            const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/scenario/getsim/'+token+'/' + companyId+'/'+scenarioId+'/'+simulationId);
            const data = await response.json();
            setSimulation(data);
        } catch (error) {
            console.error(error);
        }
    };

     const [tabSelectValue, setTabSelectValue] = useState(0);

     const handleTabSelectChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSelectValue(newValue);
    };
    useEffect(() => {
        const fetchData = async () => {
            getSimData();
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/scenario/company/getscenario/'+token+'/' +companyId+'/'+scenarioId);
                const data = await response.json();
                setScenario(data);
            } catch (error) {
                console.error(error);
            }
            // try {
            //     const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/simulation/getAAR/' + token + '/' + companyId + '/' + scenarioId + '/' + simulationId);
            //     const data = await response.json();
            //     setAAR(data);
            // } catch (error) {
            //     console.error("Error updating scenario:", error);
            // }
        };
        fetchData();
    }, []);


    function handleAnnotate(id: string) {
        setOpenAnnotateDialog(true);
        setAnnotationTargetValue(id);
        setAnnotateTextValue("");
    };

    const closeAnnotateDialog = () => {
        setOpenAnnotateDialog(false);
        setAnnotateTextValue("");
        setAnnotationTargetValue("");
    };

    const theme = useTheme();
    // const handleGenAAR = async () => {
    //     try {
    //         setIsAARLoading(true);
    //         const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/simulation/genAAR/' + token + '/' + companyId + '/' + scenarioId + '/' + simulationId);
    //         const data = await response.json();
    //         setAAR(data);
    //         setIsAARLoading(false);
    //     } catch (error) {
    //         console.error("Error updating scenario:", error);
    //     }
    // };
    const handleSaveAnnotation = async () => {
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/tabletop/scenario/simulation/annotate/${token}/${companyId}/${scenarioId}/${simulationId}/${annotationTargetValue}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(annotateTextValue),
            });
            const annotation: Annotation = await response.json();
            simulation?.messages?.forEach((msgItem:GameMessage, index) => {
                if (msgItem.id === annotation.gameMessageId) {
                    if (!msgItem.annotations) {
                        msgItem.annotations = [];
                    }
                    msgItem.annotations?.push(annotation);
                }
            });
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        closeAnnotateDialog();

    };

    return (
        <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xs={12}>
                {/*<WelcomeSimulateViewBanner scenario={scenario} simulation={simulation} companyId={scenario?.companyId} />*/}
                <Grid container={true} spacing={2} >
                    <Grid item xs={6} >
                        <HoverSocialCard primary={"Conducted on: " +simulation?.createDate} secondary={simulation?.name} color={theme.palette.primary.light} />
                    </Grid>
                    {/*<Grid item xs={3}>*/}
                    {/*    <ScenarioSimulationStatsCard primary={scenario?.type} secondary={"Stats"} color={theme.palette.primary.main} effectiveness={85} difficulty={simulation?.difficulty} />*/}
                    {/*</Grid>*/}

                </Grid>
            </Grid>
            <Grid item xs={12}>
            <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
                <Box sx={{ p: 2.5, pb: 0 }}>
                    <MainCard content={false} sx={{ p: 1.25, bgcolor: 'primary.lighter', borderColor: theme.palette.primary[100] }}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                            <ButtonGroup>
                                {/*<Button*/}
                                {/*    variant="outlined"*/}
                                {/*    size={"small"}*/}
                                {/*    startIcon={<ShareOutlined/>}*/}
                                {/*>*/}
                                {/*    Share*/}
                                {/*</Button>*/}
                                {/*<Button*/}
                                {/*    variant="outlined"*/}
                                {/*    size={"small"}*/}
                                {/*    startIcon={<PublishOutlined/>}*/}
                                {/*>*/}
                                {/*    Publish*/}
                                {/*</Button>*/}
                            </ButtonGroup>
                            <Link color="primary" component={RouterLink} to={`/company/scenario/view/${companyId}/${scenarioId}`}>
                                <Button
                                    variant="outlined"
                                    size={"small"}
                                    startIcon={<ArrowBack/>}
                                >
                                    Back to Scenario
                                </Button>
                            </Link>
                        </Stack>
                    </MainCard>
                </Box>
            <Tabs value={tabSelectValue} onChange={handleTabSelectChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                <Tab
                    label="Summary"
                    {...a11yProps(0)}
                />
                <Tab label="Transcript" {...a11yProps(1)} />
                {/*<Tab label="Analysis" {...a11yProps(2)} />*/}
                {/*<Tab label="After-Action Report" {...a11yProps(2)} />*/}
            </Tabs>

            <Grid item xs={12}>
                <TabPanel value={tabSelectValue} index={0} >
                    {scenario && <ScriptItemListComponent scriptItems={scenario.script} showSteps={false}/>}
                    {scenario && scenario.injects && <ScenarioInjectsTabletopComponent injects={scenario.injects} />}
                </TabPanel>
                <TabPanel value={tabSelectValue} index={1} >
                    {scenario &&
                        <>
                            <Grid container rowSpacing={4.5} columnSpacing={3}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <MainCard title="Simulation Transcript">
                                                <Card>
                                                    <CardContent>
                                                        <Typography color="secondary" >
                                                            <List dense={true}>
                                                                {simulation?.messages && (simulation.messages.map((msgItem:GameMessage, index) => {
                                                                    return (
                                                                        <>
                                                                            <ListItem divider>
                                                                                <ListItemText primary={`${msgItem.gameQuestion}` } secondary={`${msgItem.actionResultDetails}`}/>
                                                                                <ListItemIcon sx={{ mr: 0.5 }}>
                                                                                    <FeedbackIcon onClick={() => handleAnnotate(msgItem.id)} />
                                                                                </ListItemIcon>
                                                                            </ListItem>

                                                                            {msgItem.annotations && msgItem.annotations.map((annotationItem:Annotation, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Chip label={`I said: ${annotationItem.text}`} color="info" />

                                                                                        {/*<Typography alignContent={"right"} variant={"caption"}>I said: {annotationItem.text}</Typography>*/}
                                                                                    </>);
                                                                            })
                                                                            }
                                                                            </>
                                                                    )}))}
                                                            </List>
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </MainCard>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                </TabPanel>
                {/*<TabPanel value={tabSelectValue} index={2} >*/}
                {/*    {scenario && <ScenarioNotesTabletopComponent scenario={scenario} />}*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={tabSelectValue} index={2} >*/}
                {/*    {isAARLoading &&*/}
                {/*        <QuickLoaderComponent loadBanner={"Generating AAR....."} />*/}
                {/*    }*/}
                {/*    {aar ?(<AARComponent aar={aar} companyId={companyId} scenarioId={scenarioId} conductId={simulationId} editable={false}/>): (*/}
                {/*        <Typography variant="h6">No AAR Generated Yet - <Button*/}
                {/*            variant="outlined"*/}
                {/*            color="secondary"*/}
                {/*            onClick={handleGenAAR}*/}
                {/*        >*/}
                {/*            Generate AAR*/}
                {/*        </Button></Typography>*/}
                {/*    )}*/}
                {/*</TabPanel>*/}
            </Grid>
            </MainCard>
            </Grid>
            <Dialog open={openAnnotateDialog} fullWidth={true} onClose={closeAnnotateDialog}>
                <DialogTitle>Add Annotation</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            name="text"
                            placeholder="Annotate...."
                            fullWidth={true}
                            variant="outlined"
                            value={annotateTextValue}
                            onChange={(e) => setAnnotateTextValue(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAnnotateDialog}>Cancel</Button>
                    <Button onClick={handleSaveAnnotation} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </Grid>

    );
};
export default SimulateViewPage;
