import { useState} from 'react';

// material-ui
import {
  Box,
  Button,
  Chip,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// third-party
// import { PatternFormat } from 'react-number-format';
// import { PDFDownloadLink } from '@react-pdf/renderer';

// project import
// import CustomerModal from './CustomerModal';
// import CustomerPreview from './CustomerPreview';
// import AlertCustomerDelete from './AlertCustomerDelete';
// import ListSmallCard from './exportpdf/ListSmallCard';

import MainCard from 'components/MainCard';
// import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';

// assets
import { MoreOutlined } from '@ant-design/icons';
import { Portal} from "../../interfaces/interfaces";
import {SummarizeOutlined} from "@mui/icons-material";
// import {AssessmentOutlined, DnsOutlined} from "@mui/icons-material";

// types

// const avatarImage = require.context('assets/images/users', true);

// ==============================|| CUSTOMER - CARD ||============================== //

const PortalCard = ({ portal }: { portal: Portal }) => {
  const navigate = useNavigate();

  const handleClickOpen = () => {
    navigate(`/portal/viewportal/${portal.companyId}/${portal.portalId}`);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
        <Grid id="print" container spacing={2.25}>
          <Grid item xs={12}>
            <List sx={{ width: 1, p: 0 }}>
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton edge="end" aria-label="comments" color="secondary" onClick={handleMenuClick}>
                    <MoreOutlined style={{ fontSize: '1.15rem' }} />
                  </IconButton>
                }
              >

                <ListItemAvatar>
                  <SummarizeOutlined />
                  {/*<Avatar alt={company.name} src={avatarImage(`./avatar-${!company.avatar ? 1 : company.avatar}.png`)} />*/}
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle1">{portal.name}</Typography>}
                  secondary={
                   <>
                     <Typography variant="caption" color="secondary">
                      {portal.type}
                    </Typography>
                   </>
                  }
                />
              </ListItem>
            </List>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem sx={{ a: { textDecoration: 'none', color: 'inherit' } }}>
                {/*<PDFDownloadLink document={<ListSmallCard customer={company} />} fileName={`Customer-${company.name}.pdf`}>*/}
                {/*  Export PDF*/}
                {/*</PDFDownloadLink>*/}
              </MenuItem>
              {/*<MenuItem onClick={editCustomer}>Edit</MenuItem>*/}
              {/*<MenuItem onClick={handleAlertClose}>Delete</MenuItem>*/}
            </Menu>
          </Grid>
          <Grid item xs={12}>
            <Typography>Portal for: {portal.companyName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>{portal.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            {portal.status &&
            <Chip label={portal.status} size="small" color="primary" />
            }
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0
                }}
                component="ul"
              >
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Stack
          direction="row"
          className="hideforPDf"
          alignItems="center"
          spacing={1}
          justifyContent="space-between"
          sx={{ mt: 'auto', mb: 0, pt: 2.25 }}
        >
          <Typography variant="caption" color="secondary">
            <Chip label="Active" size="small" color="success" />
          </Typography>
          <Button variant="outlined" size="small" onClick={handleClickOpen}>
            View
          </Button>
        </Stack>
      </MainCard>
    </>
  );
};

export default PortalCard;
