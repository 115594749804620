import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import {AAR} from "../../interfaces/interfaces";
import OrganizationBrandingImageDisplay from "../organization/OrganizationBrandingImageDisplay";
import {useOrganization} from "../../hooks/useOrganization";
import ReactMarkdown from "react-markdown";

interface AAROverviewDialogProps {
    title: string;
    objectives?: string;
    agenda?: string;
    instructions?: string;
    aar?: AAR;
    aarOverviewOpen: boolean;
    handleAAROverviewClose: () => void;
}

interface StepContent {
    title: string;
    content: string | JSX.Element[];
}

const AAROverviewDialog: React.FC<AAROverviewDialogProps> = ({ title, objectives, instructions, aar, agenda, aarOverviewOpen, handleAAROverviewClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const organization = useOrganization();

    const handleClose = () => {
        setCurrentStep(0);
        handleAAROverviewClose();
    };

    const [stepContent, setStepContent] = useState<StepContent | null>({
        title: '',
        content: [],
    });

    useEffect(() => {
        const newStepContent = renderStepContent();
        setStepContent(prev => prev ? {...prev, ...newStepContent} : newStepContent);
    }, [currentStep]);

const renderStepContent = () => {
    switch (currentStep) {
        case 0:
            return {
                title: 'Executive Summary',
                content: aar?.executiveSummary ? aar.executiveSummary.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                            <>
                                {line && line.length>0 && <ReactMarkdown key={index} children={`### ${line}`} /> }
                            </>
                    </React.Fragment>
                )) : 'No executive summary provided'
            };
        case 1:
            return {
                title: 'Analysis',
                content: aar?.analysis ? aar.analysis.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                            {line && line.length>0 && <ReactMarkdown key={index} children={`### ${line}`} /> }
                    </React.Fragment>
                )) : 'No analysis provided'
            };
        case 2:
            return {
                title: 'Objectives',
                content: aar?.objectives ? aar.objectives.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`- ${line}`} /> }
                    </React.Fragment>
                ))) : 'No objectives provided'
            };
        case 3:
            return {
                title: 'Observations',
                content: aar?.observations ? aar.observations.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`- ${line}`} /> }
                    </React.Fragment>
                ))) : 'No observations provided'
            };
        case 4:
            return {
                title: 'Recommendations',
                content: aar?.recommendations ? aar.recommendations.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                    </React.Fragment>
                ))) : 'No recommendations provided'
            };
        case 5:
            return {
                title: 'What Went Well',
                content: aar?.whatWentWell ? aar.whatWentWell.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`- ${line}`} /> }
                    </React.Fragment>
                ))) : 'No data for what went well'
            };
        case 6:
            return {
                title: 'Challenges',
                content: aar?.challenges ? aar.challenges.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`- ${line}`} /> }
                    </React.Fragment>
                ))) : 'No challenges provided'
            };
        case 7:
            return {
                title: 'Areas for Improvement',
                content: aar?.areasForImprovement ? aar.areasForImprovement.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`- ${line}`} /> }
                    </React.Fragment>
                ))) : 'No areas for improvement provided'
            };
        case 8:
            return {
                title: 'Actions',
                content: aar?.actions ? aar.actions.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`- ${line}`} /> }
                    </React.Fragment>
                ))) : 'No actions provided'
            };
        case 9:
            return {
                title: 'Conclusion',
                content: aar?.conclusion ? aar.conclusion.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                    </React.Fragment>
                )) : 'No conclusion provided'
            };
        case 10:
            return {
                title: 'Lessons Learned',
                content: aar?.lessonsLearned ? aar.lessonsLearned.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                    </React.Fragment>
                ))) : 'No lessons learned provided'
            };
        case 11:
            return {
                title: 'Next Steps',
                content: aar?.nextSteps ? aar.nextSteps.flatMap(obj => obj.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                    </React.Fragment>
                ))) : 'No next steps provided'
            };
        case 12:
            return {
                title: 'Action Analysis',
                content: aar?.actionAnalysis ? aar.actionAnalysis.split('\n').map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                    </React.Fragment>
                )) : 'No action analysis provided'
            };
        default:
            return null;
    }
};

    // const stepContent = renderStepContent();
    const currentDate = new Date();

    return (
        <Dialog fullScreen open={aarOverviewOpen} onClose={handleClose}>
            <Card sx={{ border: '1px solid #000', overflow: 'auto' }}>
                <CardContent>
                    <Grid container={true} spacing={4}>
                        <Grid item xs={1} sx={{ backgroundColor: organization.organization?.brandHexColor ? organization.organization?.brandHexColor : undefined }}>
                            {organization && organization.organization?.logoSmallLocation ?
                                <OrganizationBrandingImageDisplay location={organization.organization.logoSmallLocation} />:
                                <Typography variant="h5"  gutterBottom>
                                    <Chip label={("Tabletop")} variant={"outlined"} />
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container={true} spacing={2}>
                                <Grid item={true} xs={12}>
                                    <Typography variant="h2"  gutterBottom>
                                        {title}
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Typography variant="h3" gutterBottom>
                                        <ReactMarkdown key={999} children={`## ${stepContent?.title}`} />
                                    </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Box style={{ minHeight: '800px', overflow: 'auto' }}>
                                        <Typography variant="h5" >
                                            {stepContent?.content}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1} sx={{ backgroundColor: organization.organization?.brandHexColor ? organization.organization?.brandHexColor : undefined }}>
                            <Stack direction={"column"} justifyContent={"flex-end"}>
                                <Typography variant="h5"  gutterBottom>
                                    <Chip label={currentDate.toLocaleDateString()} variant={"outlined"} />
                                </Typography>
                                <Typography variant="h5"  gutterBottom>
                                    <Chip label={"Tabletop"} variant={"outlined"} />
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <DialogActions>
                <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button variant={"contained"} disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                        Previous
                    </Button>
                    <Button variant={"contained"} disabled={currentStep === 12} onClick={() => setCurrentStep(currentStep + 1)}>
                        Next
                    </Button>
                    <Button onClick={handleClose} color="primary" variant={"contained"}>
                        Close
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default AAROverviewDialog;