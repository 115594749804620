// material-ui
import {Button, Divider, Grid, TextField} from '@mui/material';
import MainCard from 'components/MainCard';
import React, {useEffect, useState} from "react";
import {OrganizationModel} from "interfaces/interfaces";
import ArrayListComponent from "../../components/cards/ArrayListComponent";

const ModelPage = () => {
    const token = localStorage.getItem('token');
    const[updatedModel, setUpdatedModel] = useState<OrganizationModel | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/organization/model/getmodel/'+token);
                const data = await response.json();
                setUpdatedModel(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // @ts-ignore
        setUpdatedModel((prevModel) => ({
            ...prevModel,
            [name]: value,
        }));
    };

    const handleUpdate = async () => {

        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/organization/model/updatemodel/${token}/${updatedModel?.modelId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedModel),
            });
            const data = await response.json();
            setUpdatedModel(data);
            console.log("Updated Model:", updatedModel);
        } catch (error) {
            console.error("Error updating model:", error);
        }
    };

    return (
        <>
        <MainCard>
          <Grid container spacing={2}>
              <Grid item xs={12} >
                  <Grid container  spacing={4}>
                      <Grid item xs={12}>
                          {updatedModel && 
                              <Grid container rowSpacing={3} >
                                  <Grid item xs={12} >
                                      <TextField
                                          name="description"
                                          label="Model Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.description}
                                          onChange={handleInputChange}
                                      />
                                  </Grid>
                                  <Grid item xs={12} >
                                      <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="standardControls"
                                          label="Controls Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.standardControls}
                                          onChange={handleInputChange}
                                      />
                                      {updatedModel.summarizedControls &&
                                          <ArrayListComponent arrayData={updatedModel.summarizedControls} />
                                      }
                                  </Grid>
                                  <Grid item xs={12} >
                                  <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="standardCompliance"
                                          label="Compliance Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.standardCompliance}
                                          onChange={handleInputChange}
                                      />
                                      {updatedModel.summarizedCompliance &&
                                          <ArrayListComponent arrayData={updatedModel.summarizedCompliance} />
                                      }
                                  </Grid>
                                  <Grid item xs={12} >
                                      <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="standardSecurity"
                                          label="Security Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.standardSecurity}
                                          onChange={handleInputChange}
                                      />
                                      {updatedModel.summarizedSecurity &&
                                          <ArrayListComponent arrayData={updatedModel.summarizedSecurity} />
                                      }
                                  </Grid>
                                  <Grid item xs={12} >
                                      <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="standardSystems"
                                          label="Systems Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.standardSystems}
                                          onChange={handleInputChange}
                                      />
                                      {updatedModel.summarizedSystems &&
                                        <ArrayListComponent arrayData={updatedModel.summarizedSystems} />
                                      }
                                  </Grid>
                                  <Grid item xs={12} >
                                      <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="standardPeople"
                                          label="Team Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.standardPeople}
                                          onChange={handleInputChange}
                                      />
                                      {updatedModel.summarizedPeople &&
                                            <ArrayListComponent arrayData={updatedModel.summarizedPeople} />
                                      }
                                  </Grid>
                                  <Grid item xs={12} >
                                      <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="standardPolicies"
                                          label="Policies Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          value={updatedModel.standardPolicies}
                                          onChange={handleInputChange}
                                      />
                                      {updatedModel.summarizedPolicies &&
                                          <ArrayListComponent arrayData={updatedModel.summarizedPolicies} />
                                      }
                                  </Grid>
                              </Grid>
                          }
                      </Grid>

                  </Grid>

              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" color="primary" onClick={handleUpdate}>
                      Update
                  </Button>
              </Grid>
          </Grid>
      </MainCard>
            </>
    );
};
export default ModelPage;
