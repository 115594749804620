
import React, { useRef } from 'react';
import {Button, Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from "@mui/material/styles";
import {FileData} from "../interfaces/interfaces";
import QuickLoaderComponent from "./cards/QuickLoaderComponent";
// import {Input} from "@mui/base";


interface FileUploaderProps {
    apiUrl: string;
    title: string;
    onUpload?: (json: any) => void;
}

const SimpleFileUploader: React.FC<FileUploaderProps> = ({ apiUrl, title, onUpload }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
    const [shortName, setShortName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [type, setType] = React.useState("");
    const [isUploading, setIsUploading] = React.useState(false);

  const handleFileSelect = () => {
    const files = fileInputRef.current?.files;
    if (files) {
      uploadFiles(files);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    uploadFiles(files);
  };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const uploadFiles = (files: FileList) => {
        const formData = new FormData();

        // Create a FileData object
        const fileData: FileData = {
            shortName: shortName,
            description: description,
            category: "", // Add the category state variable when it's defined
            type: type,
            fileText: ""
        };

        const fileDataBlob = new Blob([JSON.stringify(fileData)], { type: 'application/json' });
        formData.append('fileData', fileDataBlob);

        Array.from(files).forEach(file => {
            formData.append('file', file);
        });

        setIsUploading(true);

        fetch(apiUrl, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                setShortName("");
                setDescription("");
                setType("");
                onUpload && onUpload(data);
                setIsUploading(false);
            })
            .catch(error => {
                // Handle error
                console.error('Upload error:', error);
                setIsUploading(false);
            });
    };

    return (
      <div
          className="p-4 border rounded text-center"
          onDrop={handleDrop}
          onDragOver={event => event.preventDefault()}
      >
          <Card sx={{ minWidth: 275 }}>
              <CardContent>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                          {isUploading && <QuickLoaderComponent loadBanner={"Uploading..."} />}
                      </Grid>
                      <Grid item xs={12}>
                          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                              {title}
                          </Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <Grid container={true} spacing={1}>
                              <Grid item xs={12}>
                                  <Card variant="outlined">
                                    <CardContent>
                                      <Typography variant="caption" color="textSecondary">
                                        <span className="drop-text">Drag and drop file(s) here or click to select</span>
                                        <VisuallyHiddenInput
                                          type="file"
                                          id="file-input"
                                          multiple
                                          onChange={handleFileSelect}
                                          className="d-none"
                                          ref={fileInputRef}
                                        />
                                      </Typography>
                                    </CardContent>
                                  </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      <Grid item xs={12}>
                          <Stack spacing={1} alignItems="center" direction={"row"} justifyContent="flex-end">
                        <Button variant="outlined"  size={"small"} onClick={() => fileInputRef.current?.click()} startIcon={<CloudUploadIcon />}>
                          Select Files to Upload
                        </Button>
                          </Stack>
                      </Grid>

                  </Grid>
              </CardContent>
          </Card>
      </div>
  );
};

export default SimpleFileUploader;