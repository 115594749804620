// material-ui
import {
   Grid
} from '@mui/material';

import  {useEffect, useState} from "react";
import {AppUserProfile, Scenario} from "interfaces/interfaces";
import {useParams} from "react-router";
import PortalScenarioConductFullComponent from "../../../components/portal/PortalTabletopConductFullComponent";
interface Params {
    scenarioId: string;
    companyId: string;
    portalId: string;
    resumeConductId?: string;
}
const PortalScenarioConductPage= () => {
    // @ts-ignore
    const { companyId, scenarioId, resumeConductId, portalId } = useParams<Params>();
    const [scenario, setScenario] = useState<Scenario>();
    // const [elapsedTime, setElapsedTime] = useState<number>(0);
    const token = localStorage.getItem('token');
    const [userProfile, setUserProfile] = useState<AppUserProfile | null | undefined>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
               // setIsScenarioLoading(true);
                console.log(scenarioId);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/company/getscenario/'+token+ '/' +companyId+'/' + portalId+'/'+scenarioId);
                const data = await response.json();
                setScenario(data);
                //setIsScenarioLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        const fetchData2 = async () => {
            const response2 = await fetch(process.env.REACT_APP_REST_API_URL + '/api/auth/profile/view/' + token);
            const data2 = await response2.json();
            setUserProfile(data2);
        };
        fetchData2();

    }, []);

    return (
        <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
                {scenario && portalId && <PortalScenarioConductFullComponent userProfile={userProfile} portalId={portalId} scenario={scenario} resumeConductId={resumeConductId}/> }
            </Grid>
        </Grid>
    );
};
export default PortalScenarioConductPage;
