import React from 'react';
import { Paper, Grid, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface RiskMatrixProps {
    highlightedProbability: string;
    highlightedImpact: string;
}

// Define the risk levels
interface RiskCell {
    value: number;
    level: string;
    probability: string;
    impact: string;
}

// Custom styles for the risk matrix cells and labels
const useStyles = makeStyles({
    paper: {
        padding: 16,
        textAlign: 'center',
        height: '100%',
    },
    highlighted: {
        borderColor: '#000000', // Black border for highlighted cell
        borderWidth: 2,
        borderStyle: 'solid',
    },
    lowRisk: {
        backgroundColor: '#4CAF50', // Green
    },
    moderateRisk: {
        backgroundColor: '#FFEB3B', // Yellow
    },
    majorRisk: {
        backgroundColor: '#FFC107', // Amber
    },
    severeRisk: {
        backgroundColor: '#F44336', // Red
    },
    axisLabel: {
        fontWeight: 'bold',
        padding: 8,
    },
    verticalAxisLabel: {
        writingMode: 'vertical-rl',
        textOrientation: 'mixed',
    },
});

const probabilities = ['Probable', 'Possible', 'Unlikely', 'Rare']; // Reversed order for y-axis
const impacts = ['Low', 'Medium', 'High', 'Very High'];

const matrixData: RiskCell[][] = [
    // Rows are ordered from 'Rare' to 'Probable'
    // Columns are ordered from 'Low' to 'Very High'
    [
        { value: 4, level: 'Moderate', probability: 'Probable', impact: 'Low' },
        { value: 8, level: 'Major', probability: 'Probable', impact: 'Medium' },
        { value: 12, level: 'Severe', probability: 'Probable', impact: 'High' },
        { value: 16, level: 'Severe', probability: 'Probable', impact: 'Very High' },
    ], [
        { value: 3, level: 'Minor', probability: 'Possible', impact: 'Low' },
        { value: 6, level: 'Moderate', probability: 'Possible', impact: 'Medium' },
        { value: 9, level: 'Major', probability: 'Possible', impact: 'High' },
        { value: 12, level: 'Severe', probability: 'Possible', impact: 'Very High' },
    ], [
        { value: 2, level: 'Minor', probability: 'Unlikely', impact: 'Low' },
        { value: 4, level: 'Moderate', probability: 'Unlikely', impact: 'Medium' },
        { value: 6, level: 'Moderate', probability: 'Unlikely', impact: 'High' },
        { value: 8, level: 'Major', probability: 'Unlikely', impact: 'Very High' },
    ], [
        { value: 1, level: 'Minor', probability: 'Rare', impact: 'Low' },
        { value: 2, level: 'Minor', probability: 'Rare', impact: 'Medium' },
        { value: 3, level: 'Minor', probability: 'Rare', impact: 'High' },
        { value: 4, level: 'Moderate', probability: 'Rare', impact: 'Very High' },
    ]
];

const RiskMatrix: React.FC<RiskMatrixProps> = ({ highlightedProbability, highlightedImpact }) => {
    const classes = useStyles();

    // Function to get the class name based on risk level description
    const getRiskClassName = (level: string) => {
        switch (level) {
            case 'Minor':
                return classes.lowRisk;
            case 'Moderate':
                return classes.moderateRisk;
            case 'Major':
                return classes.majorRisk;
            case 'Severe':
                return classes.severeRisk;
            default:
                return '';
        }
    };

    // Function to determine if a cell should be highlighted
    const isHighlighted = (cell: RiskCell) =>
        cell.probability === highlightedProbability && cell.impact === highlightedImpact;

    return (
        <Box sx={{ flexGrow: 1, maxWidth: 800, margin: 'auto' }}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Box sx={{ display: 'flex', height: '100%' }}>
                        <Box m="auto" className={classes.verticalAxisLabel}>
                            <Typography variant="h6" className={classes.axisLabel}>
                                PROBABILITY
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={10}>
                    <Grid container direction="column" spacing={2}>
                        {matrixData.map((row, rowIndex) => (
                            <Grid container item spacing={2} key={`row-${rowIndex}`}>
                                <Grid item xs={2}>
                                    <Typography variant="h6" align="center" className={classes.axisLabel}>
                                        {probabilities[rowIndex]}
                                    </Typography>
                                </Grid>
                                {row.map((cell, cellIndex) => (
                                    <Grid item xs={2} key={`cell-${rowIndex}-${cellIndex}`}>
                                        <Paper
                                            elevation={3}
                                            className={`${classes.paper} ${
                                                isHighlighted(cell) ? classes.highlighted : getRiskClassName(cell.level)
                                            }`}
                                        >
                                            <Typography variant="h6">{cell.value}</Typography>
                                            <Typography>{cell.level}</Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item spacing={2} justifyContent="center">
                <Grid item xs={2}></Grid>
                {impacts.map((impact, index) => (
                    <Grid item xs={2} key={impact}>
                        <Typography variant="h6" align="center" className={classes.axisLabel}>
                            {impact}
                        </Typography>
                    </Grid>
                ))}

            </Grid>
            <Grid container item spacing={2} justifyContent="center">
            <Grid item xs={2}>
                <Typography variant="h6" align="center" className={classes.axisLabel}>
                    IMPACT
                </Typography>
            </Grid>
            </Grid>
        </Box>
    );
};

export default RiskMatrix;
