import { RouterProvider } from 'react-router-dom';

// project import
import  {createRoutesForRole} from 'routes';
import ThemeCustomization from 'themes';

import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

// auth-provider
import  { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import {useOrganization} from "./hooks/useOrganization";

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

const App = () => {
    const { organization, setOrganization } = useOrganization();

  return (
      <ThemeCustomization organization={organization} setOrganization={setOrganization}>
        {/* <RTLLayout> */}
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <RouterProvider router={createRoutesForRole()}/>
                  <Snackbar/>
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
        {/* </RTLLayout> */}
      </ThemeCustomization>
  );
};

export default App;
