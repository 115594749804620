import React, { useState, useEffect } from 'react';
import { Runbook} from "../../interfaces/interfaces";
import { MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface RiskListProps {
    runbook: Runbook;
}
const RunbookStatusSelect: React.FC<RiskListProps> = ({   runbook }) => {
    const [statusTypes, setStatusTypes] = useState([]);
    const token = localStorage.getItem("token");
    const [updatedRunbook, setUpdatedRunbook] = useState(runbook);
    const [currentType, setCurrentRunbookStatusType] = useState(runbook.status);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/utils/runbook/getrunbookstatus/'+token);
                const data = await response.json();
                setStatusTypes(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: SelectChangeEvent) => {
        updatedRunbook.status = e.target.value;
        setUpdatedRunbook(updatedRunbook);
        setCurrentRunbookStatusType(e.target.value);
    };

  return (
      <Select
          name="statusType"
          placeholder="Runbook Status"
          fullWidth
          sx={{ width: '100%'}}
          value={currentType}
          onChange={handleInputChange}
      >
          <MenuItem value="">
              <em>Select Runbook Status</em>
          </MenuItem>
          {statusTypes.map((riskNVP,item) => (
              <MenuItem key={riskNVP} value={riskNVP}>{riskNVP}</MenuItem>
          ))}
      </Select>
  );
};

export default RunbookStatusSelect;