import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    TextField,
    IconButton,
    Grid, FormControlLabel, Checkbox, Stack, Typography, Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ScenarioRole} from "../../interfaces/interfaces";
import {AddOutlined} from "@mui/icons-material";
import SelectRoleType from "../utils/SelectRoleType";
import MainCard from "../MainCard";
import DialogContentText from "@mui/material/DialogContentText";

interface Props {
    initialRoles: ScenarioRole[] | null;
    editable: boolean;
    onUpdate?: (newRoles: ScenarioRole[]) => void;
}

const ScenarioRoleList: React.FC<Props> = ({ initialRoles, onUpdate, editable }) => {
    const [roles, setRoles] = useState<ScenarioRole[]>(initialRoles || []);
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [notes, setNotes] = useState('');
    const [title, setTitle] = useState('');
    const [isRequired, setIsRequired] = useState(false);
    const [expertiseLevel, setExpertiseLevel] = useState('');
    const [purpose, setPurpose] = useState('');
    const [type, setType] = useState('');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setRoles([...roles, { name, description, notes, title, isRequired, expertiseLevel, purpose, type }]);
        setName('');
        setDescription('');
        setNotes('');
        setTitle('');
        setIsRequired(false);
        setExpertiseLevel('');
        setPurpose('');
        setType('');
        handleClose();
        if (onUpdate) {
            onUpdate([...roles, {name, description, notes, title, isRequired, expertiseLevel, purpose, type}]);
        }
    };

    // State for the delete dialog
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [roleToDelete, setRoleToDelete] = useState<number | null>(null);

    const handleDeleteClick = (index: number) => {
        // Set the role to delete and open the dialog
        setRoleToDelete(index);
        setDeleteDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        if (roleToDelete !== null) {
            handleDelete(roleToDelete);
        }
        // Close the dialog
        setDeleteDialogOpen(false);
    };

    const handleCancelDelete = () => {
        // Close the dialog
        setDeleteDialogOpen(false);
    };

    const handleDelete = (index: number) => {
        const newRoles = [...roles];
        newRoles.splice(index, 1);
        setRoles(newRoles);
        if (onUpdate) {
            onUpdate(newRoles);
        }
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <List>
                        {roles && roles.length > 0 ? (
                            roles.map((role, index) => (
                                <ListItem key={index}>
                                    <Grid container={true} spacing={2} >
                                        <Grid item={true} xs={5}>
                                            <Stack spacing={0}>
                                                <Typography variant="subtitle1">
                                                    {role.name}&nbsp;&nbsp;
                                                </Typography>
                                                {role.title && <Typography variant="caption" color="textSecondary">{role.title}</Typography>}
                                                {role.description && <Typography variant="caption" color="textSecondary">{role.description}</Typography>}
                                            </Stack>
                                        </Grid>
                                        <Grid item={true} xs={4}>
                                            {role.isRequired ? <Chip color={"primary"} size={"small"} label={"Required"}/> : <Chip color={"secondary"} size={"small"} label={"Optional"}/>}
                                        </Grid>
                                        <Grid item={true} xs={3}>
                                            {editable &&
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteClick(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        ) : (
                            <Typography variant="subtitle1">No Roles Defined</Typography>
                        )}
                    </List>
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={handleCancelDelete}
                        fullWidth={true}
                    >
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete this role?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"contained"} onClick={handleConfirmDelete} color="primary">Delete</Button>
                            <Button variant={"contained"} onClick={handleCancelDelete}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
                {editable && (
                    <Grid item xs={4}>
                        <Button variant="outlined" color="primary" size="small" onClick={handleOpen}>
                            <AddOutlined/> Role
                        </Button>
                    </Grid>
                )}
            </Grid>

<Dialog open={open} fullWidth={true} onClose={handleClose}>
    <DialogTitle>Add Scenario Role</DialogTitle>
    <DialogContent>
        <form onSubmit={handleSubmit}>
        <MainCard title={"Required: Role Name"}>
            <SelectRoleType onChange={setName} />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isRequired}
                        onChange={(e) => setIsRequired(e.target.checked)}
                        name="isRequired"
                        color="primary"
                    />
                }
                label="Is Required"
            />
        </MainCard>
            <MainCard title={"Optional: Role Details"}>
            <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />

            <TextField
                margin="dense"
                label="Notes (e.g. special instructions)"
                type="text"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
            />
            <TextField
                margin="dense"
                label="Title (e.g. CEO, CFO, etc.)"
                type="text"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
                margin="dense"
                label="Expertise Level"
                type="text"
                fullWidth
                value={expertiseLevel}
                onChange={(e) => setExpertiseLevel(e.target.value)}
            />
            <TextField
                margin="dense"
                label="Purpose"
                type="text"
                fullWidth
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
            />
            <TextField
                margin="dense"
                label="Type"
                type="text"
                fullWidth
                value={type}
                onChange={(e) => setType(e.target.value)}
            />
            </MainCard>
            <DialogActions>
                <Button variant={"contained"} type="submit" color="primary">
                    Add
                </Button>
                <Button variant={"contained"}  onClick={handleClose} color="primary">
                    Cancel
                </Button>

            </DialogActions>
        </form>
    </DialogContent>
</Dialog>
        </div>
    );
};

export default ScenarioRoleList;