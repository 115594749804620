// material-ui
import {
    Box,
    Button,
    Grid, Link, Stack, Typography
} from '@mui/material';

import {useEffect, useState} from "react";
import {Risk, Runbook, Scenario} from "interfaces/interfaces";
import {useParams} from "react-router";
import RiskViewComponent from "../../components/risk/RiskViewComponent";
import MainCard from "../../components/MainCard";
import ScenarioObjectiveTabletopComponent from "../../components/tabletop/ScenarioObjectiveTabletopComponent";
import HoverSocialCard from "../../components/cards/statistics/HoverSocialCard";
import {useTheme} from "@mui/material/styles";
import ScenarioListComponent from "../../components/scenario/ScenarioListSelect";
import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";
import {Link as RouterLink} from "react-router-dom";
import RunbookListComponent from "../../components/runbooks/RunbookListSelect";

interface Params {
    companyId: string;
    riskId: string;

}
const RiskViewPage= () => {
    // @ts-ignore
    const { companyId, riskId } = useParams<Params>();
    const [scenarios, setScenarios] = useState<Scenario[]>();
    const [runbooks, setRunbooks] = useState<Runbook[]>();
    const [risk, setRisk] = useState<Risk>();
    const token = localStorage.getItem('token');
    const theme = useTheme();
    const [linkedScenarioId, setLinkedScenarioId] = useState<string>("");
    const [linkedRunbookId, setLinkedRunbookId] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [label, setLabel] = useState<string>("Loading...");

    const handleRiskLink = async () => {
        setLoading(true);
        setLabel("Linking...");
        try {
            await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/company/linkrisk/' + token + '/' + companyId + '/' + linkedScenarioId + '/' + risk?.riskId);
            setLinkedScenarioId("");
        } catch (error) {
            console.error("Error updating risk:", error);
        }
        setLoading(false);
    };
    function handleScenarioSelect(scenarioId:string) {
        setLinkedScenarioId(scenarioId);
    }

    function handleRunbookSelect(runbookId:string) {
        setLinkedRunbookId(runbookId);
    }

    const handleRiskRunbookLink = async () => {
        setLoading(true);
        setLabel("Linking...");
        try {
            await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/company/linkriskrunbook/' + token + '/' + companyId + '/' + linkedRunbookId + '/' + risk?.riskId);
            setLinkedRunbookId("");
        } catch (error) {
            console.error("Error updating risk:", error);
        }
        setLoading(false);
    };

    // const handleGenScenario = async () => {
    //     try {
    //         // console.log(id);
    //         const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/company/risk/generatescenario/' + token + '/' + companyId + '/' + riskId);
    //         const data = await response.json();
    //         setScenario(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

        useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setLabel("Loading...");
            try {
                // console.log(id);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/company/risk/viewrisk/'+token+'/' + companyId+'/'+riskId);
                const data = await response.json();
                setRisk(data);
            } catch (error) {
                console.error(error);
            }

                try {
                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/company/risk/getscenarios/' + token + '/' + companyId + '/' + riskId);
                    const data = await response.json();
                    setScenarios(data);
                } catch (error) {
                    console.error(error);
                }

            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/company/risk/getrunbooks/' + token + '/' + companyId + '/' + riskId);
                const data = await response.json();
                setRunbooks(data);
            } catch (error) {
                console.error(error);
            }
            setLoading(false);

        };
        fetchData();
    }, []);

    const handleSave = async (updatedRisk: Risk) => {
        setLoading(true);
        setLabel("Updating...");
        // @ts-ignore
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/risk/updaterisk/${token}/${risk.companyId}/${risk.riskId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedRisk),
            });
            const data = await response.json();
            setRisk(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setLoading(false);
    };

    const handleButtonClick = () => {
        if (risk) {
            handleSave(risk);
        }
    };

    const handleUpdate = (updatedRisk: Risk) => {
        setRisk(updatedRisk);
    };

    return (
        <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid container={true} spacing={2} >
                <Grid item xs={12} >
                    <Grid container={true} spacing={2} >
                        <Grid item xs={4} >
                            <HoverSocialCard primary={"Details, Profile and Analysis"} secondary={"Risk"} color={theme.palette.primary.light} />
                        </Grid>
                        <Grid item xs={4}>
                            <HoverSocialCard primary={risk?.description} secondary={risk?.name} color={theme.palette.primary.main} />
                        </Grid>
                    </Grid>
                </Grid>
                <MainCard title="Risk Profile">
                <Grid item xs={12} >
                        {isLoading &&
                            <QuickLoaderComponent loadBanner={label} />
                        }
                        <Box sx={{ p: 2.5, pb: 0 }}>
                            <MainCard content={false} sx={{ p: 1.25, bgcolor: 'primary.lighter', borderColor: theme.palette.primary[100] }}>
                                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                    <Button variant="outlined" size={"small"} onClick={handleButtonClick}>Update</Button>

                                    <Link color="primary" component={RouterLink} to={`/company/viewcompany/${companyId}`}>
                                        <Button
                                            variant="outlined"
                                            size={"small"}
                                        >
                                            Back to Company
                                        </Button>
                                    </Link>
                                </Stack>
                            </MainCard>
                        </Box>
                    </Grid>

                {risk &&
                    <Grid item xs={12} >
                        <RiskViewComponent risk={risk}  onUpdate={handleUpdate}/>
                    </Grid>
                    }
                </MainCard>

                <Grid item xs={12}>
                <Grid item xs={12}>
                        <MainCard title="Scenarios" >
                                <Grid container={true} spacing={3}>
                                    {scenarios && scenarios.map((scenario,item)=> (
                                        <>
                                        <Grid item xs={10}>
                                        <Typography variant="h4">
                                            {scenario.name}
                                        </Typography>
                                        </Grid>
                                            <Grid item xs={2}>
                                                <Link  component={RouterLink} to={`/company/scenario/view/${companyId}/${scenario.scenarioId}`}>
                                                    <Button variant={"outlined"}>
                                                        View
                                                    </Button>
                                                </Link>
                                            </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {scenario.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ScenarioObjectiveTabletopComponent scenario={scenario}/>
                                    </Grid>
                                        </>
                                ))}
                                </Grid>

                            {risk && risk.companyId &&
                                <Grid container spacing={3} >
                                    <Grid item xs={8}>
                                        <ScenarioListComponent companyId={risk?.companyId} label={"Link Scenario"} onScenarioSelected={handleScenarioSelect}/>
                                    </Grid>
                                    <Grid item xs={4} sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}>
                                        <Button variant="contained"  color="primary" onClick={handleRiskLink}>
                                            Link
                                        </Button>
                                    </Grid>
                                </Grid>
                             }
                    </MainCard>
                </Grid>
                    <Grid item xs={12}>
                        <MainCard title="Runbooks" >
                            <Grid container={true} spacing={3}>
                                {runbooks && runbooks.map((runbook:Runbook)=> (
                                    <>
                                        <Grid item xs={10}>
                                            <Typography variant="h4">
                                                {runbook.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Link  component={RouterLink} to={`/company/viewrunbook/${companyId}/${runbook.runbookId}`}>
                                                <Button variant={"outlined"}>
                                                    View
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">
                                                {runbook.description}
                                            </Typography>
                                        </Grid>
                                    </>
                                ))}
                            </Grid>

                            {risk && risk.companyId &&
                                <Grid container spacing={3} >
                                    <Grid item xs={8}>
                                        <RunbookListComponent companyId={risk?.companyId} label={"Link RUNBOOK"} onRunbookSelected={handleRunbookSelect}/>
                                    </Grid>
                                    <Grid item xs={4} sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                    }}>
                                        <Button variant="contained"  color="primary" onClick={handleRiskRunbookLink}>
                                            Link
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                        </MainCard>
                    </Grid>
            </Grid>
            </Grid>
        </Grid>
    );
};
export default RiskViewPage;
