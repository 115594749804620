import React from "react";


interface ScenarioImageProps {
    location:string;
}
const OrganizationBrandingImageDisplay: React.FC<ScenarioImageProps> = ({ location }) => {


    return (
        <>
            {location && location.length > 0 &&
                    <>
                     <img src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${location}`}
                          alt="image" style={{width: '100px', height: '100px'}}/>
                    </>
            }
        </>
    );
};

export default OrganizationBrandingImageDisplay;