// third-party
import { FormattedMessage } from 'react-intl';

// assets
import Groups2Icon from '@mui/icons-material/Groups2';
// type
import { NavItemType } from 'types/menu';

// icons


// ==============================|| MENU ITEMS - PAGES ||============================== //

const portalConduct: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="portal" />,
  type: 'group',
  children: [
    {
      id: 'portal-conduct',
      title: <FormattedMessage id="portal-conduct" />,
      type: 'item',
      url: `/portal/myportals`,
      icon: Groups2Icon,
    }
  ]
};

export default portalConduct;
