import React from 'react';
import {Grid, IconButton, Typography} from '@mui/material';
import { Scenario } from "interfaces/interfaces";
import MainCard from "../MainCard";
import ReactMarkdown from "react-markdown";
import {SettingsOutlined} from "@mui/icons-material";

interface ScenarioDashboardDetailsProps {
    scenario: Scenario | undefined;
    openSetupDialog?: () => void;
}

const ScenarioDashboardDetails: React.FC<ScenarioDashboardDetailsProps> = ({ scenario, openSetupDialog }) => {
    return (
        <MainCard title="Details" secondary={
            openSetupDialog ? (
                <IconButton onClick={openSetupDialog} size={"small"}>
                    <SettingsOutlined fontSize={"small"}/>
                </IconButton>
            ) : false
        }>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <Typography variant={"h3"}>{scenario?.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"h4"}>Description</Typography>
                    <Typography variant={"body1"}>
                        {scenario && scenario.description && scenario.description.split('\n').map((line, index) => (
                           <>
                            {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                            </>
                        ))}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {scenario && scenario.objective &&
                    <>
                        <Typography variant={"h4"}>Objectives</Typography>
                        <Typography  variant={"body1"}>
                            {scenario && scenario.objective && scenario.objective.split('\n').map((line, index) => (
                                <>
                                    {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                                </>
                            ))}
                        </Typography>
                    </>
                    }
                </Grid>
                <Grid item xs={12}>
                    {scenario && scenario.focusArea &&
                    <>
                        <Typography variant={"h4"}>Focus Area</Typography>
                        <Typography  variant={"body1"}>
                            {scenario && scenario.focusArea && scenario.focusArea.split('\n').map((line, index) => (
                              <>
                                  {line && line.length>0 && <ReactMarkdown key={index} children={`${line}`} /> }
                              </>
                            ))}
                        </Typography>
                    </>
                    }
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default ScenarioDashboardDetails;