
import { useEffect, useState} from "react";
import {
    Organization, Scenario
} from "interfaces/interfaces";

import OrganizationScenarioProfile from "../components/organization/OrganizationScenarioProfile";
import {Grid} from "@mui/material";

const OrganizationScenarioLibraryPage = () => {
    // @ts-ignore
    const token = localStorage.getItem('token');
    const [organization, setOrganization] = useState<Organization>();
    const [scenarios, setScenarios] = useState<Scenario[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/organization/getorganization/' + token);
                const data = await response.json();
                setOrganization(data);
            } catch (error) {
                console.error(error);
            }

        };
        fetchData();
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            if (organization) {
                try {
                    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/organization/getscenarios/' + token + '/' + organization.id);
                    const data = await response.json();
                    setScenarios(data);
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchData();
    }, [organization]);


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {organization &&
                    <OrganizationScenarioProfile organization={organization} scenarios={scenarios} setScenarios={setScenarios} />
                }
            </Grid>
        </Grid>
    );
};
export default OrganizationScenarioLibraryPage;
