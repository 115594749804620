import {ReactNode} from "react";
import {Box} from "@mui/material";

export interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
    indexLabel?: string |`simple-tab`;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, indexLabel, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`${indexLabel}-${index}`} aria-labelledby={`${indexLabel}-${index}`} {...other}>
            {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
        </div>
    );
    }
export function a11yProps_label(index: number, indexLabel: string | `simple-tab`) {
    return {
        id: `${indexLabel}-${index}`,
        'aria-controls': `${indexLabel}-${index}`
    };
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tab-${index}`
    };
}
export function a11yProps_string(label: string) {
    return {
        id: `simple-tab-${label}`,
        'aria-controls': `simple-tabpanel-${label}`
    };
}