import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH,PORTAL_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';
import {useContext} from "react";
import JWTContext from "../../contexts/JWTContext";

// ==============================|| MAIN LOGO ||============================== //
//START HERE FOR NAVIGATION AND USER PERMISSIONS

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { isLoggedIn } = useAuth();
  const jwtContext = useContext(JWTContext);
  const user = jwtContext ? jwtContext.user : null;
//    console.log("In logo: "+user?.role);
  const defaultPath = user?.role != 'Designer' ? PORTAL_DEFAULT_PATH : APP_DEFAULT_PATH;


  return (
    <ButtonBase disableRipple {...(isLoggedIn && { component: Link, to: !to ? defaultPath : to, sx })}>
      {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
    </ButtonBase>
  );
};

export default LogoSection;
