// material-ui
import {Button, Grid, Stack, Typography} from '@mui/material';

// project imports
import MainCard from 'components/MainCard';
import { GenericCardProps } from 'types/root';
import { useTheme} from "@mui/material/styles";


// ==============================|| REPORT CARD ||============================== //

interface ReportCardProps extends GenericCardProps {
  onStatusChange?: () => void;
}

const StatusCard = ({ primary, secondary, iconPrimary, color, onStatusChange }: ReportCardProps) => {
  const IconPrimary = iconPrimary!;
  const primaryIcon = iconPrimary ? <IconPrimary fontSize="large" /> : null;

  const theme = useTheme();
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Approved':
        return theme.palette.success.main;
      case 'Pending Approval':
        return theme.palette.warning.main;
      case 'Under Review':
        return theme.palette.info.main;
      case 'Archived':
        return theme.palette.grey.A200;
      default:
        return theme.palette.primary.main;
    }
  }
  let colorIcon = theme.palette.primary.main;
  if (typeof primary === 'string') {
    colorIcon = getStatusColor(primary);
  }
  return (
    <MainCard>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Stack spacing={1}>
            <Button style={{ color: colorIcon }} onClick={onStatusChange}>
              <Typography variant="h4">{primary}</Typography>
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Typography variant="h4" style={{ color: colorIcon }}>
            {primaryIcon}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <div style={{ height: '10px', backgroundColor: colorIcon }}></div>
      </Grid>
    </MainCard>
  );
};

export default StatusCard;
