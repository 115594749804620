// material-ui
import {Grid, Typography, Stack, Box, Chip, ButtonGroup} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import MainCard from 'components/MainCard';

//asset
import WelcomeImage from 'assets/images/analytics/welcome-banner.png';
import WelcomeImageArrow from 'assets/images/analytics/welcome-arrow.png';

// types
import { ThemeDirection } from 'types/config';
import React from "react";
import ScenarioBackToButton from "../ScenarioBackToButton";
import {AAR} from "../../../interfaces/interfaces";
import SimDifficultyChipComponent from "../../tabletop/SimDifficultyChipComponent";
import SimulationViewButton from "../SimulationViewButton";

// ==============================|| ANALYTICS - WELCOME ||============================== //

interface WelcomeScenarioSimulateProps {
  aar: AAR | undefined;
}
const WelcomeAARViewBanner : React.FC<WelcomeScenarioSimulateProps> = ({  aar }) => {
  const theme = useTheme();


  // @ts-ignore
  return (
    <MainCard
      border={false}
      sx={{
        background:
          theme.direction === ThemeDirection.RTL
            ? `linear-gradient(60.38deg, ${theme.palette.primary.lighter} 114%, ${theme.palette.primary.light} 34.42%, ${theme.palette.primary.main} 60.95%, ${theme.palette.primary.dark} 84.83%, ${theme.palette.primary.darker} 104.37%)`
            : `linear-gradient(250.38deg, ${theme.palette.primary.lighter} 2.39%, ${theme.palette.primary.light} 34.42%, ${theme.palette.primary.main} 60.95%, ${theme.palette.primary.dark} 84.83%, ${theme.palette.primary.darker} 104.37%)`
      }}
    >
      <Grid container>
        <Grid item md={6} sm={6} xs={12}>
          <Stack spacing={2} sx={{ padding: 3.4 }}>
            <Typography variant="h2" color={theme.palette.background.paper}>
              {aar?.reportTitle}
            </Typography>
            <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack direction="row" spacing={1}>
                <SimDifficultyChipComponent variant="combined" difficulty={aar?.difficulty ?? 0} size="medium" label={"Difficulty: "}/>
                <Chip label={"Effectiveness: 70%"} variant="combined" color="success" size="medium" />
              </Stack>
            </Grid>
            </Grid>
            <Box>
              <ButtonGroup>
                { aar && <SimulationViewButton companyId={aar?.companyId} scenarioId={aar?.scenarioId} simulationId={aar.simulationId} /> }
                { aar && <ScenarioBackToButton companyId={aar.companyId} scenarioId={aar.scenarioId} /> }
              </ButtonGroup>
            </Box>

          </Stack>
        </Grid>
        <Grid item sm={6} xs={12} sx={{ display: { xs: 'none', sm: 'initial' } }}>
          <Stack sx={{ position: 'relative', pr: { sm: 3, md: 8 } }} justifyContent="center" alignItems="flex-end">
            <img src={WelcomeImage} alt="Welcome" />
            <Box sx={{ position: 'absolute', bottom: 0, right: '10%' }}>
              <img src={WelcomeImageArrow} alt="Welcome Arrow" />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default WelcomeAARViewBanner;
