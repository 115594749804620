import React, {useEffect, useState} from 'react';
import {AAR, Conduct} from "./interfaces/interfaces";
import {Button, ButtonGroup, Card, CardContent, Divider, Grid, Stack, TextField} from "@mui/material";
import QuickLoaderComponent from "./components/cards/QuickLoaderComponent";
import ReactMarkdown from "react-markdown";
import MainCard from "./components/MainCard";

interface RecommendationAnalysisComponentProps {
    conduct: Conduct; // replace with the actual type
    portalId: string;
    companyId: string;
    scenarioId: string;
    aar: AAR;
    editable: boolean;
    onUpdate: () => void;
    persistAnalysis?: (analysis: string) => void;
    persistAnalysisValue?: string;
    isPortal: boolean;
}

const RecommendationAnalysisComponent: React.FC<RecommendationAnalysisComponentProps> = ({ conduct, portalId, scenarioId, aar, isPortal,
                                                                                             companyId, editable, onUpdate, persistAnalysis, persistAnalysisValue,  }) => {
    const [frameworkBanner, setFrameworkBanner] = useState<string>("Loading Framework Analysis...");
    const [isFrameworkAnalysisLoading, setFrameworkAnalysisLoading] = useState<boolean>(false);
    const [updatedAAR, setAAR] = useState<AAR>(aar);
    const [frameworkAnalysis, setFrameworkAnalysis] = useState<string[]>(persistAnalysisValue ? [persistAnalysisValue] : []);

    useEffect(() => {
        if (updatedAAR && updatedAAR.recommendations) {
            setFrameworkAnalysis(updatedAAR.recommendations);
        }
    }, [updatedAAR]);

    useEffect(() => {
        if (persistAnalysisValue) {
            setFrameworkAnalysis([persistAnalysisValue]);
        }
    }, [persistAnalysisValue]);

    const handleSpec = async (specName: string, analysisPrompt: string) => {
        setFrameworkBanner(`Looking for recommendations..`);
        setFrameworkAnalysisLoading(true);
        let token = localStorage.getItem("token");
        try {
            const response = await fetch(`${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/recommendationanalysis/${token}/${companyId}/${portalId}/${scenarioId}/${conduct.conductId}/${analysisPrompt}`);
            const data = await response.text(); // get response as text
            setFrameworkAnalysis(prevState => [
                ...prevState,
                `${data}`
            ]);
            setAAR(prevState => ({
                ...prevState,
                recommendations: prevState.recommendations
                    ? [...prevState.recommendations, data]
                    : [data]
            }));
            if (persistAnalysis) {
                persistAnalysis(data);
            }
        } catch (error) {
            console.error("Error adding spec:", error);
        }
        setFrameworkAnalysisLoading(false);
    };

    const handleUpdate = async () => {
        setFrameworkBanner("Updating After-Action Review with Recommendations...");
        setFrameworkAnalysisLoading(true);
        let token = localStorage.getItem("token");

        const updateURL = isPortal
            ? `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/updateaar/${token}/${companyId}/${portalId}/${scenarioId}/${conduct.conductId}`
            : `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/scenario/tabletop/aar/updateaar/${token}/${companyId}/${scenarioId}/${conduct.conductId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedAAR),
            });
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setFrameworkAnalysisLoading(false);
        setFrameworkBanner("After-Action Review Updated!");
        onUpdate();
    }

    const [isEditMode, setIsEditMode] = useState<boolean>(editable);

    return (
        <MainCard title="Action Analysis">
        <Card>
            <CardContent>
                <Grid container={true} spacing={2}>
                    {editable &&
                        <>
                            {isFrameworkAnalysisLoading &&
                                <Grid item xs={12}>
                                    <QuickLoaderComponent loadBanner={frameworkBanner}/>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleSpec("recommend", `Analyze the tabletop exercise and provide detailed recommendations. Be specific with your recommendations, why they are appropriate and level of effort to implement. your response should be in markdown format with the name of the spec in the title.`)}
                                        >
                                            Recommend
                                        </Button>
                            </Grid>
                            <Grid item xs={9}>
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                                                <ButtonGroup size={"small"}>
                                                    <Button
                                                        variant="outlined"
                                                        disabled={!isEditMode}
                                                        onClick={() => setIsEditMode(false)}
                                                    >
                                                        Preview
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        disabled={isEditMode}
                                                        onClick={() => setIsEditMode(true)}
                                                    >
                                                        Edit
                                                    </Button>
                                                </ButtonGroup>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider/>
                                        </Grid>
                                        {frameworkAnalysis.map((analysis, index) => (
                                            <>
                                                <Grid item xs={12}>
                                                    {isEditMode ? (
                                                        <TextField
                                                            key={index}
                                                            multiline
                                                            fullWidth
                                                            value={analysis}
                                                            onChange={(event) => {
                                                                const newAnalysis = [...frameworkAnalysis];
                                                                newAnalysis[index] = event.target.value;
                                                                setFrameworkAnalysis(newAnalysis);
                                                            }}
                                                        />
                                                    ) : (
                                                        <ReactMarkdown key={index} children={analysis} />
                                                    )}                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </>
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={1}>
                                {frameworkAnalysis && frameworkAnalysis.length > 0 &&
                                    <Button
                                    onClick={handleUpdate}
                                    variant="contained"
                                    color="primary"
                                    disabled={aar?.isPublished}
                                >
                                    Update
                                </Button>
                                }
                            </Grid>
                        </>
                    }
                </Grid>
            </CardContent>
        </Card>
        </MainCard>
    );
};

export default RecommendationAnalysisComponent;