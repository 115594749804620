import { useState, useEffect } from 'react';
import { Organization } from "interfaces/interfaces";

// Replace this with your actual data fetching function
async function fetchOrganizationData(token: string|null): Promise<Organization> {

    const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/organization/getorganization/' + token);
    const organization: Organization = await response.json();
    return organization;
}

export function useOrganization() {
    const [organization, setOrganization] = useState<Organization>();

    useEffect(() => {
        const token = localStorage.getItem('token');
        fetchOrganizationData(token).then(setOrganization);
    }, []);

    return { organization, setOrganization };
}