import { Risk} from "../../interfaces/interfaces";
import {
    Button, Divider,
    Grid, TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import RiskTypeSelect from "./RiskTypeSelect";
import RiskImpactSelect from "./RiskImpactSelect";
import RiskMatrix from "./RiskMatrix";
import RiskProbabilitySelect from "./RiskProbabilitySelect";

interface RiskViewProps {
    risk: Risk;
    onUpdate?: (risk: Risk) => void;
}
const RiskViewComponent: React.FC<RiskViewProps> = ({ risk, onUpdate }) => {
    const [updatedRisk, setUpdatedRisk] = useState(risk);
    const token = localStorage.getItem("token");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdatedRisk((prevRisk) => ({
            ...prevRisk,
            [name]: value,
        }));
    };

    useEffect(() => {
        setUpdatedRisk(risk);
    }, [risk]);

    useEffect(() => {

        if (onUpdate) {
            onUpdate(updatedRisk);
        }
    }, [updatedRisk, onUpdate]);

    // const handleUpdate = () => {
    //     const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/tabletop/company/risk/updaterisk/${token}/${risk.companyId}/${risk.riskId}`;
    //     try {
    //         fetch(updateURL, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(updatedRisk),
    //         });
    //         console.log("Updated risk:", updatedRisk);
    //     } catch (error) {
    //         console.error("Error updating scenario:", error);
    //     }
    // };

    // const handleGenerateRiskDescription = async () => {
    //     try {
    //         const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/risk/assistant/generate/' + token + '/' + risk.riskId+'/generate risk description');
    //         //console.log(response);
    //         const data = await response.json();
    //         //console.log(data);
    //         setUpdatedRisk((prevRisk) => ({
    //             ...prevRisk,
    //             description: data
    //         }));      // console.log(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    //
    // };
    // const handleGenerateRiskMitigations = async () => {
    //     try {
    //         const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/risk/assistant/generatemitigationlist/' + token + '/' + risk.riskId+'/generate risk mitigations');
    //         //console.log(response);
    //         const data = await response.json();
    //         //console.log(data);
    //         setUpdatedRisk((prevScenario) => ({
    //             ...prevScenario,
    //             mitigations: data
    //         }));      // console.log(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    //
    // };

    async function handleWordsmith(attributeName: keyof Risk) {
        // setLoading(true);
        const attributeValue = updatedRisk[attributeName];
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/assistant/wordsmith/' +
                token + '/' + risk.companyId + '/' + attributeValue);
            const data = await response.json();
            setUpdatedRisk(prevCompany => ({
                ...prevCompany,
                [attributeName]: data
            }));
        } catch (error) {
            console.error(error);
        }
        // setLoading(false);
    }

    return (
        <Grid id={`id-tab-risk-riskviewid${risk.riskId}`} key={`key-tab-risk-riskviewid${risk.riskId}`} item xs={12}  >
            <Grid container spacing={3} >
                <Grid item xs={4} >
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            {/*<MainCard title="Risk Info" secondary={*/}
                            {/*    <Button variant="outlined" size="small" color="primary" onClick={handleGenerateRiskDescription}>*/}
                            {/*        Generate*/}
                            {/*    </Button>*/}
                            {/*}*/}
                            {/*>*/}
                                <Grid container spacing={3} >
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            placeholder="Name"
                                            variant={"outlined"}
                                            label={"Name"}
                                            fullWidth
                                            sx={{ width: '100%'}}
                                            value={updatedRisk.name}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                        name="description"
                                        placeholder="Description"
                                        variant={"outlined"}
                                        label={"Description"}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={updatedRisk.description}
                                        onChange={handleInputChange}
                                        />
                                        <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('description')}>
                                            Wordsmith
                                        </Button>
                                    </Grid>
                                </Grid>
                            {/*</MainCard>*/}
                        </Grid>
                        <Grid item xs={12} >
                                <Grid container rowSpacing={3} >
                                    <Grid item xs={12} >
                                        <RiskTypeSelect risk={updatedRisk}/>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <RiskProbabilitySelect risk={updatedRisk} />
                                    </Grid>
                                    <Grid item xs={12} >
                                    <RiskImpactSelect risk={updatedRisk}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="notes"
                                            placeholder="Notes"
                                            variant={"outlined"}
                                            label={"Notes"}
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={updatedRisk.notes}
                                            onChange={handleInputChange}
                                        />
                                        <Button variant="outlined" color="primary" size={"small"} onClick={() => handleWordsmith('notes')}>
                                            Wordsmith
                                        </Button>
                                    </Grid>
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation={"vertical"}/>
                <Grid item xs={7}>
                    <RiskMatrix highlightedProbability={updatedRisk.probability} highlightedImpact={updatedRisk.impact} />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default RiskViewComponent;
