import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import ReactMarkdown from "react-markdown";

interface Props {
    companyId?: string;
    openScenarioDialog: boolean;
    handleCloseScenarioDialog: () => void;
    handleSaveAnalysis: () => void;
}


const ScenarioAnalysisDialog: React.FC<Props> = ({ openScenarioDialog, handleCloseScenarioDialog, handleSaveAnalysis, companyId}) => {
    const [analysisNotesValue, setAnalysisNotesValue] = useState("");
    const [scenarioAnalysisLoading, setScenarioAnalysisLoading] = useState<boolean>(false);

    async function getScenarioAnalysis(companyId: string | undefined) {
        const token = localStorage.getItem('token');
        setAnalysisNotesValue("")
        setScenarioAnalysisLoading(true);
        const response: Response =  await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/company/suggest/' + token + '/' + companyId);
        const data = await response.text();
        if (data) {
            setAnalysisNotesValue(data);
        }
        setScenarioAnalysisLoading(false);
    }

    useEffect(() => {
        if (openScenarioDialog) {
            getScenarioAnalysis(companyId);
        }
    }, [openScenarioDialog]);


    return (
        <Dialog open={openScenarioDialog} fullWidth={true} onClose={handleCloseScenarioDialog}>
            <DialogTitle>Scenario Analysis</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {scenarioAnalysisLoading &&
                        <Grid item xs={12}>
                            <QuickLoaderComponent loadBanner={"Performing scenario analysis..."} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <ReactMarkdown>{analysisNotesValue}</ReactMarkdown>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={<Checkbox defaultChecked/>}*/}
                    {/*        label="Generate Backstory"*/}
                    {/*    />*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={<Checkbox defaultChecked/>}*/}
                    {/*        label="Generate Script"*/}
                    {/*    />*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={<Checkbox defaultChecked/>}*/}
                    {/*        label="Generate Injects"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleCloseScenarioDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScenarioAnalysisDialog;