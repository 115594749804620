import React, {useEffect} from 'react';
import { InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface ExerciseFrequencySelectListProps {
    frequency: string;
    handleSelectInputChange: (event: SelectChangeEvent<string>) => void;
}

const ExerciseFrequencySelectList: React.FC<ExerciseFrequencySelectListProps> = ({ frequency, handleSelectInputChange }) => {
    const [frequencyList, setFrequencyList] = React.useState<string[]>([]);

    useEffect(() => {
        // setLatestMessage("");
        const fetchData = async () => {
            try {
                let token = localStorage.getItem('token');
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/utils/list/frequency/' + token);
                const data = await response.json();
                setFrequencyList(data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }
    , []);
  return (
    <>
      <InputLabel id="risk-select-list">Frequency of Exercise</InputLabel>
      <Select
        name="frequency"
        placeholder="Frequency of Exercise"
        fullWidth
        value={frequency}
        onChange={handleSelectInputChange}
      >
        <MenuItem value="">
          <em>Select Frequency</em>
        </MenuItem>
        {frequencyList && frequencyList.map((lmh, item) => (
          <MenuItem key={lmh} value={lmh}>{lmh}</MenuItem>
        ))}
      </Select>
    </>
  );
};

export default ExerciseFrequencySelectList;