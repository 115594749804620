// Define your new fetch function
import { LOGOUT} from "../contexts/auth-reducer/actions";
import {logout} from "./logout";

export async function apiFetch(input: RequestInfo, init?: RequestInit, onUnauthorized?: () => void): Promise<Response> {
  const response = await fetch(input, init);
  //console.error("apifetch:"+response.status);

  if (response.status === 401 ) {
    if (onUnauthorized) {
      onUnauthorized();
    } else {
      window.location.href = "/login"; // Replace "/login" with your actual login page route
    }
  }

  return response;
}

export function handleUnauthorizedNow(dispatch: any) {
  logout(dispatch);
}

export function handleUnauthorized() {

  return async (dispatch: any) => {
    dispatch({
      type: LOGOUT,
      payload: {
        isLoggedIn: false,
        user: null,
        error: "Session expired.  Please log in again."
      }
    });
    throw new Error("Session expired.  Please log in again.");
  }

}