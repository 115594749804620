// material-ui
import {Box, Card, CardContent, Chip, Grid, Stack, Typography} from '@mui/material';

// types
import { GenericCardProps } from 'types/root';

// ===========================|| HOVER SOCIAL CARD ||=========================== //

interface HoverSocialCardProps extends GenericCardProps {
    chipText?: string;
    chipLocation?: 'top' | 'bottom';
}

const HoverSocialCard = ({ primary, secondary, iconPrimary, color, chipText, chipLocation }: HoverSocialCardProps) => {
  const IconPrimary = iconPrimary!;
  const primaryIcon = iconPrimary ? <IconPrimary /> : null;

  return (
        <Card sx={{ bgcolor: color ? color : 'default' }} elevation={1}>
        <CardContent>
        <Box>
          {primaryIcon}
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" color="inherit">
                  {secondary}
                </Typography>
                  {chipText && chipLocation && chipLocation === 'top' &&
                      <Chip color={"primary"} label={chipText} size={"small"} />
                  }
              </Stack>
          </Grid>
          <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2" color="inherit">
                {primary && typeof primary === 'string' && primary.split('\n').map((line: string, i: number) => (
                  <Typography key={i} variant="subtitle2" color="inherit">
                    {line}
                  </Typography>
                ))}
            </Typography>
              {chipText && chipLocation && chipLocation === 'bottom' &&
                  <Chip color={"primary"} label={chipText} size={"small"} />
              }
              </Stack>
          </Grid>
            <Grid item xs={12}>

          </Grid>

            </Grid>
      </CardContent>
    </Card>
  );
};

export default HoverSocialCard;
