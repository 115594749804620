import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid, Box, Tooltip
} from '@mui/material';
import {GameMessage} from "../../interfaces/interfaces";
import InstructionalChatComponent from "../organization/InstructionalChatComponent";
import IconButton from "../@extended/IconButton";
import { QuestionCircleOutlined} from "@ant-design/icons";
import {ThemeMode} from "../../types/config";
import {useTheme} from "@mui/material/styles";

interface InstructionalChatDialogProps {
    onNewMessage?: (message: GameMessage) => void;
}

const InstructionalChatDialog: React.FC<InstructionalChatDialogProps> = ({  onNewMessage }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

    const handleClose = () => {
        setOpen(false);
    }
    return (
        <>
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <Tooltip title={"Open the help assistant"}>
                    <IconButton
                        color="secondary"
                        variant="light"
                        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : 'transparent' }}
                        aria-label="fullscreen toggler"
                        onClick={() => setOpen(true)}
                    >
                        <QuestionCircleOutlined/>
                    </IconButton>
                </Tooltip>
                <Dialog fullWidth={true} open={open} onClose={handleClose}>
                    <DialogTitle>Help with Opsbook</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Chat with this assistant for basic help / support.
                        </DialogContentText>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InstructionalChatComponent onNewMessage={onNewMessage} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>

    );
};

export default InstructionalChatDialog;