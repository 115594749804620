import React from 'react';
import {
    Button,
    Card, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {AppUserProfile, Conduct, Participant, UserLinkKey} from "../../interfaces/interfaces";
import SelectRoleType from "../utils/SelectRoleType";
import ConductParticipantList from "../tabletop/ConductParticipantList";

interface ScenarioParticipantAddDialogProps {
    open: boolean;
    userProfile:AppUserProfile|null|undefined
    onClose: () => void;
    userLinks?: UserLinkKey[];
    participants?: Participant[] | null;
    onRemove: (participantId: string) => void;
    onAdd: (name: string, email: string, role: string) => void;
    onNotify?: (participantId: string) => void;
    onAttended?: (participantId: string) => void;
    conduct?: Conduct;
}

const ScenarioParticipantAddDialog: React.FC<ScenarioParticipantAddDialogProps> = ({
    open,
    onClose,
    userLinks,
    participants,
    onAdd,
    onNotify,
    onRemove,
    conduct,
    userProfile,
    onAttended
}) => {
    const [participantName, setParticipantName] = React.useState('');
    const [participantEmail, setParticipantEmail] = React.useState('');
    const [participantRole, setParticipantRole] = React.useState('');

    function handleDelete(participant: Participant) {
        if (!participant) return;
        // if (!participant.participantId) {
        //     let randomString = Math.random().toString(36).substring(2);
        //     participant.participantId = randomString;
        // }
        if (participant.participantId) {
            onRemove(participant.participantId);
        }
    }

    return (
        <Dialog open={open} fullWidth={true} onClose={onClose}>
            <DialogTitle>Manage Participant</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}><Typography variant={"h6"} >Current Participants</Typography></Grid>
                    <Grid item xs={12}>
                        {participants && participants.length>0 && userProfile ? <ConductParticipantList userProfile={userProfile} participants={participants} onTeamDelete={handleDelete} />:<Chip size={"small"}  label="No Participants" color="warning" />}
                    </Grid>
                    <Grid item xs={12}><Divider/></Grid>
                    <Grid item xs={12}><Typography variant={"h6"} >Add Participant</Typography></Grid>
                    {userLinks && userLinks.length>0 &&
                        <>
                        <Grid item xs={12}>
                            <Typography variant="caption">Option 1: Add a current Portal user to the tabletop</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select fullWidth={true} onChange={(event) => {
                                const selectedParticipant = userLinks.find(participant => participant.userId === event.target.value);
                                if (selectedParticipant) {
                                    setParticipantName(selectedParticipant.username);
                                    setParticipantEmail(selectedParticipant.userEmail);
                                }
                            }}>
                                {userLinks && userLinks.map((participant) => (
                                    <MenuItem key={participant.userId} value={participant.userId}>
                                        {participant.username} ({participant.userEmail})
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Typography variant="caption">
                            {userLinks && userLinks.length>0?
                                "Option 2:  Enter a non-user participant":"Enter a participant for this tabletop"
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            required={true}
                            margin="dense"
                            id="name"
                            label="Name"
                            fullWidth
                            onChange={(e) => setParticipantName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email"
                            fullWidth
                            onChange={(e) => setParticipantEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectRoleType
                            onChange={(e) => setParticipantRole(e)}
                        />
                    </Grid>
                    {conduct && conduct.roles && conduct.roles.length>0 &&
                        <>
                            <Grid item xs={12}><Divider/></Grid>
                            <Grid item xs={12}>
                                <><Typography variant={"h6"}>Prescribed Roles for this Scenario</Typography>
                                    {conduct.roles.map((role) => (
                                        <Chip key={role.title} label={role.name} variant="outlined" color="primary" />
                                    ))}
                                </>
                            </Grid>
                        </>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <Card>
                            <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                <Button variant="contained" onClick={() => onAdd(participantName, participantEmail, participantRole)}>Add</Button>
                                <Button variant="contained" onClick={onClose}>Close</Button>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ScenarioParticipantAddDialog;