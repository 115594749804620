// material-ui
import { CSSObject } from '@mui/material/styles';
import { Box } from '@mui/material';

// project import
import { ColorProps } from 'types/extended';

interface Props {
  color?: ColorProps;
  size?: number;
  variant?: string;
  sx?: CSSObject;
}

const Dot = ({ color, size, variant, sx }: Props) => {

  return (
    <Box
      component="span"
    />
  );
};

export default Dot;
