import React, { useState, useEffect } from 'react';
import { Risk} from "../../interfaces/interfaces";
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface RiskViewProps {
    risk: Risk;
}
const RiskTypeSelect: React.FC<RiskViewProps> = ({ risk }) => {
    const [updatedRisk, setUpdatedRisk] = useState(risk);
    const [currentRiskType, setCurrentRiskType] = useState(risk.type);
    const [riskTypes, setRiskTypes] = useState([]);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                // console.log(id);
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/utils/risk/getrisktypes/'+token);
                const data = await response.json();
                setRiskTypes(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: SelectChangeEvent) => {
        updatedRisk.type = e.target.value;
        setUpdatedRisk(updatedRisk);
        setCurrentRiskType(e.target.value);
    };

  return (
    <Select
      name="riskType"
      placeholder="Risk Type"
      fullWidth
      sx={{ width: '100%'}}
      value={currentRiskType}
      onChange={handleInputChange}
    >
      <MenuItem value="">
        <em>Select Risk Type</em>
      </MenuItem>
      {riskTypes.map((riskNVP,item) => (
        <MenuItem key={riskNVP} value={riskNVP}>{riskNVP}</MenuItem>
      ))}
    </Select>
  );
};

export default RiskTypeSelect;