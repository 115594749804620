import { ReactNode, useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider, Theme, TypographyVariantsOptions } from '@mui/material/styles';

// project import
import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';

// types
import { CustomShadowProps } from 'types/theme';
import {Organization} from "../interfaces/interfaces";

// types
type ThemeCustomizationProps = {
    children: ReactNode;
    organization: Organization | undefined;
    setOrganization: React.Dispatch<React.SetStateAction<Organization | undefined>>;
};

// ==============================|| DEFAULT THEME - MAIN ||============================== //

export default function ThemeCustomization({ children, organization }: ThemeCustomizationProps) {
  const { themeDirection, mode, presetColor, fontFamily } = useConfig();

    const theme: Theme = useMemo<Theme>(() => {
        const baseTheme = Palette(mode, presetColor);
        if (organization && organization.brandHexColor && organization.brandHexColor.length > 0) {
            baseTheme.palette.primary.main = organization.brandHexColor;
            baseTheme.palette.warning.main = organization.brandHexColor;
            baseTheme.palette.primary.light = organization.brandHexColor;
            baseTheme.palette.primary.dark = organization.brandHexColor;
            baseTheme.palette.warning.light = organization.brandHexColor;
            baseTheme.palette.warning.dark = organization.brandHexColor;
            baseTheme.palette.secondary.main = organization.brandHexColor;
            baseTheme.palette.secondary.light = organization.brandHexColor;
            baseTheme.palette.secondary.dark = organization.brandHexColor;
        }
        return baseTheme;
    }, [mode, presetColor, organization]);
  const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
    () => Typography(mode, fontFamily, theme),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode, fontFamily]
  );
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(() => CustomShadows(theme), [theme]);

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
