// project import

// types
import { NavItemType } from 'types/menu';
import portalConduct from "./portal-item-page";

// ==============================|| MENU ITEMS ||============================== //

const portalItems: { items: NavItemType[] } = {
  items: [ portalConduct]
};

export default portalItems;
