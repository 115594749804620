// material-ui
import {Box, Button, Grid, Link, Stack} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import {useEffect, useState} from "react";
import {  Runbook} from "interfaces/interfaces";
import {useParams} from "react-router";
import CompanyRunbookComponent from "../../../components/company/CompanyRunbookComponent";
import HoverSocialCard from "../../../components/cards/statistics/HoverSocialCard";
import {useTheme} from "@mui/material/styles";
import QuickLoaderComponent from "../../../components/cards/QuickLoaderComponent";
import {Link as RouterLink} from "react-router-dom";
import StatusCard from "../../../components/statistics/StatusCard";
import {CheckBoxOutlined} from "@mui/icons-material";
// ==============================|| SAMPLE PAGE ||============================== //

interface Params {
    runbookId: string;
    companyId: string;
    portalId: string;
}
const PortalRunbookPage= () => {
    // @ts-ignore
    const { runbookId, companyId, portalId } = useParams<Params>();
    const [runbook, setRunbook] = useState<Runbook>();
    const token = localStorage.getItem('token');
    const theme = useTheme();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [label, setLabel] = useState<string>("Loading Runbook...");


        useEffect(() => {
        const fetchData = async () => {
            setLabel("Loading Runbook...");
            setLoading(true);
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/portal/company/viewrunbook/'+token+'/' + companyId+'/'+portalId+'/'+runbookId);
                const data = await response.json();
                setRunbook(data);
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
            setLabel("");
        };
        fetchData();
    }, []);
    return (
        <Grid container rowSpacing={2} columnSpacing={3}>
                {/*<WelcomeRunbookViewBanner name={runbook?.name} companyId={runbook?.companyId}/>*/}
                <Grid item xs={12} >
                    <Grid container={true} spacing={2} >
                        <Grid item xs={2} >
                            <HoverSocialCard primary={"Details"} secondary={"Portal: Runbook"} color={theme.palette.warning.light} />
                        </Grid>
                        <Grid item xs={7}>
                            <HoverSocialCard primary={runbook?.description} secondary={runbook?.name} color={theme.palette.warning.main} />
                        </Grid>
                        <Grid item xs={3} >
                            <StatusCard primary={runbook?.status}  iconPrimary={CheckBoxOutlined} />
                        </Grid>
                    </Grid>
                </Grid>
            <Grid item xs={12}>
                <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
                <Grid item xs={12}>
                    {isLoading &&
                        <QuickLoaderComponent loadBanner={label} />
                    }
                    <Box sx={{ p: 2.5, pb: 0 }}>
                        <MainCard content={false} sx={{ p: 1.25, bgcolor: 'warning.lighter', borderColor: theme.palette.primary[100] }}>
                            <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                <Link color="primary" component={RouterLink} to={`/portal/viewportal/${companyId}/${portalId}`}>
                                    <Button
                                        variant="outlined"
                                        size={"small"}
                                    >
                                        Back to Portal
                                    </Button>
                                </Link>
                            </Stack>
                        </MainCard>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                {runbook &&
                    <CompanyRunbookComponent runbook={runbook} />
                }
                </Grid>
                </MainCard>
            </Grid>
        </Grid>
    );
};
export default PortalRunbookPage;
