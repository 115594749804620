import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';

const getBackgroundColor = (colorType: 'primary' | 'paper', theme: Theme) => {
  return colorType === 'primary' ? theme.palette.background.paper : theme.palette.background.paper;
};

const ChatBubble = styled(Card)<{ colorType: 'primary' | 'paper' }>(({ theme, colorType }) => ({
  backgroundColor: getBackgroundColor(colorType, theme),
  borderRadius: '20px',
  padding: theme.spacing(1),
  maxWidth: '75%',
  minWidth: '75%',
  margin: theme.spacing(1, 0),
  boxShadow: theme.shadows[3],
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '10%',
    width: 0,
    height: 0,
    border: '10px solid transparent',
    borderTopColor: theme.palette.background.paper,
    borderBottom: 0,
    borderLeft: 0,
    marginLeft: '-5px',
    marginBottom: '-10px',
  },
}));

export default ChatBubble;