import React, { useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Grid, MenuItem, Select, SelectChangeEvent,
    Stack, TextField, Typography
} from "@mui/material";
import { Organization, Scenario} from "../../interfaces/interfaces";
import MainCard from "../MainCard";
import {useTheme} from "@mui/material/styles";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import {LibraryBooksOutlined, TipsAndUpdatesOutlined} from "@mui/icons-material";
import TipsDialog from "../dialog/TipsDialog";
import HoverSocialCard from "../cards/statistics/HoverSocialCard";
import TabOrganizationScenarios from "./TabOrganizationScenarios";
import ScenarioRibbonButtonGroup from "../scenario/ScenarioRibbonButtonGroup";
import ScenarioAnalysisDialog from "../scenario/ScenarioAnalysisDialog";
import ExerciseFrequencySelectList from "../scenario/ExerciseFrequencySelectList";

interface OrganizationScenarioProfileProps {
    organization: Organization;
    scenarios: Scenario[];
    setScenarios: React.Dispatch<React.SetStateAction<Scenario[]>>;

}
const OrganizationScenarioProfile: React.FC<OrganizationScenarioProfileProps> = ({  organization, scenarios, setScenarios }) => {
    const token = localStorage.getItem('token');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [loaderBanner, setLoaderBanner] = useState<string>("Loading Threat Profile...");
    const [openScenarioDialog, setOpenScenarioDialog] = useState(false);
    const [scenarioNameValue, setScenarioNameValue] = useState("");
    const [scenarioDescriptionValue, setScenarioDescriptionValue] = useState("");
    const [scenarioNotesValue, setScenarioNotesValue] = useState("");
    const [openTips, setOpenTips] = useState(false);
    const [scenarioTypeValue, setScenarioTypeValue] = useState("");
    const [scenarioFrequencyValue, setScenarioFrequencyValue] = useState("");

    const handleFrequencyChange = (e: SelectChangeEvent) => {
        setScenarioFrequencyValue(e.target.value);
    }

    const handleSaveScenario = async () => {
        setOpenScenarioDialog(false);
        setLoaderBanner("Generating your new Scenario...");
        setLoading(true);
        let updatedScenario: Scenario = {} as Scenario;
        updatedScenario.name = scenarioNameValue;
        updatedScenario.description = scenarioDescriptionValue;
        updatedScenario.instructions = scenarioNotesValue;
        updatedScenario.exerciseType = scenarioTypeValue;
        updatedScenario.frequency = scenarioFrequencyValue;
        //console.log(updatedScenario?.name);
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/scenario/organization/addscenario/${token}/${organization.id}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedScenario),
            });
            const newData = await response.json();
            setScenarios(prevScenarios => prevScenarios ? [...prevScenarios, newData] : [newData]);
            setOpenScenarioDialog(false);
            setScenarioDescriptionValue("");
            setScenarioNameValue("");
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        setLoading(false);

    };
    const handleNewScenarioDialog = () => {
        setOpenScenarioDialog(true);
    }
    const handleCloseScenarioDialog = () => {
        setOpenScenarioDialog(false);
    }

    const theme = useTheme();


    function handleTips() {
        setOpenTips(true);
    }

    function closeTips() {
        setOpenTips(false);
    }

    const [openScenarioAnalysisDialog, setOpenScenarioAnalysisDialog] = useState<boolean>(false);

    function handleAnalysisDialog() {
        setOpenScenarioAnalysisDialog(true)
    }

    function handleCloseScenarioAnalysisDialog() {
        setOpenScenarioAnalysisDialog(false)
    }

    function handleSaveAnalysis() {

    }

    return (
        <>
            <ScenarioAnalysisDialog openScenarioDialog={openScenarioAnalysisDialog}
                                    handleCloseScenarioDialog={handleCloseScenarioAnalysisDialog}
                                    handleSaveAnalysis={handleSaveAnalysis}/>

            <Grid container rowSpacing={2} columnSpacing={3}>
                <Grid item xs={12} >
                    {isLoading &&
                        <QuickLoaderComponent loadBanner={loaderBanner} />
                    }
                        <MainCard title={
                            <Stack direction="row" spacing={1} alignItems="center">
                                <LibraryBooksOutlined/>
                                <Typography variant="h4">{organization.name}: Scenario Library</Typography>
                            </Stack>
                        }  content={false} secondary={
                            <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                {organization && organization.isDemo ?
                                    (
                                        <Button
                                            onClick={handleTips}
                                            variant="contained"
                                            color="success"
                                            size="small"
                                            startIcon={<TipsAndUpdatesOutlined />}
                                        >
                                            Tips
                                        </Button>
                                    )
                                    : null  }
                                <ScenarioRibbonButtonGroup handleNewScenarioDialog={handleNewScenarioDialog}
                                                           handleAnalysisDialog={handleAnalysisDialog}
                                                           organization={organization}
                                />
                            </Stack>} >
                            {organization && scenarios  && scenarios.length > 0 ? (
                                <TabOrganizationScenarios scenarios={scenarios} orgId={organization.id}/>
                            ) : (
                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent>
                                        <HoverSocialCard primary={"Scenarios in cybersecurity serve as critical tools for organizations to simulate " +
                                            "potential threats and challenges in a controlled environment. By crafting detailed, realistic scenarios, " +
                                            "companies can test their response mechanisms, identify gaps in their defenses, and train their " +
                                            "teams against specific, targeted risks. These exercises not only enhance preparedness but also " +
                                            "inform the development and refinement of runbooks, ensuring that responses are both efficient " +
                                            "and effective. Ultimately, scenarios are a cornerstone in building a proactive and resilient " +
                                            "cybersecurity posture."} secondary={"About Scenarios"} color={theme.palette.primary.dark} />
                                    </CardContent>
                                </Card>
                            )}
                        </MainCard>
                </Grid>

            </Grid>

            <Dialog open={openScenarioDialog} fullWidth={true} onClose={handleCloseScenarioDialog}>
                <DialogTitle>Create New Exercise</DialogTitle>
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Select
                                        value={scenarioTypeValue? scenarioTypeValue: "Tabletop"}
                                        defaultValue={"Tabletop"}
                                        onChange={(e) => setScenarioTypeValue(e.target.value as string)}
                                        fullWidth={true}
                                    >
                                        <MenuItem value={"Tabletop"}>Tabletop</MenuItem>
                                        <MenuItem value={"Review"}>Review</MenuItem>
                                        <MenuItem value={"Meeting"}>Meeting</MenuItem>
                                        <MenuItem value={"Workshop"}>Workshop</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        name="scenarioNameValue"
                                        placeholder="Scenario name"
                                        variant="outlined"
                                        value={scenarioNameValue}
                                        fullWidth={true}
                                        onChange={(e) => setScenarioNameValue(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="scenarioDescriptionValue"
                                        placeholder="Detailed description"
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline={true}
                                        rows={4}
                                        value={scenarioDescriptionValue}
                                        onChange={(e) => setScenarioDescriptionValue(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="scenarioNotesValue"
                                        placeholder="Prompt instructions for this exercise. Be as specific as possible."
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline={true}
                                        rows={5}
                                        value={scenarioNotesValue}
                                        onChange={(e) => setScenarioNotesValue(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ExerciseFrequencySelectList
                                        frequency={scenarioFrequencyValue}
                                        handleSelectInputChange={handleFrequencyChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleSaveScenario} color="primary">Save</Button>
                    <Button variant={"contained"} onClick={handleCloseScenarioDialog}>Cancel</Button>
                </DialogActions>
            </Dialog>


            {/*<Dialog open={openScenarioDialog} fullWidth={true} onClose={handleCloseScenarioDialog}>*/}
            {/*    <DialogTitle>Create New Scenario</DialogTitle>*/}
            {/*    <DialogContent>*/}
            {/*        <Grid container spacing={4}>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    name="scenarioNameValue"*/}
            {/*                    placeholder="Name"*/}
            {/*                    variant="standard"*/}
            {/*                    value={scenarioNameValue}*/}
            {/*                    fullWidth={true}*/}
            {/*                    onChange={(e) => setScenarioNameValue(e.target.value)}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    name="scenarioDescriptionValue"*/}
            {/*                    placeholder="Detailed description"*/}
            {/*                    variant="standard"*/}
            {/*                    fullWidth={true}*/}
            {/*                    multiline={true}*/}
            {/*                    rows={2}*/}
            {/*                    value={scenarioDescriptionValue}*/}
            {/*                    onChange={(e) => setScenarioDescriptionValue(e.target.value)}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <TextField*/}
            {/*                    name="scenarioNotesValue"*/}
            {/*                    placeholder="Special instructions or notes for this scenario"*/}
            {/*                    variant="standard"*/}
            {/*                    fullWidth={true}*/}
            {/*                    multiline={true}*/}
            {/*                    rows={2}*/}
            {/*                    value={scenarioNotesValue}*/}
            {/*                    onChange={(e) => setScenarioNotesValue(e.target.value)}*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <FormControlLabel*/}
            {/*                    control={<Checkbox defaultChecked/>}*/}
            {/*                    label="Generate Backstory"*/}
            {/*                />*/}
            {/*                <FormControlLabel*/}
            {/*                    control={<Checkbox defaultChecked/>}*/}
            {/*                    label="Generate Script"*/}
            {/*                />*/}
            {/*                <FormControlLabel*/}
            {/*                    control={<Checkbox defaultChecked/>}*/}
            {/*                    label="Generate Injects"*/}
            {/*                />*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        <Button variant={"contained"} onClick={handleSaveScenario} color="primary">Save</Button>*/}
            {/*        <Button variant={"contained"} onClick={handleCloseScenarioDialog}>Cancel</Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
            <TipsDialog tipsText={["Scenarios are to pre-defined combinations of potential risks and threats, outlining their specific circumstances and potential impact on the system. Scenarios help organizations prepare and plan for various security incidents by simulating these situations and devising appropriate response strategies.. Runbooks are commonly used in IT operations, helping to streamline workflows and ensure consistency in response to various scenarios.", "You can auto-generate Scenarios based on your Threat Profile (scenarios will be generated based on risks & threats in the profile) and then add new Scenarios by clicking the '+ Scenario' button.", "Click 'View' to drill into the details of a Scenario and run a simulated or live Tabletop."]} open={openTips} handleClose={closeTips}/>

        </>
    );
};
export default OrganizationScenarioProfile;