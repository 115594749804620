import {Button, Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {ThemeMode} from "../../types/config";
import {FC} from "react";
import {useTheme} from "@mui/material/styles";



const ScenarioViewButton: FC<{ companyId: string, label?: string,scenarioId: string, simulationId:string }> = ({ companyId, label, scenarioId, simulationId }) => {
    const labelText = label ? label : "View Simulation ";
    const theme = useTheme();

    return (
        <Link  component={RouterLink} to={`/company/scenario/simulation/view/${companyId}/${scenarioId}/${simulationId}`}>
            <Button
                variant="outlined"
                color="secondary"
                sx={{
                    color: theme.palette.background.paper,
                    borderColor: theme.palette.background.paper,
                    '&:hover': {
                        color: 'background.paper',
                        borderColor: theme.palette.background.paper,
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'primary.darker' : 'primary.main'
                    }
                }}
            >
                {labelText}
            </Button>
        </Link>
    );
};

export default ScenarioViewButton;

