
// material-ui
import { Theme } from '@mui/material/styles';
import {  useMediaQuery } from '@mui/material';

// project import

import FullScreen from './FullScreen';
import Customization from './Customization';
import MobileSection from './MobileSection';

// types

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // const { i18n, menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //  const localization = useMemo(() => <Localization />, [i18n]);
  //
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
      <>
        {/*{menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}*/}
        {/*  {!downLG && <DrawerHeader open={true} />}*/}
        {/*{!downLG && megaMenu}*/}
        {/*{!downLG && localization}*/}

        {/*<Notification />*/}
        {/*<Message />*/}
        {!downLG && <FullScreen />}
        <Customization />
        {downLG && <MobileSection />}
      </>
  );
};

export default HeaderContent;
