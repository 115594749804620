import React, { useState, useEffect } from 'react';
import {Portal} from "../../interfaces/interfaces";
import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface PortalListProps {
    companyId: string;
    label?: string;
    selectLabel: string;
    selectValue?: string | null;
    onPortalSelect: (risk: string) => void;
}
const PortalListSelect: React.FC<PortalListProps> = ({  companyId, label, onPortalSelect, selectLabel, selectValue }) => {
    const [selectedPortal, setSelectedPortal] = useState(selectValue);
    const [portals, setPortals] = useState<Portal[] | null>(null);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/company/getportals/' + token + '/' + companyId);
                const data = await response.json();
                setPortals(data);
            } catch (error) {
                console.error("Error getting portals:", error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: SelectChangeEvent) => {
        setSelectedPortal( e.target.value);
        onPortalSelect(e.target.value);
    };

  return (
      <>
          {label &&
            <InputLabel id="portal-select-list">{label}</InputLabel>
          }
          {portals &&
          <Select
              name="portal-select-list"
              id={"portal-select-list"}
              variant={"outlined"}
              fullWidth
              value={selectedPortal?selectedPortal:''}
              onChange={handleInputChange}
          >
              <MenuItem value="">
                  <em>{selectLabel}</em>
              </MenuItem>
              {portals && portals.length>0 && portals.map((portal,item) => (
                  <MenuItem  key={portal.portalId} value={portal.portalId}>{portal.name}</MenuItem>
              ))}
          </Select>
          }
      </>

  );
};

export default PortalListSelect;