import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import PortalLayout from "../layout/Portal";
import PortalList from "../pages/extra-pages/portal/portal-list-page";

import { useParams } from 'react-router-dom';
import PortalAfterActionReportPage from "../pages/extra-pages/portal/portal-aar-conduct-view-page";
import PortalRunbookPage from "../pages/extra-pages/portal/portal-runbook-page";
import PortalScenarioViewPage from "../pages/extra-pages/portal/portal-scenario-view-page";
import PortalScenarioConductPage from "../pages/extra-pages/portal/portal-scenario-conduct-page";

interface Params {
    companyId: string;
    portalId: string;
}

const PortalLayoutWrapper: React.FC = () => {
    // @ts-ignore
    const { companyId, portalId } = useParams<Params>();
    return <PortalLayout companyId={companyId} portalId={portalId} />;
};
// import SimpleLayout from 'layout/Simple';

// types
// import { SimpleLayoutType } from 'types/config';


const PortalViewPage = Loadable(lazy(() => import('pages/extra-pages/portal/portal-view-page')));
// ==============================|| MAIN ROUTING ||============================== //


const PortalRoutes = {
    path: '/',
    children: [
        {
            path: '/portal',
            element: <PortalLayoutWrapper />,
            children: [
                {
                    path: 'myportals/',
                    element: <PortalList />
                },
                {
                    path: 'viewportal/:companyId/:portalId',
                    element: <PortalViewPage />
                },{
                    path: 'aar/:portalId/:companyId/:scenarioId/:conductId',
                    element: <PortalAfterActionReportPage />
                },{
                    path: 'viewrunbook/:portalId/:companyId/:runbookId',
                    element: <PortalRunbookPage />
                }, {
                    path: 'scenario/view/:portalId/:companyId/:scenarioId',
                    element: <PortalScenarioViewPage />
                }  ,
                {
                    path: 'conduct/:portalId/:companyId/:scenarioId',
                    element: <PortalScenarioConductPage />
                },
                {
                    path: 'conduct/:portalId/:companyId/:scenarioId/:resumeConductId',
                    element: <PortalScenarioConductPage />
                }
            ]
        }
    ]
};

export default PortalRoutes;
