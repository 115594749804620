import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import DashboardAnalytics from "../pages/dashboard/newRegistrationDashboard";
import KnowledgeBase from "../pages/extra-pages/orgainization-knowledge-base";
import OrganizationPage from "../pages/extra-pages/organization-page";
import ScenarioSimulatePage from "../pages/extra-pages/scenario-simulate-page";
import RiskViewPage from "../pages/extra-pages/risk-view-page";
import SimulateViewPage from "../pages/extra-pages/simulate-view-page";
import AARSimulateViewPage from "../pages/extra-pages/aar-simulation-view-page";
import AARConductViewPage from "../pages/extra-pages/aar-conduct-view-page";
import DesignerConductViewPage from "../pages/extra-pages/designer/designer-conduct-view-page";
import OrganizationScenarioLibraryPage from "../pages/organization-scenario-library";
import DesignerOrganizationScenarioViewPage from "../pages/extra-pages/designer/designer-org-scenario-view-page";


const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const CompanyPage = Loadable(lazy(() => import('pages/extra-pages/company-page2')));
const CompanyRunbookPage = Loadable(lazy(() => import('pages/extra-pages/designer/designer-company-runbook-page')));

const ScenarioConductPage = Loadable(lazy(() => import('pages/extra-pages/designer/designer-scenario-prep-conduct-page')));
const ScenarioExercisePage = Loadable(lazy(() => import('pages/extra-pages/scenario-exercise-page')));
const ScenarioViewPage = Loadable(lazy(() => import('pages/extra-pages/designer/designer-scenario-view-page')));
const Portals = Loadable(lazy(() => import('pages/extra-pages/portal/portal-list-page')));
// ==============================|| MAIN ROUTING ||============================== //


const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'sample-page',
          element: <DashboardAnalytics />
        },
        {
          path: 'knowledgebase',
          element: <KnowledgeBase />
        },
        {
          path: 'organization',
          element: <OrganizationPage />
        },
        {
          path: 'scenariolibrary',
          element: <OrganizationScenarioLibraryPage />
        },
        {
          path: 'analytics',
          element: <DashboardAnalytics />
        }, {
          path: 'companies',
          element: <DashboardAnalytics />
        },{
          path: 'company/viewcompany/:id',
          element: <CompanyPage />
        },{
          path: 'company/viewrunbook/:companyId/:runbookId',
          element: <CompanyRunbookPage />
        }, {
          path: 'organization/scenario/view/:orgId/:scenarioId',
          element: <DesignerOrganizationScenarioViewPage />
        },{
          path: 'company/scenario/view/:companyId/:scenarioId',
          element: <ScenarioViewPage />
        }, {
          path: 'company/risk/view/:companyId/:riskId',
          element: <RiskViewPage />
        }, {
          path: 'company/scenario/simulation/view/:companyId/:scenarioId/:simulationId',
          element: <SimulateViewPage />
        },{
          path: 'company/scenario/tabletop/view/:companyId/:scenarioId/:conductId',
          element: <DesignerConductViewPage />
        },{
          path: 'company/scenario/simulation/aar/:companyId/:scenarioId/:simulationId',
          element: <AARSimulateViewPage />
        },{
          path: 'company/scenario/tabletop/aar/:companyId/:scenarioId/:conductId',
          element: <AARConductViewPage />
        },{
          path: 'portals',
          element: <Portals />
        }
      ]
    },
    {
      path: '/scenario',
      element: <PagesLayout />,
      children: [
        {
          path: 'conduct/:companyId/:scenarioId',
          element: <ScenarioConductPage />
        },
        {
          path: 'conduct/:companyId/:scenarioId/:resumeConductId',
          element: <ScenarioConductPage />
        },
        {
          path: 'exercise/:id',
          element: <ScenarioExercisePage />
        },
        {
          path: 'simulate/:companyId/:scenarioId',
          element: <ScenarioSimulatePage />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <PagesLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
