// material-ui
import {Button, Divider, Grid, TextField, Typography} from '@mui/material';
import MainCard from 'components/MainCard';
import React, {useEffect, useState} from "react";
import {OrganizationServices} from "../../interfaces/interfaces";

const ServicesPage = () => {
    const token = localStorage.getItem('token');
    const[updatedServices, setUpdatedServices] = useState<OrganizationServices | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/organization/services/getservices/'+token);
                const data = await response.json();
                setUpdatedServices(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // @ts-ignore
        setUpdatedServices((prevModel) => ({
            ...prevModel,
            [name]: value,
        }));
    };

    const handleUpdate = async () => {

        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/organization/services/updateservices/${token}/${updatedServices?.orgId}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedServices),
            });
            const data = await response.json();
            setUpdatedServices(data);
        } catch (error) {
            console.error("Error updating model:", error);
        }
    };

    return (
        <>
        <MainCard>
          <Grid container spacing={2}>
              <Grid item xs={12} >
                  <Grid container  spacing={4}>
                      <Grid item xs={12}>
                          {updatedServices &&
                              <Grid container rowSpacing={3} >
                                  <Grid item xs={12} >
                                      <Typography variant="body2">
                                          About Your Company</Typography>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="orgDescription"
                                          label="Organization Description"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          rows={8}
                                          value={updatedServices.orgDescription}
                                          onChange={handleInputChange}
                                      />
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="peopleSummary"
                                          label="People Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          rows={3}
                                          value={updatedServices.peopleSummary}
                                          onChange={handleInputChange}
                                      />

                                  </Grid>
                                  <Grid item xs={12} >
                                      <Divider/>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <Typography variant="body2">
                                          About Your Services</Typography>
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="servicesSummary"
                                          label="Services Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          rows={5}
                                          value={updatedServices.servicesSummary}
                                          onChange={handleInputChange}
                                      />
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="skillsSummary"
                                          label="Skills Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          rows={5}
                                          value={updatedServices.skillsSummary}
                                          onChange={handleInputChange}
                                      />
                                  </Grid>
                                  <Grid item xs={12} >
                                      <TextField
                                          name="competencySummary"
                                          label="Competency Summary"
                                          variant={"standard"}
                                          fullWidth
                                          multiline
                                          rows={5}
                                          value={updatedServices.competencySummary}
                                          onChange={handleInputChange}
                                      />
                                  </Grid>
                              </Grid>
                          }
                      </Grid>

                  </Grid>

              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="contained" color="primary" onClick={handleUpdate}>
                      Update
                  </Button>
              </Grid>
          </Grid>
      </MainCard>
            </>
    );
};
export default ServicesPage;
