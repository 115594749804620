import { ReactNode, useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
// import IconButton from 'components/@extended/IconButton';

import {  useGetMenuMaster } from 'api/menu';

// assets
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

// types
import Logo from "../../../components/logo";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;


  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);

  // const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  // common header
  const mainHeader: ReactNode = (
    <Toolbar>
      <Logo isIcon={true} sx={{ width: false ? 'auto' : 35, height: 35 }} />

      {/*{!isHorizontal ? (*/}
      {/*  <IconButton*/}
      {/*    aria-label="open drawer"*/}
      {/*    onClick={() => handlerDrawerOpen(!drawerOpen)}*/}
      {/*    edge="start"*/}
      {/*    color="secondary"*/}
      {/*    variant="light"*/}
      {/*    sx={{ color: 'text.primary', bgcolor: drawerOpen ? 'transparent' : iconBackColor, ml: { xs: 0, lg: -2 } }}*/}
      {/*  >*/}
      {/*    {!drawerOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}*/}
      {/*  </IconButton>*/}
      {/*) : null}*/}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      zIndex: 1200,
      width: '100%'
      // width: isHorizontal
      //   ? '100%'
      //   : { xs: '100%', lg: drawerOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${MINI_DRAWER_WIDTH}px)` }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={drawerOpen} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
