// material-ui
// import { useTheme } from '@mui/material/styles';
// import { ThemeMode } from 'types/config';

import {useOrganization} from "../../hooks/useOrganization";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
    const organization = useOrganization();
  return (
        <>
      {organization && organization.organization?.logoLocation ?
          <img
              src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${organization.organization.logoLocation}`}
              alt="image" style={{height: '45px', width: '225px'}}/>
          :
      <>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
         width="100%" viewBox="0 0 550 102" enable-background="new 0 0 1106 202" >
<path fill="#232323" opacity="1.000000" stroke="none"
      d="
M254.000000,103.000000
	C169.333359,103.000000 85.166710,103.000000 1.000045,103.000000
	C1.000030,69.000031 1.000030,35.000057 1.000015,1.000063
	C129.666611,1.000042 258.333221,1.000042 386.999878,1.000021
	C386.999908,34.999935 386.999908,68.999870 386.999939,102.999901
	C342.833344,103.000000 298.666656,103.000000 254.000000,103.000000
M42.992153,90.571541
	C43.737087,90.419357 44.482025,90.267181 46.080833,90.421463
	C52.722153,90.455017 59.363785,90.546455 66.004730,90.510353
	C80.935249,90.429184 95.865433,90.284752 111.414940,90.703613
	C112.225128,90.673424 113.035309,90.643242 114.498451,90.853027
	C114.946396,90.007011 115.394341,89.160995 116.005867,87.350113
	C116.058311,65.065063 116.082451,42.779881 116.189957,20.495102
	C116.208763,16.597174 114.846840,13.208278 111.205765,12.186119
	C108.707047,11.484654 104.783577,11.666553 103.023247,13.202655
	C91.031570,23.666843 79.444862,34.595127 67.335167,45.289764
	C67.335167,45.289764 67.044220,45.574387 66.551201,45.321369
	C66.367546,45.341114 66.183891,45.360863 65.815948,44.697010
	C59.695969,39.066338 53.587727,33.422836 47.452396,27.808935
	C42.293884,23.088835 37.348209,18.082216 31.805771,13.862512
	C29.631742,12.207325 25.761009,11.252121 23.268633,11.999054
	C21.040373,12.666835 18.177277,16.079622 18.122637,18.342260
	C17.785534,32.301769 18.164270,46.278564 17.867332,60.737564
	C17.967340,62.480541 18.067348,64.223518 18.070778,66.619057
	C18.161898,67.045166 18.253019,67.471275 18.066908,68.633057
	C20.907974,78.644081 26.441658,86.109772 37.702999,89.024719
	C38.368092,89.165337 39.033180,89.305946 39.835209,90.102249
	C40.631981,90.175842 41.428753,90.249435 42.992153,90.571541
M306.081635,56.183243
	C308.771912,63.621220 314.170013,67.355026 321.980988,67.444366
	C328.657318,67.520737 334.485413,63.537693 336.873077,57.624180
	C339.421173,51.313370 337.673126,43.758636 332.658112,39.407578
	C327.554626,34.979763 319.497253,34.179474 313.524200,37.507111
	C307.226349,41.015682 304.647949,47.094574 306.081635,56.183243
M157.831329,40.338326
	C152.888794,46.610741 152.050354,53.241253 155.435104,59.288204
	C158.749527,65.209503 165.160629,68.209404 172.379974,67.217049
	C178.953217,66.313522 183.925583,61.834137 185.595276,55.312031
	C187.219727,48.966648 184.519485,41.819229 179.134323,38.210243
	C172.838318,33.990841 165.117310,34.589962 157.831329,40.338326
M301.763824,54.847210
	C301.849548,53.688107 302.020081,52.528084 302.007507,51.370045
	C301.912445,42.602306 295.649475,36.110752 286.719055,35.480751
	C277.991974,34.865097 270.771729,40.731476 269.601532,49.388546
	C268.533386,57.290634 273.557922,64.912613 281.142120,66.895088
	C290.307281,69.290833 298.155670,64.941841 301.763824,54.847210
M242.978577,52.497551
	C242.978577,57.264011 242.978577,62.030468 242.978577,67.000580
	C247.700912,67.000580 251.845261,67.051125 255.987839,66.986809
	C260.411224,66.918129 264.273468,65.668709 266.063873,61.136734
	C267.856567,56.598953 265.882355,53.198048 262.326385,50.454449
	C268.782257,44.702194 264.906311,36.858131 258.444183,36.207150
	C253.404495,35.699467 248.272766,36.105515 242.978577,36.105515
	C242.978577,41.541504 242.978577,46.520691 242.978577,52.497551
M362.978027,35.957253
	C361.593597,36.976467 359.991119,37.800171 358.867645,39.053318
	C356.044373,42.202271 353.437561,45.545265 349.848572,49.895855
	C349.848572,44.390995 349.848572,40.219849 349.848572,36.127850
	C347.307098,36.127850 345.356598,36.127850 343.286255,36.127850
	C343.286255,46.498878 343.286255,56.694817 343.286255,66.663956
	C345.666840,66.663956 347.731018,66.663956 350.169861,66.663956
	C350.169861,61.947319 350.169861,57.568787 350.169861,52.229248
	C353.124664,55.970772 355.765411,58.726662 357.705505,61.909618
	C360.587708,66.638275 364.353424,68.142723 369.873199,66.496521
	C365.663422,61.149612 361.600922,55.989788 357.437073,50.701233
	C359.584015,48.141548 361.494019,45.858780 363.410278,43.581242
	C365.371338,41.250412 367.338531,38.924744 369.844421,35.955276
	C367.094971,35.955276 365.447754,35.955276 362.978027,35.957253
M228.967361,67.216484
	C235.272385,66.450729 238.369553,64.042267 238.989532,59.422810
	C239.685303,54.238598 237.521439,51.107826 231.546463,48.648430
	C230.015289,48.018173 228.275269,47.649021 227.025070,46.661339
	C226.045547,45.887497 225.674164,44.343899 225.032608,43.142284
	C226.150085,42.570072 227.353897,41.401531 228.367294,41.548256
	C230.560242,41.865757 232.655655,42.857010 234.920654,43.616161
	C236.260544,42.495640 237.763107,41.239075 239.351089,39.911068
	C235.314301,36.098160 231.128937,35.084126 226.433334,35.721027
	C222.086258,36.310654 218.831604,38.333561 218.042923,42.899124
	C217.231079,47.598751 219.318527,50.956333 223.624146,52.896812
	C225.584366,53.780258 227.804337,54.225819 229.548721,55.401039
	C230.711349,56.184322 231.242065,57.905540 232.055603,59.206985
	C230.727753,59.983418 229.282288,61.553043 228.095657,61.377094
	C225.317276,60.965134 222.662949,59.716446 219.875275,58.778561
	C218.659454,59.765106 217.162857,60.979488 215.690689,62.174049
	C219.300583,66.461342 223.476898,67.577499 228.967361,67.216484
M202.995560,55.755772
	C210.681793,54.376167 213.716873,51.161186 213.254395,44.888828
	C212.819992,38.997276 208.792618,36.016605 201.141769,35.960270
	C197.531219,35.933681 193.920303,35.955711 190.189590,35.955711
	C190.189590,46.503475 190.189590,56.579422 190.189590,66.773964
	C192.469940,66.773964 194.533707,66.773964 197.107300,66.773964
	C197.107300,62.993290 197.107300,59.437710 197.107300,55.789547
	C199.118958,55.789547 200.615952,55.789547 202.995560,55.755772
z"/>
                      <path fill="#FDFCFB" opacity="1.000000" stroke="none"
                            d="
M18.344139,67.897385
	C18.253019,67.471275 18.161898,67.045166 18.358089,66.167877
	C19.510237,65.432358 20.375072,65.148018 21.423477,64.981049
	C21.836031,65.287560 22.007729,65.519180 22.176529,66.078621
	C22.561981,66.526299 22.893047,66.688614 23.403843,66.904289
	C23.583574,66.957649 23.915350,67.132362 23.992897,67.448578
	C24.786724,68.115715 25.503002,68.466621 26.560078,68.876968
	C27.578354,69.071777 28.255831,69.207146 29.036573,69.666855
	C32.657753,72.812439 35.945587,72.479393 39.334732,69.190094
	C40.070892,69.057953 40.495964,69.005974 41.184929,68.929337
	C41.662506,68.638733 41.876194,68.372787 42.291683,67.854736
	C43.926956,66.694862 45.360428,65.787109 47.077560,64.828064
	C47.539925,64.455406 47.718624,64.134026 48.021965,63.628136
	C48.355495,63.224194 48.602489,63.063225 49.159782,62.898621
	C49.613689,62.527367 49.795395,62.218212 50.098274,61.714439
	C50.429245,61.287624 50.681881,61.119976 51.234875,60.949692
	C51.671459,60.589657 51.850529,60.296810 52.225723,59.732307
	C55.583466,56.606998 58.745087,53.753349 62.233078,50.896370
	C63.035313,50.254768 63.511185,49.616493 64.132370,48.793697
	C64.492714,48.375500 64.744080,48.191452 65.404251,48.059830
	C67.213181,48.352654 68.649635,48.642681 70.048164,48.965141
	C70.010239,48.997578 70.063972,48.913532 70.101959,49.241646
	C70.815872,49.994148 71.491798,50.418541 72.466499,50.981491
	C85.173004,62.417698 97.580727,73.715340 110.012314,85.350563
	C110.289375,87.180779 110.542564,88.673424 110.795753,90.166061
	C95.865433,90.284752 80.935249,90.429184 66.004730,90.510353
	C59.363785,90.546455 52.722153,90.455017 45.664562,89.974892
	C44.165512,89.351349 43.082737,89.174377 41.597420,88.985092
	C40.696011,89.130707 40.197140,89.288635 39.698273,89.446564
	C39.033180,89.305946 38.368092,89.165337 37.336258,88.552284
	C36.617020,87.739159 36.324223,87.260971 35.903179,87.078140
	C28.095749,83.688187 22.615753,78.236778 20.277790,69.856476
	C20.062218,69.083771 19.008612,68.544853 18.344139,67.897385
z"/>
                      <path fill="#F1CA4E" opacity="1.000000" stroke="none"
                            d="
M109.988434,85.012985
	C97.580727,73.715340 85.173004,62.417698 72.385605,50.679840
	C71.358612,49.797592 70.711288,49.355564 70.063972,48.913536
	C70.063972,48.913532 70.010239,48.997578 69.991364,48.655914
	C69.224144,47.334045 68.475800,46.353840 67.727463,45.373634
	C79.444862,34.595127 91.031570,23.666843 103.023247,13.202655
	C104.783577,11.666553 108.707047,11.484654 111.205765,12.186119
	C114.846840,13.208278 116.208763,16.597174 116.189957,20.495102
	C116.082451,42.779881 116.058311,65.065063 115.685486,87.837860
	C114.935417,88.537254 114.505730,88.748894 114.010132,88.641479
	C112.625641,87.219269 111.307037,86.116127 109.988434,85.012985
z"/>
                      <path fill="#E88535" opacity="1.000000" stroke="none"
                            d="
M46.793900,64.879349
	C45.360428,65.787109 43.926956,66.694862 42.035721,67.894730
	C41.358982,68.442558 41.140007,68.698273 40.921036,68.953995
	C40.495964,69.005974 40.070892,69.057953 38.946316,69.103012
	C35.142315,69.178230 32.037811,69.260376 28.933310,69.342514
	C28.255831,69.207146 27.578354,69.071777 26.465048,68.569580
	C25.324596,67.845947 24.619974,67.489151 23.915352,67.132362
	C23.915350,67.132362 23.583574,66.957649 23.293398,66.660385
	C22.709528,66.173164 22.415834,65.983215 22.122139,65.793259
	C22.007729,65.519180 21.836031,65.287560 21.363865,64.663712
	C20.182135,62.902550 19.243587,61.576080 18.305042,60.249611
	C18.164270,46.278564 17.785534,32.301769 18.122637,18.342260
	C18.177277,16.079622 21.040373,12.666835 23.268633,11.999054
	C25.761009,11.252121 29.631742,12.207325 31.805771,13.862512
	C37.348209,18.082216 42.293884,23.088835 47.452396,27.808935
	C53.587727,33.422836 59.695969,39.066338 66.036362,45.139774
	C66.256775,45.582539 66.511398,45.788811 66.310173,46.067490
	C65.749893,46.916458 65.390839,47.486748 65.031784,48.057034
	C64.744080,48.191452 64.492714,48.375500 63.814919,48.814281
	C62.870338,49.646156 62.388523,50.272926 61.906708,50.899704
	C58.745087,53.753349 55.583466,56.606998 51.975693,59.810020
	C51.345478,60.445225 51.161415,60.731052 50.977356,61.016884
	C50.681881,61.119976 50.429245,61.287624 49.844048,61.802475
	C49.274960,62.376987 49.081272,62.668854 48.887581,62.960720
	C48.602489,63.063225 48.355495,63.224194 47.767998,63.722565
	C47.190891,64.294121 46.992393,64.586731 46.793900,64.879349
z"/>
                      <path fill="#F6F6F7" opacity="1.000000" stroke="none"
                            d="
M306.007263,55.793869
	C304.647949,47.094574 307.226349,41.015682 313.524200,37.507111
	C319.497253,34.179474 327.554626,34.979763 332.658112,39.407578
	C337.673126,43.758636 339.421173,51.313370 336.873077,57.624180
	C334.485413,63.537693 328.657318,67.520737 321.980988,67.444366
	C314.170013,67.355026 308.771912,63.621220 306.007263,55.793869
M326.202637,60.075634
	C327.278595,59.074425 328.654999,58.251858 329.375854,57.039490
	C331.822540,52.924450 331.578064,48.650414 328.652405,44.970398
	C325.858154,41.455685 321.829865,40.962406 317.839752,42.613960
	C313.343079,44.475208 312.242584,48.343807 312.795502,52.862141
	C313.593750,59.385365 317.997314,62.006802 326.202637,60.075634
z"/>
                      <path fill="#F6F6F7" opacity="1.000000" stroke="none"
                            d="
M158.090179,40.080719
	C165.117310,34.589962 172.838318,33.990841 179.134323,38.210243
	C184.519485,41.819229 187.219727,48.966648 185.595276,55.312031
	C183.925583,61.834137 178.953217,66.313522 172.379974,67.217049
	C165.160629,68.209404 158.749527,65.209503 155.435104,59.288204
	C152.050354,53.241253 152.888794,46.610741 158.090179,40.080719
M168.745377,61.022362
	C175.515717,60.810963 179.257523,57.156315 178.919724,51.084999
	C178.581833,45.012150 174.334076,41.270985 168.379822,41.945362
	C163.796051,42.464523 161.333298,45.225163 160.752792,49.722363
	C160.002655,55.533592 162.122528,58.816612 168.745377,61.022362
z"/>
                      <path fill="#F6F6F7" opacity="1.000000" stroke="none"
                            d="
M301.623657,55.235321
	C298.155670,64.941841 290.307281,69.290833 281.142120,66.895088
	C273.557922,64.912613 268.533386,57.290634 269.601532,49.388546
	C270.771729,40.731476 277.991974,34.865097 286.719055,35.480751
	C295.649475,36.110752 301.912445,42.602306 302.007507,51.370045
	C302.020081,52.528084 301.849548,53.688107 301.623657,55.235321
M288.755249,60.786598
	C289.744263,60.197624 290.846710,59.739361 291.702423,58.996799
	C295.186188,55.973778 295.955719,50.504139 293.591125,46.275791
	C291.493866,42.525490 286.627075,40.788357 282.367249,42.269524
	C278.344177,43.668373 276.198212,47.683620 276.774170,52.734631
	C277.459167,58.741375 280.723969,61.139317 288.755249,60.786598
z"/>
                      <path fill="#F9F9FA" opacity="1.000000" stroke="none"
                            d="
M242.978577,51.998714
	C242.978577,46.520691 242.978577,41.541504 242.978577,36.105515
	C248.272766,36.105515 253.404495,35.699467 258.444183,36.207150
	C264.906311,36.858131 268.782257,44.702194 262.326385,50.454449
	C265.882355,53.198048 267.856567,56.598953 266.063873,61.136734
	C264.273468,65.668709 260.411224,66.918129 255.987839,66.986809
	C251.845261,67.051125 247.700912,67.000580 242.978577,67.000580
	C242.978577,62.030468 242.978577,57.264011 242.978577,51.998714
M258.100311,60.884071
	C258.492889,59.127663 259.585052,56.053043 259.164062,55.829617
	C256.510071,54.421120 253.520157,53.645618 250.199066,52.509789
	C250.199066,56.418381 250.199066,58.959263 250.199066,61.938507
	C252.849945,61.667858 255.108856,61.437233 258.100311,60.884071
M250.675797,41.294899
	C250.337509,43.440842 249.999222,45.586781 249.661057,47.731945
	C256.706390,48.461647 258.961761,47.779949 258.732178,44.925690
	C258.351929,40.198418 254.480820,41.435417 250.675797,41.294899
z"/>
                      <path fill="#F8F8F9" opacity="1.000000" stroke="none"
                            d="
M363.389282,35.956264
	C365.447754,35.955276 367.094971,35.955276 369.844421,35.955276
	C367.338531,38.924744 365.371338,41.250412 363.410278,43.581242
	C361.494019,45.858780 359.584015,48.141548 357.437073,50.701233
	C361.600922,55.989788 365.663422,61.149612 369.873199,66.496521
	C364.353424,68.142723 360.587708,66.638275 357.705505,61.909618
	C355.765411,58.726662 353.124664,55.970772 350.169861,52.229248
	C350.169861,57.568787 350.169861,61.947319 350.169861,66.663956
	C347.731018,66.663956 345.666840,66.663956 343.286255,66.663956
	C343.286255,56.694817 343.286255,46.498878 343.286255,36.127850
	C345.356598,36.127850 347.307098,36.127850 349.848572,36.127850
	C349.848572,40.219849 349.848572,44.390995 349.848572,49.895855
	C353.437561,45.545265 356.044373,42.202271 358.867645,39.053318
	C359.991119,37.800171 361.593597,36.976467 363.389282,35.956264
z"/>
                      <path fill="#F5F5F6" opacity="1.000000" stroke="none"
                            d="
M228.575195,67.269081
	C223.476898,67.577499 219.300583,66.461342 215.690689,62.174049
	C217.162857,60.979488 218.659454,59.765106 219.875275,58.778561
	C222.662949,59.716446 225.317276,60.965134 228.095657,61.377094
	C229.282288,61.553043 230.727753,59.983418 232.055603,59.206985
	C231.242065,57.905540 230.711349,56.184322 229.548721,55.401039
	C227.804337,54.225819 225.584366,53.780258 223.624146,52.896812
	C219.318527,50.956333 217.231079,47.598751 218.042923,42.899124
	C218.831604,38.333561 222.086258,36.310654 226.433334,35.721027
	C231.128937,35.084126 235.314301,36.098160 239.351089,39.911068
	C237.763107,41.239075 236.260544,42.495640 234.920654,43.616161
	C232.655655,42.857010 230.560242,41.865757 228.367294,41.548256
	C227.353897,41.401531 226.150085,42.570072 225.032608,43.142288
	C225.674164,44.343899 226.045547,45.887497 227.025070,46.661339
	C228.275269,47.649021 230.015289,48.018173 231.546463,48.648430
	C237.521439,51.107826 239.685303,54.238598 238.989532,59.422810
	C238.369553,64.042267 235.272385,66.450729 228.575195,67.269081
z"/>
                      <path fill="#F9F8F9" opacity="1.000000" stroke="none"
                            d="
M202.554260,55.772659
	C200.615952,55.789547 199.118958,55.789547 197.107300,55.789547
	C197.107300,59.437710 197.107300,62.993290 197.107300,66.773964
	C194.533707,66.773964 192.469940,66.773964 190.189590,66.773964
	C190.189590,56.579422 190.189590,46.503475 190.189590,35.955711
	C193.920303,35.955711 197.531219,35.933681 201.141769,35.960270
	C208.792618,36.016605 212.819992,38.997276 213.254395,44.888828
	C213.716873,51.161186 210.681793,54.376167 202.554260,55.772659
M206.309540,46.455437
	C205.361130,41.390575 204.331268,40.826424 197.262848,41.612823
	C197.262848,44.445148 197.262848,47.290741 197.262848,51.774071
	C200.816483,49.974045 203.465698,48.632141 206.309540,46.455437
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M18.205524,68.265221
	C19.008612,68.544853 20.062218,69.083771 20.277790,69.856476
	C22.615753,78.236778 28.095749,83.688187 35.903179,87.078140
	C36.324223,87.260971 36.617020,87.739159 37.025681,88.414062
	C26.441658,86.109772 20.907974,78.644081 18.205524,68.265221
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M18.086185,60.493587
	C19.243587,61.576080 20.182135,62.902550 21.180294,64.546349
	C20.375072,65.148018 19.510237,65.432358 18.406378,65.841599
	C18.067348,64.223518 17.967340,62.480541 18.086185,60.493587
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M110.012314,85.350571
	C111.307037,86.116127 112.625641,87.219269 113.953835,88.916321
	C113.924118,89.877831 113.884804,90.245445 113.845490,90.613052
	C113.035309,90.643242 112.225128,90.673424 111.105347,90.434837
	C110.542564,88.673424 110.289375,87.180779 110.012314,85.350571
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M65.404251,48.059830
	C65.390839,47.486748 65.749893,46.916458 66.404182,46.074009
	C66.699417,45.801849 67.044220,45.574387 67.044220,45.574387
	C67.044220,45.574387 67.335167,45.289764 67.531311,45.331699
	C68.475800,46.353840 69.224144,47.334045 70.029282,48.623482
	C68.649635,48.642681 67.213181,48.352654 65.404251,48.059830
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M41.999958,88.997406
	C43.082737,89.174377 44.165512,89.351349 45.237625,89.821655
	C44.482025,90.267181 43.737087,90.419357 42.603394,90.221420
	C42.088524,89.593391 42.016968,89.302101 41.999958,88.997406
z"/>
                      <path fill="#87838A" opacity="1.000000" stroke="none"
                            d="
M114.171967,90.733040
	C113.884804,90.245445 113.924118,89.877831 114.019730,89.235382
	C114.505730,88.748894 114.935417,88.537254 115.603699,88.320297
	C115.394341,89.160995 114.946396,90.007011 114.171967,90.733040
z"/>
                      <path fill="#87838A" opacity="1.000000" stroke="none"
                            d="
M41.597420,88.985092
	C42.016968,89.302101 42.088524,89.593391 42.220078,90.097160
	C41.428753,90.249435 40.631981,90.175842 39.766739,89.774406
	C40.197140,89.288635 40.696011,89.130707 41.597420,88.985092
z"/>
                      <path fill="#F1CA4E" opacity="1.000000" stroke="none"
                            d="
M66.797714,45.447876
	C67.044220,45.574387 66.699417,45.801849 66.605408,45.795330
	C66.511398,45.788811 66.256775,45.582539 66.128502,45.481575
	C66.183891,45.360863 66.367546,45.341114 66.797714,45.447876
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M29.036573,69.666855
	C32.037811,69.260376 35.142315,69.178230 38.635231,69.183182
	C35.945587,72.479393 32.657753,72.812439 29.036573,69.666855
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M62.233074,50.896370
	C62.388523,50.272926 62.870338,49.646156 63.669605,48.998802
	C63.511185,49.616493 63.035313,50.254768 62.233074,50.896370
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M70.101959,49.241646
	C70.711288,49.355564 71.358612,49.797592 72.086823,50.541275
	C71.491798,50.418541 70.815872,49.994148 70.101959,49.241646
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M23.992897,67.448578
	C24.619974,67.489151 25.324596,67.845947 26.124252,68.510139
	C25.503002,68.466621 24.786724,68.115715 23.992897,67.448578
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M47.077560,64.828064
	C46.992393,64.586731 47.190891,64.294121 47.643360,63.907074
	C47.718624,64.134026 47.539925,64.455406 47.077560,64.828064
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M41.184929,68.929337
	C41.140007,68.698273 41.358982,68.442558 41.833916,68.146835
	C41.876194,68.372787 41.662506,68.638733 41.184929,68.929337
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M22.176527,66.078621
	C22.415834,65.983215 22.709528,66.173164 23.113667,66.607025
	C22.893047,66.688614 22.561981,66.526299 22.176527,66.078621
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M49.159782,62.898621
	C49.081272,62.668854 49.274960,62.376987 49.722874,61.997086
	C49.795395,62.218212 49.613689,62.527367 49.159782,62.898621
z"/>
                      <path fill="#C2BEBF" opacity="1.000000" stroke="none"
                            d="
M51.234871,60.949692
	C51.161415,60.731052 51.345478,60.445225 51.779572,60.081680
	C51.850529,60.296810 51.671459,60.589657 51.234871,60.949692
z"/>
                      <path fill="#0C051D" opacity="1.000000" stroke="none"
                            d="
M325.837860,60.244167
	C317.997314,62.006802 313.593750,59.385365 312.795502,52.862141
	C312.242584,48.343807 313.343079,44.475208 317.839752,42.613960
	C321.829865,40.962406 325.858154,41.455685 328.652405,44.970398
	C331.578064,48.650414 331.822540,52.924450 329.375854,57.039490
	C328.654999,58.251858 327.278595,59.074425 325.837860,60.244167
z"/>
                      <path fill="#0C051D" opacity="1.000000" stroke="none"
                            d="
M168.341553,60.979504
	C162.122528,58.816612 160.002655,55.533592 160.752792,49.722363
	C161.333298,45.225163 163.796051,42.464523 168.379822,41.945362
	C174.334076,41.270985 178.581833,45.012150 178.919724,51.084999
	C179.257523,57.156315 175.515717,60.810963 168.341553,60.979504
z"/>
                      <path fill="#0C051D" opacity="1.000000" stroke="none"
                            d="
M288.356934,60.867970
	C280.723969,61.139317 277.459167,58.741375 276.774170,52.734631
	C276.198212,47.683620 278.344177,43.668373 282.367249,42.269524
	C286.627075,40.788357 291.493866,42.525490 293.591125,46.275791
	C295.955719,50.504139 295.186188,55.973778 291.702423,58.996799
	C290.846710,59.739361 289.744263,60.197624 288.356934,60.867970
z"/>
                      <path fill="#1C162C" opacity="1.000000" stroke="none"
                            d="
M257.734039,61.045338
	C255.108856,61.437233 252.849945,61.667858 250.199066,61.938507
	C250.199066,58.959263 250.199066,56.418381 250.199066,52.509789
	C253.520157,53.645618 256.510071,54.421120 259.164062,55.829617
	C259.585052,56.053043 258.492889,59.127663 257.734039,61.045338
z"/>
                      <path fill="#140E25" opacity="1.000000" stroke="none"
                            d="
M251.110367,41.250725
	C254.480820,41.435417 258.351929,40.198418 258.732178,44.925690
	C258.961761,47.779949 256.706390,48.461647 249.661057,47.731945
	C249.999222,45.586781 250.337509,43.440842 251.110367,41.250725
z"/>
                      <path fill="#110A21" opacity="1.000000" stroke="none"
                            d="
M206.212219,46.872837
	C203.465698,48.632141 200.816483,49.974045 197.262848,51.774071
	C197.262848,47.290741 197.262848,44.445148 197.262848,41.612823
	C204.331268,40.826424 205.361130,41.390575 206.212219,46.872837
z"/>
</svg>
          </>
          }
      </>
  );
};

export default LogoMain;
