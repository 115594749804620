import React, {useEffect, useRef, useState} from 'react';

// material-ui
import {
  Button, ButtonGroup,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Divider, FormControl,
  Grid, Menu, MenuItem,
  Stack, TextField,
  Theme, Tooltip,
  Typography
} from '@mui/material';

// project imports

// types
import {
  Annotation,
  AppUserProfile, ConductFile,
  ConductScriptItem,
  GameMessage,
  Reaction,
  ScriptItem
} from "../../interfaces/interfaces";
import Scrollbar from "react-scrollbars-custom";
import {
  ArrowDropDown, FlagSharp, Highlight,
  InsightsOutlined,
  NoteAddOutlined, QuestionMarkOutlined, Star, StarBorderOutlined, Timer
} from "@mui/icons-material";
import MainCard from "../MainCard";
import FlaggedComponent from "./FlaggedComponent";
import TimeElapsedComponent from "./TimeElapsedComponent";
import ReactMarkdown from "react-markdown";
import ChatBubble from "../ChatBubble";
import FileList from "../FileList";

interface ChatHistoryProps {
  theme: Theme;
  chat: GameMessage[];
  companyId: string;
  sceanrioId: string;
  portalId: string;
  latestScriptItem?: ScriptItem;
  onAnnotation?: (annotation: Annotation) => void;
  onConductScriptItemChange?: (conductScriptItem: ConductScriptItem) => void;
  onSectionComplete?: (sectionId: string) => void;
  onFileDownload?: (file: ConductFile) => void;
  onBranch: (conductScriptItemId: string, branchName:string, branchDescription: string) => void;
  onStar?: (gameMessageId: string) => void;
  onQuestion?: (gameMessageId: string, text: string) => void;
  onInsights?: (gameMessageId: string, text: string) => void;
  onFlagChange?: (id: string, flag: string) => void;
  onTimerChange?: (id: string, timeInMinutes: number, time: string) => void;
  userProfile?: AppUserProfile | null | undefined;
  isVirtualAssistantOn: boolean;
}

const GuidedConductChatHistory = ({ theme, chat, onAnnotation, latestScriptItem,
                                    onConductScriptItemChange, onSectionComplete, onBranch, sceanrioId, userProfile,
                                    portalId, companyId, onStar, onInsights, onQuestion, onFlagChange, onTimerChange, isVirtualAssistantOn,
                                  onFileDownload}: ChatHistoryProps) => {
  const bottomRef = useRef(null);
  const [openAnnotateDialog, setOpenAnnotateDialog] = useState(false);
  const [annotateTextValue, setAnnotateTextValue] = useState("");
  const [annotationTargetValue, setAnnotationTargetValue] = useState("");
  // const [showActions, setShowActions] = useState(false);
  // const [showSuggestions, setShowSuggestions] = useState<string | null>(null);
  // const [suggestions, setSuggestions] = useState<{ [key: string]: string }>({});
  // const [currentAction, setCurrentAction] = useState<string>();
  // const token = localStorage.getItem('token');
  // const [showBranch, setShowBranch] = useState<string | null>(null);
  // const [branchName, setBranchName] = useState("");
  // const [conductScriptItems, setConductScriptItems] = useState<ConductScriptItem[]>([]);
  function handleAnnotate(id: string) {
    setOpenAnnotateDialog(true);
    //console.log("Annotate: "+id);
    setAnnotationTargetValue(id);
    setAnnotateTextValue("");
  };

  function handleStar(id: string) {
    if (onStar) {
      onStar(id);
    }
  };

  function handleInsights(id: string, text: string) {
    if (onInsights) {
      onInsights(id, text);
    }
  }

  function handleQuestion(id: string, text: string) {
    if (onQuestion) {
      onQuestion(id, text);
    }
  }

  useEffect(() => {
    if (latestScriptItem && latestScriptItem.conductScriptItems) {
      //setConductScriptItems(latestScriptItem.conductScriptItems);
    }
  }, [latestScriptItem]);

// useEffect(() => {
//   // Check if showSuggestTextField is true and currentSuggestion is null or blank
//   if (showSuggestions && (!suggestions[showSuggestions] || suggestions[showSuggestions].trim() === '')) {
//     // Make the API call
//     const fetchData = async () => {
//       try {
//         const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/assistant/getactionsuggestion/'+token+'/'+
//             companyId+'/'+portalId+'/'+currentAction);
//         const data = await response.json();
//         setSuggestions(prevSuggestions => ({ ...prevSuggestions, [showSuggestions]: data.suggestion })); // Set the suggestion for this item
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };
//
//     fetchData();
//   }
// }, [showSuggestions, suggestions]); // Add showSuggestTextField and suggestions as dependencies
  const handleSaveAnnotation = async () => {
    setOpenAnnotateDialog(false);
    onAnnotation && onAnnotation({
      companyId: "",
      gameMessageId: annotationTargetValue,
      madeBy: "",
      scenarioId: "",
      simulationId: "",
      annotationId: "",
      text: annotateTextValue });
    setAnnotateTextValue("");
    setAnnotationTargetValue("");
  }

  const closeAnnotateDialog = () => {
    setOpenAnnotateDialog(false);
    setAnnotateTextValue("");
    setAnnotationTargetValue("");
  };

  useEffect(() => {
    // @ts-ignore
    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [chat]);

  const [openReactionDialog, setOpenReactionDialog] = useState(false);
  const [selectedReactions, setSelectedReactions] = useState<Reaction[] | null>(null);
// Function to handle row click
  const handleRowClick = (reactions: Reaction[]) => {
    setSelectedReactions(reactions);
    setOpenReactionDialog(true);
  };

  const [openInsightsDialog, setOpenInsightsDialog] = useState(false);
  const [openQuestionsDialog, setOpenQuestionsDialog] = useState(false);
  const [insightsText, setInsightsText] = useState("");
  const [questionsText, setQuestionsText] = useState("");

  function handleInsightsDialogClose() {
    setOpenInsightsDialog(false);
    handleInsights(annotationTargetValue, insightsText);
  }

  function handleQuestionsDialogClose() {
    setOpenQuestionsDialog(false);
    handleQuestion(annotationTargetValue, questionsText);
  }

  function handleInsightsOpen(id: string) {
    setOpenInsightsDialog(true);
    setInsightsText("");
    setAnnotationTargetValue(id);
  }

    function handleQuestionOpen(id: string) {
        setOpenQuestionsDialog(true);
        setQuestionsText("");
        setAnnotationTargetValue(id);
    }

  const [anchorEl, setAnchorEl] = React.useState<{ [key: string]: null | HTMLElement }>({});
  const handleFlagClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEl(prevState => ({ ...prevState, [id]: event.currentTarget }));
  };

  const handleFlagClose = (id: string) => {
    setAnchorEl(prevState => ({ ...prevState, [id]: null }));
  };

  function handleFlagChange(id: string, flag: string) {
    return function() {
      if (onFlagChange) {
        //console.log("Flagging: "+id);
        onFlagChange(id, flag);
      }
    }
  }

  const [anchorEngagementEl, setAnchorEngagementEl] = React.useState<{ [key: string]: null | HTMLElement }>({});

  const handleEngagementClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorEngagementEl(prevState => ({ ...prevState, [id]: event.currentTarget }));
  };
  function handleEngagementClose(id: string) {
    setAnchorEngagementEl(prevState => ({ ...prevState, [id]: null }));
  }

  const [anchorTimerEl, setAnchorTimerEl] = React.useState<{ [key: string]: null | HTMLElement }>({});
  const handleTimerClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    setAnchorTimerEl(prevState => ({ ...prevState, [id]: event.currentTarget }));
  };
  function handleTimerClose(id: string) {
    setAnchorTimerEl(prevState => ({ ...prevState, [id]: null }));
  }
  function handleTimerChange(id: string, timeInMinutes: number, time: string) {
    return function() {
      if (onTimerChange) {
        onTimerChange(id, timeInMinutes, time);
      }
    }
  }

  function handleDownload(file: ConductFile) {
    if (onFileDownload) {
      onFileDownload(file);
    }
  }

  return (
      <MainCard content={false} sx={{ bgcolor: theme.palette.background.paper, p: 1 }} border={true}>
      <Grid container spacing={2}>
      <Grid item xs={12} >
        <Scrollbar style={{ width: '100%', height: '500px' }}>
          {chat && chat.map((history, index) => (
              <>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  {history.answer &&
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Stack direction="column" justifyContent="flex-start"  spacing={1}>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12}>
                                        <Stack direction="row" justifyContent={isVirtualAssistantOn?"flex-end":"flex-start"}  spacing={1}>
                                          <ButtonGroup size={"small"} >
                                            <Button  variant="contained" size={"small"} >Actions</Button>
                                            <Tooltip title={"Highlight, add an insight or question to an action"}>
                                              <Button variant="outlined" onClick={(event) => handleEngagementClick(event, history.id)} size={"extraSmall"}>
                                                <ArrowDropDown fontSize={"small"}/><Highlight fontSize={"small"}/>
                                              </Button>
                                            </Tooltip>
                                            <Menu
                                                id={`simple-flagmenu-${history.id}`}
                                                anchorEl={anchorEngagementEl[history.id]}
                                                keepMounted
                                                open={Boolean(anchorEngagementEl[history.id])}
                                                onClose={() => handleEngagementClose(history.id)}
                                                anchorOrigin={{
                                                  vertical: 'bottom',
                                                  horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'center',
                                                }}
                                            >
                                              <MenuItem onClick={() => {handleStar(history.id); handleEngagementClose(history.id);}}>
                                                <Tooltip title="Highlight this action.">
                                                  <><StarBorderOutlined/>&nbsp;Highlight</>
                                                </Tooltip>
                                              </MenuItem>
                                              <MenuItem onClick={() => {handleInsightsOpen(history.id); handleEngagementClose(history.id);}}>
                                                <Tooltip title="Add an insight to this action.">
                                                  <> <InsightsOutlined/>&nbsp;Insight</>
                                                </Tooltip>
                                              </MenuItem>
                                              <MenuItem onClick={() => {handleQuestionOpen(history.id); handleEngagementClose(history.id);}}>
                                                <Tooltip title="Add a question to this action.">
                                                  <><QuestionMarkOutlined/>&nbsp;Question</>
                                                </Tooltip>
                                              </MenuItem>
                                            </Menu>

                                            {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                                <>
                                                  {onTimerChange &&
                                                      <>
                                                        <Tooltip title={"Set approximate time for this action"}>
                                                          <Button variant="outlined" onClick={(event) => handleTimerClick(event, history.id)} >
                                                            <ArrowDropDown fontSize={"small"}/> <Timer fontSize={"small"}/>
                                                          </Button>
                                                        </Tooltip>
                                                        <Menu
                                                            id={`simple-flagmenu-${history.id}`}
                                                            anchorEl={anchorTimerEl[history.id]}
                                                            keepMounted
                                                            open={Boolean(anchorTimerEl[history.id])}
                                                            onClose={() => handleTimerClose(history.id)}
                                                        >
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 5, "5 minutes")()}> 5 minutes</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 10, "10 minutes")()}> 10 minutes</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 15, "15 minutes")()}> 15 minutes</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 30, "30 minutes")()}> 30 minutes</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 60, "1 hour")()}> 1 hour</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 120, "2 hours")()}> 2 hours</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 240, "4 hours")()}> 4 hours</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 480, "8 hours")()}> 8 hours</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 720, "12 hours")()}> 12 hours</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 1440, "1 Day")()}> 1 Day</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 2880, "8 hours")()}> 2 Days</MenuItem>
                                                          <MenuItem onClick={() => handleTimerChange(history.id, 1080, "8 hours")()}> 1 Week</MenuItem>


                                                        </Menu>
                                                      </>
                                                  }
                                                  <Tooltip title={"Flag this as an action item, identified gap or for further review"}>
                                                    <Button variant="outlined" onClick={(event) => handleFlagClick(event, history.id)} size={"small"}>
                                                      <ArrowDropDown fontSize={"small"}/> <FlagSharp fontSize={"small"}/>
                                                    </Button>
                                                  </Tooltip>
                                                  <Menu
                                                      id={`simple-flagmenu-${history.id}`}
                                                      anchorEl={anchorEl[history.id]}
                                                      keepMounted
                                                      open={Boolean(anchorEl[history.id])}
                                                      onClose={() => handleFlagClose(history.id)}
                                                  >
                                                    <MenuItem onClick={() => handleFlagChange(history.id, "Action Item")()}> Action Item</MenuItem>
                                                    <MenuItem onClick={() => handleFlagChange(history.id, "Identified Gap")()}> Identified Gap</MenuItem>
                                                    <MenuItem onClick={() => handleFlagChange(history.id, "Review")()}> Review</MenuItem>
                                                    <MenuItem onClick={() => handleFlagChange(history.id, "Critical Action")()}> Critical Action</MenuItem>
                                                  </Menu>
                                                  {onAnnotation &&
                                                      <>
                                                        {/*<Button size={"small"} onClick={() => {setShowSuggestions(null);;setShowActions(true);setShowBranch(null);}} ><CheckBoxOutlined color={"primary"}/></Button>*/}
                                                        {/*<Button size={"small"} onClick={() => {setShowSuggestions(history.id); setCurrentAction(history.gameQuestion); setShowActions(false);setShowBranch(null);}} ><TipsAndUpdatesOutlined color={"primary"}/></Button>*/}
                                                        {/*<Button size={"small"} onClick={() => {setShowActions(false); setShowSuggestions(null); setShowBranch(history.id)}} ><ForkRightOutlined color={"primary"}/></Button>*/}
                                                        <Tooltip title="Annotate to provide feedback for this action or comment.">
                                                          <Button size={"small"} onClick={() => handleAnnotate(history.id)} >
                                                            <NoteAddOutlined fontSize={"small"}/></Button>
                                                        </Tooltip>
                                                      </>
                                                  }
                                                </>
                                            }
                                          </ButtonGroup>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Stack direction="row" justifyContent={isVirtualAssistantOn?"flex-end":"flex-start"}  spacing={1}>
                                          <ChatBubble variant="elevation" colorType={"primary"}>
                                            <Grid container spacing={1}>
                                              <Grid item xs={11}>
                                                <Typography variant="h6">
                                                  <ReactMarkdown>{history.answer}</ReactMarkdown>
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={1}>
                                                { history.files && history.files.length > 0 &&
                                                    <Tooltip title={"Attachment"}>
                                                      <FileList files={history.files} onDownload={handleDownload} displayIconOnly={true} />
                                                    </Tooltip>
                                                }
                                              </Grid>
                                              <Grid item xs={4}>
                                                {history.userAudit &&
                                                    <Typography fontSize={"smaller"} variant={"caption"} color={"textSecondary"} align={"right"}>{history.userAudit.createdByName} on {history.userAudit.createdDate}</Typography>
                                                }
                                              </Grid>
                                              <Grid item xs={4}>
                                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                                  {history.reactions && (
                                                      <Grid item xs={12}>
                                                          <Grid container spacing={2} onClick={() => history.reactions && handleRowClick(history.reactions)}>
                                                            <Grid item>
                                                              <Star fontSize={"small"} style={{ color: 'yellow' }} />
                                                              <Typography variant={"caption"} style={{fontSize:'0.70rem'}} color={"textSecondary"}>{history.reactions.filter((reaction) => reaction.isFavorite).length}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                              <InsightsOutlined fontSize={"small"} style={{ color: 'lightskyblue' }} />
                                                              <Typography variant={"caption"}  style={{fontSize:'0.70rem'}} color={"textSecondary"}>{history.reactions.filter((reaction) => reaction.isInsight).length}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                              <QuestionMarkOutlined fontSize={"small"} style={{ color: 'lightgreen' }} />
                                                              <Typography variant={"caption"}  style={{fontSize:'0.70rem'}} color={"textSecondary"}> {history.reactions.filter((reaction) => reaction.isQuestion).length}</Typography>
                                                            </Grid>
                                                          </Grid>
                                                      </Grid>
                                                  )}
                                                </Stack>
                                              </Grid>
                                              <Grid item xs={4}>
                                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                                {history.timeElapsed &&
                                                    <TimeElapsedComponent timeText={history.timeElapsed}/>
                                                }
                                                  {history.flag &&
                                                      <FlaggedComponent flag={history.flag} />
                                                  }
                                                </Stack>
                                              </Grid>
                                              {onAnnotation && history.annotations &&  <Grid item xs={12}><Divider>Annotations</Divider></Grid>}
                                              {onAnnotation && history.annotations && history.annotations.map((annotation, index) => (
                                                  <>
                                                    <Grid item xs={12}>
                                                      <Grid container spacing={2} onClick={() => history.reactions && handleRowClick(history.reactions)}>
                                                        <Grid item xs={12}>
                                                          <Stack direction="row" justifyContent="flex-end" alignItems="flex-start">
                                                            <NoteAddOutlined style={{ color: 'lightblue' }} fontSize={"small"} />
                                                            <Typography variant="caption" color={"textSecondary"} sx={{overflowWrap: 'anywhere'}}>
                                                              {annotation.text}
                                                            </Typography>
                                                          </Stack>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </>
                                              ))}
                                            </Grid>
                                          </ChatBubble>
                                        </Stack>
                                      </Grid>
                                    </Grid>
                                  </Stack>
                                </Grid>

                                {isVirtualAssistantOn && history.messageThread &&
                                    <Grid item xs={12}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                          <Stack direction="column" justifyContent="flex-start"  spacing={1}>
                                            <Grid container spacing={1}>
                                              {history.messageThread.map((message, index) => (
                                                  <Grid item xs={12}>
                                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                                      <ChatBubble variant="elevation" colorType={"paper"}>
                                                        <Grid container spacing={1}>
                                                          <Grid item xs={12}>
                                                            <Typography variant={"body2"}>
                                                              <ReactMarkdown>{message.message}</ReactMarkdown>
                                                            </Typography>
                                                          </Grid>
                                                            <Grid item xs={4}>
                                                              <Typography fontSize={"smaller"} variant={"caption"} color={"textSecondary"} align={"right"}>AI Facilitator</Typography>
                                                            </Grid>
                                                        </Grid>
                                                      </ChatBubble>
                                                    </Stack>
                                                  </Grid>
                                              ))}
                                            </Grid>
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}><br/><br/></Grid>

                                {/*<Typography variant={"caption"} color={"textSecondary"}>{history.id}</Typography>*/}
                              </Grid>
                          </Grid>

                          <Dialog fullWidth={true} open={openInsightsDialog} onClose={handleInsightsDialogClose}>
                            <DialogTitle>Comment on the Insight</DialogTitle>
                            <DialogContent>
                              <TextField
                                  value={insightsText}
                                  multiline={true}
                                  fullWidth={true}
                                  rows={2}
                                  onChange={(e) => setInsightsText(e.target.value)}
                                  placeholder="What made this action so insightful?  Add your comments here."
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button variant={"contained"} onClick={handleInsightsDialogClose}>Save</Button>
                              <Button variant={"contained"} onClick={() => setOpenInsightsDialog(false)}>Cancel</Button>
                            </DialogActions>
                          </Dialog>

                          <Dialog fullWidth={true} open={openQuestionsDialog} onClose={handleQuestionsDialogClose}>
                            <DialogTitle>Questions about this action?</DialogTitle>
                            <DialogContent>
                              <TextField
                                  value={questionsText}
                                  multiline={true}
                                  fullWidth={true}
                                  rows={2}
                                  onChange={(e) => setQuestionsText(e.target.value)}
                                  placeholder="What question(s) does this action raise?  Add your questions here."
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button variant={"contained"} onClick={handleQuestionsDialogClose}>Save</Button>
                              <Button variant={"contained"}onClick={() => setOpenQuestionsDialog(false)}>Cancel</Button>
                            </DialogActions>
                          </Dialog>

                          <Dialog fullWidth={true} open={openReactionDialog} onClose={() => setOpenReactionDialog(false)}>
                            <DialogTitle>Reactions on this action</DialogTitle>
                            <DialogContent>
                              <Grid container spacing={2}>
                                {selectedReactions && selectedReactions.map((reaction, index) => (
                                    <>{
                                        (reaction.isFavorite || reaction.isQuestion || reaction.isInsight) &&
                                        <><Grid item xs={12}>
                                          <Typography variant="caption" color={"textSecondary"}>From {reaction.madeByName} </Typography>
                                        </Grid>
                                          {reaction.isFavorite &&
                                              <Grid item xs={12}>
                                                <Typography key={index}>
                                                  <Star fontSize={"small"} style={{ color: 'yellow' }} /> Favorite Action
                                                </Typography>
                                              </Grid>
                                          }
                                          {reaction.isQuestion && reaction.questionComment &&
                                              <>
                                                <Grid item xs={12}>
                                                  <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                                                    <QuestionMarkOutlined fontSize={"small"} style={{ color: 'lightgreen' }} />
                                                    Question Raised:
                                                    <Typography variant="caption" color={"textSecondary"}>
                                                      {reaction.questionComment}
                                                    </Typography>
                                                  </Stack>
                                                </Grid>
                                              </>
                                          }
                                          {reaction.isInsight && reaction.insightComment &&
                                              <Grid item xs={12}>
                                                <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                                                  <InsightsOutlined fontSize={"small"} style={{ color: 'lightskyblue' }} />
                                                  Insights Provided:
                                                  <Typography variant="caption" color={"textSecondary"}>
                                                    {reaction.insightComment}
                                                  </Typography>
                                                </Stack>
                                              </Grid>
                                          }
                                        </>
                                    }
                                      <Grid item xs={12}>
                                        <Divider/>
                                      </Grid>
                                    </>
                                ))}
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button variant={"contained"} onClick={() => setOpenReactionDialog(false)}>Close</Button>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                  }
                </Grid>
              </Grid>
              </>
          ))}
        </Scrollbar>
        {/*<Grid item ref={bottomRef} />*/}
      </Grid>
        <Dialog open={openAnnotateDialog} fullWidth={true} onClose={closeAnnotateDialog}>
          <DialogTitle>Add Annotation</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                  name="text"
                  placeholder="Annotate - add your comments here."
                  fullWidth={true}
                  multiline
                  rows={4}
                  variant="outlined"
                  value={annotateTextValue}
                  onChange={(e) => setAnnotateTextValue(e.target.value)}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAnnotateDialog}>Cancel</Button>
            <Button onClick={handleSaveAnnotation} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      </MainCard>
  );
};

export default GuidedConductChatHistory;
