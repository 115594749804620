// material-ui
// import { Theme } from '@mui/material/styles';
import {
    Button, Chip, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    Link,
    Paper, Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow, TextField, Typography
} from '@mui/material';

import {Scenario} from "../../interfaces/interfaces";
import React, {useMemo, useState} from "react";
import MainCard from "../MainCard";
import {flexRender, useReactTable, ColumnDef, getCoreRowModel, HeaderGroup} from '@tanstack/react-table';
import {Link as RouterLink} from "react-router-dom";
import ScrollX from "../ScrollX";
import { LabelKeyObject } from 'react-csv/lib/core';
import ScenarioWalkthroughDialog from "../dialog/ScenarioWalkthroughDialog";

interface OrganizationScenariosProps {
  scenarios: Scenario[];
  orgId: string;
}

interface ReactTableProps {
    columns: ColumnDef<Scenario>[];
    data: Scenario[];
    title?: string;
    orgId: string;
    searchTerm?: string;
    handleSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function ScenarioTable({ columns, data, title, orgId, searchTerm, handleSearchChange }: ReactTableProps) {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });

    let headers: LabelKeyObject[] = [];
    table.getAllColumns().map((columns) =>
        headers.push({
            label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
            // @ts-ignore
            key: columns.columnDef.accessorKey
        })
    );

    return (
        <>
            <MainCard content={false} title={
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Stack direction="row" justifyContent={"flex-start"} spacing={1}>
                            <TextField
                                placeholder="Search Library"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            }>
            <ScrollX>
                <TableContainer component={Paper}>
                    <Table size="medium">
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableCell key={header.id} {...header.column.columnDef.meta}>
                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ScrollX>
        </MainCard>
    </>
);
}
const getStatusChip = (status: string) => {
    switch (status) {
        case 'Approved':
            return <Chip color="success" label={status}  size="medium" variant="outlined" />;
        case 'Pending Approval':
            return <Chip color="success" label={status}  size="medium" variant="outlined" />;
        case 'Under Review':
            return <Chip color="primary" label="Under Review" size="medium" variant="outlined" />;
        case 'Archived':
            return <Chip color="secondary" label="Archived" size="medium" variant="outlined" />;
        default:
            return <Chip color="primary" label={status} size="medium" variant="outlined" />;
    }
}

const TabOrganizationScenarios: React.FC<OrganizationScenariosProps> = ({  scenarios, orgId }) => {
    //const data = scenarios;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const filteredScenarios = scenarios.filter(
        scenario => {
            const name = scenario.name ? scenario.name.toLowerCase() : null;
            const description = scenario.description ? scenario.description.toLowerCase() : '';
            const status = scenario.status ? scenario.status.toLowerCase() : '';
            const exerciseType = scenario.exerciseType ? scenario.exerciseType.toLowerCase() : '';
            const category = scenario.category ? scenario.category.toLowerCase() : '';
            const type = scenario.type ? scenario.type.toLowerCase() : '';
            return name && (name.includes(searchTerm.toLowerCase()) ||
                description.includes(searchTerm.toLowerCase()) ||
                status.includes(searchTerm.toLowerCase()) ||
                type.includes(searchTerm.toLowerCase()) ||
                category.includes(searchTerm.toLowerCase()) ||
                exerciseType.includes(searchTerm.toLowerCase())) && status !== 'archived';
        }
    );

    const [scenarioWalkthroughOpen, setScenarioWalkthroughOpen] = useState(false);
    const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(null);
    function handleScenarioWalkthroughOpen(scenario: Scenario) {
        setSelectedScenario(scenario);
        setScenarioWalkthroughOpen(true);
    }
    function handleScenarioWalkthroughClose() {
        setScenarioWalkthroughOpen(false);
    }

    //const activeData = scenarios.filter(scenario => scenario.status !== 'Archived');
    const archivedData = scenarios.filter(scenario => scenario.status === 'Archived');

    const columns = useMemo<ColumnDef<Scenario>[]>(
        () => [
            {
                header: 'Exercise Information',
                footer: '',
                accessorKey: 'description',
                cell: (props)=>
                    <>
                        <Grid container={true} spacing={1}>
                            <Grid item xs={2}>
                                <Typography variant="subtitle1">
                                <Stack spacing={1} direction={"column"}>
                                    {props.row.original.industry &&
                                        <Chip color="primary" label={props.row.original.industry} size="medium"
                                              variant="outlined"/>
                                    }
                                    {props.row.original.category &&
                                        <Chip color="primary" label={props.row.original.category} size="medium"
                                              variant="outlined"/>
                                    }
                                    {props.row.original.type && props.row.original.type!==props.row.original.category &&
                                        <Chip color="primary" label={props.row.original.type} size="medium"
                                              variant="outlined"/>
                                    }
                                    {props.row.original.eventType &&
                                          <>
                                            <Chip color="primary" label={props.row.original.eventType} size="medium"
                                                  variant="outlined"/>
                                          </>
                                    }
                                    {/*<Typography variant="caption">*/}
                                    {/*    {props.row.original.lastExercised && `Last Exercised: ${props.row.original.lastExercised}`}*/}
                                    {/*</Typography>*/}
                                </Stack>
                            </Typography>
                        </Grid>
                            <Grid item xs={10}>
                                <Grid container={true} spacing={1}>
                                    <Grid item xs={11}>
                                        <Link  component={RouterLink} to={`/organization/scenario/view/${orgId}/${props.row.original.scenarioId}`}>
                                            <Typography variant="h5">{props.row.original.name}</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography variant="caption" color="textSecondary">
                                            {props.row.original.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Stack direction="row" justifyContent={"flex-start"} spacing={1}>
                                            <Button variant={"text"} size={"small"} onClick={() => handleScenarioWalkthroughOpen(props.row.original)}>Walkthrough</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
            },
            {
                header: 'Type',
                footer: '',
                accessorKey: 'exerciseType',
                cell: (props) => {
                    switch (props.getValue()) {
                        case 'Tabletop':
                            return <Chip color="primary" label={props.row.original.exerciseType}  size="medium" variant="outlined" />;
                        case 'Review':
                            return <Chip color="secondary" label={props.row.original.exerciseType}  size="medium" variant="outlined" />;
                        default:
                            return <Chip color="primary" label={"Tabletop"}  size="medium" variant="outlined" />;
                    }
                }
            },
            {
                header: 'Status',
                footer: '',
                accessorKey: 'status',
                cell: (props) => {
                    return getStatusChip(props.getValue() as string);
                }
            }
        ],
        []
    );
    return (
        <>
            <Grid container={true} spacing={1}>
                <Grid item xs={12}>
                    {selectedScenario && <ScenarioWalkthroughDialog scenario={selectedScenario} scenarioOverviewOpen={scenarioWalkthroughOpen} handleScenarioWalkthroughClose={handleScenarioWalkthroughClose} />}

                    <ScenarioTable {...{ data: filteredScenarios, columns, title: '', orgId, searchTerm, handleSearchChange }} />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                    <Button variant="text" color="primary" size={"small"} onClick={handleClickOpen}>
                        View Archived
                    </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>Archived Scenarios</DialogTitle>
                <DialogContent>
                    <ScenarioTable {...{ data: archivedData, columns, title: 'Archived Scenarios',orgId }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default TabOrganizationScenarios;
