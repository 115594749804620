import {
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
    Checkbox, Stack, ButtonGroup, Tooltip
} from "@mui/material";
import {AppUserProfile, Participant} from "../../interfaces/interfaces";
import React, {useEffect} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import {EmailOutlined} from "@mui/icons-material";
import {green} from "@mui/material/colors";
interface CompanyTeamProps {
    participants: Participant[];
    onTeamDelete?: (deletedTeam: Participant) => void;
    onParticipantAttended?: (attendedParticipant: Participant) => void;
    onParticipantNotify?: (notifiedParticipant: Participant) => void;
    userProfile?: AppUserProfile;
}
const ConductParticipantList: React.FC<CompanyTeamProps> = ({ participants,
                                                                onTeamDelete,
                                                                userProfile,
                                                                onParticipantAttended,
                                                                onParticipantNotify }) => {
    const [updatedTeam, setUpdatedTeam] = React.useState(participants);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [teamToDelete, setTeamToDelete] = React.useState<Participant | null>(null);

    useEffect(() => {
        setUpdatedTeam(participants);
    }, [participants]);

    const handleDeleteClick = (team: Participant) => {
        setTeamToDelete(team);
        setOpenDialog(true);
    };

    // Handle notify button
    const handleNotify = (team: Participant) => {
        if (onParticipantNotify) {
            onParticipantNotify(team);
            setNotified(true);
        }
    };
    // Handle delete button
    const handleConfirmDelete = () => {
        if (!teamToDelete) return;

        setUpdatedTeam(updatedTeam.filter(t => t !== teamToDelete));
        if (onTeamDelete) onTeamDelete(teamToDelete);

        // Close the dialog
        setOpenDialog(false);
    };

    const [notified, setNotified] = React.useState(false);


    return (
        <>
        <List component="nav" aria-label="main mailbox folders" sx={{ py: 0, '& .MuiListItem-root': { p: 0, py: 1 } }}>
            {updatedTeam ? (updatedTeam.map((t:Participant) => {
                return (
                    <ListItem divider={true}>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={6}>
                                <Stack direction={"column"} spacing={0} justifyContent={"flex-start"} >
                                <ListItemText primary={`${t.name}`} secondary={ (t.role ? ` ${t.role}` : ' Participant')} />
                                <Typography variant="caption" color={"textSecondary"}>{t.email}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item={true} xs={6}>
                                {onParticipantAttended &&
                                    <>
                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                                            <Typography variant="caption"><Checkbox
                                                checked={t.attended}
                                                onChange={() => onParticipantAttended(t)}
                                            />
                                                Attended</Typography>
                                        </Stack>
                                    </>
                                }
                                {onTeamDelete &&
                                    <>
                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
                                            <ButtonGroup>
                                                {userProfile && userProfile.email !== t.email &&
                                                <Tooltip title={"Remove user from this tabletop"} placement={"top"}>
                                                    <Button onClick={() => handleDeleteClick(t)}>
                                                         <DeleteIcon />
                                                    </Button>
                                                </Tooltip>
                                                }
                                            {onParticipantNotify && t.email && userProfile && userProfile.email !== t.email &&
                                                <Tooltip title={"Notify user"} placement={"top"}>
                                                <Button onClick={() => handleNotify(t)}>
                                                    {notified ? <Checkbox checked={true} style={{ color: green[500] }} /> : <EmailOutlined />}
                                                </Button>
                                                </Tooltip>
                                            }
                                            </ButtonGroup>
                                        </Stack>
                                    </>
                                }

                            </Grid>
                        </Grid>
                    </ListItem>

                );
            }) ) : (
                <Grid item xs={12}  sm={12} md={12} lg={12}>
                    <Card >
                        <CardContent>
                            <Typography variant="caption">No Team</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </List>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this team member?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={handleConfirmDelete} color="primary">Delete</Button>
                    <Button variant={"contained"} onClick={() => setOpenDialog(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            </>
    );
};
export default ConductParticipantList;