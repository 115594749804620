// material-ui
// import { Theme } from '@mui/material/styles';
import {
    Chip, Grid, Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow, Typography
} from '@mui/material';

import { Company} from "../../interfaces/interfaces";
import React, {useMemo} from "react";
import {flexRender, useReactTable, ColumnDef, getCoreRowModel, HeaderGroup} from '@tanstack/react-table';
import ScrollX from "../ScrollX";
import { LabelKeyObject } from 'react-csv/lib/core';
import {Link as RouterLink} from "react-router-dom";

interface CompanyAssetsProps {
    token: string | null;
    companies: Company[];
}

interface ThreatReactTableProps {
    columns: ColumnDef<Company>[];
    data: Company[];
    title?: string;
}
function TabCompanies({ columns, data, title }: ThreatReactTableProps) {

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });

    let headers: LabelKeyObject[] = [];
    table.getAllColumns().map((columns) =>
        headers.push({
            label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
            // @ts-ignore
            key: columns.columnDef.accessorKey
        })
    );

    return (
        <>
            <ScrollX>
                <TableContainer component={Paper}>
                    <Table size="medium">
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableCell key={header.id} {...header.column.columnDef.meta}>
                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ScrollX>
        </>
    );
}


const CompanyTableView: React.FC<CompanyAssetsProps> = ({  token, companies }) => {
    const data = companies;

    // @ts-ignore
    const columns = useMemo<ColumnDef<Company>[]>(
        () => [
            {
                header: 'Client',
                footer: '',
                accessorKey: 'description',
                cell: (props)=>
                        <Grid container={true} spacing={1}>
                            <Grid item xs={12}>
                                <Link  component={RouterLink} to={`/company/viewcompany/${props.row.original.companyId}`}>
                                <Typography variant="h5">{props.row.original.name}
                                    <Typography variant="caption" color="textSecondary">
                                      &nbsp; ({props.row.original.domain})
                                    </Typography>
                                </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={11}>
                                <Grid container={true} spacing={1}>
                                    <Grid item xs={12}>
                                    <Typography variant="body1" color="textSecondary">
                                        {props.row.original.description}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
            },
            {
                header: 'Industry',
                footer: '',
                accessorKey: 'industry',
                cell: (props)=>
                    <>
                        {props.row.original.industry &&
                            <Chip label={props.row.original.industry} size={"medium"} color={"primary"} variant="outlined" />
                        }
                    </>
            }
        ],
        []
    );
    return <TabCompanies {...{ data, columns }} />;
};
export default CompanyTableView;
