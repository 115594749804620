import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import { ScenarioStory} from "../../interfaces/interfaces";
import ScrollX from "../ScrollX";
import {useOrganization} from "../../hooks/useOrganization";
import OrganizationBrandingImageDisplay from "../organization/OrganizationBrandingImageDisplay";

interface ScenarioOverviewDialogProps {
    title: string;
    objectives?: string;
    agenda?: string;
    instructions?: string;
    scenarioStory?: ScenarioStory;
    scenarioOverviewOpen: boolean;
    handleScenarioOverviewClose: () => void;
}

const ScenarioOverviewDialog: React.FC<ScenarioOverviewDialogProps> = ({ title, objectives,
                                                                           instructions, scenarioStory, agenda, scenarioOverviewOpen, handleScenarioOverviewClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const organization = useOrganization();

    const handleClose = () => {
        setCurrentStep(0);
        handleScenarioOverviewClose();
    };
    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return {
                    title: 'Agenda',
                    content: agenda ? agenda.split('\n').map((line: string, index: number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : 'No agenda provided'
                };
            case 1:
                return {
                    title: 'Instructions',
                    content: instructions ? instructions.split('\n').map((line: string, index: number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : 'No instructions provided'
                };
            case 2:
                return {
                    title: 'Background',
                    content: scenarioStory && scenarioStory?.storyBackground ? scenarioStory.storyBackground.split('\n').map((line: string, index: number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : 'No background provided'
                };
            case 3:
                return {
                    title: 'Overview',
                    content: scenarioStory && scenarioStory?.storyOverview ? scenarioStory.storyOverview.split('\n').map((line: string, index: number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : 'No overview provided'
                };
            case 4:
                return {
                    title: 'Objectives',
                    content: objectives ? objectives.split('\n').map((line: string, index: number) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    )) : 'No objectives provided'
                };
            default:
                return null;
        }
    };
    const stepContent = renderStepContent();
    //need the current date formated as mm/dd/yyyy
    const currentDate = new Date();

    return (
    <Dialog fullScreen open={scenarioOverviewOpen} onClose={handleClose}>
        <Card sx={{ border: '1px solid #000', overflow: 'auto' }}>
                <CardContent >
                    <Grid container={true} spacing={2}>
                        <Grid item xs={1} sx={{ backgroundColor: organization.organization?.brandHexColor ? organization.organization?.brandHexColor : undefined }}>
                            {organization && organization.organization?.logoSmallLocation &&
                                <OrganizationBrandingImageDisplay location={organization.organization.logoSmallLocation} />
                            }
                        </Grid>
                        <Grid item={true} xs={10}>
                            <Box sx={{ flexGrow: 1 }}>
                            <Grid container={true} spacing={2} >
                                <Grid item={true} xs={12}>
                            <Typography variant="h1"  gutterBottom>
                                {title}
                            </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                            <Divider/>
                                </Grid>
                                <Grid item={true} xs={12}>
                            <Typography variant="h2" gutterBottom>
                                {stepContent?.title}
                            </Typography>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Box style={{ minHeight: '700px', overflow: 'auto' }}>
                                        <ScrollX>
                                        <Typography variant="h4" gutterBottom>
                                            {stepContent?.content}
                                        </Typography>
                                        </ScrollX>
                                    </Box>
                                </Grid>
                            </Grid>
                            </Box>
                        </Grid>
                        <Grid item={true} xs={1}>
                            <Typography variant="h5"  gutterBottom>
                                {currentDate.toLocaleDateString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <DialogActions>
                <Stack spacing={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button variant={"contained"} disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
                        Previous
                    </Button>
                    <Button variant={"contained"} disabled={currentStep === 4} onClick={() => setCurrentStep(currentStep + 1)}>
                        Next
                    </Button>
                    <Button onClick={handleClose} color="primary" variant={"contained"}>
                        Close
                    </Button>
                </Stack>

            </DialogActions>
        </Dialog>
    );
};

export default ScenarioOverviewDialog;