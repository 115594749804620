import Box from "@mui/material/Box";
import {LinearProgress, Typography} from "@mui/material";
import React from "react";

interface QuickLoaderComponentProps {
    loadBanner: string;
}
const QuickLoaderComponent : React.FC<QuickLoaderComponentProps> = ({ loadBanner }) => {

return (

    <>
        <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>
        <Typography variant="h6">
            {loadBanner}
        </Typography>
    </>
);
};

export default QuickLoaderComponent;