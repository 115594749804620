
import { useState, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface SelectRoleTypeProps {
    onChange: (event: string) => void;
    //... any other props required by SelectRoleType component
}
const SelectRoleType: React.FC<SelectRoleTypeProps> = ({ onChange }) => {
  const [roletypes, setRoleTypes] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState('');
    const token = localStorage.getItem('token');

    useEffect(() => {
        // call api or anything
        fetchData();
    }, []);

    const fetchData = async () => {
      try {
          const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/utils/team/getroletypes/' + token);
          // console.log(response);
          const data = await response.json();
          setRoleTypes(data);
          // console.log(data);
      } catch (error) {
          console.error(error);
      }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-helper-label">Participant's Role</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={selectedValue}
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>Select Role Type</em>
        </MenuItem>
        {roletypes.map((roletype,item) => (
          <MenuItem key={"roletype_"+item} value={roletype}>{roletype}</MenuItem>
        ))}
      </Select>
      {/*<FormHelperText>helper text</FormHelperText>*/}
    </FormControl>
  );
};

export default SelectRoleType;