import React, {useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import {ScriptItem} from "../../interfaces/interfaces";
import {Box, Button, Grid, Stack, Tooltip} from '@mui/material';
import {ForkRightOutlined, VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";

interface ScriptItemProps {
    script: ScriptItem[];
    orientation?: 'vertical' | 'horizontal';
    itemSelectedCallback?: (scriptId: string, actionId: string) => void;
    itemsVisible?: boolean;
}

const ConductScriptItemTimeline: React.FC<ScriptItemProps> = ({ script, orientation = 'vertical', itemSelectedCallback, itemsVisible }) => {
    const uniqueSections = Array.from(new Set(script.map(item => item.section)));
    const [showItems, toggleShowItems] = useState(itemsVisible);

    return (
        <>
            <Box style={{ overflow: 'auto', maxHeight:'500px' }}>
            {orientation === 'horizontal' ?
                (
                <Grid container={true} spacing={1} justifyContent="center" alignItems="center">
                    {uniqueSections.map((section, index) => (
                        <Grid item xs={2} key={index}>
                            <Grid container={true} justifyContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography color="textSecondary">{section}
                                        {/*{index < uniqueSections.length - 1 && <CallToAction/>}*/}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                ) : (
                    <>
                    <Timeline>
                        {script.map((section, index) => (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent>
                                    <Typography color="textSecondary">{section.section?section.section:section.name}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color={section.sectionComplete ? "success" : "primary"} />
                                    {index < uniqueSections.length - 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>
                                    {showItems && section.conductScriptItems && section.conductScriptItems.map((item, itemIndex) => (
                                        <>
                                            <Button onClick={() => itemSelectedCallback?.(section.id, item.id)}>
                                                <Typography key={itemIndex} variant={"body2"} color={item.isSuccess ? "green" : item.isSkipped ? "orange" : item.isFailed ? "red" : "lightgrey"}>
                                                    {item.name}
                                                </Typography>
                                            </Button>
                                            {item.branches && item.branches.length > 0 &&
                                                <>{item.branches.map((branch, branchIndex) => (
                                                        <Grid container spacing={2}>
                                                            <Grid item={true} xs={2}>
                                                            <Typography><ForkRightOutlined fontSize={"small"}/></Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={10}>
                                                            <Typography key={itemIndex} variant={"caption"} color={branch.isSuccess ? "green" : branch.isSkipped ? "orange" : branch.isFailed ? "red" : "lightgrey"}>
                                                                {branch.name} {branch.isComplete}
                                                            </Typography>
                                                            </Grid>
                                                            </Grid>
                                                    ))}</>
                                            }
                                        </>
                                    ))}
                                    {showItems && section.conductScriptItems ?
                                        section.conductScriptItems.map((item, itemIndex) => (
                                        <>
                                            <Button onClick={() => itemSelectedCallback?.(section.id, item.id)}>
                                                <Typography key={itemIndex} variant={"body2"} color={item.isSuccess ? "green" : item.isSkipped ? "orange" : item.isFailed ? "red" : "lightgrey"}>
                                                    {item.name}
                                                </Typography>
                                            </Button>
                                            {item.branches && item.branches.length > 0 &&
                                                <>{item.branches.map((branch, branchIndex) => (
                                                    <Grid container spacing={2}>
                                                        <Grid item={true} xs={2}>
                                                            <Typography><ForkRightOutlined fontSize={"small"}/></Typography>
                                                        </Grid>
                                                        <Grid item={true} xs={10}>
                                                            <Typography key={itemIndex} variant={"caption"} color={branch.isSuccess ? "green" : branch.isSkipped ? "orange" : branch.isFailed ? "red" : "lightgrey"}>
                                                                {branch.name} {branch.isComplete}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}</>
                                            }
                                        </>
                                    )) :
                                        showItems && section.scenarioScriptSectionSteps && section.scenarioScriptSectionSteps.map((item, itemIndex) => (
                                            <>
                                                <Button onClick={() => itemSelectedCallback?.(section.id, item.stepName)}>
                                                    <Typography key={itemIndex} variant={"body2"} color={"lightgrey"}>
                                                        {item.stepName}
                                                    </Typography>
                                                </Button>

                                            </>
                                        ))}
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                        </>
                )
            }
            </Box>
            <Stack direction="row" justifyContent="end" alignItems="flex-end">
                <Tooltip title={showItems ? "Hide items" : "Show items"}>
                <Button variant={"outlined"} size={"small"} onClick={() => toggleShowItems(!showItems)}>{!showItems?<VisibilityOutlined/>:<VisibilityOffOutlined/>}</Button>
                </Tooltip>
            </Stack>
        </>
    );
};

export default ConductScriptItemTimeline;