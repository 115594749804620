import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CardContent } from '@mui/material';
import MainCard from '../MainCard';
import ResourceList from './ResourceList';
import ResourceSelect from './ResourceSelect';
import { ScriptResource } from '../../interfaces/interfaces';
import {AddCircleOutline} from "@mui/icons-material";

interface ResourceCardProps {
    title: string;
    linkedResources: string[];
    resources: ScriptResource[];
    id: string;
    onLinkResource: (resourceId: string, scriptId: string) => void;
    onUnlinkResource: (resourceId: string, scriptId: string) => void;
}

const ResourceCard: React.FC<ResourceCardProps> = ({ title, linkedResources, resources, id, onLinkResource, onUnlinkResource }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <MainCard title={title} secondary={
            <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={handleOpen}>
                Add Resource
            </Button>
        }>
            <CardContent>
                <ResourceList linkedResources={linkedResources} resources={resources} scriptId={id} onUnlinkResource={onUnlinkResource} />
            </CardContent>
            <Dialog open={open} onClose={handleClose} fullWidth={true}>
                <DialogTitle>Select Resource</DialogTitle>
                <DialogContent>
                    <ResourceSelect resources={resources} onLinkResource={onLinkResource} scriptId={id} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </MainCard>
    );
};

export default ResourceCard;