import { FC } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    useTheme,
    Typography, Grid
} from "@mui/material";

interface TipsDialogProps {
    tipsText: string[];
    open: boolean;
    handleClose: () => void;
}

const TipsDialog: FC<TipsDialogProps> = ({ tipsText, open, handleClose }) => {
    const theme = useTheme();

    return (
        <Dialog
            fullWidth={true}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{background: theme.palette.success.main}} >Tips</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container={true} spacing={3}>
                        <Grid item xs={12}></Grid>
                            {tipsText && tipsText.map((tip, index) => {
                                return <Grid item xs={11}><Typography key={index} variant={"body1"} sx={{fontWeight: 600}}>{tip}</Typography></Grid>
                            })}
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="success">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TipsDialog;