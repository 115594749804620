import React, {useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tooltip, Typography
} from '@mui/material';
import {AppUserProfile, ConductFile} from "../interfaces/interfaces";
import {DeleteForever, DownloadOutlined} from "@mui/icons-material";
import DialogContentText from "@mui/material/DialogContentText";

interface FileListProps {
  files: ConductFile[];
  userProfile?: AppUserProfile | null | undefined;
  onDownload?: (file: ConductFile) => void;
  onReview?: (file: ConductFile, reviewed: boolean) => void;
  onDelete?: (file: ConductFile) => void;
  displayIconOnly?: boolean;
  reviewId?: string;
}

const FileList: React.FC<FileListProps> = ({ files, onDelete, onDownload,
                                               onReview, reviewId, displayIconOnly, userProfile }) => {
    const [localFiles, setLocalFiles] = useState<ConductFile[]>([]);

    const [fileText, setFileText] = useState("");
    const [analysisText, setAnalysisText] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<ConductFile | null>(null);
    const handleViewFile = (fileText: string, analysisText: string) => {
        setFileText(fileText);
        setAnalysisText(analysisText);
        setOpenDialog(true);
    };

    useEffect(() => {
        setLocalFiles(files);
    }, [files]);

    const handleOpenConfirmDialog = (file:ConductFile) => {
        setFileToDelete(file);
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        if (fileToDelete) {
            onDelete && onDelete(fileToDelete);
        }
        setOpenConfirmDialog(false);
    };

  return (
      <>
      <List>
        {localFiles && localFiles.map((file) => (
            file && !displayIconOnly &&
            (<ListItem key={file.fileId}>
                <ListItemText
                    primary={file.shortName}
                    secondary={
                        <div>
                            <div>{`Description: ${file.description}`}</div>
                            <div>{`Type: ${file.type}`}</div>
                            <div>{`Name: ${file.fileName}`}</div>
                        </div>
                    }
                />
                <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                    <ButtonGroup size={"small"}>
                        {!file.fileLocation &&
                            <Tooltip title={"View the file"} arrow>
                                <Button
                                    onClick={() => handleViewFile(file.fileText, file.analysisText)}><DownloadOutlined/></Button>
                            </Tooltip>
                        }
                        {onDownload && file.fileLocation &&
                            <Tooltip title={"Download the file"} arrow>
                                <Button onClick={() => onDownload(file)}><DownloadOutlined/></Button>
                            </Tooltip>
                        }
                        {onDelete &&
                            <Tooltip title={"Delete the file"} arrow>
                                <Button onClick={() => handleOpenConfirmDialog(file)}><DeleteForever/></Button>
                            </Tooltip>
                        }
                    </ButtonGroup>
                    {onReview &&
                        <Tooltip title={"Review the file"} arrow>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => onReview(file, event.target.checked)}                                    />
                                }
                                label="Reviewed"
                            />
                        </Tooltip>
                    }
                </Stack>
            </ListItem>)
        ))}

          {localFiles && localFiles.map((file) => (
              file && displayIconOnly &&
                  <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                      <ButtonGroup size={"small"}>
                          {!file.fileLocation &&
                          <Tooltip title={"View the file"} arrow>
                              <Button
                                  onClick={() => handleViewFile(file.fileText, file.analysisText)}><DownloadOutlined/></Button>
                          </Tooltip>
                          }
                          {onDownload && file.fileLocation &&
                          <Tooltip title={"Download the file"} arrow>
                              <Button onClick={() => onDownload(file)}><DownloadOutlined/></Button>
                          </Tooltip>
                          }
                          {onDelete &&
                          <Tooltip title={"Delete the file"} arrow>
                              <Button onClick={() => handleOpenConfirmDialog(file)}><DeleteForever/></Button>
                          </Tooltip>
                          }
                      </ButtonGroup>
                  </Stack>
          ))}
      </List>

          <Dialog
              open={openConfirmDialog}
              fullWidth={true}
              onClose={handleCloseConfirmDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this file?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button variant={"contained"} onClick={handleConfirmDelete} color="primary" autoFocus>
                      Delete
                  </Button>
                  <Button variant={"contained"} onClick={handleCloseConfirmDialog} color="primary">
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>

    <Dialog fullWidth={true} open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>File Text</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Typography variant="caption" color="textSecondary">Summary</Typography>
                <pre style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>
            {analysisText.split('\n').map((text, index) => (
                <React.Fragment key={index}>
                    {text}
                    <br/>
                </React.Fragment>
            ))}
            </pre>
                <Typography variant="caption" color="textSecondary">Asset Content</Typography>
                <pre style={{whiteSpace: "pre-wrap", wordWrap: "break-word"}}>
            {fileText.split('\n').map((text, index) => (
                <React.Fragment key={index}>
                    {text}
                    <br/>
                </React.Fragment>
            ))}
            </pre>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} onClick={() => setOpenDialog(false)} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
    </>
  );
};

export default FileList;