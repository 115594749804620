import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import {
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography
} from "@mui/material";
import {SendOutlined} from "@ant-design/icons";
import { GameMessage, Scenario} from "../../interfaces/interfaces";
import SimulationChatHistory from "./SimulationChatHistory";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";

interface Props {
  scenario: Scenario;
  chatId: string | null;
}
const ScenarioSimulationChatComponent : React.FC<Props> = ({ scenario, chatId})=> {
  const [conversation, setConversation] = useState<GameMessage[]>([]);
  // const [listMessages, setListMessages] = useState<GameMessage[]>([]);

  const [message, setMessage] = useState("");
  // const textAreaRef = useAutoResizeTextArea();
  const bottomOfChatRef = useRef<HTMLDivElement>(null);
  const token = localStorage.getItem('token');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [simChatBanner, setSimChatBanner] = useState<string>("");

  // useEffect(() => {
  //   // console.log("ScenarioSimulationChatComponent...fetching "+chatId);
  //   fetchData(); // Call the fetchData function when latestMessage changes
  // }, [latestMessage]);

  useEffect(() => {
    if (bottomOfChatRef.current) {
     // bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  //used for when the component is "reloaded"
  useEffect(() => {
      fetchData();
  }, [chatId]);

  const fetchData = async () => {
    // console.log(id);
    try {
        setLoading(true);
        setSimChatBanner("Simulation being generated....");
        const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/games/startresumesimulatechat/'+token+'/'+scenario.companyId+'/'+chatId);
      const data = await response.json();
      setConversation(data);
      setSimChatBanner("");
        setLoading(false);

        // console.log(data);
    } catch (error) {
      //console.error(error);
    }
  };

  const sendMessage = async (e: any) => {
    e.preventDefault();

    // Clear the message & remove empty chat
    setMessage("");
    // setShowEmptyChat(false);

    try {
        setLoading(true);
        setSimChatBanner("Sim Assistant is responding to your request....");
      const callURL = process.env.REACT_APP_REST_API_URL+'/api/tabletop/scenario/simulation/assistant/message/'+token+'/'+scenario.companyId+'/'+chatId;
      const response = await fetch(callURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({  text: message}),

      });

      if (response.ok) {
        //fetchData();
         const data = await response.json();
          setConversation(data);
      } else {
        console.error(response);
      }
        setLoading(false);
        setSimChatBanner("");
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleKeypress = (e: any) => {
    // It's triggers by pressing the enter key
    if (e.keyCode == 13 && !e.shiftKey) {
      sendMessage(e);
      e.preventDefault();
    }
  };

  return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', minHeight: '500px' }}>
        <Grid container spacing={3} justifyContent="center" sx={{ padding: '0 8px' }}>
            {isLoading &&
                <QuickLoaderComponent loadBanner={simChatBanner} />
            }
            <Grid item xs={12} >
                <OutlinedInput
                    id={`outlined-message${scenario.chatId}`}
                    fullWidth
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    ref={bottomOfChatRef}
                    onKeyDown={handleKeypress}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={sendMessage}
                                edge="end"
                            >
                                <SendOutlined />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Grid>
        <Grid item xs={12}  sx={{ marginBottom: '8px' }}>
            <SimulationChatHistory chat={conversation} />
        </Grid>
        </Grid>
        <Typography variant="caption">
          {chatId}
        </Typography>
      </Box>
  );
};

export default ScenarioSimulationChatComponent;
