import React from 'react';
import { Drawer, Grid, DialogActions, Button, TextField, Select, MenuItem, FormControlLabel, Checkbox, Chip, Divider, Typography } from "@mui/material";
import {Conduct} from "../../interfaces/interfaces";
import MainCard from "../MainCard";

interface ScheduleDrawerProps {
    open: boolean;
    onClose: () => void;
    conduct: any;
    handleDialogUpdate: () => void;
    setConduct: (conduct: Conduct) => void;
}

const ScheduleDrawer: React.FC<ScheduleDrawerProps> = ({ open, onClose, conduct, handleDialogUpdate, setConduct }) => {
    const drawerWidth = 450;

    return (
        <Drawer open={open} onClose={onClose}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    overflow: 'auto',
                },
            }}
            anchor="left"
            variant={"temporary"}
        >
            <MainCard title="Tabletop Scheduling - Date, Location and Notifications" sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    overflow: 'auto',
                },
            }} >
                <Grid container={true} spacing={2} >
                    <Grid item xs={12}>
                        {conduct &&
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <HoverSocialCard primary={scenario?.description} secondary={"Scheduling Instructions"} color={theme.palette.grey.A400} />*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" >Scheduled Date & Time + Duration</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id="conductScheduledDate"
                                                            placeholder="Scheduled Date & Time"
                                                            type="datetime-local"
                                                            fullWidth
                                                            value={conduct.scheduledDate}
                                                            onChange={(e) => setConduct({ ...conduct, scheduledDate: e.target.value })}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            id="conductScheduleDuration"
                                                            placeholder="Tabletop Duration"
                                                            fullWidth
                                                            type={"number"}
                                                            value={conduct?.timeDuration}
                                                            onChange={(e) => setConduct({ ...conduct, timeDuration: parseInt(e.target.value) })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Select
                                                            value={conduct?.timeDurationUnit}
                                                            onChange={(e) => setConduct({ ...conduct, timeDurationUnit: e.target.value })}
                                                            fullWidth
                                                        >
                                                            <MenuItem value={"Hours"}>Hours</MenuItem>
                                                            <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                            <MenuItem value={"Days"}>Days</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" >Location Info</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="dense"
                                                        id="location"
                                                        label="Tabletop Location"
                                                        fullWidth
                                                        value={conduct?.location}
                                                        onChange={(e) => setConduct({ ...conduct, location: e.target.value })}
                                                    />
                                                </Grid>
                                                {/*<Grid item xs={12}>*/}
                                                {/*    <Typography variant="caption" color="primary">Location can be Zoom/Teams link, conference room.  Will be included in notification & After-Action Report</Typography>*/}
                                                {/*</Grid>*/}
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={conduct.isRemote}
                                                                onChange={(event) => setConduct({ ...conduct, isRemote: event.target.checked })}
                                                            />
                                                        }
                                                        label="Remote Tabletop?"
                                                    />
                                                </Grid>
                                                {/*<Grid item xs={12}>*/}
                                                {/*    <Typography variant="caption" color="primary">Check if tabletop is to be conducted via Zoom/Teams...</Typography>*/}
                                                {/*</Grid>*/}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" >Agenda</Typography>
                                                </Grid>
                                                <TextField
                                                    placeholder="Agenda"
                                                    value={conduct.agenda}
                                                    fullWidth={true}
                                                    multiline={true}
                                                    rows={4}
                                                    onChange={(e) => setConduct({ ...conduct, agenda: e.target.value })}
                                                />
                                            </Grid>
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <Grid item xs={12}>*/}
                                            {/*        <Typography variant="caption" >Notes</Typography>*/}
                                            {/*    </Grid>*/}
                                            {/*    <Grid item xs={12}>*/}
                                            {/*        <TextField*/}
                                            {/*            margin="dense"*/}
                                            {/*            id="notes"*/}
                                            {/*            label="Tabletop Notes"*/}
                                            {/*            fullWidth*/}
                                            {/*            multiline={true}*/}
                                            {/*            rows={3}*/}
                                            {/*            value={conduct?.notes}*/}
                                            {/*            onChange={(e) => setConduct({ ...conduct, notes: e.target.value })}*/}
                                            {/*        />*/}
                                            {/*    </Grid>*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container={true} spacing={2}>
                                                    <Grid item xs={6}>
                                                        {conduct && (!conduct.participants || conduct.participants.length === 0) ?
                                                            <Chip size={"small"}  label="No Participants" color="warning" /> : <Chip size={"small"} label={`${conduct.participants.length} Participants Added`} color="success" />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" >Participant Notification</Typography>
                                                    </Grid>
                                                    {/*   <Stack direction="row" justifyContent="flex-end" spacing={1}>*/}
                                                    {/*    <ButtonGroup>*/}
                                                    {/*        <Button size={"small"} variant={"outlined"}  onClick={handleOpenAddParticpantDialog}>*/}
                                                    {/*            <AddOutlined  />  Add Participant*/}
                                                    {/*        </Button>*/}
                                                    {/*    </ButtonGroup>*/}
                                                    {/*</Stack>*/}

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={conduct.sendNotification}
                                                                onChange={(event) => setConduct({ ...conduct, sendNotification: event.target.checked })}
                                                            />
                                                        }
                                                        label="Send Scheduling Notification?"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="primary">If checked, defined participants will be sent a notification with the Tabletop details & scheduled time</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={conduct.sendReminder}
                                                                onChange={(event) => setConduct({ ...conduct, sendReminder: event.target.checked })}
                                                            />
                                                        }
                                                        label="Send Reminder?"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="primary">If checked, reminder will be sent to participants 1 day before scheduled tabletop</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            <Button variant={"outlined"} onClick={handleDialogUpdate} color="primary">Schedule</Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </MainCard>
        </Drawer>
    );
};

export default ScheduleDrawer;