import React from 'react';
import {Chip} from "@mui/material";
import {Flag} from "@mui/icons-material";


interface FlaggedComponentProps {
    flag: string | null;
}

const FlaggedComponent: React.FC<FlaggedComponentProps> = ({ flag }) => {
    if (flag) {
        return <Chip icon={<Flag />} label={flag} color="primary" size={"small"} />;
    }
    return null;
};

export default FlaggedComponent;