import React, { useState, useEffect } from 'react';
import { Runbook} from "../../interfaces/interfaces";
import {InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface RunbookListProps {
    companyId: string;
    label: string;
    onRunbookSelected: (risk: string) => void;
}
const RunbookListComponent: React.FC<RunbookListProps> = ({  companyId, label, onRunbookSelected }) => {
    const [selectedScenario, setSelectedScenario] = useState("");
    const [runbooks, setRunbooks] = useState<Runbook[] | null>(null);
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/company/getrunbooks/'+token+'/'+companyId);
                const data = await response.json();
                setRunbooks(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const handleInputChange = (e: SelectChangeEvent) => {
        setSelectedScenario( e.target.value);
        onRunbookSelected(e.target.value);
    };

  return (
      <>
          <InputLabel id="risk-select-list">{label}</InputLabel>
          <Select
              name="risk-select-list"
              id={"risk-select-list"}
              variant={"outlined"}
              fullWidth
              value={selectedScenario}
              onChange={handleInputChange}
          >
              <MenuItem value="">
                  <em>Select Risk Type</em>
              </MenuItem>
              {runbooks && runbooks.map((runbook,item) => (
                  <MenuItem key={runbook.runbookId} value={runbook.runbookId}>{runbook.name}</MenuItem>
              ))}
          </Select>
      </>

  );
};

export default RunbookListComponent;