import { useState } from 'react';

// material-ui
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, TextField
} from '@mui/material';

// assets
import {  LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {PasswordOutlined} from "@mui/icons-material";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
    const [openChangePwd, setOpenChangePwd] = useState(false);
    const [oldPwdValue, setOldPwdValue] = useState('');
    const [newPwdValue, setNewPwdValue] = useState('');
    const [confirmPwdValue, setConfirmPwdValue] = useState('');

    function openChangePassword() {
        setOpenChangePwd(true);
    }

    function handleCloseChangePwd() {
        setOpenChangePwd(false);
        setOldPwdValue('');
        setNewPwdValue('');
        setConfirmPwdValue('');
    }

    async function handleUpdateChangePwd() {
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/auth/changepassword`;
        try {
            const pwdRequest = {
                token: localStorage.getItem("token"),
                newPassword: newPwdValue,
                oldPassword: oldPwdValue,
                confirmNewPassword: confirmPwdValue
            };
             await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(pwdRequest),
            });

        } catch (error) {
            console.error("Error changing password:", error);
        }
        handleCloseChangePwd()
    }

    return (
        <>
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
        <ListItemButton >
            <ListItemIcon>
                <UserOutlined />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" />
        </ListItemButton>
      <ListItemButton  onClick={openChangePassword}>
        <ListItemIcon>
          <PasswordOutlined />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      <ListItemButton  onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
            <Dialog fullWidth={true} maxWidth={"lg"} open={openChangePwd} onClose={handleCloseChangePwd}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            name="oldPasswordValue"
                            fullWidth
                            type="password"
                            placeholder="Old Password"
                            variant="outlined"
                            onChange={(e) => setOldPwdValue(e.target.value)}
                        />
                        <TextField
                            name="newPasswordValue"
                            fullWidth
                            type="password"
                            placeholder="New Password"
                            variant="outlined"
                            onChange={(e) => setNewPwdValue(e.target.value)}
                        />
                        <TextField
                            name="confirmPasswordValue"
                            type="password"
                            fullWidth
                            placeholder="Confirm New Password"
                            variant="outlined"
                            onChange={(e) => setConfirmPwdValue(e.target.value)}
                        />
                    </FormControl>
                    <DialogActions>
                        <Button variant={"contained"} onClick={handleCloseChangePwd}>Cancel</Button>
                        <Button  variant={"contained"} onClick={handleUpdateChangePwd} color="primary">Update</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
    </>
  );
};

export default ProfileTab;
