import React from 'react';
import { OpenInNewOutlined } from "@mui/icons-material";
import {Stack, Typography} from "@mui/material";

interface LandingPageLinkProps {
    landingPageCode: string;
}

const LandingPageLink: React.FC<LandingPageLinkProps> = ({ landingPageCode }) => {
    return (
        <>
            <Stack direction={"row"} spacing={1} alignItems={"center"} >
                <Typography variant="h6">View Landing Page</Typography>
                    <a href={`/landing-conduct/${landingPageCode}`} target="_blank" rel="noopener noreferrer">
                        <OpenInNewOutlined color={"primary"}/>
                    </a>
            </Stack>
            </>
    );
};

export default LandingPageLink;