import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Button,
    Stack,
    DialogTitle, Divider, Chip
} from '@mui/material';
import {AppUserProfile, Conduct, UserLinkKey} from "../../interfaces/interfaces";
import LandingPageLink from "../tabletop/LandingPageLink";
import ScenarioParticipantAddDialog from "./ScenarioParticipantAddDialog";

interface ScheduleConductDialogProps {
    scheduleConductOpen: boolean;
    handleScheduleConductClose: () => void;
    handleUpdateConduct: (conduct: Conduct | null) => void;
    conduct: Conduct;
    userProfile: AppUserProfile | null | undefined;
}

const ScheduleConductDialog: React.FC<ScheduleConductDialogProps> = ({ scheduleConductOpen, handleScheduleConductClose,
                                                                         handleUpdateConduct, conduct, userProfile
                                                                          }) => {

    const [updatedConduct, setUpdatedConduct] = useState<Conduct | null>(null);

    useEffect(() => {
        setUpdatedConduct(conduct);
    }, [conduct]);

    const [openAddParticipants, setOpenAddParticipants] = useState<boolean>(false);
    const [portalUsers, setPortalUsers] = useState<UserLinkKey[]>([]);

    function handleOpenAddParticpants() {
        setOpenAddParticipants(true);
        setPortalUsers([]);
    }

    function handleRemoveParticipant(participantId: string) {
        //update updateConduct and remove participant from the array; check nulls on participantId and all arrays
        console.log("Removing participant: " + participantId);
        let newParticipants = updatedConduct?.participants ? updatedConduct.participants.filter((participant) => participant.participantId !== participantId) : [];
        if (updatedConduct) {
            setUpdatedConduct({...updatedConduct, participants: newParticipants});
        }
    }

    function handleAddParticpant(name: string, email: string, role: string) {
        //update updatedConduct with new participant; create a new Participant object, create the array in updatedConduct if it doesn't exist and push the new one in
        let randomString = Math.random().toString(36).substring(2);
        let newParticipant = {
            participantId: randomString,
            name: name,
            email: email,
            role: role,
            attended: false
        }
        let newParticipants = updatedConduct?.participants ? updatedConduct.participants : [];
        newParticipants.push(newParticipant);
        if (updatedConduct) {
            setUpdatedConduct({...updatedConduct, participants: newParticipants,});
        }
    }

    function handleScheduleConductSave() {
        if (updatedConduct) {
            handleUpdateConduct(updatedConduct);
        }
        handleScheduleConductClose();
    }

    return (
        <Dialog fullWidth={true} open={scheduleConductOpen} onClose={() => handleScheduleConductClose}>
            <DialogTitle>
                <Grid container={true} spacing={2} >
                    <Grid item xs={8}> Schedule Tabletop: {conduct.name}</Grid>
                <Grid item xs={4}>
                    <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"} >

                    {conduct && conduct.landingPageCode &&
                        <LandingPageLink landingPageCode={conduct.landingPageCode}/>
                    }
                    </Stack>
                </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container={true} spacing={2} >
                    <Grid item xs={12}>
                        {updatedConduct &&
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <HoverSocialCard primary={scenario?.description} secondary={"Scheduling Instructions"} color={theme.palette.grey.A400} />*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" >Scheduled Date & Time + Duration</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            id="conductScheduledDate"
                                                            placeholder="Scheduled Date & Time"
                                                            type="datetime-local"
                                                            fullWidth
                                                            value={updatedConduct.scheduledDate}
                                                            onChange={(e) => setUpdatedConduct({ ...updatedConduct, scheduledDate: e.target.value })}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <TextField
                                                            id="conductScheduleDuration"
                                                            placeholder="Tabletop Duration"
                                                            fullWidth
                                                            type={"number"}
                                                            value={updatedConduct?.timeDuration}
                                                            onChange={(e) => setUpdatedConduct({ ...updatedConduct, timeDuration: parseInt(e.target.value) })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Select
                                                            value={updatedConduct?.timeDurationUnit}
                                                            onChange={(e) => setUpdatedConduct({ ...updatedConduct, timeDurationUnit: e.target.value })}
                                                            fullWidth
                                                        >
                                                            <MenuItem value={"Hours"}>Hours</MenuItem>
                                                            <MenuItem value={"Minutes"}>Minutes</MenuItem>
                                                            <MenuItem value={"Days"}>Days</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" >Agenda</Typography>
                                                </Grid>
                                                <TextField
                                                    placeholder="Agenda"
                                                    value={updatedConduct.agenda}
                                                    fullWidth={true}
                                                    multiline={true}
                                                    rows={6}
                                                    onChange={(e) => setUpdatedConduct({ ...updatedConduct, agenda: e.target.value })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" >Location Info</Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        margin="dense"
                                                        id="location"
                                                        label="Tabletop Location (Zoom/Teams link and/or physical location)"
                                                        fullWidth
                                                        value={updatedConduct?.location}
                                                        onChange={(e) => setUpdatedConduct({ ...updatedConduct, location: e.target.value })}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} alignItems={"center"} >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={updatedConduct.isRemote}
                                                                onChange={(event) => setUpdatedConduct({ ...updatedConduct, isRemote: event.target.checked })}
                                                            />
                                                        }
                                                        label="Remote?"
                                                    />
                                                    </Stack>
                                                </Grid>
                                                </Grid>
                                                {/*<Grid item xs={12}>*/}
                                                {/*    <Typography variant="caption" color="primary">Check if tabletop is to be conducted via Zoom/Teams...</Typography>*/}
                                                {/*</Grid>*/}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container={true} spacing={2}>
                                                    <Grid item xs={6}>
                                                        {updatedConduct && (!updatedConduct.participants || updatedConduct.participants.length === 0) ?
                                                            <Chip size={"small"}  label="No Participants" color="warning" /> : <Chip size={"small"} label={`${updatedConduct.participants.length} Participants Added`} color="success" />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
                                                            <Button variant={"contained"} onClick={handleOpenAddParticpants}>Manage Participants</Button>
                                                            <ScenarioParticipantAddDialog open={openAddParticipants}
                                                                                          userProfile={userProfile}
                                                                                          onClose={() => setOpenAddParticipants(false)}
                                                                                          userLinks={portalUsers}
                                                                                          participants={updatedConduct?.participants}
                                                                                          onRemove={handleRemoveParticipant}
                                                                                          onAdd={handleAddParticpant}
                                                                                          conduct={updatedConduct}
                                                                                          />
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="caption" >Participant Notification</Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={updatedConduct.sendNotification}
                                                                onChange={(event) => setUpdatedConduct({ ...updatedConduct, sendNotification: event.target.checked })}
                                                            />
                                                        }
                                                        label="Send Scheduling Notification?"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="primary">If checked, defined participants will be sent a notification with the Tabletop details & scheduled time</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={updatedConduct.sendReminder}
                                                                onChange={(event) => setUpdatedConduct({ ...updatedConduct, sendReminder: event.target.checked })}
                                                            />
                                                        }
                                                        label="Send Reminder?"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="caption" color="primary">If checked, reminder will be sent to participants 1 day before scheduled tabletop</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
                <DialogActions>
                    <Stack direction={"row"} spacing={1} justifyContent="flex-end">
                        <Button variant={"contained"} onClick={handleScheduleConductSave}>
                            Save
                        </Button>
                        <Button variant={"contained"} onClick={handleScheduleConductClose}>
                            Close
                        </Button>
                    </Stack>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default ScheduleConductDialog;