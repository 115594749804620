

import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    Grid,
    Stack,
    ListItemIcon,
    Tooltip,
    Chip,
    IconButton
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MainCard from '../MainCard';
import {Conduct, GameMessage, Reaction} from "../../interfaces/interfaces";
import {DownloadRounded, Star} from "@mui/icons-material";
import FlaggedComponent from "./FlaggedComponent";
import TimeElapsedComponent from "./TimeElapsedComponent";

interface ConductTranscriptComponentProps {
    conduct: Conduct | null;
    onAnnotate?: (id: string) => void ;
}

const ConductTranscriptComponent: React.FC<ConductTranscriptComponentProps> = ({ conduct, onAnnotate }) => {
    const downloadCSV = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/files/conduct/download/transcript/' + token + '/' + conduct?.companyId + '/' + conduct?.scenarioId+'/'+conduct?.conductId);
            if (!response.ok) {
                throw new Error("HTTP error " + response.status);
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = conduct?.name+'_script.csv';
            link.click();
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };
    return (
        <MainCard title="Tabletop Transcript" secondary={
            <IconButton onClick={downloadCSV}>
                <DownloadRounded />
            </IconButton>
        }>
            <Card>
                <CardContent>
                    <Typography color="secondary" >
                        <List dense={true}>
                            {conduct?.messages && (conduct.messages.map((msgItem: GameMessage, index: number) => {
                                return (
                                    <>
                                        <ListItem divider>
                                            <Grid container={true} spacing={2} >
                                                <Grid item={true} xs={12}>
                                                {msgItem.conductScriptItem ?
                                                    (
                                                        <Typography variant="h5">
                                                            {msgItem.conductScriptItem.type && <Chip label={msgItem.conductScriptItem.type} color="secondary" size={"small"} /> }
                                                            &nbsp; &nbsp;{msgItem.conductScriptItem.name}</Typography>
                                                    ) : (index===0 && <Typography variant="h5">Exercise Start</Typography>)
                                                }
                                                </Grid>

                                                <Grid item={true} xs={1}> </Grid>
                                                <Grid item={true} xs={9}>
                                                    <Grid container={true} spacing={2}>
                                                        <Grid item={true} xs={12}>
                                                            <Grid container={true} spacing={1}>
                                                                <Grid item={true} xs={12}>
                                                                    {msgItem.answer &&<Typography variant={"body1"}>{msgItem.answer}</Typography>}
                                                                </Grid>
                                                                <Grid item={true} xs={12}>
                                                                    {msgItem.userAudit?.createdDate && <Typography variant={"caption"} color={"textSecondary"} >{msgItem.userAudit.createdByName && <>{msgItem.userAudit.createdDate} - {msgItem.userAudit.createdByName}</>} {msgItem.sentByRole && <>({msgItem.sentByRole})</>}</Typography>}
                                                                    {/*{msgItem.actionMockData && <Chip label={"Action: "+msgItem.actionMockData} color="primary" />}*/}
                                                                </Grid>
                                                                {msgItem.reactions && msgItem.reactions.map((reaction: Reaction, index: number) => {                                                                return (
                                                                    <Grid item={true} xs={12}>{reaction.isFavorite && <Star fontSize={"small"} style={{ color: 'yellow' }} />} </Grid>
                                                                );
                                                                }
                                                                )}
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                                <Grid item={true} xs={2}>
                                                    <Grid container={true} spacing={1}>
                                                        {msgItem.flag &&
                                                            <Grid item={true} xs={12}>
                                                             <FlaggedComponent flag={msgItem.flag} />
                                                            </Grid>
                                                        }
                                                        {msgItem.timeElapsed &&
                                                            <Grid item={true} xs={12}>
                                                                <TimeElapsedComponent timeText={msgItem.timeElapsed} />
                                                            </Grid>
                                                        }
                                                        {onAnnotate &&
                                                            <Grid item={true} xs={12}>
                                                                <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                                                    <ListItemIcon sx={{ mr: 0.5 }}>
                                                                        <Tooltip title={"Annotate"}>
                                                                            <FeedbackIcon onClick={() => onAnnotate(msgItem.id)} />
                                                                        </Tooltip>
                                                                    </ListItemIcon>
                                                                </Stack>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {/*{msgItem.sentByName &&*/}
                                                {/*<><Grid item={true} xs={1}> </Grid>*/}
                                                {/*<Grid item={true} xs={11}>*/}
                                                {/*    {msgItem.sentByName && <Typography variant={"caption"}>{msgItem.sentByName} ({msgItem.sentByRole}</Typography>}*/}
                                                {/*</Grid>*/}
                                                {/*</>*/}
                                                {/*}*/}
                                                <Grid item={true} xs={1}> </Grid>
                                                <Grid item={true} xs={11}>
                                                    {msgItem.annotations && msgItem.annotations.map((annotationItem: any, index: number) => {
                                                        return (
                                                            <>
                                                                <Chip label={`${annotationItem.text}`} color="info" />&nbsp;
                                                            </>);
                                                    })
                                                    }
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </>
                                )
                            }))}
                        </List>
                    </Typography>
                </CardContent>
            </Card>
        </MainCard>
    );
};

export default ConductTranscriptComponent;