// material-ui
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Link,
    Stack, Tab, Tabs, ToggleButton,
    ToggleButtonGroup,
    Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import React, { useEffect, useState} from "react";
import {  Scenario} from "interfaces/interfaces";
import {useParams} from "react-router";
import ScriptItemListComponent from "../../components/tabletop/ScriptItemListComponent";
import ScenarioInjectsTabletopComponent from "../../components/tabletop/ScenarioInjectsTabletopComponent";
import ScenarioSimulationChatComponent from "../../components/scenario/ScenarioSimulationChatComponent";
import ScenarioNotesTabletopComponent from "../../components/tabletop/ScenarioNotesTabletopComponent";
// import SimDifficultyChipComponent from "../../components/tabletop/SimDifficultyChipComponent";
import HoverSocialCard from "../../components/cards/statistics/HoverSocialCard";
import {useTheme} from "@mui/material/styles";
import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";
import PlayPauseStopButtons from "../../components/PlayPauseStopButtons";
import {Link as RouterLink} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import {a11yProps, TabPanel} from "../../hooks/tabPanelHelpers";
import AssessmentIcon from "@mui/icons-material/Assessment";

interface Params {
    scenarioId: string;
    companyId: string;
}


const ScenarioSimulatePage= () => {
    // @ts-ignore
    const { scenarioId, companyId } = useParams<Params>();
    // const [project, setProject] = useState<Project>();
    const [scenario, setScenario] = useState<Scenario>();
    const [difficultyLevel, setDifficultyLevel] = useState<number>(0);
    // const [latestMessage, setLatestMessage] = useState("");
    const token = localStorage.getItem('token');
    const [chatId, setChatId] = useState("");
    const [simulationId, setSimulationid] = useState("");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [simMode, setSimMode] = useState('guided');

    const [tabSelectValue, setTabSelectValue] = useState(0);

    const handleTabSelectChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSelectValue(newValue);
    };
    const handleSimModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setSimMode(newAlignment);
    };
    const handleChatStart = async () => {
        try {
            // setChatId("--Simulation Not Started--");
            setLoading(true);
            setDifficultyLevel(difficultyLevel);
            setChatId("");
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/startnewsimulation/' + token + '/' +
                companyId+ '/' + scenarioId+'/' + difficultyLevel);
            const data = await response.json();
            setChatId(data.gameChatId);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

        useEffect(() => {
            const fetchData = async () => {
                try {
                     // console.log(scenarioId);
                    setLoading(true);
                    const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/scenario/company/getscenario/'+token+ '/' +companyId+'/' + scenarioId);
                    const data = await response.json();
                    setScenario(data);
                    setDifficultyLevel(data.difficultyLevel);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                }
            };
                fetchData();
            }, []);
    const theme = useTheme();

    function handlePause() {

    }

    function handleStop() {
        setSimulationid("abc");
    }

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2}>
            <Grid item xs={12}>
                <Grid container={true} spacing={2} >
                    <Grid item xs={4} >
                        <HoverSocialCard primary={"Facilitate a simualted tabletop exercise"} secondary={"Simulate Scenario"} color={theme.palette.primary.light} />
                    </Grid>
                    <Grid item xs={6} >
                        <HoverSocialCard primary={scenario?.type+" Scenario"} secondary={scenario?.name} color={theme.palette.primary.light} />
                    </Grid>
                    {/*<Grid item xs={6} >*/}
                    {/*    <BoomComponent color={theme.palette.background.paper} leftOrRight={scenario?.leftOrRightOfBoom} leftCoverage={(scenario?.leftOfBoomPercent ? scenario.leftOfBoomPercent:0)} rightCoverage={(scenario?.rightOfBoomPercent ? scenario.rightOfBoomPercent:0)}/>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
                    <Grid item xs={12} >
                        {isLoading &&
                            <QuickLoaderComponent loadBanner={"Generating Scenario"} />
                        }
                        <Box sx={{ p: 2.5, pb: 0 }}>
                            <MainCard content={false} sx={{ p: 1.25, bgcolor: 'primary.lighter', borderColor: theme.palette.primary[100] }}>
                                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <ToggleButtonGroup color="primary"
                                                               aria-label="Platform"
                                                               exclusive={true}
                                                               value={simMode}
                                                               size={"small"}
                                                               onChange={handleSimModeChange}
                                            >
                                        <ToggleButton size={"small"} disabled={true} value="auto">Auto</ToggleButton>
                                        <ToggleButton size={"small"} disabled={true}  value="guided">Guided</ToggleButton>
                                    </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <PlayPauseStopButtons handlePlay={handleChatStart} handlePause={handlePause} handleStop={handleStop}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                    {simulationId && simulationId!="abc" &&
                                    <Link  component={RouterLink} to={`/company/scenario/simulation/aar/${companyId}/${scenarioId}/${simulationId}`}>
                                        <Button size={"small"} variant={"outlined"}>
                                            <AssessmentIcon />  View AAR
                                        </Button>
                                    </Link>
                                    }
                                        </Grid>
                                        <Grid item xs={1}>
                                    <Link color="primary" component={RouterLink} to={`/company/scenario/view/${companyId}/${scenarioId}`}>
                                        <Button variant="outlined" size={"small"} startIcon={<ArrowBack />} >
                                            Scenario
                                        </Button>
                                    </Link>
                                        </Grid>
                                    </Grid>
                                    {/*<Link color="primary" component={RouterLink} to={`/company/viewcompany/${companyId}`}>*/}
                                    {/*    <Button variant="outlined" size={"small"} startIcon={<ArrowBack />} >*/}
                                    {/*        Back to Company*/}
                                    {/*    </Button>*/}
                                    {/*</Link>*/}
                                </Stack>
                            </MainCard>
                        </Box>
                    </Grid>
                {scenario &&
                    <>
                      <Grid container rowSpacing={3} columnSpacing={2}>
                          <Grid item xs={12}>
                              <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                      {/*<MainCard title="Simulation Screen" secondary={*/}
                                      {/*    <Stack direction="row" justifyContent="flex-end" spacing={1}>*/}
                                      {/*        <ToggleButtonGroup color="primary"*/}
                                      {/*            aria-label="Platform"*/}
                                      {/*            exclusive={true}*/}
                                      {/*            value={simMode}*/}
                                      {/*            size={"small"}*/}
                                      {/*            onChange={handleSimModeChange}*/}
                                      {/*        >*/}
                                      {/*            <ToggleButton size={"small"} disabled={true} value="auto">Auto</ToggleButton>*/}
                                      {/*            <ToggleButton size={"small"} disabled={true}  value="guided">Guided</ToggleButton>*/}
                                      {/*        </ToggleButtonGroup>*/}
                                      {/*        <PlayPauseStopButtons handlePlay={handleChatStart} handlePause={handlePause} handleStop={handleStop}/>*/}
                                      {/*    /!*<SimDifficultyChipComponent difficulty={difficultyLevel} label={"Difficulty:"}/>*!/*/}
                                      {/*    </Stack>*/}
                                      {/*} >*/}
                                          <Card>
                                              <CardContent>
                                                  <Typography color="secondary" >
                                                      { (scenario && chatId) ?
                                                          (<ScenarioSimulationChatComponent scenario={scenario} chatId={chatId}/>): (
                                                              <Card sx={{ minWidth: 275 }}>
                                                                  <CardContent>
                                                                      <HoverSocialCard primary={"Click the 'Start' button to begin your Scenario Simulation. The Simulator allows you issue commands and 'play' the scenario script prior to conducting a live tabletop."} secondary={"About Tabletop Simulations"} color={theme.palette.primary.dark} />
                                                                  </CardContent>
                                                              </Card>
                                                          )
                                                      }
                                                  </Typography>
                                              </CardContent>
                                          </Card>
                                      {/*</MainCard>*/}
                                  </Grid>
                                  <Grid item xs={4}>
                                      <Tabs value={tabSelectValue} onChange={handleTabSelectChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                                          <Tab label="Scenario Plan" {...a11yProps(0)} />
                                          <Tab label="Injects" {...a11yProps(1)} />
                                          <Tab label="Notes" {...a11yProps(2)} />
                                      </Tabs>
                                      <Grid container>
                                          <Grid item xs={12}>
                                              <TabPanel value={tabSelectValue} index={0} >
                                                  {scenario && <ScriptItemListComponent scriptItems={scenario.script} showSteps={true}/>}
                                              </TabPanel>

                                              <TabPanel value={tabSelectValue} index={1} >
                                                  {scenario && scenario.injects && <ScenarioInjectsTabletopComponent injects={scenario.injects} />}
                                              </TabPanel>

                                              <TabPanel value={tabSelectValue} index={2} >
                                                  {scenario && <ScenarioNotesTabletopComponent scenario={scenario} />}
                                              </TabPanel>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                  </>
                }
                </MainCard>
            </Grid>
        </Grid>
    );
};
export default ScenarioSimulatePage;
