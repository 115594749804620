import {Divider, Grid, IconButton, ListItemText} from "@mui/material";
import React from "react";
import {Scenario} from "../../interfaces/interfaces";
import {useTheme} from "@mui/material/styles";
import MainCard from "../MainCard";
import {SettingsOutlined} from "@mui/icons-material";


interface ScenarioDashboardReportCardProps {
    scenario:Scenario;
    showExerciseDates?:boolean;
    openSetupDialog?:()=>void;
}

const ScenarioDashboardReportCards: React.FC<ScenarioDashboardReportCardProps> = ({ scenario, showExerciseDates, openSetupDialog }) => {
    const theme = useTheme();

    // Parse the lastExercised date
    const lastExercised = new Date(scenario?.lastExercised);

// Calculate the next exercise date based on the frequency
    let nextExerciseDate = new Date(lastExercised);
    switch (scenario?.frequency) {
        case 'Monthly':
            lastExercised.setMonth(lastExercised.getMonth() + 1);
            nextExerciseDate = lastExercised;
            break;
        case 'Quarterly':
            lastExercised.setMonth(lastExercised.getMonth() + 3);
            nextExerciseDate = lastExercised;
            break;
        case 'Semi-annually':
            lastExercised.setMonth(lastExercised.getMonth() + 6);
            nextExerciseDate = lastExercised;
            break;
        case 'Yearly':
            lastExercised.setFullYear(lastExercised.getFullYear() + 1);
            nextExerciseDate = lastExercised;
            break;
        default:
            nextExerciseDate = lastExercised;
    }
    const nextExercise = nextExerciseDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    return (
        <Grid container spacing={1} >
            <Grid item xs={12}>
                <MainCard title="Data" content={true}  secondary={
                    openSetupDialog ? (
                        <IconButton onClick={openSetupDialog} size={"small"}>
                            <SettingsOutlined fontSize={"small"}/>
                        </IconButton>
                    ) : false
                }
                >
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <ListItemText secondary={(scenario.userAudit && scenario.userAudit.createdDate)?scenario.userAudit.createdDate:"---"} primary={"Created On"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ListItemText secondary={(scenario.userAudit && scenario.userAudit.modifiedDate)?scenario.userAudit.modifiedDate:"---"} primary={"Last Modified"}/>
                        </Grid>
                        {showExerciseDates &&
                           <>
                           <Grid item xs={12}>
                               <ListItemText secondary={(scenario.lastExercised && scenario.lastExercised)?scenario.lastExercised:"---"} primary={"Last Exercise"}/>
                           </Grid>
                           <Grid item xs={12}>
                               {scenario &&
                                   <ListItemText secondary={(nextExercise )?nextExercise:"---"} primary="Next Exercise" color={theme.palette.success.main} />
                               }
                           </Grid>
                           </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={(scenario.exerciseType && scenario.exerciseType)?scenario.exerciseType:"Tabletop"} primary="Type" color={theme.palette.success.main} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={(scenario.category && scenario.category)?scenario.category:"---"} primary="Category" color={theme.palette.success.main} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={(scenario.eventType && scenario.eventType)?scenario.eventType:"---"} primary="Event" color={theme.palette.success.main} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={scenario.frequency} primary="Frequency" color={theme.palette.success.main}  />
                            }
                        </Grid>

                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={(scenario.estimatedTime && scenario.estimatedTimeUnit)?scenario.estimatedTime+" "+scenario.estimatedTimeUnit:"---"} primary="Time" color={theme.palette.success.main} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={scenario.levelOfEffort} primary="Effort" color={theme.palette.success.main}  />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            {scenario &&
                                <ListItemText secondary={scenario.difficulty} primary="Difficulty" color={theme.palette.success.main} />
                            }
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
    </Grid>
    );
};
    export default ScenarioDashboardReportCards;
