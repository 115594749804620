import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import {Grid, IconButton, InputAdornment, OutlinedInput} from "@mui/material";
import {SendOutlined} from "@ant-design/icons";
import { GameMessage} from "../../interfaces/interfaces";
import SimulationChatHistory from "../scenario/SimulationChatHistory";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";

interface Props {
  onNewMessage?: (message: GameMessage) => void;
}
const InstructionalChatComponent : React.FC<Props> = ({ onNewMessage})=> {
  const [conversation, setConversation] = useState<GameMessage[]>([]);
  const [message, setMessage] = useState("");
  const bottomOfChatRef = useRef<HTMLDivElement>(null);
  const token = localStorage.getItem('token');
  const [assistantLoading, setAssistantLoading] = useState(false);
  const [assistantLabel, setAssistantLabel] = useState("Sending message to Assistant...");

  const handleReceiveMessage = (message: GameMessage) => {
    if (onNewMessage) {
      onNewMessage(message);
    }
  };

  useEffect(() => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);
  const sendMessage = async (e: any) => {
    e.preventDefault();
    setMessage("");
    setAssistantLoading(true);
    setAssistantLabel("Sending message to Assistant...");
    try {
      const callURL = process.env.REACT_APP_REST_API_URL+'/api/tabletop/assistant/instructionhelp/'+token;
      const response = await fetch(callURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: message }),
      });

      if (response.ok) {
        const data = await response.json();
        setAssistantLabel("Response received!");
        handleReceiveMessage(data);
        // Check if data is not null
        if (data) {
          // Add the returned GameMessage to the conversation array
          setConversation(prevConversation => [...prevConversation, data]);
        }
      } else {
        console.error(response);
      }

    } catch (error: any) {
      console.error(error);
    }
    setAssistantLoading(false);
  };

  const handleKeypress = (e: any) => {
    // It's triggers by pressing the enter key
    if (e.keyCode == 13 && !e.shiftKey) {
      sendMessage(e);
      e.preventDefault();
    }
  };
  return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column-reverse' }}>
        <Grid container={true} spacing={2}>
        <Grid item xs={12}>
          {assistantLoading && <QuickLoaderComponent loadBanner={assistantLabel} /> }
        </Grid>
          <Grid item xs={12}>
            <OutlinedInput
                id="outlined-controlled"
                fullWidth
                multiline={true}
                rows={4}
                placeholder={"Ask the Exercise Assistant questions, analysis and suggestions for the current exercise..."}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                onKeyDown={handleKeypress}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={sendMessage}
                        edge="end"
                    >
                      <SendOutlined />
                    </IconButton>
                  </InputAdornment>
                }
            />
          </Grid>
          <Grid item xs={12}>
            <SimulationChatHistory chat={conversation} />
          </Grid>
        </Grid>
      </Box>
  );
};

export default InstructionalChatComponent;
