import {Card, CardContent, Grid, List, ListItem, ListItemText, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {MessageAction} from "../../interfaces/interfaces";

interface CompanyTeamProps {
    actions: MessageAction[];
}
const ConductActionsList: React.FC<CompanyTeamProps> = ({ actions }) => {
    const [updatedTeam, setUpdatedTeam] = React.useState(actions);

    useEffect(() => {
        setUpdatedTeam(actions);
    }, [actions]);


    return (
        <List component="nav" aria-label="main mailbox folders" sx={{ py: 0, '& .MuiListItem-root': { p: 0, py: 1 } }}>
            {updatedTeam ? (updatedTeam.map((t:MessageAction) => {
                return (
                    <ListItem>
                        <ListItemText primary={`${t.messageAction}`} secondary={t.whoPerformedAction } />
                    </ListItem>
                );
            }) ) : (
                <Grid item xs={12}  sm={12} md={12} lg={12}>
                    <Card >
                        <CardContent>
                            <Typography variant="caption">No Team</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </List>

    );
};
export default ConductActionsList;