import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Typography,
    DialogActions,
    Button,
    Grid
} from '@mui/material';

interface BranchInjectDialogProps {
    branchDescription?: string;
    open: boolean;
    handleClose: () => void;
    createBranchFromInject: (name: string, description: string) => void;
}

const BranchOnInjectDialog: React.FC<BranchInjectDialogProps> = ({  createBranchFromInject, branchDescription, open, handleClose }) => {
    const [branchName, setBranchName] = useState('');
    const [branchDesc, setBranchDesc] = useState(branchDescription || '');
    function handleCreateBranch() {
       // console.log('Dialog : ', branchName, branchDescription);
        createBranchFromInject(branchName, branchDesc || '');
        handleClose();
    }

    useEffect(() => {
        setBranchDesc(branchDescription || '');
    }, [branchDescription]);

    return (
        <Dialog fullWidth={true} open={open} onClose={handleClose}>
            <DialogTitle>Create Branch</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create a new branch from the current tabletop step
                </DialogContentText>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Branch Name"
                            value={branchName}
                            onChange={(e) => setBranchName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Branch Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            fullWidth
                            rows={4}
                            value={branchDesc}
                            onChange={(e) => setBranchDesc(e.target.value)}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleCreateBranch}>Branch</Button>
                <Button variant={"contained"} onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BranchOnInjectDialog;