import React from 'react';
import {Button, ButtonGroup, List, ListItem, ListItemText, Stack, Tooltip, Typography} from '@mui/material';
import {AppUserProfile, Artifact} from "../interfaces/interfaces";
import {DeleteForeverOutlined, FileOpenOutlined, RefreshOutlined} from "@mui/icons-material";

interface ArtifactListProps {
  artifacts: Artifact[];
  userProfile?: AppUserProfile | null | undefined;
  onDownload?: (artifact: Artifact) => void;
    onRegenerate?: (artifact: Artifact) => void;
    onRemove?: (artifact: Artifact) => void;
}

const ArtifactList: React.FC<ArtifactListProps> = ({ artifacts, onDownload,
                                                     onRegenerate, onRemove, userProfile }) => {
  return (
    <List>
      {artifacts.map((artifact) => (
        <ListItem key={artifact.artifactId}>
          <ListItemText
            primary={artifact.name}
            secondary={
              <div>
                <Typography variant={"caption"}>{artifact.description}</Typography>
              </div>
            }
          />
          <Stack direction={"row"} spacing={1} justifyContent={"flex-end"}>
          <ButtonGroup size={"small"}>
          {onDownload &&
              <Tooltip title={"Open and view the artifact"} arrow>
              <Button onClick={() => onDownload(artifact)}><FileOpenOutlined/></Button>
              </Tooltip>
          }
          {onRegenerate && userProfile && userProfile.role != 'PortalViewParticipant' &&
              <Tooltip title={"Regenerate the artifact text"} arrow>
              <Button onClick={() => onRegenerate(artifact)}><RefreshOutlined/></Button>
              </Tooltip>
          }
            {onRemove && userProfile && userProfile.role != 'PortalViewParticipant' &&
                <Tooltip title={"Remove the artifact"} arrow>
                <Button onClick={() => onRemove(artifact)}><DeleteForeverOutlined/></Button>
                </Tooltip>
            }
          </ButtonGroup>
          </Stack>
        </ListItem>
      ))}
    </List>
  );
};

export default ArtifactList;