import {List, ListItem, Typography} from "@mui/material";
import React from "react";

interface ArrayListProps {
    arrayData: String[];
}
const ArrayListComponent: React.FC<ArrayListProps> = ({ arrayData }) => {

    return (
        <List>
            {arrayData.map((control) => (
                <ListItem >
                    <Typography variant="body2">{control}</Typography>
                </ListItem>
            ))}
        </List>
    );
}
export default ArrayListComponent;
