// material-ui
import {Theme} from '@mui/material/styles';
import {Box,  useMediaQuery} from '@mui/material';

// project import
// import Message from './Message';
import Profile from './Profile';
// import Notification from './Notification';
import FullScreen from './FullScreen';
import MobileSection from './MobileSection';

import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/Dashboard/Drawer/DrawerHeader';

// types
import { MenuOrientation } from 'types/config';
import Search from "../../../Portal/Header/HeaderContent/Search";
import Customization from "../../../Dashboard/Header/HeaderContent/Customization";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {/*<Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>*/}
      {/*  <FormControl sx={{ width: { xs: '100%', md: 224 } }}>*/}
      {/*    <Chip label={"Tabletop Portal"} color={"warning"} size={"small"} sx={{ bgcolor: theme.palette.warning.light }}/>        </FormControl>*/}
      {/*</Box>*/}
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}

      {/*<Notification />*/}
      {/*<Message />*/}
      {!downLG && <FullScreen />}
      <Customization />
      {!downLG && <Profile  />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
