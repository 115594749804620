import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { PortalIdContext } from './portalIdContext';

// material-ui
import { useTheme } from '@mui/material/styles';
import {useMediaQuery, Box, Container, Toolbar} from '@mui/material';

// project import
// import Drawer from './Drawer';
import Header from './Header';
// import Footer from './Footer';
// import HorizontalBar from './Drawer/HorizontalBar';
import Loader from 'components/Loader';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';
import AuthGuard from 'utils/route-guard/AuthGuard';

import useConfig from 'hooks/useConfig';
import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';

// types
import { MenuOrientation } from 'types/config';
// import ProfileCard from "./ProfileCard";

// ==============================|| MAIN LAYOUT ||============================== //
interface PortalLayoutProps {
    companyId: string|undefined;
    portalId: string | undefined;
}

const PortalLayout: React.FC<PortalLayoutProps> = ({ companyId, portalId }) => {
  const theme = useTheme();
  const { menuMasterLoading } = useGetMenuMaster();
  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  //fixes that weird resize overlay error
    useEffect(() => {
        // console.log("portal layout "+companyId+" "+portalId);
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);


    // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      handlerDrawerOpen(!matchDownXL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownXL]);

  if (menuMasterLoading) return <Loader />;

  return (
      <PortalIdContext.Provider value={portalId}>
      <AuthGuard>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />

        <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
          <Container
            maxWidth={!container ? 'xl' : false}
            sx={{
              ...(container && { px: { xs: 0, sm: 2, xl: 1 } }),
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/*<Breadcrumbs />*/}
            {/*  <ProfileCard />*/}
            <Outlet />
            {/*<Footer />*/}
          </Container>
        </Box>
      </Box>
    </AuthGuard>
          </PortalIdContext.Provider>
  );
};

export default PortalLayout;
