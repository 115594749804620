import {Card, CardContent, Typography} from "@mui/material";
import { Scenario} from "../../interfaces/interfaces";
import MainCard from "../MainCard";
import React from "react";


interface ScenarioInjectsTabletopComponentProps {
    scenario: Scenario;
}
const ScenarioNotesTabletopComponent: React.FC<ScenarioInjectsTabletopComponentProps> = ({ scenario }) => {


    return (
        <>
            <MainCard title="Notes">
                <Card>
                    <CardContent>
                        <Typography color="secondary" >
                            {scenario.notes}
                        </Typography>
                    </CardContent>
                </Card>
            </MainCard>

        </>
    );
};
export default ScenarioNotesTabletopComponent;