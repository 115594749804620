import { FC } from 'react';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { ScriptResource } from '../../interfaces/interfaces';

interface ResourceListProps {
  linkedResources: string[];
  resources: ScriptResource[];
  scriptId: string;
  onUnlinkResource?: (resourceId: string, scriptId: string) => void;
}

const ResourceList: FC<ResourceListProps> = ({ linkedResources, resources, scriptId, onUnlinkResource }) => {
  return (
    <List>
      {linkedResources && linkedResources.map((resourceId) => {
        const resource = resources.find((res) => res.resourceId === resourceId);
        return (
          <ListItem key={resourceId}>
            <ListItemText primary={resource?.resourceShortName} secondary={resource?.resourceName+" ("+resource?.resourceType+")"} />
            {onUnlinkResource && (
              <ListItemSecondaryAction>
                <Button onClick={() => onUnlinkResource(resourceId, scriptId || '')}>Delete</Button>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default ResourceList;