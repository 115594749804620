// material-ui
import { Typography} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import {useEffect, useState} from "react";
import {Project} from "interfaces/interfaces";
// ==============================|| SAMPLE PAGE ||============================== //

const SamplePage = () => {
const [projects, setProjects] = useState<Project[]>([]);
const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/project/getprojects/'+token);
                const data = await response.json();
                setProjects(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);
    return (
      <MainCard title="Knowledge Base">
        <Typography variant="body2">
            {projects.map((project) => (
                <Typography variant="body2">
                    {project.companyName}
                </Typography>
            ))}
        </Typography>

      </MainCard>
    );
};
export default SamplePage;
